import "../../CSS/Reusable/DataInput.scss";
import {useEffect, useState} from "react";
import {devLog, setJsonFromDotedValue} from "../../globalHelper"


export default function DataInput({
                                      columnSpan,
                                      bigInput,
                                      placeHolder,
                                      type,
                                      onChange,
                                      tittle,
                                      ChangeState,
                                      targetName,
                                      defaultValue,
                                      targetIndex,
                                      onlyReadable,
                                      className,
                                      containerClassName,
                                      name,
                                      realTimeRefresh,
                                      currentSetState,
                                      currentState
                                  }) {

    const getColSpan = () => {
        switch (columnSpan) {
            case 1: {
                return "md:col-span-1 ";
            }
            case 2: {
                return "md:col-span-2 ";
            }
            case 3: {
                return "md:col-span-3 ";
            }
            default: {
                return ""
            }
        }
    }

    const [data, setData] = useState("");

    let classNameDataInput = "";

    classNameDataInput = classNameDataInput.concat(
        " flex flex-row " 
        ,getColSpan()
        ,containerClassName?containerClassName:''
    );

    useEffect(() => {
        if (defaultValue) {
            setData(defaultValue)
        }
    }, [defaultValue])

    const getClasses = () => {
        return "dataInput "
            .concat(bigInput ? " bigInput " : "")
            .concat(onlyReadable ? " onlyReadable " : "")
            .concat(className);
    }

    const eventHandler = (data) => {
        if (realTimeRefresh) {
            ChangeState(data,name,currentSetState,currentState)
            setData(data)
        }
        else{
            if (data < 0 && type === 'Number') {
                alert('Solo se aceptan valores mayores que 0')
            } else {
                if (ChangeState) {
                    setData(data)
                    if (type === "Number") {
                        ChangeState(setJsonFromDotedValue({}, targetName, Number(data)), targetIndex)
                    } else {
                        ChangeState(setJsonFromDotedValue({}, targetName, data), targetIndex)
                    }
                } else {
                    setData(data)
                }
            }
        }
    }
    const inputCheckNumber = (e) => {
        const value = e.target.value;
        if ((isNaN(value) || value === "") && type === 'Number') {
            devLog(true, 'Solo se aceptan números');
            setData(0);
        } else {
            setData(value); 
        }
    };

    const getComponent = () => {
        if (bigInput)
            return <textarea name={name} placeholder={placeHolder} className={getClasses()}
                             onChange={(e) => eventHandler(e.target.value)} value={data}
                             onWheel={(e) => e.target.blur()}/>

        return (<input name={name} type={type} min="1" pattern="^[0-9]+" placeholder={placeHolder} className={getClasses()}
                       onChange={(e) => eventHandler(e.target.value)}
                       onInput={inputCheckNumber}
                       onWheel={(e) => e.target.blur()}
                       value={data}/>)

    }

    return (
        <div className={containerClassName}>
            {tittle ? <h3 className="text-grey-2 w-max">{tittle}</h3> : null}
            {getComponent()}
        </div>
    )
}