import React, { ReactNode } from 'react';

export default function CommissionTitle({children, className}) {

    let commissionTitleClasses='';
    commissionTitleClasses = commissionTitleClasses.concat(
        " text-comision-type-titles-mobile lg:text-comision-type-titles-desktop text-grey-1 mt-auto mb-2 "
        ,className?className:''
    );

    return (
        <h2 className={commissionTitleClasses}>
            {children}
        </h2>
    )
}