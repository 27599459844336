import React from 'react';

function PropertyInfoWrapper({children, classname}) {

  const propertyInfoWrapperClassname =''.concat(
    'flex flex-col rounded-[20px] py-8 px-4 shadow-preperty-info-wrapper '
    ,classname
  );

  return (
    <div className={propertyInfoWrapperClassname}>
        {children}
    </div>
  );
}

export default PropertyInfoWrapper;