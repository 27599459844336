import "../../CSS/GeneralStructure/NavBarMobile.css";
import {useState, useEffect} from "react";
import {fetchSideBarRoutes} from "../../Services/MainViews/Login/userRouteComponentService";
import {NavLink, Outlet} from "react-router-dom";
import {logout} from "../../Services/MainViews/Login/authLoginService";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

localStorage.setItem("userType", "User");

export default function MobileNavbar() {
    const [sideBarData, setSideBarData] = useState();
    const[mobileMenu, setMobileMenu] = useState(false);

    let menu;
    let mobileIcon;

    useEffect(() => {
        fetchSideBarRoutes().then((res) => {
            setSideBarData(res);
        });
    }, []);

    const ShowMobileMenu =()=>{
        setMobileMenu(!mobileMenu);
    }

    if(mobileMenu){
        menu    =   
        <div    className='side-navbar'>
            <ul>
                {sideBarData?.map((elem) => {
                        return <li className='navbar-link'><NavLink to={elem.url}>{elem.title}</NavLink></li>;
                    })}
                <li className='navbar-link'><NavLink to="/" onClick={logout}>Salir</NavLink></li>
            </ul>
        </div>
        mobileIcon  =   <MenuOpenIcon fontSize="large"/>;
    }
    else{mobileIcon= <MenuIcon fontSize="large"/>}
    
    return (
        <div className="navbar">
            <div className="wrap-navbar">
                <button  className='mobile-menu' onClick={ShowMobileMenu}>{mobileIcon}</button>
            </div>
            {menu}
        </div>
    );
}