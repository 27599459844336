import ReservationRecords from "./ReservationRecords";
import SearchesRecords from "./SearchesRecords";

export default function RecordsScreen() {

    return(
      <>
        <ReservationRecords/>
        <SearchesRecords/>
      </>
    )
  }