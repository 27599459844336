import React from 'react';

export default function Grid({columns, children, containerType}) {

   /* const ColumnsWidthContext = React.createContext({});
    const ColumnsWidthProvider = ColumnsWidthContext.Provider
*/
    let gridContainerType;
    if (containerType) {
        gridContainerType = containerType;
    }
    else{
        gridContainerType = '';
    }
    const getColumnsClassNames = () => {
        let grid = "grid grid-cols-1 md:gap-2 mt-4 "+gridContainerType+" "
        switch (columns) {
            case "1": {
                return grid.concat("md:grid-cols-1");
            }
            case "2": {
                return grid.concat("md:grid-cols-2");
            }
            case "3": {
                return grid.concat("md:grid-cols-3");
            }case "4": {
                return grid.concat("md:grid-cols-4");
            }
            default: {
                return grid;
            }
        }
    }

    return (
        /*<ColumnsWidthProvider value={{
            columnsWidthElement1: props.columnsWidthElement1,
            columns: props.columns
        }
        }>*/
            <div className={getColumnsClassNames()}>
                {children}
            </div>
/*
        </ColumnsWidthProvider>
*/
    )
}