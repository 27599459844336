import {
    getCommissions,
    collectedController,
    accountedController,
    updatePartialCollectedAmount
} from "../../../Services/MainViews/Home/Home";
import "../../../CSS/MainViews/Commissions.scss";
import {useEffect, useState} from "react";
import ButtonBlue from "../../Reusable/ButtonBlue";
import ButtonStandard from "../../Reusable/ButtonStandard";
import DataInput from "../../Reusable/DataInput"
import Grid from "../../Reusable/Grid";
import DataContainerCard from "../../Reusable/DataContainerCard";
import CircularLoader from "../../Reusable/CircularLoader";
import DataTable from "../../Reusable/DataTable/DataTable";
import ListLinks from "../../Reusable/ListLinks";
import {mapCurrencyName} from "../../../dataMappingUtilHelper";
import {existAndReturn, formatedDate, formatNumberWithDots} from "../../../globalHelper"

export default function Commissions() {
    const [actualCommissionType, setActualCommissionType] = useState('BROKER');
    const [commissionsPageable, setCommissionsPageable] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [changeState, setChangeState] = useState(false);

    const setCommissions = async (types) => {
        setIsLoading(true);
        let commissionType = types;
        const res = await getCommissions(commissionType);
        if (res && res !==null && res !==undefined && res.status !== 500) {
            res.map((resCommission) => {
                if (resCommission?.commissionOwner?.currentPayment?.isAccounted === true && 
                    resCommission?.commissionOwner?.currentPayment?.isCollected === true
                ) {
                    resCommission.status = "Finalizado";
                }
                if (resCommission?.commissionOwner?.currentPayment?.isAccounted === false && 
                    resCommission?.commissionOwner?.currentPayment?.isCollected === true
                ) {
                    resCommission.status = "Rendir";
                }
                if (resCommission?.commissionOwner?.currentPayment?.isAccounted === true && 
                    resCommission?.commissionOwner?.currentPayment?.isCollected === false
                ) {
                    resCommission.status = "Cobrar";
                }
                if (resCommission?.commissionOwner?.currentPayment?.isAccounted === false && 
                    resCommission?.commissionOwner?.currentPayment?.isCollected === false
                ) {
                    resCommission.status = "";
                }
                if (resCommission?.commissionOwner?.currentPayment?.isAccounted) {
                    resCommission.commissionOwner.currentPayment.partialCollectedAmount = "";
                }
                resCommission.longText = <div className="longText-container">
                    <div className='long'>{resCommission.commission && resCommission.commissionOwner.currentPayment.observations ? resCommission.commissionOwner.currentPayment.observations : undefined}</div>
                    <div className='tooltip'>{resCommission.commission && resCommission.commissionOwner.currentPayment.observations ? resCommission.commissionOwner.currentPayment.observations : undefined}</div>
                </div>;

                if (resCommission?.commissionOwner?.currentPayment?.expectedPaymentDate)
                    resCommission.commissionOwner.currentPayment.expectedPaymentDate =  formatedDate(resCommission.commissionOwner.currentPayment.expectedPaymentDate);

                if (resCommission.commission)
                    resCommission.commission.amountAndCurrency = '' +mapCurrencyName(resCommission.commission.calculatedCommissionAmount.currency)  + '' +formatNumberWithDots(resCommission.commission.calculatedCommissionAmount.value);

                if(resCommission?.commissionOwner?.currentPayment){
                    resCommission.commissionOwner.currentPayment.updateInputMax =
                        <div className="maxCommission"><ButtonStandard showName="MAX"
                                                                       onClick={(e) => sendCommissionUpdate(resCommission.commissionOwner.currentPayment.id)}
                                                                       blueColor/></div>
                    resCommission.commissionOwner.currentPayment.updateInput =
                        <div>
                            <DataInput placeHolder="" type="Number"
                                       ChangeState={(e) => onInputChange(e, resCommission.commission.calculatedCommissionAmount.value)}
                                       targetName={(resCommission.commissionOwner.currentPayment.id.toString())}
                                       defaultValue={existAndReturn(resCommission, "commissionOwner.currentPayment.partialCollectedAmount")}/>
                            <div className="maxCommission"><ButtonStandard showName="MAX"
                                                                           onClick={(e) => sendCommissionUpdate(resCommission.commissionOwner.currentPayment.id, resCommission.commission.calculatedCommissionAmount.value)}
                                                                           blueColor shortButton/></div>
                        </div>

                    resCommission.commissionOwner.currentPayment.isAccountedCheckBox =
                        <input type="checkbox" id={resCommission.commissionOwner.currentPayment.id} value="isAccounted"
                               checked={resCommission.commissionOwner.currentPayment.isAccounted} onChange={e => OnChangeCheckBox(e)}/>
                    resCommission.commissionOwner.currentPayment.isCollectedCheckBox =
                        <input type="checkbox" id={resCommission.commissionOwner.currentPayment.id} value="isCollected"
                               checked={resCommission.commissionOwner.currentPayment.isCollected} onChange={e => OnChangeCheckBox(e)}/>


                    if (resCommission.commissionOwner.owner)
                        resCommission.commissionOwner.owner.fullname = (existAndReturn(resCommission, "commissionOwner.owner.name") + ' ' + existAndReturn(resCommission, "commissionOwner.owner.surname")).replace("null", '');

                }



                if (resCommission.commission?.referredOperation?.referred)
                    resCommission.commission.referredOperation.referred.fullname = (existAndReturn(resCommission, "commission.referredOperation.referred.name") + ' ' + existAndReturn(resCommission, "commission.referredOperation.referred.surname")).replace("null", '');
            })
        }
        setCommissionsPageable(res);
        setIsLoading(false);
    }

    useEffect(() => {
        setCommissions(actualCommissionType);
    }, [changeState])

    const OnChangeCheckBox = (e) => {
        let paymentValue = e.target.checked;
        let paymentLabel = e.target.value;
        let paymentId = Number(e.target.id);

        if (paymentLabel === "isCollected") {
            Promise.resolve(collectedController(paymentId, paymentValue)).then((res) => {
                if (res.message) {
                    alert(res.message)
                    setIsSending(false)
                } else {
                    setChangeState(!changeState);
                }

            })
        }
        if (paymentLabel === "isAccounted") {
            Promise.resolve(accountedController(paymentId, paymentValue)).then((res) => {
                if (res.message) {
                    alert(res.message)
                    setIsSending(false)
                } else {
                    setChangeState(!changeState);
                }

            })
        }

    }

    const sendCommissionUpdate = (e, fullCollected) => {
        let value = "true";
        let id = e;
        accountedController(id, value);
        updatePartialCollectedAmount(id, fullCollected)
        setChangeState(!changeState);
    }

    const onInputChange = (formData, maxValue) => {
        let idCommission = Object.keys(formData)[0];
        let commissionPartialValue = Object.values(formData)[0];

        if (commissionPartialValue > maxValue) {
            alert('No puedes ingresar un monto mayor al maximo a abonar')
            setChangeState(!changeState);
        }
        if (commissionPartialValue === maxValue) {
            sendCommissionUpdate(idCommission, maxValue);
        }
        if (commissionPartialValue < maxValue) {
            updatePartialCollectedAmount(idCommission, commissionPartialValue)
        }
    }


    const dataTableColumns = [
        {field: 'status', showName: 'Estado'},
        {field: "commissionOwner.currentPayment.updateInput", showName: 'Rendido'},
        {field: 'commissionOwner.currentPayment.expectedPaymentDate', showName: 'Fecha'},
        {field: 'address.street', showName: 'Direccion'},
        {field: 'commission.amountAndCurrency', showName: 'Monto'},
        {field: 'commissionOwner.currentPayment.isCollectedCheckBox', showName: 'Cobrado'},
        {field: 'commissionOwner.owner.fullname', showName: 'Broker'},
        {field: 'commissionOwner.currentPayment.isAccountedCheckBox', showName: 'Rendido'},
        {field: 'commissionOwner.currentPayment.observations', showName: 'Observaciones'}
    ];

    const dataTableColumnsReferral = [
        {field: 'status', showName: 'Estado'},
        {field: "commissionOwner.currentPayment.updateInput", showName: 'Rendido'},
        {field: 'commissionOwner.currentPayment.expectedPaymentDate', showName: 'Fecha'},
        {field: 'address.street', showName: 'Direccion'},
        {field: 'commission.amountAndCurrency', showName: 'Monto'},
        {field: 'commissionOwner.currentPayment.isAccountedCheckBox', showName: 'Rendido'},
        {field: 'commission.referredOperation.referred.fullname', showName: 'Referido'}, // Referente
        {field: 'commissionOwner.currentPayment.observations', showName: 'Observaciones'}
    ];

    const dataLinks = [
        {showName: "Broker", value: "BROKER"},
        {showName: "Agente", value: "RUNNER"},
        {showName: "B", value: "BAREDES"},
        {showName: "Referentes", value: "REFERRAL"}
    ];

    const changeBrokerType = (data) => {
        setCommissions(data);
        setActualCommissionType(data)
    }

    return (
        <>
            <DataContainerCard tittle="Comisiones">
                <ListLinks childrens={dataLinks} clickEvent={changeBrokerType}
                           actualCommissionType={actualCommissionType}/>
                {isLoading ?
                    <CircularLoader/> :
                    <Grid columns="1">
                        <div className="dataHomeCard">{commissionsPageable ?
                            <DataTable rows={commissionsPageable} columns={actualCommissionType === "REFERRAL" ? dataTableColumnsReferral : dataTableColumns}/> :
                            null
                        }
                        </div>
                    </Grid>}
            </DataContainerCard>
        </>
    )
}