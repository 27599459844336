import React from 'react';

function PropertyInfoWrapperTitle({children}) {

  return (
    <h3 className='text-[1.5rem] lg:text-[2.188rem] font-bold text-[#5F585A]'>
        {children}
    </h3>
  );
}

export default PropertyInfoWrapperTitle;