import "../../../CSS/MainViews/Contacts.scss";
import {useNavigate} from "react-router-dom";
import Grid from "../../Reusable/Grid";
import DataContainerCard from "../../Reusable/DataContainerCard";
import CircularLoader from "../../Reusable/CircularLoader";
import DataTable from "../../Reusable/DataTable/DataTable";
import DataInput from "../../Reusable/DataInput";
import DataSelectBox from "../../Reusable/DataSelectBox";
import DoubleConfirmation from "../../Reusable/DoubleConfirmation";
import {fetchSearches, deleteSearch} from "../../../Services/MainViews/Properties/searchesService";
import {mapCurrencyName, mapOperationType2, mapPropertyType} from "../../../dataMappingUtilHelper"
import {contractOperationsEntriesFilter} from "../../../dataEntriesHelper"
import {formatedDate, existAndReturn, formatNumberWithDots} from "../../../globalHelper"
import {useEffect, useState} from "react";
import {logDOM} from "@testing-library/react";


export default function SearchesRecords() {

    const [keyword, setKeyword] = useState('');
    const [currentSelectedSearchID, setCurrentSelectedSearchID] = useState('');
    const [showSection, setShowSection] = useState(false);
    const [responseModal, setResponseModal] = useState(false);
    const [showModal, setShowModal] = useState({show: false});
    const [isLoading, setIsLoading] = useState(false);
    const [operationType, setOperationType] = useState('');
    const [currentSearches, setCurrentSearches] = useState([]);
    const navigate = useNavigate();

    let arrowSection;
    let searchesList;

    const getSearches = async () => {
        setIsLoading(true);
        const searches = await fetchSearches();
        if (searches && searches !==null && searches !==undefined && searches.status !== 500) {
            searches.map(search => {
                console.log(search);
                
                search.date = formatedDate(existAndReturn(search, "date"));
                search.operationType = mapOperationType2(existAndReturn(search, "operationType"));
                search.propertyType = mapPropertyType(existAndReturn(search, "propertyType"));
                search.searchCompleteMin = mapCurrencyName(existAndReturn(search, "minimumValue.currency"))+"" + formatNumberWithDots(existAndReturn(search, "minimumValue.value"));
                search.searchCompleteMax = mapCurrencyName(existAndReturn(search, "maximumValue.currency"))+ formatNumberWithDots(existAndReturn(search, "maximumValue.value")) ;
                if (search.applicant)
                    search.applicant.fullname = existAndReturn(search, "applicant.name") + ' ' + existAndReturn(search, "applicant.surname");
                if (search.manager)
                    search.manager.fullname = existAndReturn(search, "manager.name") + ' ' + existAndReturn(search, "manager.surname");
            })
            setCurrentSearches(searches);
            setIsLoading(false);
        }
        else{
            console.log('Error fetching searches or no searches available');
            setCurrentSearches([]);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getSearches();
        const interval = setInterval(() => {
            getSearches()
        }, 30 * 10000);


        return () => clearInterval(interval)
    }, [])

    const keywordChange = (jsonData) => {
        let keywordValue = Object.values(jsonData)[0];
        setKeyword(keywordValue);
    }
    const userTypeChange = (jsonData) => {
        let operationTypeValue = Object.values(jsonData)[0];
        if (operationTypeValue !== '') {
            setOperationType(mapOperationType2(operationTypeValue));
        }
        if (operationTypeValue === '') {
            setOperationType(operationTypeValue);
        }

    }

    const dataTableColumns = [
        {field: 'date', showName: 'Fecha'},
        {field: 'operationType', showName: 'Tipo de operacion'},
        {field: 'propertyType', showName: 'Tipo de propiedad'},
        {field: 'searchCompleteMin', showName: 'Precio minimo'},
        {field: 'searchCompleteMax', showName: 'Precio maximo'},
        {field: 'applicant.fullname', showName: 'Solicitante'},
        {field: 'manager.fullname', showName: 'Gestion'}
    ];

    const filteredSearches = currentSearches.filter(x => x.applicant.name).filter(x => x.applicant.name.includes(keyword)).filter((item) => {
        if (operationType !== '') {
            return (item.operationType.indexOf(operationType) >= 0);
        }
        if (operationType === '') {
            return (item);
        }
    });

    const actions = [
        {onClick: (e) => edSearch(e), showName: 'Editar'},
        {onClick: (e) => delSearch(e), showName: 'Eliminar'}
    ];

    const edSearch = (searchId) => {
        navigate("/searches/edit/" + searchId);
    }

    const delSearch = (contactId) => {
        setShowModal({show: true});
        setCurrentSelectedSearchID(contactId);
    }

    const finallyDelSearch = (contactId) => {
        Promise.resolve(deleteSearch(contactId)).then((res) => {
            if (res.message) {
                alert(res.message)
            } else {
                getSearches();
            }

        })
    }

    if (showSection === true) {
        searchesList =

            <div style={{'display': 'grid', "grid-gap": "2.5rem"}}>
                <div style={{
                    'display': 'flex',
                    'justifyContent': 'space-between',
                    'width': '100%',
                    "alignItems": "center"
                }}>
                    <div style={{'display': 'flex', "alignItems": "start"}}>
                        <DataInput tittle="Nombre del solicitante" type="Text" ChangeState={keywordChange}
                                   targetName={"keyword"}
                                   defaultValue={''} float="left"/>
                        <DataSelectBox tittle="Tipo contrato" entries={contractOperationsEntriesFilter}
                                       ChangeState={userTypeChange}
                                       targetName={"userType"}
                                       defaultValue={''}/>
                    </div>
                </div>
                {isLoading ?
                    <CircularLoader/> :
                    <div style={{'maxHeight': '29rem', 'overflow-y': 'scroll'}}>
                        <Grid columns="1">
                            {filteredSearches ? <DataTable rows={filteredSearches} columns={dataTableColumns}
                                                           actions={actions}/> : null}
                        </Grid>
                    </div>
                } </div>
        arrowSection = '▴'
    } else {
        searchesList = ''
        arrowSection = '▾'
    }

    return (
        <>
            <DataContainerCard>
                {<DoubleConfirmation modalState={showModal}
                                     children={'Si la busqueda es eliminada, no podra recuperarse. Deberas crearla nuevamente'}
                                     className="ContractModalForm"
                                     modalSubmit={finallyDelSearch} responseModal={responseModal}
                                     title={'¡CUIDADO ESTA POR ELIMINAR UNA BUSQUEDA!'}
                                     currentSelectedID={currentSelectedSearchID}/>}
                <div style={{'display': 'flex', "justify-content": "space-between", 'margin': '1rem 0px'}}>
                    <h1>Historial de busquedas</h1>
                    <button onClick={() => {
                        setShowSection(!showSection)
                    }} style={{'fontSize': '2rem'}}>{arrowSection}</button>
                </div>
                {
                    searchesList
                }
            </DataContainerCard>
        </>
    );
}
