import ContractFormStep1 from "./ContractFormStep1"
import Grid from "../Grid"
import ButtonStandard from "../ButtonStandard";
import {useNavigate, useParams} from "react-router-dom";
import BackgroundContainer from "../../MainViews/BackgroudContainer"
import {useEffect, useState} from "react";
import ContractFormStep2 from "./ContractFormStep2";
import DataContainerCard from "../DataContainerCard";
import {Step, StepLabel, Stepper} from "@mui/material";
import CircularLoader from "../CircularLoader";
import MyModal from "../MyModal";
import {
    createFinalContract,
    getContract,
    updateFinalContract
} from "../../../Services/MainViews/Contracts/contractsService";
import {getUser} from "../../../Services/MainViews/Users/contactsService";
import {existAndReturn, unionJsonValue, monthsBetween, calculateTotalRent, formatedDateToSend} from "../../../globalHelper"
import {checkRequiredInputs} from "../../../errorValidationHelper"
import {dataAssignContractServices} from "../../../dataAssignHelper"
import {getProperty} from "../../../Services/MainViews/Properties/propertiesService";
import {updateReferredOperation,fetchReferredOperationsByReferredUserId} from "../../../Services/referredOperationService";


export default function ContractForm() {

    let params = useParams();

    const [isLoading, setIsLoading] = useState(false);

    const style = {width: '100%'};

    const navigate = useNavigate();

    const [isSending, setIsSending] = useState(false);
    const [adjustment, setAdjustment] = useState(0);
    const [baredesCommissionPercent, setBaredesCommissionPercent] = useState(100);
    const [catchmentCommissionPercentEditableValue, setCatchmentCommissionPercentEditableValue] = useState({
        currency: '',
        value:0
    });
    const [keyCommissionPercentEditableValue, setKeyCommissionPercentEditableValue] = useState({
        currency: '',
        value:0
    });
    const [contractForm, setContractForm] = useState({
        operationType:"RENTAL",
        paymentSchedule:{
            firstPaymentStartDate:new Date(),
            lastPaymentEndDate:new Date()
        },
        ownersEquity:[
            {
                user:{},
                equity:0
            }
        ],
        observations:[]
    });

    const [commissionData, setCommissionData] = useState({
        catchmentCommission:{
            totalPercent:0,
            owners: [
            {}
        ],},
        salesCommission:{
            totalPercent:0,
            runner:{
                percent:0,
                currency:'PESOS'
            },
            broker:{
                percent:0,
                currency:'PESOS'
            }
        },
        contractCommission:{
            amount:{
                value:0,
                currency:'PESOS'
            }
        },
        keyCommission:{
            amount:{
                value:0,
                currency:'PESOS'
            }
        },
        
    });

    const [editing, setEditing] = useState(false);

    const validateForm  =()=>{
        //currencys
        const commissionCurrency = existAndReturn(contractForm, "commission.currency");
        const baredesCurrency = existAndReturn(contractForm, "baredesCommission.payment.currency");
        const runnerCurrency = existAndReturn(contractForm, "runnerCommission.payment.currency");
        const brokerCurrency = existAndReturn(contractForm, "brokerCommission.payment.currency");

        //amount
        const commissionAmount = existAndReturn(contractForm, "commission.value");
        const baredesAmount = existAndReturn(contractForm, "baredesCommission.payment.amount");
        const runnerAmount = existAndReturn(contractForm, "runnerCommission.payment.amount");
        const brokerAmount = existAndReturn(contractForm, "brokerCommission.payment.amount");

        //Valida que las comisiones hijas esten en porcentaje si la comision neta lo esta
        const  validatePercentCurrency =()=>{
            if (commissionCurrency === 'PERCENTAGE' && ((baredesCurrency !== 'PERCENTAGE' && baredesCurrency !== null)  || (runnerCurrency !== 'PERCENTAGE' && runnerCurrency !== null) || (brokerCurrency !== 'PERCENTAGE' && brokerCurrency !== null))) {
                alert('Si la Comisión neta se encuentra en porcentaje, las comisiones dependientes deben estarlo tambien')
                return false
            }
            else{
                return true
            }
        }
        //Valida que la suma de las comisiones hijas den el 100%(cuando la comision neta no es %)
        const validateHundredPercent =  ()=>{
            if (commissionCurrency !== 'PERCENTAGE' && (baredesCurrency === 'PERCENTAGE'  || runnerCurrency === 'PERCENTAGE' || brokerCurrency === 'PERCENTAGE')) {
                if (baredesAmount+runnerAmount+brokerAmount !==100 ) {
                    alert('La suma de las comision en porcentaje, debe dar el 100%')
                    return false
                }
                else{
                    return true
                }
            }
            else{
                return true;
            }
        }
        //Valida que al estar todas las comisiones en % no se debe superar a la comision neta
        const validateAllCommissionsOnPercent =()=>{
        if (commissionCurrency === 'PERCENTAGE' && (baredesCurrency === 'PERCENTAGE'  || runnerCurrency === 'PERCENTAGE' || brokerCurrency === 'PERCENTAGE')) {
            if (
                baredesAmount+
                runnerAmount+
                brokerAmount>
                commissionAmount) {
                alert('La suma de las comisiones no debe superar la Comisión neta');
                return false
            }
            else{
                return true
            }
            }
            else{
                return true
            }
        }
        //Valida nombre de broker y runners
        const  validateRunnerName =()=>{
            let runnerCurrency = existAndReturn(contractForm, "runnerCommission.payment.currency");
            let runnerAmount = existAndReturn(contractForm, "runnerCommission.payment.amount");
            let runnerName = existAndReturn(contractForm, "runnerCommission.user");

            if ((runnerCurrency !== null || runnerAmount !== null) && runnerName === null) {
                alert('No se puede crear una comision Runner sin su correspondiente nombre');
                return false
            }
            else{
                return true
            }

        }
        const  validateBrokerName =()=>{
            let brokerCurrency = existAndReturn(contractForm, "brokerCommission.payment.currency");
            let brokerAmount = existAndReturn(contractForm, "brokerCommission.payment.amount");
            let brokerName = existAndReturn(contractForm, "brokerCommission.user");

            if ((brokerCurrency !== null || brokerAmount !== null) && brokerName === null) {
                alert('No se puede crear una comision Broker sin su correspondiente nombre');
                return false
            }
            else{
                return true
            }
        }
        return {
            validatePercentCurrency:validatePercentCurrency,
            validateHundredPercent:validateHundredPercent,
            validateAllCommissionsOnPercent:validateAllCommissionsOnPercent,
            validateRunnerName:validateRunnerName,
            validateBrokerName:validateBrokerName

        };
    }

    const validateFN = validateForm();

    function dateIsValid(date) {
        return !Number.isNaN(new Date(date).getTime());
      }

    const getSingleContract = async () => {
        setIsLoading(true);
        if(params.id){
            const res = await getContract(params.id)
            if (!dateIsValid(res.paymentSchedule.lastPaymentEndDate)) {
                res.paymentSchedule.lastPaymentEndDate = new Date(res.paymentSchedule.firstPaymentStartDate );
            }
            /*Setting render values*/
            res.internalContractNumber = res.contractFinal.internalContractNumber;
            res.showOwnerServices   =  dataAssignContractServices(res.contractFinal.ownerServices);
            res.showTenantServices  =   dataAssignContractServices(res.contractFinal.tenantServices);
            res.ownersEquity   =  res.contractFinal.ownersEquity;
            res.secondPartsEquity  =   res.contractFinal.secondPartsEquity;
            res.paymentSchedule.initialAmount = {value:res.paymentSchedule.initialAmount.value,currency:res.paymentSchedule.initialAmount.currency}
            /*delete res.property.propertyOperations;*/
            delete res.paymentSchedule.id;

            if (res.commissionContainer?.contractCommission?.roundedAmount) {
                const contractCommissionRoundedAmount = {
                        currency: res.commissionContainer.contractCommission.roundedAmount.currency,
                        value: res.commissionContainer.contractCommission.roundedAmount.value
                }
                setCatchmentCommissionPercentEditableValue(contractCommissionRoundedAmount)  
            }
            
            if (res.paymentSchedule && res.paymentSchedule?.payments) {
                res.payments = res.paymentSchedule?.payments;
            }
            delete res.paymentSchedule.payments;
            if (res.operationType === "BUY") {
                res.paymentSchedule.increaseEveryXMonths =0;
                res.paymentSchedule.description="";
                res.paymentSchedule.increaseEveryXMonthsPercentage=0;
                res.paymentSchedule.lastPaymentEndDate = new Date();
            }

            const userInfosCatchmen = res.commissionContainer?.catchmentCommission?.commissionOwners.map(user => ({
                ...user,
                }));
                const userInfosSales = res.commissionContainer?.salesCommission?.commissionOwners.map(user => ({
                ...user,
                }));

            const getUserComissions = (userInfos,commission)=>{
                const newCommissionData = {
                        owners: [],
                        referrer: { commissionPercent: 0 },
                        runner: { commissionPercent: 0 },
                        baredes: { commissionPercent: 0 },
                        broker:{ commissionPercent: 0 }
                    };
                    newCommissionData.totalPercent = res.commissionContainer[commission].totalAmountOfOwners?.value

                    
                    userInfos.forEach((result, index) => {
                            const userType = userInfos[index].commissionType;
                            const combinedUserData = result;
                            if (userType === 'OWNER') {
                            newCommissionData.owners.push(combinedUserData);
                            } else if (userType === 'REFERRAL') {
                            newCommissionData.referrer = combinedUserData;
                            } else if (userType === 'RUNNER') {
                            newCommissionData.runner = combinedUserData;
                            } else if (userType === 'BAREDES') {
                            newCommissionData.baredes = combinedUserData;
                            }
                            else if (userType === 'BROKER') {
                                newCommissionData.broker = combinedUserData;
                            }
                            
                    });
                return newCommissionData
            }
            let newSales ={}
            if (userInfosCatchmen !== undefined && userInfosSales !== undefined) {
                newSales = getUserComissions(userInfosSales,'salesCommission');
            }
            
            setCommissionData({
                contractCommission:res.commissionContainer.contractCommission,
                keyCommission:res.commissionContainer.keyCommission,
                catchmentCommission:getUserComissions(userInfosCatchmen,'catchmentCommission'),
                salesCommission:newSales
            })
            res.internalContractNumber = res.contractFinal.internalContractNumber
            setContractForm(res)
            setEditing(true);
            setIsLoading(false);
        }
      };

    useEffect(() => {
        getSingleContract();
      }, [params.id]);

      /*Actualizo comision baredes con y dependiendo broke y Agente*/
      useEffect(() => {
        let firstNumber = parseInt(commissionData.salesCommission.runner?.percentageCurrentOperation)
        let secondNumber = parseInt(commissionData.salesCommission.broker.percentageCurrentOperation)
        let totalBaredesPercent = 100-((firstNumber>0?firstNumber:0) + (secondNumber>0?secondNumber:0));
        
        setBaredesCommissionPercent(totalBaredesPercent);
    }, [commissionData]);

    /**Actualizo los valores editables de Contract Commission en torno al resto */
    useEffect(() => {
        if (commissionData.contractCommission !== undefined && commissionData.contractCommission.amount?.currency == "PERCENTAGE") {

            let totalMonths = monthsBetween(existAndReturn(contractForm, "paymentSchedule.firstPaymentStartDate"),existAndReturn(contractForm, "paymentSchedule.lastPaymentEndDate"));
            let initialRent = existAndReturn(contractForm, "paymentSchedule.initialAmount.value");
            let icreaseRentPerMonths = existAndReturn(contractForm, "paymentSchedule.increaseEveryXMonths");
            let adjustment = existAndReturn(contractForm, "paymentSchedule.increaseEveryXMonthsPercentage");
            let totalRent = calculateTotalRent(initialRent, adjustment, icreaseRentPerMonths, totalMonths);

            let contractPorcent = existAndReturn(commissionData, "contractCommission.amount.value");

            let editableCommission ={
                currency: existAndReturn(contractForm, "paymentSchedule.initialAmount.currency"),
                value : (totalRent*contractPorcent)/100
            }

            console.log(contractForm);
            console.log(totalMonths);
            

            if (catchmentCommissionPercentEditableValue.value === 0) {
                setCatchmentCommissionPercentEditableValue(editableCommission)
            }
            else if(editableCommission.value  !== 0)
            {
                setCatchmentCommissionPercentEditableValue(editableCommission)
            }
            
        }
        else if(commissionData.contractCommission !== undefined && commissionData.contractCommission.amount?.currency !== "PERCENTAGE"){
            setCatchmentCommissionPercentEditableValue(commissionData.contractCommission.amount)
        }    
    }, [commissionData.contractCommission.amount?.value, commissionData.contractCommission.amount?.currency]);
    /**Actualizo los valores editables de Key Commission en torno al resto */
    useEffect(() => {
        if (commissionData.keyCommission !== undefined && commissionData.keyCommission.amount?.currency == "PERCENTAGE") {
            if (contractForm.key) {
                let totalKey = existAndReturn(contractForm, "key");
                let keyPorcent = existAndReturn(commissionData, "keyCommission.amount.value");
                
                let editableCommission ={
                    currency: totalKey.currency,
                    value:(keyPorcent*totalKey.value)/100
                }
                setKeyCommissionPercentEditableValue(editableCommission)
            }
            else{
                console.log('Se necesita el campo llave para crear una comision llave');
            }

        } 
        else if(commissionData.keyCommission !== undefined && commissionData.keyCommission.amount?.currency !== "PERCENTAGE"){
            setKeyCommissionPercentEditableValue(commissionData.keyCommission.amount)
        }  
    }, [commissionData.keyCommission.amount?.value, commissionData.keyCommission.amount?.currency]);

    /**Actualizo ajuste */
    useEffect(() => {
        if (catchmentCommissionPercentEditableValue && catchmentCommissionPercentEditableValue) {
            let firstValue = catchmentCommissionPercentEditableValue.value?catchmentCommissionPercentEditableValue.value:0
            let secondValue = keyCommissionPercentEditableValue.value?keyCommissionPercentEditableValue.value:0
            setAdjustment(firstValue + secondValue); 
        }
      }, [keyCommissionPercentEditableValue?.value, keyCommissionPercentEditableValue?.currency, catchmentCommissionPercentEditableValue?.value, catchmentCommissionPercentEditableValue?.currency]);

    const [step, setStep] = useState(0);

    const steps = ["Paso 1", "Paso 2"];

    const [showSuccessModal, setShowSuccessModal] = useState({show: false});

    const getStepComponent = () => {
        switch (step) {
            case 0:
                return <ContractFormStep1   contractForm={contractForm}
                                            setContractForm={setContractForm}
                                            onInputChange={onInputChange}
                                            onInputChangeCommission={onInputChangeCommission}
                                            commissionData={commissionData}
                                            setCommissionData={setCommissionData}
                                            baredesCommissionPercent={baredesCommissionPercent}
                                            catchmentCommissionPercentEditableValue={catchmentCommissionPercentEditableValue}
                                            setCatchmentCommissionPercentEditableValue={setCatchmentCommissionPercentEditableValue}
                                            setKeyCommissionPercentEditableValue={setKeyCommissionPercentEditableValue}
                                            keyCommissionPercentEditableValue={keyCommissionPercentEditableValue}
                                            adjustment={adjustment}
                                            />;
                                            
            case 1:
                return <ContractFormStep2   contractForm={contractForm}
                                            setContractForm={setContractForm}
                                            onInputChange={onInputChange}
                                            params={params}/>;
            default:
                return <ContractFormStep1/>;
        }
    };

    const checkCommissionState =()=>{
        if (commissionData.contractCommission.amount.value > 0 && ((commissionData.catchmentCommission.totalPercent === null || commissionData.catchmentCommission.totalPercent === undefined)  || (commissionData.salesCommission.totalPercent === null || commissionData.salesCommission.totalPercent === undefined))) {
            alert('Ingresar la comisiones de Captacion y Venta correspondientes')
            return false
        }
        else{
            return true
        }

    }

    const plusStep = () => {
        if (step < steps.length - 1 && checkCommissionState()) {
            setStep(step + 1);
        }
    }

    const minusStep = () => {
        if (step > 0)
            setStep(step - 1);
        contractForm.paymentSchedule.firstPaymentStartDate = new Date(contractForm.paymentSchedule.firstPaymentStartDate );
        contractForm.paymentSchedule.lastPaymentEndDate = new Date(contractForm.paymentSchedule.lastPaymentEndDate );
    }


    const filteringOverlapMultibox =(services, data, overlapForm, newForm)=>{
        if (Object.keys(data)[0] === services) {
            delete overlapForm[services];
            newForm = unionJsonValue(overlapForm,data);
        }
    }

    const onInputChange = (jsonData)  =>{
        let newValue = unionJsonValue(contractForm,jsonData);
        setContractForm(newValue)

        let servicesOverlapFilter = contractForm;
        filteringOverlapMultibox("showTenantServices",jsonData,servicesOverlapFilter,newValue);
        filteringOverlapMultibox("showOwnerServices",jsonData,servicesOverlapFilter,newValue);
    }

    const onInputChangeCommission =(jsonData)=>{
        let newValue = unionJsonValue(commissionData, jsonData);
        setCommissionData(newValue)
    }

      const onSubmit = (e)=>{
        e.preventDefault();
        let requiredInputs =    [
            {value:existAndReturn(contractForm, "operationType"), name:'Tipo de operacion'},
            {value:existAndReturn(contractForm, "ownersEquity"),    name:'Propiertario/s'},
            {value:existAndReturn(contractForm, "secondPartsEquity"),   name:'Inquilino'},
            {value:existAndReturn(contractForm, "paymentSchedule.firstPaymentStartDate"),   name:'Fecha de pago'},
            {value:existAndReturn(contractForm, "paymentSchedule.initialAmount"),   name:'Monto inicial'},
            {value:existAndReturn(contractForm, "property.id"), name:'Propiedad'},
            ]

        if (checkRequiredInputs(requiredInputs) <= 0) {
                let newContract  = JSON.parse(JSON.stringify(contractForm));
                /*Setting new Dates*/
                let newFP = existAndReturn(contractForm, "paymentSchedule.firstPaymentStartDate");
                let newlP =  existAndReturn(contractForm, "paymentSchedule.lastPaymentEndDate");

                if ((contractForm.paymentSchedule.firstPaymentStartDate instanceof Date)) {
                    newFP = contractForm.paymentSchedule.firstPaymentStartDate.toISOString().substring(0, 10);
                }
                if ((contractForm.paymentSchedule.lastPaymentEndDate instanceof Date)) {
                    newlP =  contractForm.paymentSchedule.lastPaymentEndDate.toISOString().substring(0, 10);
               }
               newContract.paymentSchedule.firstPaymentStartDate = newFP;
               newContract.paymentSchedule.lastPaymentEndDate = newlP;
               /*Setting new services*/
                let ownerServiceValues = [];

                if( contractForm.showOwnerServices !== undefined){
                    contractForm.showOwnerServices.map(service=>{
                        ownerServiceValues.push(service.value);
                    })
                }

                let tenantServiceValues = [];
                if (contractForm.showTenantServices !== undefined) {
                    contractForm.showTenantServices.map(service=>{
                        tenantServiceValues.push(service.value);
                    })
                }

                /*Setting opertation type*/
                let bookingTypeOperation ='';
                if(contractForm.operationType === "BUY"){
                    bookingTypeOperation = "BuyPaymentSchedule";
                }
                if(contractForm.operationType !== "BUY"){
                    bookingTypeOperation = "RentPaymentSchedule";
                }
                /*Setting missing keys*/
                if (newContract.paymentSchedule) {
                    newContract.paymentSchedule.type=bookingTypeOperation;
                }
                newContract.type ="FinalContract";
                if (params.id) {
                    newContract.id = Number(params.id);
                }
                newContract.paymentSchedule.paysEveryXMonths=1;

                newContract.commissionContainer = JSON.parse(JSON.stringify(commissionData))

                newContract.commissionContainer.contractCommission ={
                    amount:newContract.commissionContainer.contractCommission.amount,
                    roundedAmount:catchmentCommissionPercentEditableValue
                }
                
                /**Formateo las comisiones */
                const formatCommissionsOwners =(commission,getOwners)=>{

                    // Accediendo dinámicamente a la propiedad
                    let ownersCommissionFormated =[];

                    if (getOwners === true && commissionData[commission].owners !== undefined) {
                        ownersCommissionFormated = commissionData[commission].owners.map(owner => {
                            return {
                            commissionType: "OWNER",
                            owner: owner.owner,
                            percentageCurrentOperation: owner.percentageCurrentOperation,
                            percentageNextOperation: 0
                            };
                        });
                    }
       
                    // Crear los nuevos objetos solo si ownerId es válido
                    let newRunner = commissionData[commission].runner ? {
                        commissionType: "RUNNER",
                        owner: {id:commissionData[commission].runner?.owner?.id},
                        percentageCurrentOperation: commissionData[commission].runner?.percentageCurrentOperation,
                        percentageNextOperation: 0
                    } : null;

                    let newReferrer = commissionData[commission].referrer ? {
                        commissionType: "REFERRAL",
                        owner: commissionData[commission].referrer?.owner,
                        percentageCurrentOperation: commissionData[commission].referrer?.percentageCurrentOperation,
                        percentageNextOperation: commissionData[commission].referrer?.percentageNextOperation
                    } : null;

                    let newBroker = commissionData[commission].broker ? {
                        commissionType: "BROKER",
                        owner: {id:commissionData[commission].broker?.owner?.id},
                        percentageCurrentOperation: commissionData[commission].broker?.percentageCurrentOperation,
                        percentageNextOperation: commissionData[commission].broker?.percentageNextOperation
                    } : null;


                    let newBaredes = {
                        commissionType: "BAREDES",
                        owner:{id:1},
                        percentageCurrentOperation: commissionData[commission].baredes?.percentageCurrentOperation
                    };

                    // Empujar los objetos que tienen un ownerId válido
                    if (newRunner?.percentageCurrentOperation > 0 && newRunner && newRunner !== null && newRunner !== undefined) ownersCommissionFormated.push(newRunner);
                    if (newReferrer?.percentageCurrentOperation > 0 && newReferrer && newReferrer !== null && newReferrer !== undefined) ownersCommissionFormated.push(newReferrer);
                    if (newBaredes?.percentageCurrentOperation > 0 && newBaredes && newBaredes !== null && newBaredes !== undefined)ownersCommissionFormated.push(newBaredes);
                    if (newBroker?.percentageCurrentOperation > 0 && newBroker && newBroker !== null && newBroker !== undefined)ownersCommissionFormated.push(newBroker);

                    let commissionFormated ={};
    
                    return commissionFormated = {
                        commissionOwners:ownersCommissionFormated
                    }
    
                }
                newContract.commissionContainer.catchmentCommission = formatCommissionsOwners('catchmentCommission',true);
                newContract.commissionContainer.salesCommission = formatCommissionsOwners('salesCommission',false);

                newContract.commissionContainer.catchmentCommission.totalAmountOfOwners =   {
                    currency:'PERCENTAGE',
                    value: commissionData.catchmentCommission.totalPercent
                }
                newContract.commissionContainer.salesCommission.totalAmountOfOwners =   {
                                                                                                currency:'PERCENTAGE',
                                                                                                value: commissionData.salesCommission.totalPercent
                                                                                            }

                let newTermsAndConditions = {
                    date: null,
                    termsAndConditionsApp: { version: "1", date: null },
                    termsAndConditionsStatus: "ACCEPTED"
                };

                newContract.contractFinal = {
                    ownersEquity: newContract.ownersEquity,
                    secondPartsEquity: newContract.secondPartsEquity,
                    internalContractNumber: newContract.internalContractNumber,
                    deposit:null,
                    pdfContract: newContract.pdfContract,
                    pdfWarranty:newContract.pdfWarranty,
                    tenantServices:newContract.tenantServices,
                    ownerServices:newContract.ownerServices
                }
                newContract.contractBooking = null;
                newContract.dtype = null;
                newContract.type = "FinalContract";
                
                newContract.commissionContainer.catchmentCommission.commissionOwners.forEach(obj => {
                    delete obj.ownerId;
                  });
                newContract.commissionContainer.salesCommission.commissionOwners.forEach(obj => {
                delete obj.ownerId;
                });

                Promise.resolve(getProperty(newContract.property.id)).then( (res) => {
                    if (res.message) {
                        alert(res.message)
                    }
                    else{
                        setIsSending(true)
                        newContract.tenantServices = tenantServiceValues;
                        newContract.ownerServices = ownerServiceValues;
                        if (isContactEdit() && validateFN.validatePercentCurrency() && validateFN.validateAllCommissionsOnPercent() && validateFN.validateHundredPercent()
                                            && validateFN.validateBrokerName() && validateFN.validateRunnerName()) {
                            if (existAndReturn(contractForm, ".pdfContract.base64Code")) {
                                delete newContract.pdfContract.base64Code;
                            }
                            setIsSending(true)
                            const newTestContract = JSON.parse(JSON.stringify(newContract));
                            newTestContract.property ={
                                id: newContract.property.id
                            }
                            newTestContract.contractFinal.ownersEquity.forEach(obj => {
                                delete obj.id;
                                obj.currency = 'PERCENTAGE';
                              });
                            newTestContract.contractFinal.secondPartsEquity.forEach(obj => {
                                delete obj.id;
                                obj.currency = 'PERCENTAGE';
                                obj.equity = 0;
                            });
                            newTestContract.contractFinal.tenantServices = newContract.tenantServices
                            newTestContract.contractFinal.ownerServices = newContract.ownerServices
                            newTestContract.contractFinal.contractFinalId = contractForm.contractFinal.contractFinalId
                            delete newTestContract.ownersEquity;
                            delete newTestContract.secondPartsEquity;
                            delete newTestContract.internalContractNumber;
                            delete newTestContract.tenantServices;
                            delete newTestContract.ownerServices;
                            delete newTestContract.showOwnerServices;
                            delete newTestContract.showTenantServices;
                            delete newTestContract.deposit;
                            delete newTestContract.pdfContract;
                            delete newTestContract.pdfWarranty;
                            delete newTestContract.commissionContainer.contractCommission.amount.amountValueId;
                            delete newTestContract.commissionContainer.contractCommission.roundedAmount.amountValueId;
                            delete newTestContract.commissionContainer.keyCommission.amount.amountValueId;
                            delete newTestContract.id;
                            Promise.resolve(updateFinalContract(newTestContract)).then( (res) => {
                                if (res?.status !== 200) {
                                    setIsSending(false);
                                }
                                else if (res?.status === 200) {

                                    if (newContract.referredOperation) {

                                        newContract.referredOperation.contracts = [{id: res.id, type: "FinalContract"}];
                                        newContract.referredOperation.referred = {id: res.ownersEquity[0].user.id};

                                        if (newContract.referredOperation.properties?.length > 0)
                                            delete newContract.referredOperation.properties;

                                        Promise.resolve(updateReferredOperation(newContract.referredOperation)).then(res => {
                                            if (res?.status !== 200) {
                                                setIsSending(false);
                                            } else if (res?.status === 200) {
                                                alert("Contrato Actualizado con exito");
                                                window.location.href = "/contracts";
                                                setIsSending(false)
                                            }

                                        });
                                    }else{
                                        alert("Contrato Actualizado con exito");
                                        window.location.href = "/contracts";
                                        setIsSending(false)
                                    }
                                    setIsSending(false);
                                }

                            })
                        }
                        if(!params.id   && validateFN.validatePercentCurrency() && validateFN.validateAllCommissionsOnPercent() && validateFN.validateHundredPercent()
                                        && validateFN.validateBrokerName() && validateFN.validateRunnerName()){
                            setIsSending(true)
                            const newTestContract = {
                                "dtype": null,
                                "operationType": newContract.operationType,
                                "isDeleted": false,
                                "observations": newContract.observations,
                                "contractFinal": {
                                  "ownersEquity": newContract.ownersEquity,
                                  "secondPartsEquity": newContract.secondPartsEquity,
                                  "tenantServices": newContract.tenantServices?newContract.tenantServices:[],
                                  "ownerServices": newContract.ownerServices?newContract.ownerServices:[],
                                  "internalContractNumber": newContract.internalContractNumber,
                                  "deposit": null,
                                  "pdfContract": newContract.pdfContract,
                                  "pdfWarranty":  newContract.pdfWarranty,
                                },
                                "contractBooking": null,
                                "type": "FinalContract",
                                "commissionContainer": {
                                    "catchmentCommission": newContract.commissionContainer.catchmentCommission,
                                    "contractCommission": newContract.commissionContainer.contractCommission,
                                    "keyCommission": newContract.commissionContainer.keyCommission,
                                    "salesCommission": newContract.commissionContainer.salesCommission,
                                },
                                "key": newContract.key,
                                "paymentSchedule": newContract.paymentSchedule,
                                "property":{id: newContract.property.id,}
                              }
                              
                            Promise.resolve(createFinalContract(newTestContract)).then( (res) => {
                                if (res?.status !== 200) {
                                    setIsSending(false)
                                }
                                else if (res?.status === 200) {
                                    const contractCreated = res;
                                    console.log(contractCreated);
                                    
                                    if (commissionData?.catchmentCommission?.referrer?.referredId) {
                                        Promise.resolve(fetchReferredOperationsByReferredUserId(commissionData?.catchmentCommission?.referrer?.referredId)).then(res => {
                                            if (res.message) {
                                                alert(res.message)
                                                setIsSending(false);
                                            } else {

                                                let actualReferredOperation = res;
                                                actualReferredOperation[0].contracts.push({contractId:1})
                                                console.log(actualReferredOperation[0]);
                                                
                                               /* Promise.resolve(updateReferredOperation(actualReferredOperation[0])).then(res => {
                                                    if (res.message) {
                                                        alert(res.message)
                                                        setIsSending(false);
                                                    } else {
                                                        alert("Contrato creado con exito");
                                                        window.location.href = "/contracts";
                                                        setIsSending(false)
                                                    }
                                                });*/
                                                alert("Contrato creado con exito");
                                                window.location.href = "/contracts";
                                                setIsSending(false)
                                            }
                                        });
                                        
                                    }else{
                                        alert("Contrato creado con exito");
                                        window.location.href = "/contracts";
                                        setIsSending(false)
                                    }
                                }

                            })
                        }
                    }
                })
        }
        else{
            alert("Debe llenar los siguientes campos: "+checkRequiredInputs(requiredInputs))
        }
      }

      const isContactEdit = () =>{
        return params.id
    }
    const checkFormLoading =()=>{
        if (isContactEdit && isLoading) {
            return <CircularLoader/>
        }
        else{
            return getStepComponent()
        }
    }


    return (
        <BackgroundContainer>

            <div style={style}>

                <DataContainerCard>
                    <Grid columns="1">
                        <Stepper activeStep={step} alternativeLabel className="mt-5">
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </DataContainerCard>

                {params.id?
                checkFormLoading():getStepComponent()}

                <MyModal modalState={showSuccessModal} children={<label>Contrato creado</label>}/>

            </div>
            <div className="w-full flex"    style={{"margin":"1.6rem 0px 1.6rem 0px"}}>
                {step > 0 ? <div  className={isSending ? 'UnabledButton' : ''}  style={{'marginRight':'auto'}}><ButtonStandard showName="Anterior" onClick={minusStep} float="left" blueColor/></div>: null}
                {step === 0 ? <ButtonStandard showName="Guardar y Continuar" onClick={plusStep} blueColor float="right"/> : null}
                {step === 1 ? <div  className={isSending ? 'UnabledButton' : ''} style={{'marginLeft':'auto'}}><ButtonStandard showName={isContactEdit()?"Actualizar contrato":"Crear contrato"} onClick={onSubmit} blueColor/></div> : null}
            </div>

        </BackgroundContainer>

    )
}