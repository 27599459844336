import  React,  { useState, useEffect  } from  'react';
import axios from "axios";
import authHeaderService from "../../authHeaderService";
import {logout} from "../Login/authLoginService";
import {authorizationCheck, devLog} from "../../../globalHelper";

export async function getUser(id){
    devLog(true, "[Binculo] Services - getUser")
    return await axios
    .get(
        `${process.env.REACT_APP_API_URL}/api/users/${id}`,
        authHeaderService()
    )
    .then((res) => {
        return res.data;
    })
    .catch((e) => {
        if(e.response){
            devLog(true, e.response.status)
            authorizationCheck(e);
            return  e.response.data;
        }
        else{
            console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
        }
    });
  };

export async function resetUserPw(id){
    devLog(true, "[Binculo] Services - resetPassword")
    return await axios
        .get(
            `${process.env.REACT_APP_API_URL}/api/users/${id}/resetPassword`,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if (e.response) {
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
            }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
};


  export async function fetchContacts(searchFields) {
     devLog(true, "[Binculo] Services - fetchContacts")
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/users/filterbynames`,
        searchFields,
        authHeaderService()
      )
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        if (e.response) {
            devLog(true, e.response.status)
            authorizationCheck(e);
            return  e.response.data;
        }
        else{
            console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
        }
      });
  }

export async function fetchContactsByRole(searchFields) {
    devLog(true, "[Binculo] Services - fetchContactsByRole")
      
    return await axios
        .put(
            `${process.env.REACT_APP_API_URL}/api/users/filterbynamesandrole`,
            searchFields,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if (e.response) {
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
            }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }  
        });
}

export async function fetchContactsList(operations, page = 0, size = 7) {
    devLog(true, "[Binculo] Services - fetchContactsList")
      
    return await axios
        .put(
            `${process.env.REACT_APP_API_URL}/api/users`,
            {
                operations: operations ? operations : null,
                paging: { page, size },
            },
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if (e.response) {
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
            }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}

export async function addNewUser(user) {
  devLog(true, "[Binculo] Services - addNewUser: ", user)
    try {
        const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/users`,
                user,
                authHeaderService()
            );
        
        if (response.status < 200 || response >= 300) {
            devLog(true, "[Binculo] Error when add the user")
            return null;
        }
        
        return response

    } catch (e) {
        if (e.response) {
            authorizationCheck(e);
            devLog(true, "[Binculo] Error when add the user", e, e.response)
        } 
        else{
            console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
        }
    }
}

export async function editUser(user) {
    devLog(true, "[Binculo] Services - editUser")
      
    return await axios
        .post(
            `${process.env.REACT_APP_API_URL}/api/users`,
            user,
            authHeaderService()
        )
        .then((res) => {
            return res.data
        })
        .catch((e) => {
            if (e.response) {
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
            }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}

export async function updateUserPassword(id,user) {
    devLog(true, "[Binculo] Services - updateUserPassword")
      
    return await axios
        .put(
            `${process.env.REACT_APP_API_URL}/api/users/${id}/password/`,
            user
            ,authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if (e.response) {
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
            }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}

export async function deleteContact(id) {
    devLog(true, "[Binculo] Services - deleteContact")
      
    return await axios
        .delete(
            `${process.env.REACT_APP_API_URL}/api/users/${id}`,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if (e.response) {
                devLog(true, e.response.status);
                authorizationCheck(e);
                return  e.response.data;
            }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}