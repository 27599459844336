import React, { useEffect, useState } from "react";

// Styles
import { Box, Modal } from "@mui/material";
import "../../../CSS/Reusable/MyModal.scss";

// Components
import ButtonBlue from "../ButtonBlue";
import DataInput from "../DataInput";
import Grid from "../Grid";

// Services
import { updateUserPassword } from "../../../Services/MainViews/Users/contactsService";

// Helpers
import { existAndReturn, unionJsonValue } from "../../../globalHelper";

const FirstLoginModal = ({ modalState, childrenTitle, modalSubmit }) => {
    const [modalForm, setModalForm] = useState({});
    const [userData, setUserData] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem("user")));
        if (modalState.show) setOpen(true);
    }, [modalState.show]);

    const handleClose = (event, reason) => {
        // Evita que el modal se cierre al hacer clic fuera de él
        if (reason && reason === "backdropClick") return;
        if (modalSubmit) modalSubmit();
        setOpen(false);
    };

    const validateForm = () => {
        const newPassword = existAndReturn(modalForm, "newPassword");
        const checkNewPassword = existAndReturn(modalForm, "checkNewPassword");
        if (newPassword === checkNewPassword) {
            return true;
        } else {
            alert("Las dos contraseñas deben coincidir");
            return false;
        }
    };

    const handleEventButton = (e) => {
        if (validateForm()) {
            const id = userData.id;
            const newPassword = {
                password: modalForm.newPassword,
                hasAlreadyChangedPassword: true,
            };
            updateUserPassword(id, newPassword)
                .then((contact) => {
                    if (contact.message) {
                        alert(contact.message);
                    } else {
                        handleClose();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const onInputChange = (jsonData) => {
        const newValue = unionJsonValue(modalForm, jsonData);
        setModalForm(newValue);
    };

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClose={handleClose}
        >
            <Box className="modalContainer rounded-md text-center w-96 grid grid-cols-1 gap-4 ">
                <h1 className="xs:text-[1rem] font-bold mb-[1rem]">{childrenTitle}</h1>
                <DataInput tittle="Ingresa la nueva contraseña" type="password" ChangeState={onInputChange} targetName={"newPassword"} />
                <DataInput tittle="Vuelva a ingresar la contraseña" type="password" ChangeState={onInputChange} targetName={"checkNewPassword"} />
                <div style={{ display: modalSubmit ? "block" : "none" }}>
                    <Grid columns="1">
                        <ButtonBlue showName="Completar" onClick={handleEventButton} float="center" />
                    </Grid>
                </div>
            </Box>
        </Modal>
    );
};

export default FirstLoginModal;