import axios from "axios";
import authHeaderService from "../../authHeaderService";
import {authorizationCheck, devLog} from "../../../globalHelper";

export async function addNewSearch(search) {
    return await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/searches`,
        search,
        authHeaderService()
      )
      .then((res) => {
        return res.data
      })
      .catch((e) => {
        if(e.response){
            devLog(true, e.response.status)
            authorizationCheck(e);
            return  e.response.data;
            }
        else{
            console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
        }
      });
  }

  export async function fetchSearches(){
    return await axios
    .get(
        `${process.env.REACT_APP_API_URL}/api/searches`,
        authHeaderService()
    )
    .then((res) => {
        return res.data
    })
    .catch((e) => {
        if(e.response){
            devLog(true, e.response.status)
            authorizationCheck(e);
            return  e.response.data;
            }
        else{
            console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
        }
    });
  };

  export async function fetchUserPropertyCategory(){
      return await axios
          .get(
              `${process.env.REACT_APP_API_URL}/api/user/property_category`,
              authHeaderService()
          )
          .then((res) => {
              return res.data
          })
          .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
          });
  };

  export async function fetchBrokerSuburbs(){
    return await axios
    .get(
        `${process.env.REACT_APP_API_URL}/api/brokerrunner/suburbs`,
        authHeaderService()
    )
    .then((res) => {
        return res.data
    })
    .catch((e) => {
        if(e.response){
            devLog(true, e.response.status)
            authorizationCheck(e);
            return  e.response.data;
            }
        else{
            console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
        }
    });
  };

  export async function deleteSearch(id) {
    return await axios
        .delete(
            `${process.env.REACT_APP_API_URL}/api/searches/${id}`,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
  }

export async function fetchSearchById(id){
    return await axios
        .get(
            `${process.env.REACT_APP_API_URL}/api/searches/${id}`,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}

export async function editSearch(id, search){
    return await axios
        .put(
            `${process.env.REACT_APP_API_URL}/api/searches/${id}`,
            search,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}