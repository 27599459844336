import "../../../CSS/Reusable/DataTableInput.scss";
import ThreeDotSelector from "../ThreeDotSelector";
import {getValueFromObject} from "../../../globalHelper"
import Label from "../Label";


export default function DataTableInput({row, columns, gridCols, actions}) {

    const isSingleColumn = columns.length === 1;

    const onTouchInfo = (info) => {
        if (window.screen.width < 860) {
            alert(info);
        }
    };
    
    const getClases = () => {
        return "grid " + gridCols + " dataTableInput".concat(isSingleColumn?' w-[100%]':'');
    };
    
    return (
        <div className={getClases()}>
            {columns.map((column, i) => {
                let contentCell = null;

                if (column.renderCell) {
                    contentCell = column.renderCell(row);
                    console.log(row);
                    
                } else {
                    const value = getValueFromObject(row, column.field);
                    contentCell = value || "-";
                }
                
                let styleUserType = null;
                if(column['field'] === "roles.[0]") {
                    styleUserType = row.roles[0];
                }
                if (column['field'] === "status") {
                    styleUserType = row.status;
                }

                // Verificar si solo hay una columna
                
                const labelClass = `text-center _text-ellipsis p-4 ${isSingleColumn ? '_single-column' : ''}`;

                return (
                    <div className={labelClass} key={i}>
                        <label
                            id={styleUserType}
                            title={contentCell}
                            onClick={() => onTouchInfo(contentCell)}
                        >
                            {contentCell}
                        </label>
                    </div>
                );
            })}
            {actions ? <ThreeDotSelector rowComponentId={row.id} actions={actions} rowComponentType={row.type}/> : null}
        </div>
    );
}