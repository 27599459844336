const initialState = {
  properties: [],
  filters: [],
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_PROPERTIES":
      return {
        ...state,
        properties: action.payload,
      };

    case "FILTER_PROPERTIES": {
        const { property, value, operator } = action.payload;

        // Si la propiedad no es "address.suburb", eliminamos todos los filtros con la misma propiedad
        let updatedFilters;
        if (property !== "address") {
          updatedFilters = state.filters.filter(
            (filter) => filter.property !== property
          );
        } else {
          updatedFilters = [...state.filters];
        }

        // Agregar o reemplazar el filtro actual
        updatedFilters.push({ property, value, operator });

        return {
          ...state,
          filters: updatedFilters,
        };
      }

    case "RESET_FILTERS":
      return {
        ...state,
        filters: [],
      };

    default:
      return state;
  }
}