import SelectBoxFilter from "./SelectBoxFilter";
import SelectIndividualFilter from "./SelectIndividualFilter";
import { useSelector } from "react-redux";
import PriceFilter from "./PriceFilter";
import {
  roomsPropertiesFilter, suburbsPropertiesFilter, operationsPropertiesFilter, propertyTypePropertiesFilter} from "../../dataEntriesHelper"

export default function PropertiesFilters(props) {
  const filters = useSelector((state) => state.reducerProperties.filters);
  //no me lo esta leyendo y actualizando pero habria que ver de bsucar si esta seleccionado USD entonces price se muestra en dolares

  return (
    //PARA LA V2 AGREGAR LA CANTIDAD DE CADA UNO DE LOS MATCHES DENTRO DEL FILTRO EJ: CABALLITO (5)

    <>
      {/*<SelectIndividualFilter filterComponent="Properties" filterName="propertyOperations.type" entries={operationsPropertiesFilter} clearFilters={props.clearFilters} currentFilters={props.currentFilters}/>
      <SelectIndividualFilter filterComponent="Properties" filterName="type" entries={propertyTypePropertiesFilter} clearFilters={props.clearFilters} currentFilters={props.currentFilters}/>*/}
      <SelectIndividualFilter filterComponent="Properties" filterName="address.suburb" entries={suburbsPropertiesFilter}  clearFilters={props.clearFilters} currentFilters={props.currentFilters}/>
      <SelectIndividualFilter filterComponent="Properties" filterName="rooms" entries={roomsPropertiesFilter} clearFilters={props.clearFilters} currentFilters={props.currentFilters}/>
    </>
  );
}
