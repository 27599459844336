import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Grid from "./Grid"
import BackgroundContainer from "../MainViews/BackgroudContainer"
import DataInput from "./DataInput"
import DataSelectBoxInput from "./DataSelectBoxInput"
import DataSelectBox from "./DataSelectBox"
import DataMultiSelectBox from "./DataMultiSelectBox"
import DataContainerCard from "./DataContainerCard";
import {getIcon} from "../../Services/iconService";
import LazyImage from "./LazyImage";
import {convertBase64} from "../../globalHelper";
import DataDayPicker from "./DataDayPicker"
import "../../CSS/Reusable/ContactForm.scss";
import {useEffect, useState} from "react";
import {addNewUser, getUser} from "../../Services/MainViews/Users/contactsService";
import {useDispatch} from "react-redux";
import ButtonStandard from "./ButtonStandard";
import Switch from '@mui/material/Switch';
import {unionJsonValue, existAndReturn, devLog, addPrefixIfMissing} from "../../globalHelper"
import {phonePrefixEntries} from "../../dataEntriesHelper"
import {dataAssignPropertyCategories, dataAssignSuburbs} from "../../dataAssignHelper"
import {
    brokersPropertyCategoryEntries,
    brokersSuburbsEntries,
    runnersPropertyCategoryEntries,
    suburbsEntries,
    bsAsCabaNeighborhoods
} from "../../dataEntriesHelper"
import CircularLoader from "./CircularLoader";
import {checkRequiredInputs} from "../../errorValidationHelper"
import { FaLessThanEqual } from "react-icons/fa";


export default function ContactForm() {

    let params = useParams();
    // ref
    const inputRef = React.useRef(null);
    const dispatch = useDispatch();
    const [isSending, setIsSending] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [runnerChecked, setRunnerChecked] = useState(false);
    const [brokerChecked, setBrokerChecked] = useState(false);
    const [runnerFormState, setRunnerFormState] = useState({"display": "none"});
    const [brokerFormState, setBrokerFormState] = useState({"display": "none"});
    const [form, setForm] = useState({
        type: "Contact",
        suburb: "PALERMO",
        comercialSuburb: "PALERMO",
        dateOfBirth: new Date(),
        username:null,
        userCategories:[],
        cellPhone:{
            prefix:'54',
            phoneNumber:0
        }
    });
    const [userCategoriesContainer, setUserCategoriesContainer] = useState({
        RESIDENTIAL:{},
        COMMERCIAL:{},
        LAND:{}
    });
    const style = {width: '100%'};
    const navigate = useNavigate();
    const [editing, setEditing] = useState(false);

    const setSwitch = (userType) => {
        if (userType === "RUNNER") {
            setRunnerChecked(true);
            setBrokerChecked(false);
            setRunnerFormState({"display": "block"});
            setBrokerFormState({"display": "none"});
        }
        if (userType === "BROKER") {
            setBrokerChecked(true);
            setRunnerChecked(false);
            setBrokerFormState({"display": "block"});
            setRunnerFormState({"display": "none"});
        }
        if (!params.id) {
            if (userType !== "RUNNER" && userType !== "BROKER") {
                setBrokerChecked(false);
                setRunnerChecked(false);
                setBrokerFormState({"display": "none"});
                setRunnerFormState({"display": "none"});
            }
        }

    }

    const getSingleContact = async () => {
        setIsLoading(true);
        if (isContactEdit()) {
            const contact = await getUser(params.id);

            devLog(true, "[Binculo] ContactForm - getUser: ", contact)
            
            // Update contact
            if (existAndReturn(contact, "type") === "Broker") {
                contact.moneyAccount = [{"id": "", "type": "CBU", "number": 0}];
            }
            contact.showPropertyCategories = dataAssignPropertyCategories(contact.propertyCategories);
            contact.showSuburbs = dataAssignSuburbs(contact.suburbs);

            devLog(true, "[Binculo] ContactForm - updated user: ", contact)
            /*userCategories[0].suburbs 0(Residential)2(Commercial)3(Land)*/
            contact.userCategories?.map((category=>{
                category.suburbs = category.suburbs.map(suburbs => {return {suburb: suburbs.suburb}})
            }))

            const newUserCategoryContainer = {};

            // Solo procesar si no hay un prefijo ya definido
            if (contact.cellPhone.prefix === undefined || contact.cellPhone.prefix === null) {
                // Iterar sobre el array de prefijos y buscar si el número empieza con alguno de ellos
                const matchedPrefix = phonePrefixEntries.find(entry => 
                    contact.cellPhone.startsWith(entry.value)
                );

                if (matchedPrefix) {
                    // Si coincide un prefijo, separar el prefijo del número
                    const prefixLength = matchedPrefix.value.length;
                    const prefix = matchedPrefix.value;
                    const phoneNumber = contact.cellPhone.substring(prefixLength);

                    contact.cellPhone = {
                        prefix: prefix,
                        phoneNumber: phoneNumber
                    };
                } else {
                    // Si no coincide ningún prefijo, usar uno por defecto (Argentina 54 en este caso)
                    contact.cellPhone = {
                        prefix: '54',  // Prefijo por defecto
                        phoneNumber: contact.cellPhone
                    };
                }
            }

            contact?.userCategories?.forEach(item => {
              const category = item.propertyCategory;
              newUserCategoryContainer[category] = item;
            });
            setUserCategoriesContainer(newUserCategoryContainer)
            setSwitch(contact.roles[0]);
            setForm(contact)
            setEditing(true);
            setIsLoading(false);
        }
        else{
            setIsLoading(false);
        }   
    };

    useEffect(() => {
        getSingleContact();
    }, [params.id]);

    const filteringOverlapMultibox =(services, data, overlapForm, newForm)=>{
        if (Object.keys(data)[0] === services) {
            delete overlapForm[services];
            newForm = unionJsonValue(overlapForm,data);
        }
    }

    const onInputChange = (jsonData) => {
        let newValue = unionJsonValue(form, jsonData);
        setForm(newValue)
    }
    const onCategoryChange = (data,category) => {
        const suburbsData = data.map(singleSuburb => {
            const newSuburbOjetct = {suburb: singleSuburb.value}
            return newSuburbOjetct
        });
        let newValue = userCategoriesContainer;
        let newObject = { propertyCategory: category, suburbs: suburbsData };

        newValue[category] = newObject

    }
    const handleType = (event) => {
        let userType = event.target.value;
        let userRol = event.target.id;

        if (!params.id) {
            if (userRol === "BROKER" && existAndReturn(form, "type") === 'Broker') {
                userRol = "CONTACT"
                userType = "Contact"
            }
    
            if (userRol === "RUNNER" && existAndReturn(form, "type") === 'Runner') {
                userRol = "CONTACT"
                userType = "Contact"
            }
        }

        setSwitch(userRol);

        setForm({
            ...form,
            type: userType,
            roles: [userRol]
        });
    }

    const setContactDefaultPassword = (newContact) => {
        newContact.password = process.env.REACT_APP_USER_DEFAULT_PASSWORD;
    }

    const filterCategories = (inputObject) => {
        const result = [];
      
        for (const [key, value] of Object.entries(inputObject)) {
          if (value.suburbs && value.suburbs.length > 0) {
            result.push(value);
          }
        }
      
        return result;
      };

    const onSubmit = (e) => {
        let requiredInputs =    [  
            {value:existAndReturn(form, "name"), name:'Nombre'},
            {value:existAndReturn(form, "surname"),    name:'Apellido'},
            {value:existAndReturn(form, "cellPhone"),   name:'Telefono celular'},
            {value:existAndReturn(form, "username"),   name:'Email'}
            ]

        e.preventDefault();
        if (checkRequiredInputs(requiredInputs) <= 0) {
            let userPropertyCategoriesValues = [];
            if (form.showPropertyCategories !== undefined && form.showPropertyCategories !== null) {
                form.showPropertyCategories.map(service => {
                    userPropertyCategoriesValues.push(service.value);
                })
            }
            
            let brokerSuburbsValues = [];
            if (form.showSuburbs !== undefined && form.showSuburbs !== null) {
                form.showSuburbs.map(service => {
                    brokerSuburbsValues.push(service.value);
                })
            }
            const newContact = JSON.parse(JSON.stringify(form));
            const newCategoryList = JSON.parse(JSON.stringify(userCategoriesContainer));
            if (existAndReturn(form, "type") === "Broker") {
                form.type = "Broker"
                delete newContact[newContact.moneyAccount]
            }
            newContact.userCategories = filterCategories(newCategoryList);

            const cellPhoneAdapted = newContact.cellPhone.prefix+''+newContact.cellPhone.phoneNumber
            newContact.cellPhone = addPrefixIfMissing(cellPhoneAdapted)
    
            if (!isContactEdit()) {
                setContactDefaultPassword(newContact);
            }
            setIsSending(true)

            Promise.resolve(addNewUser(newContact)).then( (res) => {
                    if (res?.status !== 200) {
                        setIsSending(false)
                    }
                    else if(res?.status === 200){
                        if (isContactEdit()) {
                            alert("Usuario actualizado con exito")
                        }
                        else{
                            alert("Usuario creado con exito");
                        }
                        window.location.href = "/contacts";
                        setIsSending(false)
                    }     
            })
        }
        else{
            alert("Debe llenar los siguientes campos: "+checkRequiredInputs(requiredInputs))
        }

    }

    let paymentSection = "";

    if (existAndReturn(form, "type") === "Broker") {
        paymentSection = "";

    } else {
        paymentSection =
            <Grid columns="2">
                <DataInput tittle="CBU" type="text" ChangeState={onInputChange} targetName={"moneyAccount[0].number"}
                            defaultValue={existAndReturn(form, "moneyAccount[0].number")}/>
                <DataInput tittle="Alias" type="text" ChangeState={onInputChange} targetName={"moneyAccount[0].alias"}
                            defaultValue={existAndReturn(form, "moneyAccount[0].alias")}/>
            </Grid>
    }


    const isContactEdit = () =>{
        return params.id
    }
    // triggers when file is selected with click
    const handleImageChange = function(e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) { 
        // handleFiles(e.target.files);
        }
        convertBase64(e.target.files,form,setForm,'image','',setIsLoading, true);
    };
    
    // triggers the input when the button is clicked
    const onImageButtonClick = (e) => {
        e.preventDefault();
        inputRef.current.click();
    };
    const deleteImage =(e)=>{
        e.preventDefault()
        setForm({...form,image : null});
    }

    return (
        <BackgroundContainer>
            <ButtonStandard showName="Atrás" onClick={() => navigate("/contacts")} blueColor/>
            <div style={style}>
                <Grid columns="1">
                    {isLoading?
                    <CircularLoader/>:
                    <form>
                        <DataContainerCard tittle="DATOS DE CONTACTO" className="ContractForm">
                            <Grid columns="2">
                                <DataInput type="Text" tittle="Nombre *" ChangeState={onInputChange}
                                            targetName={"name"} defaultValue={existAndReturn(form, "name")}/>
                                <DataInput type="Text" tittle="Apellido *" ChangeState={onInputChange}
                                            targetName={"surname"} defaultValue={existAndReturn(form, "surname")}/>
                            </Grid>
                            <Grid columns="1">
                                <DataDayPicker tittle="Fecha de nacimiento" placeHolder="Ingrese la fecha de nacimiento"
                                                ChangeState={onInputChange} targetName={"dateOfBirth"}
                                                defaultValue=""/>
                            </Grid>
                            <Grid columns="3">
                                <DataInput tittle="DNI" type="text" ChangeState={onInputChange}
                                            targetName={"identificationNumber"}
                                            defaultValue={existAndReturn(form, "identificationNumber")}/>

                                {/*<DataInput tittle="Teléfono celular *" type="text" ChangeState={onInputChange}
                                            targetName={"cellPhone"} defaultValue={existAndReturn(form, "cellPhone")}/>*/}

                                <DataSelectBoxInput type="text" tittle="Teléfono celular *"
                                                     entries={phonePrefixEntries} ChangeState={onInputChange}
                                                     targetName={"cellPhone"}
                                                     indexValues={["prefix", "phoneNumber"]}
                                                     defaultValue={existAndReturn(form, "cellPhone")}
                                                     phoneInput/>

                                <DataInput tittle="Teléfono fijo" type="text" ChangeState={onInputChange}
                                            targetName={"phone"} defaultValue={existAndReturn(form, "phone")}/>
                            </Grid>
                            <Grid columns="1">
                                <DataInput type="Text" tittle="Email *" ChangeState={onInputChange}
                                            targetName={"username"} defaultValue={existAndReturn(form, "username")}/>
                            </Grid>
                            <Grid columns="1">
                                <DataInput type="Text" tittle="Calle y número" ChangeState={onInputChange}
                                            targetName={"address.street"}
                                            defaultValue={existAndReturn(form, "address.street")}/>
                            </Grid>
                            <Grid columns="2">
                                <DataInput tittle="Unidad" type="text" ChangeState={onInputChange}
                                            targetName={"address.houseNumber"}
                                            defaultValue={existAndReturn(form, "address.houseNumber")}/>
                                <DataSelectBox tittle="Barrios"
                                                entries={suburbsEntries}
                                                ChangeState={onInputChange} targetName={"address.suburb"}
                                                defaultValue={existAndReturn(form, "address.suburb")}/>
                            </Grid>
                            <Grid columns="2">
                                <DataInput tittle="CUIT o CUIL" type="text" ChangeState={onInputChange}
                                            targetName={"CUIT"} defaultValue={existAndReturn(form, "CUIT")}/>
                                <DataInput tittle="Código postal" type="text" ChangeState={onInputChange}
                                            targetName={"address.postcode"}
                                            defaultValue={existAndReturn(form, "address.postcode")}/>
                            </Grid>
                            <Grid columns="1">
                                <DataInput type="Text" tittle="Tipo de rubro" ChangeState={onInputChange}
                                            targetName={"area"} defaultValue={existAndReturn(form, "area")}/>
                            </Grid>
                            {paymentSection}
                        </DataContainerCard>
                        <DataContainerCard tittle="Direccion comercial" className="ContractForm">
                            <Grid columns="1">
                                <DataInput type="Text" tittle="Calle y número" ChangeState={onInputChange}
                                            targetName={"commercialAddress.street"}
                                            defaultValue={existAndReturn(form, "commercialAddress.street")}/>
                            </Grid>
                            <Grid columns="2">
                                <DataInput tittle="Unidad" type="text" ChangeState={onInputChange}
                                            targetName={"commercialAddress.houseNumber"}
                                            defaultValue={existAndReturn(form, "commercialAddress.houseNumber")}/>
                                <DataSelectBox tittle="Barrios"
                                                entries={suburbsEntries} ChangeState={onInputChange}
                                                targetName={"commercialAddress.suburb"}
                                                defaultValue={existAndReturn(form, "commercialAddress.suburb")}/>
                            </Grid>
                            <Grid columns="2">
                                <DataInput tittle="Código postal" type="text" columnSpan={1}
                                            ChangeState={onInputChange} targetName={"commercialAddress.postcode"}
                                            defaultValue={existAndReturn(form, "commercialAddress.postcode")}/>
                            </Grid>

                        </DataContainerCard>
                        <DataContainerCard tittle="Tipo de usuario" className="ContractForm">
                            <div className='flex flex-col space-y-8'>
                                <Grid columns="2">
                                    <Grid columns="1">
                                        <div className=" flex items-center justify-start space-x-4">
                                            <h1>Agente</h1><Switch inputProps={{'aria-label': 'controlled'}} onChange={handleType} value={"Runner"} checked={runnerChecked} id="RUNNER"/>
                                        </div>
                                        <div className=" flex items-center justify-start space-x-4">
                                            <h1>Broker</h1><Switch inputProps={{'aria-label': 'controlled'}} onChange={handleType} value={"Broker"} checked={brokerChecked} id="BROKER"/>
                                        </div>
                                    </Grid>
                                    <Grid columns="1">
                                        {brokerChecked?<DataInput type="Text" tittle="Inmobiliaria" ChangeState={onInputChange}
                                                    targetName={"firmName"} defaultValue={existAndReturn(form, "firmName")}/>:''}
                                        <div className='flex flex-col space-y-5 justify-end items-start lg:items-end w-full'>
                                            <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleImageChange} />
                                            <ButtonStandard showName="Cargar imagen" onClick={(e)=>onImageButtonClick(e)} blueColor iconName='uploadFile'/>
                                            {form.image?                       
                                            <div className="w-max h-max p-2 relative border-2 border-[#081382] rounded-xl ">
                                                <button className="delete-image-icon-button"    onClick={(e) => deleteImage(e)}>
                                                    <div className="delete-image-icon">{getIcon("deleteX")}</div>
                                                </button>
                                                <LazyImage src={form.image.base64Code ? form.image.base64Code: form.image.urlPath} height="100px"  width="100px" className='rounded-xl max-h-[50px] max-w-[50px] min-h-[50px] min-w-[50px]'/>
                                            </div>
                                            :''
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid columns="3">
                                    <div className="mt-4">
                                        <h4 className="text-xl text-[#081382] font-bold text-center">Residencial</h4>
                                        <DataMultiSelectBox 
                                            entries={brokersSuburbsEntries} 
                                            tittle="Barrios"
                                            ChangeState={onCategoryChange}
                                            defaultValue={existAndReturn(userCategoriesContainer, "RESIDENTIAL.suburbs")}
                                            category="RESIDENTIAL"
                                            />
                                    </div>
                                    <div className="mt-4">
                                        <h4 className="text-xl text-[#081382] font-bold text-center">Comercial</h4>
                                        <DataMultiSelectBox 
                                            entries={brokersSuburbsEntries} 
                                            tittle="Barrios"
                                            ChangeState={onCategoryChange}
                                            defaultValue={existAndReturn(userCategoriesContainer, "COMMERCIAL.suburbs")}
                                            category="COMMERCIAL"/>
                                    </div>
                                    <div className="mt-4">
                                        <h4 className="text-xl text-[#081382] font-bold text-center">Terrenos</h4>
                                        <DataMultiSelectBox 
                                            entries={brokersSuburbsEntries} 
                                            tittle="Barrios"
                                            ChangeState={onCategoryChange}
                                            defaultValue={existAndReturn(userCategoriesContainer, "LAND.suburbs")}
                                            category="LAND"/>
                                    </div>
                                </Grid>
                            </div>
                        </DataContainerCard>
                    </form>}
                </Grid>
            </div>
            <div className={isSending ? 'UnabledButton' : ''}    style={{"margin":"1.6rem 0px 1.6rem 0px"}}>
                <ButtonStandard showName={isContactEdit()?"Actualizar usuario":"Crear usuario"} onClick={onSubmit} float="right" blueColor/>
            </div>
        </BackgroundContainer>
    )
}