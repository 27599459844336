import "../../CSS/MainViews/Calendar.scss";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, {useEffect, useState} from "react";
import {Calendar, dateFnsLocalizer} from "react-big-calendar";
import CircularLoader from "../Reusable/CircularLoader";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import {fetchPayments} from "../../Services/MainViews/Calendar/Calendar";
import {fetchContactsList} from "../../Services/MainViews/Users/contactsService";
import {es} from "date-fns/locale";
import BackgroudContainer from "./BackgroudContainer";
import DataContainerCard from "../Reusable/DataContainerCard";
import {existAndReturn, formatedDate, fixSystemDate, formatNumberWithDots} from "../../globalHelper"
import {mapCurrencyName} from "../../dataMappingUtilHelper"
import Grid from "../Reusable/Grid";

const locales = {
    "es": es
};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const messages = {
    allDay: 'Todo el dia',
    previous: '<',
    next: '>',
    today: 'Hoy',
    month: 'Mes',
    week: 'Semana',
    day: 'Dia',
    agenda: 'Agenda',
    date: 'Fecha',
}

function getMondayOfCurrentWeekend(d) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
}

function getFridayOfCurrentWeek(d) {
    const today = d;
    const first = today.getDate() - today.getDay() + 1;
    const fifth = first + 4;

    const friday = new Date(today.setDate(fifth));

    return friday;
}

export default function CalendarView() {
    const [newEvent, setNewEvent] = useState({title: "", start: "", end: ""});
    const [allEvents, setAllEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const newCalendarEvent = (event, startDate, formatedEvents, eventCategory, eventTitle) => {
        return formatedEvents.push({
            "start": startDate,
            "title": eventTitle,
            "end": startDate,
            "allDay": true,
            "category": eventCategory,
            "user": event
        });
    }

    const mapPaymentsForCalendarShow = (res) => {
        const formatedEvents = []
        if (res && res !== null && res !== undefined) {
            res.map(event => {
                const currentDate = new Date();
                const currentFridayStartDate = getFridayOfCurrentWeek(currentDate);
                const currentMondayStartDate = getMondayOfCurrentWeekend(currentDate);
                const startDate = fixSystemDate((existAndReturn(event, "payment.expectedPaymentDate")));
                const fridayStartDate = getFridayOfCurrentWeek(startDate);
                const mondayStartDate = getMondayOfCurrentWeekend(startDate);
                let additionalEventAdrress = '';
                if (event.contract && event.commissionContainer === null) {
                    const additionalEventPrice = ' | ' +  mapCurrencyName(existAndReturn(event, "payment.currency")) + ' ' + formatNumberWithDots(existAndReturn(event, "payment.amount"));
                    additionalEventAdrress = (' | ' + existAndReturn(event, "contract.property.address.street") + ' ' + existAndReturn(event, "contract.property.address.houseNumber")).replace("null", '');
    
                    let eventNameCollection = (existAndReturn(event, "contract.contractFinal.secondPartsEquity[0].user.name") + ' ' + existAndReturn(event, "contract.contractFinal.secondPartsEquity[0].user.surname")).replace("null", '');
    
                    let eventNameAccounting = (existAndReturn(event, "contract.contractFinal.ownersEquity[0].user.name") + ' ' + existAndReturn(event, "contract.contractFinal.ownersEquity[0].user.surname")).replace("null", '');
    
    
                    if (!event.payment.isAccounted && !event.payment.isCollected && (startDate <= currentDate)) {
                        const eventTitle = "Cobranza " + eventNameCollection + ' (' + formatedDate(startDate) + ')';
                        newCalendarEvent(event, currentMondayStartDate, formatedEvents, 'accounted', eventTitle + additionalEventPrice + additionalEventAdrress);
                    } else if (!event.payment.isAccounted && !event.payment.isCollected && (startDate > currentDate)) {
                        const eventTitle = "Cobranza " + eventNameCollection + ' (' + formatedDate(startDate) + ')';
                        newCalendarEvent(event, mondayStartDate, formatedEvents, 'accounted', eventTitle + additionalEventPrice + additionalEventAdrress);
                    }
                    if (!event.payment.isAccounted && event.payment.isCollected && (startDate <= currentDate)) {
                        const eventTitle = "Rendicion " + eventNameAccounting + ' (' + formatedDate(startDate) + ')';
                        newCalendarEvent(event, currentFridayStartDate, formatedEvents, 'colected', eventTitle + additionalEventPrice + additionalEventAdrress);
                    } else if (!event.payment.isAccounted && event.payment.isCollected && (startDate > currentDate)) {
                        const eventTitle = "Rendicion " + eventNameAccounting + ' (' + formatedDate(startDate) + ')';
                        newCalendarEvent(event, fridayStartDate, formatedEvents, 'colected', eventTitle + additionalEventPrice + additionalEventAdrress);
                    }
                }
                if (event.commission && event.contract) {
                    let eventName = (existAndReturn(event, "commission.user.name") + ' ' + existAndReturn(event, "commission.user.surname")).replace("null", '');
                    const additionalEventAdrress = (' | ' + existAndReturn(event, "contract.property.address.street") + ' ' + existAndReturn(event, "contract.property.address.houseNumber")).replace("null", '');
                    const additionalEventPrice = ' | ' +mapCurrencyName(existAndReturn(event, "commission.calculatedCommissionAmount.currency"))  + ' ' + formatNumberWithDots(existAndReturn(event, "commission.calculatedCommissionAmount.value"));
    
                    if (!event.payment.isAccounted && !event.payment.isCollected && (startDate <= currentDate)) {
                        const eventTitle = "Cobranza de Broker/Agente " + eventName + ' (' + formatedDate(startDate) + ')';
                        newCalendarEvent(event, currentMondayStartDate, formatedEvents, 'accountedComision', eventTitle + additionalEventPrice + additionalEventAdrress);
                    } else if (!event.payment.isAccounted && !event.payment.isCollected && (startDate > currentDate)) {
                        const eventTitle = "Cobranza de Broker/Agente " + eventName + ' (' + formatedDate(startDate) + ')';
                        newCalendarEvent(event, mondayStartDate, formatedEvents, 'accountedComision', eventTitle + additionalEventPrice + additionalEventAdrress);
                    }
                    if (!event.payment.isAccounted && event.payment.isCollected && (startDate <= currentDate)) {
                        const eventTitle = "Rendicion de Broker/Agente " + eventName + ' (' + formatedDate(startDate) + ')';
                        newCalendarEvent(event, currentFridayStartDate, formatedEvents, 'colectedComision', eventTitle + additionalEventPrice + additionalEventAdrress);
                    } else if (!event.payment.isAccounted && event.payment.isCollected && (startDate > currentDate)) {
                        const eventTitle = "Rendicion de Broker/Agente " + eventName + ' (' + formatedDate(startDate) + ')';
                        newCalendarEvent(event, fridayStartDate, formatedEvents, 'colectedComision', eventTitle + additionalEventPrice + additionalEventAdrress);
                    }
                }
            }) 
        }
        else{
            console.log('Error fetching events or no events available');
        }
        return formatedEvents;
    }

    const mapBirthdaysForCalendarShow = (res) => {
        const formatedEvents = []
        if (res && res !== null && res !== undefined) {
            res.map(event => {
                let year = new Date().getFullYear();
                let month = new Date(event.dateOfBirth).getMonth() + 1;
                let day = new Date(event.dateOfBirth).getDate() + 1;
                const startDate = new Date(year + "-" + month + "-" + day);
                if (event.surname && event.name) {
                    const eventTitle = "Cumpleaños de " + event.surname + "," + event.name;
                    newCalendarEvent(event, startDate, formatedEvents, 'birthdays', eventTitle)
                }
            })
        }
        else{
            console.log('Error fetching events or no events available');
        }
        return formatedEvents;
    }

    const handleEventsPayment = async () => {
        setIsLoading(true)
        const paymentsRes = await fetchPayments();
        const birthdayRes = await fetchContactsList();
        const concatArr = mapPaymentsForCalendarShow(paymentsRes?paymentsRes:null).concat(mapBirthdaysForCalendarShow(birthdayRes?birthdayRes.content:null));
        setAllEvents(concatArr);
        setIsLoading(false)
    }

    const handleAddEvent = () => {
        setAllEvents([...allEvents, newEvent]);
    }

    useEffect(() => {
        handleEventsPayment()
    }, []);
    const eventStyleGetter = (event) => {

        let style = "";

        switch (event.category) {
            case "accounted":
                style = {
                    backgroundColor: "#EB323270",
                    borderLeft: "3px  solid #EB3232",
                    color: "#EB3232"
                };
                break;
            case "colected":
                style = {
                    backgroundColor: "#FAFF06",
                    borderLeft: "3px  solid #FFD706",
                    color: "#AAAC2A"
                };
                break;
            case "accountedComision":
                style = {
                    backgroundColor: "#F59E0B1A",
                    borderLeft: "3px  solid #F59E0B1A",
                    color: "#FF8A00"
                };
                break;
            case "colectedComision":
                style = {
                    backgroundColor: "#C8F5B7",
                    borderLeft: "3px  solid #42B418",
                    color: "#215A0C"
                };
                break;
            case "birthdays":
                style = {
                    backgroundColor: "#F1DAFB",
                    borderLeft: "3px  solid #D086F3",
                    color: "#D086F3"
                };
                break;
            default:
                break;
        }
        return {
            style: style
        };
    }

    return (
        <>
            <BackgroudContainer>
                <DataContainerCard>
                    {isLoading ?
                        <CircularLoader/> :
                        <Calendar localizer={localizer} events={allEvents}
                                  messages={messages} culture={"es"}
                                  eventPropGetter={eventStyleGetter}
                                  startAccessor="start" endAccessor="end"/>
                    }
                </DataContainerCard>
            </BackgroudContainer>

        </>
    );
}
