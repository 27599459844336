import * as React from "react";
import { Link } from "react-router-dom";
import "../../CSS/GeneralStructure/SideBarLi.scss";
import { getIcon } from "../../Services/iconService";

export default function SideBarLi({ title, url, icon, sideBarView }) {
  return (
    <Link to={url} className="SideBarLi">
      <div className="SideBarLi_Icon">{getIcon(icon)}</div>
      {!sideBarView && <div className="SideBarLi_Title">{title}</div>}
    </Link>
  );
}
