import React, { useState, useEffect }  from 'react';
import CryptoJS from 'crypto-js';
//Components
import SliderGallery from "../Reusable/SliderGallery";
import MultimediaButton from "../Reusable/MultimediaButton";
import MultimediaButtonWrapper from "../Reusable/MultimediaButtonWrapper";
import ExporterInfoLink from "../Reusable/ExporterInfoLink";
import PropertyInfoWrapper from "../Reusable/PropertyInfoWrapper";
import PropertyInfoWrapperTitle from "../Reusable/PropertyInfoWrapperTitle";
import PropertyDetailIconsList from "../Reusable/PropertyDetailIconsList";
import ShowMoreLessComponent from "../Reusable/ShowMoreLessComponent";
import AmenitiesServicesPropertyList from "../Reusable/AmenitiesServicesPropertyList";
import YoutubePlayer from "../Reusable/YoutubePlayer";
import PropertyInfoMap from "../Reusable/PropertyInfoMap";
import NotFound from "./NotFound";
import CircularLoader from "../Reusable/CircularLoader";
//Services
import {getExportedProperty} from '../../Services/MainViews/Properties/exported/exportedPropertiesService';
import {getProperty} from '../../Services/MainViews/Properties/propertiesService';
//Helpers
import {propertyIconDetails} from "../../dataEntriesHelper";
import {mapCurrencyName, mapOperationType} from "../../dataMappingUtilHelper";
import {CapitalizeText, decryptData, formatNumberWithDots} from "../../globalHelper";
//Assets
import { ReactComponent as MailExporterIcon } from '../../Assets/Icons/mail_blue_icon.svg';
import { ReactComponent as WhatsappExporterIcon } from '../../Assets/Icons/whatsapp_blue_icon.svg';

function ExportedProperty({ id, exporterName, exporterPhone, exporterMail, internal }) {
  const secretKey = process.env.REACT_APP_PROPERTY_SECRET_KEY;
  const [actualProperty, setActualProperty] = useState(null);
  const [actualPropertyImages, setActualPropertyImages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [actualMultimedia, setActualMultimedia] = useState('photos');

  let propertySectionClasses = ''.concat(
  ' max-w-[1280px] mx-auto p-[16px] lg:p-[26px] space-y-8 '
  ,internal?'w-[95%] ':'w-[100%] '
  );

  useEffect(() => {
    const fetchProperty = async () => {
      setIsLoading(true);
      
      let res;
      if (exporterName && exporterPhone && exporterMail) {
        const DecryptedId = decryptData(id.toString(), secretKey);
        res = await getExportedProperty(DecryptedId);
      } else if (internal) {
        res = await getProperty(id);
      } else {
        setActualProperty(null);
        setIsLoading(false);
        return;
      }
  
      if (!res.status) {
        if (res.images && res.images.length > 0) {
          const imagesArray = res.images
          .sort((a, b) => a.imageOrder - b.imageOrder)
          .map(img => img.urlPath);
        
        setActualPropertyImages(imagesArray);
        }
        setActualProperty(res);
      } else {
        setActualProperty(null);
        setError(res.status);
      }
      
      setIsLoading(false);
    };
  
    fetchProperty();
  }, [id, exporterName, exporterPhone, exporterMail, internal]);

  const changeCurrentMultimedia=(e)=>{
    if (actualMultimedia !== e.target.name) {
      setActualMultimedia(e.target.name)
    }
  }

  const propertyDetailIconsArray = [
    ['totalSquareMeters',actualProperty?.totalSquareMeters],
    ['rooms',actualProperty?.rooms],
    ['bedrooms',actualProperty?.bedrooms],
    ['bathrooms',actualProperty?.bathrooms],
    ['garage',actualProperty?.garage],
  ]

  /** Exporter Info **/
  const getExporterInfo = () => {
    if (!internal) {
      return <div className='bg-[#F0F0F0]/50 p-[20px] rounded-[20px] border-2 border-[#081382]'>
                <div className='max-w-[392px] space-y-4'>
                  <h2 className='font-semibold text-[22px] lg:text-3xl text-[#5F585A]'>{exporterName}</h2>
                  <ExporterInfoLink linkPath={'tel:'+exporterPhone}><WhatsappExporterIcon height="24px" width="24px"/><p>+{exporterPhone}</p></ExporterInfoLink>
                  <ExporterInfoLink linkPath={'mailto:'+exporterMail}><MailExporterIcon height="24px" width="24px"/><p>{exporterMail}</p></ExporterInfoLink>
                </div>
              </div>
    }
    else{
      return ''
    }
  } 

  /** MultimediaButtons And Titles **/
  const getMultimediaButtons= () => {
    return  <div className='flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:justify-between'>
              <div className='break-all break-words'>
                <h1 className='text-[1.25rem] lg:text-[2.5rem] font-bold text-[#5F585A] '>{actualProperty?actualProperty.address?.street:'Sin calle'}</h1>
                <h2 className='text-[1rem] lg:text-2xl font-semibold text-[#858585] '>{CapitalizeText(actualProperty?actualProperty.address?.suburb:'Sin barrio')}</h2>
                {actualProperty.address?.houseNumber && actualProperty.address?.houseNumber !== 'Sin Unidad'?<h2 className='text-[1rem] lg:text-2xl font-semibold text-[#858585] '>Unidad: {CapitalizeText(actualProperty?actualProperty.address?.houseNumber	:'Sin Unidad')}</h2>:''}
              </div>
              <MultimediaButtonWrapper>
                {actualProperty?.images?.length > 0?
                <MultimediaButton buttonName='photos' buttonFn={changeCurrentMultimedia} actualMultimedia={actualMultimedia}>Fotos</MultimediaButton>
                :<></>}
                {actualProperty?.video?
                <MultimediaButton buttonName='videos' buttonFn={changeCurrentMultimedia} actualMultimedia={actualMultimedia}>Videos</MultimediaButton>
                :<></>}
              </MultimediaButtonWrapper>
            </div>
  } 

  /** Multimedia **/
  const getMultimedia = () => {
    return  <> {actualMultimedia==='photos'?<SliderGallery images={actualProperty?.images?actualPropertyImages:[]}/>:''}
              {actualMultimedia==='videos'?<YoutubePlayer videoURL={actualProperty?.video?.urlPath}/>:''}
            </>
  } 
  /** PropertyInfo **/
  const getPropertyInfo= () => {
    return  <PropertyInfoWrapper>
              <PropertyInfoWrapperTitle>
                {actualProperty?.propertyOperations[0] && actualProperty?.propertyOperations !== undefined && actualProperty?.propertyOperations !== null?mapCurrencyName(actualProperty.propertyOperations[0].currency)+' '+formatNumberWithDots(actualProperty.propertyOperations[0].price):''}
              </PropertyInfoWrapperTitle> 
              <div className='flex flex-col space-y-4 lg:flex-row justify-between lg:space-y-0'>
                <div>
                  <PropertyDetailIconsList iconsArray={propertyDetailIconsArray}/>
                  <div className='flex flex-row space-x-4 w-full text-[#858585] mt-4'>
                    {
                      propertyDetailIconsArray.map((detailIcon)=>{
                        const actualIcon = detailIcon[0]
                        if (detailIcon[1] > 0 && detailIcon[1] !==null && detailIcon[1] !==undefined) {
                          return <p>{propertyIconDetails[actualIcon]}: {detailIcon[1]}</p>
                        }
                      })
                    }
                  </div>
                </div>
                <span className='rounded-2xl bg-[#081382] text-white px-8 py-4 font-bold text-base text-center h-max'>
                  {actualProperty?.propertyOperations[0] && actualProperty?.propertyOperations !== undefined && actualProperty?.propertyOperations !== null?mapOperationType(actualProperty.propertyOperations[0].type):''}
                </span>
              </div>
            </PropertyInfoWrapper>
  }
  /** PropertyDescription **/
  const getPropertyDescription= () => {
    if (actualProperty?.description) {
      return  <PropertyInfoWrapper classname={'space-y-4'}>
                <PropertyInfoWrapperTitle>Descripción</PropertyInfoWrapperTitle>
                <ShowMoreLessComponent text={actualProperty?.description?actualProperty?.description:''} wordLimit={75}/>
              </PropertyInfoWrapper>
    }
    else{
      return ''
    }
  } 
  /** PropertyDescription **/
  const getPropertyAmenitiesAndServices= () => {
    return  <PropertyInfoWrapper classname={'space-y-8'}>
             {actualProperty?.amenities.length > 0?<div>
                <PropertyInfoWrapperTitle>Amenities</PropertyInfoWrapperTitle>
                <AmenitiesServicesPropertyList path={actualProperty?.amenities?actualProperty?.amenities:[]} type={'amenities'}/>
              </div>:''}
              {actualProperty?.services.length > 0?<div>
                <PropertyInfoWrapperTitle>Servicios</PropertyInfoWrapperTitle>
                <AmenitiesServicesPropertyList path={actualProperty?.services?actualProperty?.services:[]} type={'services'}/>
              </div>:''}
            </PropertyInfoWrapper>
  }
  /** PropertyLocation**/
  const getPropertyLocation= () => {
    return  <PropertyInfoWrapper classname={'space-y-4'}>
              <PropertyInfoWrapperTitle>Ubicación</PropertyInfoWrapperTitle>
              <p className='text-[#5F585A] text-[1.25rem] lg:text-[1.563rem]'>
                {actualProperty?.address?.street?actualProperty?.address?.street:''}
                {CapitalizeText(actualProperty?.address?.suburb?', '+actualProperty?.address?.suburb:'')}
                {actualProperty?.address?.city?', '+actualProperty?.address?.city:''}
                {actualProperty?.address?.country?', '+actualProperty?.address?.country:''}
              </p>
              {actualProperty?.address?.latitude && actualProperty?.address?.longitude?
              <PropertyInfoMap zoom={15} position={{
                lat:actualProperty?.address?.latitude?actualProperty.address.latitude:-40.497,
                lng:actualProperty?.address?.longitude?actualProperty.address.longitude:-63.633
                }}/>:''}
            </PropertyInfoWrapper>
  }

  /** Porperty Render **/
  if (actualProperty !==null && actualProperty !==undefined) {
    return (
      isLoading?<CircularLoader/>
      :<div className={propertySectionClasses}>
        {getExporterInfo()}
        {getMultimediaButtons()}
        {getMultimedia()}
        {getPropertyInfo()}
        {getPropertyDescription()}
        {getPropertyAmenitiesAndServices()}
        {getPropertyLocation()}
      </div>
    );
  }
  else{
    return (isLoading?<CircularLoader/>:<NotFound errorStatus={error}/>)
  }
}

export default ExportedProperty;