import "../../CSS/Reusable/DataInput.scss";
import {useEffect, useState} from "react";
import {setJsonFromDotedValue} from "../../globalHelper"

export default function DataSelectBox(props) {

    const columnSpan = props.columnSpan ? "md:col-span-" + props.columnSpan : null;

    const [selectedValue, setSelectedValue] = useState(props.defaultValue);

    useEffect(()=>{
        let data = props.defaultValue;
        setSelectedValue(data);
    }, [props.defaultValue])
    
    
    const eventHandler = (data)=>{
        if(props.ChangeState)
        {
            setSelectedValue(data)
            props.ChangeState(setJsonFromDotedValue({},props.targetName,data))
            if(props.paymentScheduleForm){
                props.paymentScheduleForm(data);
            }
        }
        else{
            setSelectedValue(data)
        }

    }

    return (
        <div className={columnSpan}>
            {props.tittle?<h3>{props.tittle}</h3>:null}
            <select className="dataInput" onChange={(event => eventHandler(event.target.value))} value={selectedValue}>
                {props.entries.map((entry, index) =>
                    <option key={index} value={entry.value}>
                        {entry.name}
                    </option>
                )}
            </select>
        </div>
    )
}

