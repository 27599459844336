import React, {useState, useEffect} from 'react';
import homeAsset1 from '../../Assets/card-home-asset-1.png';
import homeAsset2 from '../../Assets/card-home-asset-2.png';
import AccountedAndCollected from './Home/AccountedAndCollected';
import Expenses from './Home/Expenses';
import Commissions from './Home/Commissions';
import ReservationRecords from './Home/ReservationRecords';
import SearchesRecords from './Home/SearchesRecords';
import RecordsScreen from './Home/RecordsScreen';

const Card = (props) => {
    const [textTest, setTextTest] = useState(<AccountedAndCollected/>);

    const textTestChange = (e) => {
        if (e.currentTarget.value === "AccountedAndCollected") {
            setTextTest(<AccountedAndCollected/>);
        }
        if (e.currentTarget.value === "Expenses") {
            setTextTest(<Expenses/>);
        }
        if (e.currentTarget.value === "Commissions") {
            setTextTest(<Commissions/>);
        }
        if (e.currentTarget.value === "ReservationRecords") {
            setTextTest(<RecordsScreen/>);
        }

    }

    let apiTest = [
        {"sectionName": "Cobranzas & Rendiciones", "cash": 1000, "items": 6, "changeCard": 'AccountedAndCollected'},
        {"sectionName": "Gastos", "cash": 440, "items": 4, "changeCard": "Expenses"},
        {"sectionName": "Comisiones", "cash": 200, "items": 2, "changeCard": "Commissions"},
        {"sectionName": "Historial", "cash": 850, "items": 8, "changeCard": "ReservationRecords"}
    ];
    return (<>
            <div className='card-group'>
                {
                    apiTest.map(seccion => {
                        // if (seccion.sectionName === "Historial de Reservas") {
                        return (
                            <div className='card home-card center-content'
                                 key={seccion.changeCard}>
                                <div className='cash-card-home reservation'><img src={homeAsset2}
                                                                                 style={{width: '4rem'}}/></div>
                                <div className='cash-card-name'>
                                    <button onClick={textTestChange} value={seccion.changeCard}>
                                        <h5>{seccion.sectionName}</h5>
                                    </button>
                                </div>
                            </div>
                        );
                        /*}
                        return (
                            <div className='card home-card center-content' key={seccion.changeCard}>
                                <div className='cash-card-home'>
                                    <h6>{"$ " + seccion.cash}</h6></div>
                                <div className='cash-card-name'>
                                    <h5>{seccion.sectionName}</h5>
                                    <button onClick={textTestChange} value={seccion.changeCard}><p
                                        style={{fontSize: "0.8rem"}}>{seccion.items} items -</p></button>
                                </div>
                            </div>
                        )*/
                    })
                }

            </div>
            <div>{textTest}</div>
        </>
    )
}

export default Card;