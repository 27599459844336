import {useEffect, useState} from "react";
import DataContainerCard from "../DataContainerCard"
import DataDayPicker from "../DataDayPicker"
import DataInput from "../DataInput"
import Grid from "../Grid"
import {getIcon} from "../../../Services/iconService";

export default function ContractObservations({contractForm,setContractForm,onInputChange}) {

    const newContractObservation = ()  =>{
        let newData ={
            date:new Date(),
            description:""
        }
        setContractForm({
            ...contractForm,
            observations:[
                ...contractForm.observations, 
                newData
            ]
        })
    }

    const deleteContractObservation = (e,OPindex) =>  {
        e.preventDefault();
        setContractForm(prev => {
            const observations = contractForm.observations.filter((_, index) => index !== OPindex);
            return {
              ...prev,
              observations
            }
          })
    }

    return (
        <DataContainerCard tittle="OBSERVACIONES" centerTittle>
            {
                contractForm.observations.map((item, index) => {
                    let showIndex = index+1;
                    return (
                        <DataContainerCard tittle={'#'+showIndex} className="ContractForm">
                        <Grid columns="2">
                            <DataDayPicker tittle="Fecha"   placeHolder="Ingrese la fecha de la observacion"
                                                            ChangeState={onInputChange}
                                                            targetName={'observations['+index+'].date'}
                                                            defaultValue={new Date(item.date)}/>
                            <button onClick={(e) => deleteContractObservation(e,index)}>
                                <div className="dataInputIconElement">{getIcon("deleteX")}</div>
                            </button>
                        </Grid>
                        <Grid columns="1">
                            <DataInput bigInput type="Text" tittle="Observaciones"  
                                                            ChangeState={onInputChange}
                                                            targetName={'observations['+index+'].description'}
                                                            defaultValue={item.description}/>
                        </Grid>
                    </DataContainerCard>
                    );
                })
            }
            <Grid columns="1">
                <label className="text-blue-900 font-semibold cursor-pointer mt-1 mb-1" onClick={newContractObservation}> +
                    Agregar nueva
                    observacion</label>
            </Grid>
        </DataContainerCard> 
    )

}