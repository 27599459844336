import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Clipboard from "clipboard";

export default function ThreeDotSelector({ actions, rowComponentId, rowComponentType }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [copyText, setCopyText] = useState(""); // Texto dinámico para copiar
  const copyButtonRef = useRef(null); // Botón invisible para Clipboard.js

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Configurar Clipboard.js para el botón invisible
  useEffect(() => {
    if (copyButtonRef.current) {
      const clipboard = new Clipboard(copyButtonRef.current, {
        text: () => copyText, // Texto a copiar dinámicamente
      });

      clipboard.on("success", () => {
        alert("Texto copiado al portapapeles");
      });

      clipboard.on("error", () => {
        alert("Error al copiar el texto.");
      });

      return () => clipboard.destroy(); // Limpiar instancia al desmontar
    }
  }, [copyText]);

  const handleActionClick = (action, id, type) => {
    if (action.showName === "Copiar Link") {
      // Generar el texto dinámico antes de copiar
      action
        .onClick(id, type) // Llama a `exportPropertyFn`
        .then((generatedText) => {
          setCopyText(generatedText); // Actualiza el texto a copiar
          setTimeout(() => {
            copyButtonRef.current.click(); // Simula el clic en el botón oculto
          }, 0); // Permite que `copyText` se actualice antes del clic
        });
    } else {
      // Llamar otras acciones
      action.onClick(id, type);
    }
    handleClose();
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        ...
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {actions.map((action, index) => (
          <MenuItem
            key={index}
            onClick={() => handleActionClick(action, rowComponentId, rowComponentType)}
          >
            {action.showName}
          </MenuItem>
        ))}
      </Menu>
      {/* Botón invisible para Clipboard.js */}
      <button
        ref={copyButtonRef}
        style={{ display: "none" }}
        aria-hidden="true"
      />
    </>
  );
}