import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../CSS/Reusable/ListLinks.scss";
  
function SliderGallery({images}) {

    const defaultImage = require('../../Assets/WithoutImage.png');

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        asNavFor: nav2,
        beforeChange: (current, next) => setCurrentIndex(next),
    };
    
    const settingsThumbs = {
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: nav1,
        focusOnSelect: true,
        vertical: true,
        verticalSwiping: true,
        arrows: false,
    };

    if (images?.length < 1 || images === null || images === undefined) {
        images =[
            defaultImage,
            defaultImage,
            defaultImage,
        ]
    }
    else if (images.length < 3) {
        while (images.length < 3) {
            images.push(defaultImage);
          }
    }

    const thumbnailHeight = 620 / 3;

    return (
        <div className="w-full mx-auto lg:py-8 flex">
            <div className="w-full  lg:w-3/4 rounded-[20px]">
                <Slider {...settingsMain} asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                    {images.map((image, index) => (
                    <div key={index} className='h-[310px] sm:h-[500px] lg:h-[620px] rounded-[20px] overflow-hidden relative'>
                        <img src={image} alt={`Slide ${index}`} className="w-full h-full rounded-[20px]" />
                        {index === currentIndex && (
                            <span className="absolute bottom-2 right-2 bg-[#858585]/40 text-white text-xs px-2 py-1 rounded-full ">
                            {currentIndex + 1}/{images.length}
                            </span>
                        )}
                    </div>
                    ))}
                </Slider>
            </div>
            <div className="hidden lg:block lg:w-1/4 pl-4 max-h-[310px] lg:max-h-[620px] overflow-hidden">
                <Slider {...settingsThumbs} asNavFor={nav1} ref={(slider2) => setNav2(slider2)} className="thumbs-slider" style={{ height: '620px' }}>
                    {images.map((image, index) => (
                    <div key={index} className={` rounded-[20px] overflow-hidden h-['${thumbnailHeight}'px'] ${index === currentIndex ? 'border-[3px] border-[#081382]' : ''}`} style={{ height: `${thumbnailHeight}px` }}>
                        <img src={image} alt={`Thumbnail ${index}`} className={"w-full h-full object-cover cursor-pointer  "+'!h-['+thumbnailHeight+'px]'} />
                    </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default SliderGallery;