import React, { useEffect, useState } from 'react';
import "../../CSS/Reusable/dataContainerCard.scss";

export default function DataContainerCard({tittle, centerTittle = false, children, centerChildrens = false, cardType='standard', plegable=false}) {

    const [showCardInfo, setShowCardInfo] = useState(true);

    const getClasses = () => {
        switch (cardType) {
            case 'standard':
                return "h1".concat(centerTittle?" centerTittle":"")
                break;
            case 'special':
                return " !mt-auto lg:!mt-[5.438rem] mx-auto text-[1.5rem] font-semibold lg:font-bold !text-[#5F585A]"
                break;
            default:
                return "h1".concat(centerTittle?" centerTittle":"")
                break;
        }
    }

    const getChildrenClasses = () => {
        return "w-full".concat(centerChildrens?" centerChildrens":"")
    }

    return (
        <div className="dataContainerCard">
            <div className='flex flex-row justify-between items-center lg:items-end'>
                {tittle ? <h1 className={getClasses()}> {tittle}</h1> : null}
                {plegable?<button onClick={()=>{setShowCardInfo(!showCardInfo)}} className='lg:hidden'>{showCardInfo?'▴':'▾'}</button>:''}
            </div>
            {
                plegable && showCardInfo !== false?
                <div className={getChildrenClasses()}>{children}</div>
                :''
            }
            {
                !plegable && showCardInfo?
                <div className={getChildrenClasses()}>{children}</div>
                :''
            }
        </div>
    )
}