import axios from "axios";
import authHeaderService from "../../authHeaderService";
import {authorizationCheck, devLog} from "../../../globalHelper";

export async function fetchPayments(){
    
    let year = new Date().getFullYear();
    let first = year+"-01-01";
    let last = year+"-12-31";
    const dateParam = "date1="+first+"&date2="+last;

    return await axios
    .get(
        `${process.env.REACT_APP_API_URL}/api/calendar/payments?`+dateParam,
        authHeaderService()
    )
    .then((res) => {
        return res.data;
    })
    .catch((e) => {
        if (e.response) {
            devLog(true, e);
            authorizationCheck(e); 
        }
        else{
            console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
        }
    });
  };