import axios from "axios";
import {authorizationCheck, devLog} from "../../../../globalHelper";

export async function getExportedProperty(id){
    return await axios
    .get(
        `${process.env.REACT_APP_API_URL}/api/website/properties/${id}`,
    )
    .then((res) => {
        return res.data;
    })
    .catch((e) => {
      if(e.response){
        devLog(true, e.response.status)
        authorizationCheck(e);
        return  e.response.data;
    }
    else{
        console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
    }
    });
  };

export async function fetchProperties(operations, page = 0, size = 10) {
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/website/properties`,
        {
          operations: operations ? operations : null,
          paging: { page, size },
        },
      )
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        if(e.response){
          devLog(true, e.response.status)
          authorizationCheck(e);
          return  e.response.data;
      }
      else{
          console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
      }
      });
  }
  
  export function addPropertiesFilter(property, value, operator) {
    return {
      property: property,
      value: value,
      operator: operator,
    };
  }