/*Transformaciones grupales*/

import {devLog} from "./globalHelper";
import {bsAsCabaNeighborhoods} from './dataEntriesHelper'
const ERROR_001 = "error_001"

/*Mapeo y transformacion de los roles de usuario*/
export function dataMapingUserRole (data){
    devLog(true, "[Binculo], dataMapingUserRole > data: ", data)

    if (data && Array.isArray(data.content)) {
        data.content.map(value=>{
            value.roles.map((roleValue,index)=>{
                if (roleValue === "ADMIN") {
                    value.roles[index] = 'Admin';
                } else if (roleValue === "OWNER") {
                    value.roles[index] = 'Propietario';
                } else if (roleValue === "TENANT") {
                    value.roles[index] = 'Inquilino';
                } else if (roleValue === "CONTACT") {
                    value.roles[index] = 'Contacto';
                } else if (roleValue === "BROKER") {
                    value.roles[index] = 'Broker';
                } else if (roleValue === "RUNNER") {
                    value.roles[index] = 'Agente';
                } else if (roleValue === "REFERRAL") {
                    value.roles[index] = 'Referidor';
                } else {
                    devLog(true, "[Binculo],", ERROR_001, "value in #dataMapingUserRole is not valid: ", roleValue)
                }
            })
        });
    } else {
        devLog(true, "[Binculo] data.content is not defined or not is an array");
    }
    
    
}

/*Mapeo y transformacion de los tipos de moneda*/
export function dataMapingCurrency (data){
    data.map(value=>{
        value.currency.map((roleValue,index)=>{
            if (roleValue === "PESOS") {
                value.currency[index] = '$';
            }
            if (roleValue === "DOLLARS") {
                value.currency[index] = 'U$D';
            }
            if (roleValue === "OFFICIAL_DOLLARS") {
                value.currency[index] = 'U$D Oficiales';
            }
            if (roleValue === "USDT") {
                value.currency[index] = 'USDT';
            }
            if (roleValue === "PERCENTAGE") {
                value.currency[index] = '%';
            }
        })
    })
}

/*Transformaciones individuales*/

/*Mapeo el tipo de moneda*/ 
export function mapCurrencyName(data){
            if (data === "PESOS") {
                return data = '$';
            }
            if (data === "DOLLARS") {
                return data = 'U$D';
            }
            if (data === "OFFICIAL_DOLLARS") {
                return data = 'U$D Oficiales';
            }
            if (data === "USDT") {
                return data = 'USDT';
            }
            if (data === "PERCENTAGE") {
                return data = '%';
            }
}

/*Mapeo el tipo de operacion de una propiedad*/ 
export function mapOperationType(data){
    if (data === "BuyOperation") {
        return data = 'Venta';
    }
    if (data === "RentOperation") {
        return data = 'Alquiler';
    }
}
export function mapOperationType2(data){
    if (data === "BUY") {
        return data = 'Venta';
    }
    if (data === "RENTAL") {
        return data = 'Alquiler';
    }
    if (data === "TEMPORAL_RENT") {
        return data = 'Alquiler Temporal';
    }
    
}

export function mapPropertyType(data) {
    const typeMapping = {
        "BUILDING": 'Departamento',
        "HOUSE": 'Casa',
        "STORE": 'Local Comercial',
        "LAND": 'Terreno',
        "DEPOSIT": 'Deposito',
        "OFFICE": 'Oficina',
        "SHOPPING_TRIP": 'Paseo de Compras'
    };
    return typeMapping[data] || data;
}

/*Mapeo los servicios de una propiedad*/ 
export function mapPropertyServices(data){
    if (data === "ELECTRICITY") {
        return data = 'Electricidad';
    }
    if (data === "GAS") {
        return data = 'Gas';
    }
    if (data === "WIFI") {
        return data = 'Wi fi';
    }
    if (data === "WATER") {
        return data = 'Agua';
    }
    if (data === "ABL") {
        return data = 'Abl';
    }
    if (data === "TV_PROVIDER") {
        return data = 'Cable';
    }
    if (data === "PHONE") {
        return data = 'Teléfono';
    }
    if (data === "ORDINARY_EXPENSES") {
        return data = 'Expensas ordinarias';
    }
    if (data === "EXTRAORDINARY_EXPENSES") {
        return data = 'Expensas extraordinarias';
    }
}
/*Mapeo los amenities de una propiedad*/ 
export function mapPropertyAmenities(data){
    if (data === "SUM") {
        return data = 'Sum';
    }
    if (data === "BARBECUE") {
        return data = 'Parrilla';
    }
    if (data === "OUTSIDE_POOL") {
        return data = 'Pileta externa';
    }
    if (data === "INSIDE_POOL") {
        return data = 'Pileta interna';
    }
    if (data === "PLAY_ROOM") {
        return data = 'Salon de juegos';
    }
    if (data === "GUESTS_GARAGES") {
        return data = 'Garage de invitados';
    }
}
/**Mapeo individual de barrio */

export function getNeighborhoodName(suburb) {
    return bsAsCabaNeighborhoods[suburb] || "Barrio no encontrado";
}