import { MapContainer, TileLayer, Marker } from "react-leaflet";

export default function Map({ icon, center, zoom, results }) {
  return (
    <MapContainer
      key={JSON.stringify(center[0], center[1])+Math.random()}
      className="PropertiesMap"
      center={center}
      scrollWheelZoom={true}
      zoom={zoom}
    >
      {results?.map((result) => (<Marker icon={icon} key={result.x + result.y} position={[result.y, result.x]}></Marker>))}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </MapContainer>
  );
}