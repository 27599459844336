import React from 'react';
import { getIcon } from "../../Services/iconService";

function PropertyDetailIconsList({iconsArray}) {

  return (
    <div className='flex flex-row justify-between w-full lg:mr-[8rem] items-center text-[1.25rem] lg:text-[1.875rem] xl:text-[2.5rem] text-[#858585]'>
        {
            iconsArray.map((icon,index)=>{               
                if (icon[0] === 'totalSquareMeters' && icon[0] > 0 && icon[0] !== null && icon[0] !== undefined) {
                    return(
                    <div key={index} className='flex flex-row items-center lg:text-[0.938rem] lg:text-[1.563rem] space-x-2 lg:space-x-4'>{getIcon(icon[0])}<p className='text-[18px] lg:text-[25px]'> {icon[1] ? Math.round(icon[1]) : "N/A"} m<sup>2</sup></p></div>
                    )  
                }
                else if(icon[1] > 0 && icon[1] !== null && icon[1] !== undefined){                 
                    return(
                    <div key={index} className='flex flex-row items-center space-x-2 lg:space-x-4'>{getIcon(icon[0])}<p className='text-lg lg:text-[1.563rem]'> {icon[1]}</p></div>
                    )
                }
            })
        }
    </div>
  );
}

export default PropertyDetailIconsList;