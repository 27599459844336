import BackgroudContainer from "../BackgroudContainer";
import DataContainerCard from "../../Reusable/DataContainerCard";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchReferredOperationsForLoggedInReferral} from "../../../Services/referredOperationService";
import DataTable from "../../Reusable/DataTable/DataTable";
import FirstLoginModal from "../../Reusable/Users/FirstLoginModal";
import CircularLoader from "../../Reusable/CircularLoader";
import Grid from "../../Reusable/Grid";
import ButtonStandard from "../../Reusable/ButtonStandard";

export default function ReferralsHome() {

    const navigate = useNavigate();

    const [referredOperations, setReferredOperations] = useState([]);

    const [mappedReferredOperations, setMappedReferredOperations] = useState([]);

    const [showModal, setShowModal] = useState({show: false});

    const [responseModal, setResponseModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [selectedRefOp, setSelectedRefOp] = useState(undefined);

    /*
        const [operationsWithCommissions, setOperationsWithCommissions] = useState([]);
    */


    const dataTableColumns = [
        {field: 'linkProperty', showName: 'Link de la propiedad'},
    ];

    const fetchReferredOperations = () => {
        setIsLoading(true);
        Promise.resolve(fetchReferredOperationsForLoggedInReferral()).then(res => {
            console.log(res);
            setReferredOperations(res);
            setMappedReferredOperations(mapReferredOperations(res));
            setIsLoading(false);
        });
    }

    const mapReferredOperations = (referredOps) => {
        console.log(referredOps);
    
        let baseUrl = window.location.protocol + "//" + window.location.hostname;
    
        // Verifica si es localhost y si hay un puerto especificado
        if (window.location.hostname === "localhost" && window.location.port) {
          baseUrl += ":" + window.location.port;
        }
    
        const referredsMap = {};
    
        for (const refOp of referredOps) {
            const referredId = refOp.referred.id;
            
            // Si el referred.id ya existe, combinar propiedades
            if (referredsMap[referredId]) {
                referredsMap[referredId].operationsAndContracts = [
                    ...referredsMap[referredId].operationsAndContracts,
                    ...(refOp.properties || [])
                ];
            } else {
                // Si no existe, creamos la nueva entrada
                referredsMap[referredId] = {
                    fullName: refOp.referred.name + " " + refOp.referred.surname,
                    operationsAndContracts: refOp.properties ? refOp.properties : []
                };
            }
        }
    
        // Convertimos el objeto de referidos en un array
        const referredsArray = Object.values(referredsMap);
    
        // Añadimos el enlace a cada propiedad
        referredsArray.forEach(referred => {
            referred.operationsAndContracts.map(property => {
                if (property.address === null && property.propertyType === null && property.category === null) {
                    const correctId = parseInt(property.id)-1
                    console.log(correctId);
                    
                    property.linkProperty = (
                        <a className="text-[#081382] text-[18px]" target='_blank' href={baseUrl + '/properties/' + correctId}>
                            {baseUrl + '/properties/' + correctId}
                        </a>
                    );
                }
                else{
                    property.linkProperty = (
                        <a className="text-[#081382] text-[18px]" target='_blank' href={baseUrl + '/properties/' + property.id}>
                            {baseUrl + '/properties/' + property.id}
                        </a>
                    );
                }
            });
        });
    
        return referredsArray;
    };

    useEffect(() => {
        fetchReferredOperations();
    }, [])

    const modalFunction = () => {
        let newLocalVars = JSON.parse(localStorage.user);
        newLocalVars.hasAlreadyChangedPassword = true;
        localStorage.setItem("user", JSON.stringify(newLocalVars));
    }

    /*useEffect(() => {
        const fetchCommissions = async () => {
            let newOperations = [];
            for (let operation of referredOperations) {
                const commission = await fetchCommissionForReferredOperation(operation.id);
                newOperations.push({ ...operation, commissions: commission });
            }
            setOperationsWithCommissions(newOperations);
        }
        fetchCommissions();
    }, [referredOperations])
*/


    return (
        <BackgroudContainer>
            {<FirstLoginModal modalState={showModal}
                              childrenTitle={'Ingrese su nueva contraseña con la que ingreserá al sistema'}
                              className="ContractModalForm"
                              modalSubmit={modalFunction} responseModal={responseModal}/>}


            {selectedRefOp ? <ButtonStandard blueColor float="center" showName="Volver" onClick={() => setSelectedRefOp(undefined)}/> : undefined}

            {!selectedRefOp ? <DataContainerCard tittle="Referidos" centerTittle>
                {
                    isLoading ?
                        <CircularLoader/> :
                        <Grid columns="1">
                            <div style={{'gap': '1rem', 'display': 'grid'}}>{(mappedReferredOperations.map(refOp => {
                                return <ButtonStandard float="center" showName={refOp.fullName}
                                                       onClick={() => setSelectedRefOp(refOp.operationsAndContracts)}
                                                       blueColor/>
                            }))}
                            </div>

                        </Grid>
                }
            </DataContainerCard> : undefined}

            {selectedRefOp ?
                <BackgroudContainer>
                    <DataContainerCard tittle="Propiedad publicada" centerTittle >
                        <DataTable rows={selectedRefOp} columns={dataTableColumns}/>
                    </DataContainerCard>
                </BackgroudContainer> : undefined
            }

        </BackgroudContainer>
    );
}
