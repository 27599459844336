import "../../CSS/Reusable/DataSelectBoxInput.scss";
import {useEffect, useState} from "react";
import {setJsonFromDotedValue} from "../../globalHelper"

export default function DataSelectBoxInput(props) {

    useEffect(() => {
        if (props.defaultValue) {
            let index1 = props.indexValues[0];
            let index2 = props.indexValues[1];
            setSelectedValue(props.defaultValue[index1]);
            setValue(props.defaultValue[index2])
        }

    }, [props.defaultValue])

    const columnSpan = props.columnSpan ? "md:col-span-" + props.columnSpan : null;


    const [value, setValue] = useState(null);
    const [selectedValue, setSelectedValue] = useState(props.entries[0].value);

    const eventHandler = (event) => {
        if (props.realTimeRefresh) {

            let index1 = props.indexValues[0];
            let index2 = props.indexValues[1];
            let newValue = {[index1]: selectedValue, [index2]: Number(event.target.value)};

            setValue(event.target.value)
            props.ChangeState(newValue,props.name,props.currentSetState, props.currentState) 
        }
        else{
            if (event < 0 && props.type === 'number') {
                alert('Solo se aceptan valores mayores que 0')
            } else {
                if (props.ChangeState) {
                    let index1 = props.indexValues[0];
                    let index2 = props.indexValues[1];
                    let newValue = {[index1]: selectedValue, [index2]: Number(event)};
                    setValue(event)
                    props.ChangeState(setJsonFromDotedValue({}, props.targetName, newValue))
                } else {
                    setValue(event)
                }
            }
        }
    }
    const inputCheckNumber = (e) => {
        if (e === "" && props.type === 'number') {
            setValue(0)
        }
    }

    const currencyHandler = (event) => {
        if (props.realTimeRefresh) {
            let index1 = props.indexValues[0];
            let index2 = props.indexValues[1];
            let newValue = {[index1]: event.target.value, [index2]: value};
            setSelectedValue(event.target.value)
            props.ChangeState(newValue,props.name,props.currentSetState, props.currentState) 
        }
        else{
            if (props.ChangeState) {
                let index1 = props.indexValues[0];
                let index2 = props.indexValues[1];
                let newValue = {[index1]: event, [index2]: value};
                setSelectedValue(event)
                props.ChangeState(setJsonFromDotedValue({}, props.targetName, newValue))
            } else {
                setSelectedValue(event)
            }
        }

    }

    let inputClassName = '';
    let inputPaddingLgPercent = 6+(selectedValue !== null && selectedValue !== undefined?selectedValue.length:0);
    let inputPaddingMPercent = 8+(selectedValue !== null && selectedValue !== undefined?selectedValue.length:0);
    
    inputClassName = inputClassName.concat(
        ""
        ,props.onlyReadable?' pointer-events-none text-grey-1 bg-[#e6e6e6] ':''
        ,props.phoneInput?' pl-['+inputPaddingMPercent+'%] lg:pl-['+inputPaddingLgPercent+'%]':''
    );

    

    return (
        <div className={columnSpan}>
            {props.tittle ? <h3>{props.tittle}</h3> : null}
            <div className={"dataSelectBoxInput"+inputClassName}>
                <select className={props.entries.length === 1 ? 'singleOptionSelect' : null+inputClassName}
                        name={props.name}
                        onChange={(event => currencyHandler(props.realTimeRefresh?event:event.target.value))} value={selectedValue}>
                    {props.entries.map((entry, index) =>
                        <option key={index} value={entry.value}>
                            {entry.name}
                        </option>
                    )}
                </select>
                {props.phoneInput?<span className="absolute left-0 ml-[32%] font-bold text-slate-500 pointer-events-none before:content-['+'] ">{selectedValue}</span>:''}
                <input onChange={(event => eventHandler(props.realTimeRefresh?event:event.target.value))} type={props.type}
                    onWheel={(e) => e.target.blur()}
                    name={props.name}
                    placeholder={props.placeHolder} value={value} min="1" pattern="^[0-9]+"
                    onInput={(e) => inputCheckNumber(e.target.value)} className={inputClassName}/>
            </div>
        </div>
    )
}



