import React from 'react';

function ExporterInfoLink({children, linkPath}) {

  return (
    <a href={linkPath} className='flex space-x-4 text-[18px] lg:text-[22px] font-normal text-[#858585] items-center [&_*]:cursor-pointer w-max'>
        {children}
    </a>
  );
}

export default ExporterInfoLink;