import React, { useState } from 'react';

function ShowMoreLessComponent({ text, wordLimit }) {

  const [isExpanded, setIsExpanded] = useState(false);

  const words = text.split(' ');
  const shouldTruncate = words.length > wordLimit;
  const displayedText = isExpanded || !shouldTruncate ? text : words.slice(0, wordLimit).join(' ') + '...';

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className='flex flex-col space-y-4'>
      <p className='text-[#858585]'>{displayedText}</p>
      {shouldTruncate && (
        <button onClick={toggleText} className='bg-[#FAFAFA] rounded-lg p-3 w-max text-[#5F585A] text-xl font-semibold'>
          {isExpanded ?<>Mostrar menos <span className='text-[#081382]'>-</span></>:<>Ver más <span className='text-[#081382]'>+</span></>}
        </button>
      )}
    </div>
  );
}

export default ShowMoreLessComponent;