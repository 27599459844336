export const getProperties = (properties) => {
  return {
    type: "GET_PROPERTIES",
    payload: properties,
  };
};

export const filterProperties = (filter) => {
  return {
    type: "FILTER_PROPERTIES",
    payload: filter,
  };
};

export const resetFilters = () => {
  return {
    type: "RESET_FILTERS",
  };
}