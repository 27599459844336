import React, { useState } from "react";
import "../../CSS/Reusable/PropertiesMap.scss";
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import PropertyFormMap from "./PropertyFormMap";
import PropertiesLocationMap from "./PropertiesLocationMap";
import { greenMarker, redMarker } from "../../Services/MainViews/Properties/propertiesService";

export default function PropertiesMap({ properties, onChangeFunction }) {
  const initialCenter = [-34.59, -58.45];
  const [center, setCenter] = useState(initialCenter);
  const initialZoom = 12;
  const [zoom, setZoom] = useState(initialZoom);
  const [results, setResults] = useState([]);
  const provider = new OpenStreetMapProvider();
  const input = document.getElementById('addressInput');

  const [marker, setMarker] = useState(greenMarker);

  function handleInputSubmit(e) {
    e.preventDefault();
    setResults([]);
    provider.search({ query: input.value }).then(function (result) {
      setResults(result);
    });
  }

  function handleSelectAddress(e, i) {
    e.preventDefault();
    setCenter([parseFloat(e.target.attributes.y.nodeValue).toFixed(5), parseFloat(e.target.attributes.x.nodeValue).toFixed(5)]);
    setZoom(16);
    setMarker(redMarker);
    input.value = e.target.innerText;
    e.target.className = "selected";
    results.forEach((res, j) => {
      if (j === i) {
        setResults([res])
        return null;
      }
    });
    let latitude = parseFloat(e.target.attributes.y.nodeValue).toFixed(5);
    let longitude = parseFloat(e.target.attributes.x.nodeValue).toFixed(5);
    onChangeFunction([latitude, longitude])
  }

  return (
    <>
      {properties?.length ? (
        <section className="PropertiesMap_Container">
          <PropertiesLocationMap icon={marker} center={initialCenter} zoom={initialZoom} properties={properties} />
        </section>
      ) : (<section className="PropertiesMap_Container">
        <form onSubmit={(e) => handleInputSubmit(e)}>
          <input autoComplete="off" id="addressInput" type="text" placeholder="Ingrese una direccion" />
        </form>
        <PropertyFormMap icon={marker} center={center} zoom={zoom} results={results} />
      </section>
      )}
      <section className="PropertiesMap_SearchResults" style={{ display: results.length ? "flex" : "none" }}>
        {results?.map((result, i) => (<button value={result.label} x={result.x} y={result.y} onClick={(e) => handleSelectAddress(e, i)} key={i}>{result.label}</button>))}
        {/* VER COMO CLIECKEAR, SELECCIONAR Y PASARLE X Y AL BACK, ADEMAS MARCAR EL MARKER EN EL MAPA DISTINTO Y HACER CENTRO EN ESE PUNTO */}
      </section>
    </>
  );
}
