import  React,  { useState, useEffect  } from  'react';
import axios from "axios";
import Switch from '@mui/material/Switch';
import authHeaderService from "../../authHeaderService";
import {authorizationCheck, devLog} from "../../../globalHelper";


export async function getContractAddressesForLoggedOwner(){
    return await axios
    .get(
        `${process.env.REACT_APP_API_URL}/api/tenantowner/owner/addresses`,
        authHeaderService()
    )
    .then((res) => {
        return res.data;
    })
    .catch((e) => {
        if(e.response){
            devLog(true, e.response.status)
            authorizationCheck(e);
            return  e.response.data;
            }
        else{
            console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
        }
    });
  };

export async function getContractAddressesForLoggedTenant(){
    return await axios
        .get(
            `${process.env.REACT_APP_API_URL}/api/tenantowner/tenant/addresses`,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
};


export async function getContractByIdForTenant(contractId){
    return await axios
        .get(
            `${process.env.REACT_APP_API_URL}/api/tenantowner/tenant/contracts/${contractId}`,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
};

export async function getContractByIdForOwner(contractId){
    return await axios
        .get(
            `${process.env.REACT_APP_API_URL}/api/tenantowner/owner/contracts/${contractId}`,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
};

export async function getExpensesByContractId(contractId){
    return await axios
        .get(
            `${process.env.REACT_APP_API_URL}/api/expenses/contracts/${contractId}`,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
};