import "../../CSS/Reusable/ButtonBlue.scss";
import { getIcon } from "../../Services/iconService";

export default function ButtonBlue({ showName, onClick, iconName, float, isButtonAble}) {

  const getFloatPosition = () =>{
    if (float){
      switch (float.toLowerCase()){
        case "left":{
          return "!mr-auto"
        }
        case "right":{
          return "!ml-auto"
        }
        case "center":{
          return "!mr-auto !ml-auto"
        }
      }
    }

  }

  let buttonClasses = '';
  buttonClasses = buttonClasses.concat(
      " ButtonBlue "
      ,getFloatPosition()
      ,!isButtonAble && isButtonAble != null?" pointer-events-none bg-[#CDCED4] ":"  "
      
  );



  return (
    <button className={buttonClasses} onClick={onClick}>{showName}{iconName?getIcon(iconName):undefined}</button>)
}