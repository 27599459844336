import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

// CSS Import
import "../../CSS/MainViews/Contacts.scss";

// Components
import BackgroudContainer from "./BackgroudContainer";
import ButtonStandard from "../Reusable/ButtonStandard";
import CircularLoader from "../Reusable/CircularLoader";
import DataContainerCard from "../Reusable/DataContainerCard";
import DataTable from "../Reusable/DataTable/DataTable";
import DataInput from "../Reusable/DataInput";
import DataSelectBox from "../Reusable/DataSelectBox";
import DoubleConfirmation from "../Reusable/DoubleConfirmation";
import Grid from "../Reusable/Grid";
import Pagination from "../Reusable/PaginationList";

// Services
import {fetchContactsList, deleteContact, resetUserPw} from "../../Services/MainViews/Users/contactsService";

// Helpers
import {dataMapingUserRole} from "../../dataMappingUtilHelper";
import { usersEntries } from "../../dataEntriesHelper";
import { devLog, phoneFormateNumber} from "../../globalHelper";


export default function Contacts(){

    const [currentSelectedUserID, setCurrentSelectedUserID] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [responseModal, setResponseModal] = useState(false);
    const [currentModal, setCurrentModal] = useState('');
    const [showModal, setShowModal] = useState({ show: false, onConfirm: () => {} });
    const [keyword, setKeyword] = useState('');
    const [userType, setUserType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [currentContacts, setCurrentContacts] = useState({
      content: []
    });
    const navigate = useNavigate();
    const filters = [];
  
    const getContacts = async (operations, page, size) => {
      setIsLoading(true);
      let contacts = null;
      contacts = await fetchContactsList(operations, page, size);

      contacts.content = contacts.content.map(contact => {
          return {
              ...contact,
              cellPhone: phoneFormateNumber(contact.cellPhone)
          };
      });
      
      if (contacts && contacts !== null && contacts !== undefined) {
        dataMapingUserRole(contacts);
        setCurrentContacts(contacts);
        setIsLoading(false);
      }
      else{
        console.log('Error fetching contacts or no contacts available');
        setCurrentContacts([]);
        setIsLoading(false);
      }
    };
  
    useEffect(() => {
      getContacts(filters, 0, itemsPerPage);
    }, []);
  
    const keywordChange = (jsonData) => {
      let keywordValue = Object.values(jsonData)[0];
      setKeyword(keywordValue);
    };

    const updateShowModal = (updates) => {
        setShowModal(prevState => ({ ...prevState, ...updates }));
      };
  
    const userTypeChange = (jsonData) => {
      let userTypeValue = Object.values(jsonData)[0];
      setUserType(userTypeValue);
    };
  
    const dataTableColumns = [
      { field: 'roles.[0]', showName: 'Tipo de usuario' },
      { field: 'name', showName: 'Nombre' },
      { field: 'cellPhone', showName: 'Teléfono' },
      { field: 'username', showName: 'Mail' },
    ];
  
    const actions = [
      { onClick: (e) => delContact(e), showName: 'Eliminar' },
      { onClick: (e) => edContact(e), showName: 'Editar' },
      { onClick: (e) => resetUserPassword(e), showName: 'Resetear contraseña' }
    ];
  
    const delContact = (contactId) => {
      updateShowModal({ show: true });
      setCurrentSelectedUserID(contactId);
      setCurrentModal('DeleteUser');
    };
  
    const finallyDelContact = (contactId) => {
      Promise.resolve(deleteContact(contactId)).then((res) => {
        if (res.message) {
          alert(res.message);
        }
        else {
          getContacts(filters, 0, itemsPerPage);
        }
      });
    };
  
    const edContact = (contactId) => {
      navigate("/contacts/edit/" + contactId);
    };
  
    const onConfirm = () => {
      if (showModal.onConfirm) {
        showModal.onConfirm();
      }
    };
  
    const resetUserPassword = (contactId) => {
      devLog(true, "[Binculo] resetUserPassword - contactId: ", contactId)
      setCurrentSelectedUserID(contactId);
      setShowModal({ show: true, onConfirm: () => finallyResetPassword(contactId) });
      setCurrentModal('ResetPassword');
    };
  
    const finallyResetPassword = async (contactId) => {
      resetUserPw(contactId).then(() => {
        alert("Contraseña reseteada con éxito.");
        getContacts(filters, 0, itemsPerPage);
      }).catch(error => {
        devLog(true, "[Binculo] Error al resetear la contraseña:", error);
        alert("Hubo un error al resetear la contraseña.");
      });
    };
  
    const filteredContacts = currentContacts.content?.filter(x => x.name).filter(x => x.name.includes(keyword)).filter((item) => {
      if (userType !== '') {
        return (item.roles.indexOf(userType) >= 0);
      }
      if (userType === '') {
        return (item);
      }
    });
  
    return (
        <>
            <BackgroudContainer>
                <div class="flex justify-between w-full items-center">
                    <div class="flex items-start">
                        <DataInput
                            tittle="Busqueda por nombre"
                            type="Text"
                            ChangeState={keywordChange}
                            targetName="keyword"
                            defaultValue=""
                            float="left"
                        />
                        <DataSelectBox
                            tittle="Tipo de usuario"
                            entries={usersEntries}
                            ChangeState={userTypeChange}
                            targetName="userType"
                            defaultValue=""
                        />
                    </div>
                    <Link to="/contacts/new" float="right">
                        <ButtonStandard showName="+ Nuevo Usuario" blueColor />
                    </Link>
                </div>
                <DataContainerCard tittle="CONTACTOS">
                    {isLoading ? (
                        <CircularLoader />
                    ) : (
                        <Grid columns="1">
                            {filteredContacts ? (
                                <DataTable rows={filteredContacts} columns={dataTableColumns} actions={actions} />
                            ) : null}
                        </Grid>
                    )}
                    {currentModal === 'DeleteUser' ? (
                        <DoubleConfirmation
                            modalState={showModal}
                            children="Si el usuario es eliminado, no podra recuperarse. Deberas crearlo nuevamente"
                            className="ContractModalForm"
                            modalSubmit={finallyDelContact}
                            responseModal={responseModal}
                            title="¡CUIDADO ESTA POR ELIMINAR UN USUARIO!"
                            currentSelectedID={currentSelectedUserID}
                        />
                    ) : null}
                    {currentModal === 'ResetPassword' ? (
                        <DoubleConfirmation
                            modalState={showModal}
                            children="Al resetear la contraseña, esta volvera a ser la contraseña por defecto"
                            className="ContractModalForm"
                            modalSubmit={finallyResetPassword}
                            responseModal={responseModal}
                            title="¡CUIDADO ESTA POR RESETEAR LA CONTRASEÑA DEL USUARIO!"
                            currentSelectedID={currentSelectedUserID}
                        />
                    ) : null}
                </DataContainerCard>
                <div className="Properties_Pagination">
                    <Pagination
                        itemsPerPage={itemsPerPage}
                        fetchData={fetchContactsList}
                        setList={getContacts}
                        filters={filters}
                        dataChanges={currentContacts}
                    />
                </div>
            </BackgroudContainer>
        </>
    );
}
