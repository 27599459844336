import Grid from "../Grid"
import DataInput from "../DataInput"
import DataSelectBoxInput from "../DataSelectBoxInput"
import DataDayPicker from "../DataDayPicker"
import {addNewUser} from "../../../Services/MainViews/Users/contactsService";
import {useEffect, useState} from "react";
import "../../../CSS/Reusable/ContractModalForm.scss";
import {unionJsonValue} from "../../../globalHelper"
import { moneyEntries} from "../../../dataEntriesHelper"

export default function ExpenseForm({expenseModalForm,setExpenseModalForm}) {

    const internalModalChange = (jsonData)  =>{
        let newValue = unionJsonValue(expenseModalForm,jsonData);
        setExpenseModalForm(newValue)
    }
    return (
        <form>
            <Grid columns="2">
                <DataSelectBoxInput type="Number"   tittle="Monto*"
                                                    entries={moneyEntries} 
                                                    ChangeState={(e) => internalModalChange(e)}
                                                    targetName={"value"}
                                                    indexValues={["currency", "value"]}/>

                <DataInput tittle="Descripcion"     type="Text" 
                                                    ChangeState={(e) => internalModalChange(e)}
                                                    targetName={"description"}/>
            </Grid>
            <Grid columns="1">
                <DataDayPicker tittle="Fecha*"      placeHolder="Ingrese la fecha"
                                                    ChangeState={(e) => internalModalChange(e)}
                                                    targetName={"date"}/>
            </Grid>
        </form>
    )

}