import Grid from "../Grid"
import DataInput from "../DataInput"
import DataSelectBoxInput from "../DataSelectBoxInput"
import {addNewUser} from "../../../Services/MainViews/Users/contactsService";
import {useEffect, useState} from "react";
import "../../../CSS/Reusable/ContractModalForm.scss";
import {unionJsonValue, existAndReturn} from "../../../globalHelper"
import {phonePrefixEntries} from "../../../dataEntriesHelper"

export default function ContractModalForm({contractModalForm,setContractModalForm}) {
    const internalModalChange = (jsonData)  =>{
        let newValue = unionJsonValue(contractModalForm,jsonData);
        setContractModalForm(newValue)
    }
    return (
        <form>
            <Grid columns="2">         
                <DataInput tittle="Nombre *" type="text"  ChangeState={(e) => internalModalChange(e)} targetName={"name"} required/>
                <DataInput tittle="Apellido *" type="text"    ChangeState={(e) => internalModalChange(e)} targetName={"surname"}  required/>
                <DataInput tittle="Email *" type="email" ChangeState={(e) => internalModalChange(e)} targetName={"username"} required/>
                <DataSelectBoxInput type="text" tittle="Teléfono celular *"
                                                     entries={phonePrefixEntries} ChangeState={(e) => internalModalChange(e)}
                                                     targetName={"cellPhone"}
                                                     indexValues={["prefix", "phoneNumber"]}
                                                     defaultValue={existAndReturn(contractModalForm, "cellPhone")}
                                                     phoneInput/>
            </Grid>
        </form>
    )

}