import axios from "axios";
import {Route} from "react-router-dom";
import React from "react";
import {devLog, getLoggedUserRole, getSelectedLoggedUserRole} from "../../../globalHelper"
import Contracts from "../../../Components/MainViews/Contracts";
import Home from "../../../Components/MainViews/Home";


export async function fetchSideBarRoutes() {
    try {

        const loggedUserRole = getSelectedLoggedUserRole();

        let routesFileName;

        switch (loggedUserRole) {
            case "ADMIN": {
                routesFileName = "adminRoutes.json"
                break;
            }
            case "OWNER" : {
                routesFileName = "tenantOwnerRoutes.json"
                break;
            }
            case "TENANT" : {
                routesFileName = "tenantOwnerRoutes.json"
                break;
            }
            case "BROKER" : {
                routesFileName = "brokerRunner.json"
                break;
            }
            case "RUNNER" : {
                routesFileName = "brokerRunner.json"
                break;
            }
            case "REFERRAL" : {
                routesFileName = "referralRoutes.json"
                break;
            }

        }

        const response = await axios.get(
            `/sideBarRoutes/${routesFileName}`
        );
        return response.data;
    } catch (error) {
        devLog(true, "Error:", error.message);
    }
}

export function getDefaultHomeComponentPathForRole() {
    const role = getSelectedLoggedUserRole();
    switch (role) {
        case "ADMIN": {
            return "/home"
        }
        case "OWNER" : {
            return "/addresses"
        }
        case "TENANT" : {
            return "/addresses"
        }
        case "BROKER" : {
            return "/bokerrunner/properties"
        }
        case "RUNNER" : {
            return "/bokerrunner/properties"
        }
        case "REFERRAL" : {
            return "/referrals"
        }
    }
}

export function getDefaultPathAfterLogin() {
    return "/roleselector"
}

function loadComponent(name) {
    return React.lazy(() =>
        import(`../../../Components/MainViews/${name}.jsx`)
    );
}

export function generateSideBarComponents(route) {
    const Component = loadComponent(route.component);
    return <Route path={route.url} key={route.title} element={<Component/>}/>;
}
