import axios from "axios";
import authHeaderService from "../../authHeaderService";
import {authorizationCheck, devLog} from "../../../globalHelper";
import termsData from '../../../FillTexts/termsData.json';

export function createTermsAndConditions(userType) {
    /*let terms;
    switch (userType) {
        case 'ADMIN':
            terms = termsData.ADMIN;
            break;
        case 'OWNER':
            terms = termsData.OWNER;
            break;
        case 'TENANT':
            terms = termsData.TENANT;
            break;
        case 'CONTACT':
            terms = termsData.CONTACT;
            break;
        case 'BROKER':
            terms = termsData.BROKER;
            break;
        case 'RUNNER':
            terms = termsData.RUNNER;
            break;
        case 'REFERRAL':
            terms = termsData.REFERRAL;
            break;
        default:
            terms = termsData.GENERAL;
    }*/
    return {
        terms: termsData.GENERAL,
        accept: function() {
            console.log(`Los términos y condiciones han sido aceptados.`);
        }
    };
}

export async function acceptTerms(acceptTerms) {
    return await axios
        .post(
            `${process.env.REACT_APP_API_URL}/api/termsAndConditions/status`,
            acceptTerms,
            authHeaderService()
        )
        .then((res) => {
            return res.data
        })
        .catch((e) => {
            devLog(true, e.response.status)
        });
}

export function TandCCheckStatus(error) {
    console.log(error);
    let tandcLocal;
    tandcLocal = localStorage.getItem('tandc');
    if (error.response.status === 403 && !tandcLocal) {
        localStorage.setItem("tandc", "false");
        window.location.href = "/roleselector"
    }
}