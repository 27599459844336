import {Box} from "@mui/material";
import Modal from '@mui/material/Modal';
import '../../CSS/Reusable/MyModal.scss'
import ButtonBlue from "./ButtonBlue";
import ButtonStandard from "./ButtonStandard";

import {useEffect, useState} from "react";
import Grid from "./Grid";


export default function MyModal({modalState, children, modalSubmit, responseModal,canBeClosedBeforeSubmiting, isButtonAble, noCloseOnSubmit}) {

    const [submitButton, setSubmitButton] = useState({
        "display":"none"
    });

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        modalState.show = true;
        setOpen(true);
    }
    const handleClose = () => {
        modalState.show = false;
        setOpen(false);
    }

    useEffect(() => {
        if (modalState.show)
            handleOpen();
        else{
            handleClose();
        }
        if(modalSubmit){
            setSubmitButton({
                "display":"block"
            })
        }
    }, [modalState.show])

    const handleEventButton =(e)=>{
        modalSubmit(e)
        if (!canBeClosedBeforeSubmiting) {
            handleClose()
        }
    }

    return (
        <Modal open={!canBeClosedBeforeSubmiting?open:modalState.show} onClose={!canBeClosedBeforeSubmiting?handleClose:''} aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description">
            <Box className="modalContainer rounded-md">
                {!canBeClosedBeforeSubmiting?<div className="float-right">
                    <label onClick={handleClose} className="cursor-pointer ">X</label>
                </div>:<></>}
                {noCloseOnSubmit?<div className="float-right">
                    <label onClick={handleClose} className="cursor-pointer ">X</label>
                </div>:<></>}
                {children}
                <div    style={submitButton} className="text-center">
                    <Grid columns="1" className="">
                        <ButtonStandard showName="Completar" onClick={handleEventButton} buttonType='secondBlue' float="center"/>
                    </Grid>
                </div>
            </Box>
        </Modal>
    )

}