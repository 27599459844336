import DataContainerCard from "../DataContainerCard"
import {useEffect, useState} from "react";
import Grid from "../Grid"
import DataSelectBoxInput from "../DataSelectBoxInput"
import AutoCompleteInput from "../AutoCompleteInput";
import ContractObservations from "./ContractObservations";
import DataInput from "../DataInput"
import LazyImage from "../LazyImage";
import {fetchContacts} from "../../../Services/MainViews/Users/contactsService";
import {getIcon} from "../../../Services/iconService";
import {existAndReturn} from "../../../globalHelper"
import DropZoneFiles from "../DropZoneFiles"
import fileDrop from "../../../Assets/file-SVG.svg";
import {fetchReferredOperationsByReferredUserId} from "../../../Services/referredOperationService";


export default function ContractFormStep2({
                                              contractForm,
                                              setContractForm,
                                              onInputChange,
                                              params
                                          }) {

    const [showPDF, setShowPDF] = useState(contractForm.pdfContract);
    const [showWarranty, setShowWarranty] = useState(contractForm.pdfWarranty);

    const [referredOperations, setReferredOperations] = useState([]);

    const [selectedReferredOperation, setSelectedReferredOperation] = useState(null);

    useEffect(() => {
        if (contractForm.pdfContract) {
            setShowPDF(true)
        }
    }, [contractForm.pdfContract]);

    useEffect(() => {
        if (contractForm.pdfWarranty) {
            setShowWarranty(true)
        }
    }, [contractForm.pdfWarranty]);

    //I Check if there is a user in the owner's equity
    useEffect(() => {
            if (contractForm.ownersEquity[0].user && contractForm.ownersEquity[0].user.id) {
                fetchReferredOperationsByReferredUserId(contractForm.ownersEquity[0].user.id).then(response => {
                    setReferredOperations(response);
                    if (response.length === 1) {

                        if (response[0].actualReferrals === 2) {
                            setReferredOperations([]);
                        } else {
                            setSelectedReferredOperation(response[0]);
                            contractForm.referredOperation = response[0];
                        }
                    }

                })
            }
        }
        , [])


    const handleSelectReferredOperation = (selectedIndex) => {
        setSelectedReferredOperation(referredOperations[selectedIndex]);
    }

    const handleFilterContacts = async (names, type) => {
        let fields = names.split(" ");
        let searchContactDTO = {};
        searchContactDTO.types = [type];
        if (fields.length === 1)
            searchContactDTO.fieldOne = fields[0];
        if (fields.length === 2) {
            searchContactDTO.fieldOne = fields[0];
            searchContactDTO.fieldOne = fields[1];
        }

        if (fields.length === 3) {
            searchContactDTO.fieldOne = fields[0];
            searchContactDTO.fieldOne = fields[1];
            searchContactDTO.fieldOne = fields[2];
        }
        return fetchContacts(searchContactDTO);
    }


    const newContractObservation = () => {
        let newData = {
            date: new Date(),
            description: ""
        }
        setContractForm({
            ...contractForm,
            observations: [
                ...contractForm.observations,
                newData
            ]
        })
    }

    const deletePDFFn = (e, deletePath, setShow) => {
        e.preventDefault();
        delete contractForm[deletePath];
        setShow(false);

    }

    const showPDFFn = (path, show, setShow) => {
        if (show) {
            return <div style={{'display': 'flex'}}>
                <a href={path.urlPath} target="_blank">
                    <LazyImage src={fileDrop} height="75px" width="75px" className="pdf"/>
                    <h1>{path.name}</h1>
                </a>
                <button onClick={(e) => deletePDFFn(e, path.name, setShow)}>
                    <div className="dataInputIconElement">{getIcon("deleteX")}</div>
                </button>
            </div>
        }
    }

    return (
        <>
            {/*referredOperations.length > 0 &&
                <DataContainerCard tittle="Referente">
                    <Grid columns="1">
                        {selectedReferredOperation ? (
                                <DataInput onlyReadable type="text"
                                           defaultValue={selectedReferredOperation.referral?.user?.name + " " + selectedReferredOperation.referral?.user?.surname}/>) :
                            (<select className="dataInput" value={selectedReferredOperation}
                                     onChange={(e) => handleSelectReferredOperation(e.target.selectedIndex - 1)}>
                                    <option value="">Seleccione un referente</option>
                                    {referredOperations.length > 0 && referredOperations.map((referredOperation, index) =>
                                        <option key={index} value={referredOperation}>
                                            {referredOperation.referral?.user?.name + " " + referredOperation.referral?.user?.surname}
                                        </option>
                                    )}
                                </select>
                            )}
                    </Grid>

                    <Grid columns="2">

                        {selectedReferredOperation ?
                            (<>
                                <DataSelectBoxInput onlyReadable type="Number" tittle="Op. 1"
                                                    entries={[{name: "%", value: "PERCENTAGE"}]}
                                                    ChangeState={onInputChange}
                                                    indexValues={["currency", "value"]}
                                                    targetName={"referredOperation.referral.operations[0]"}
                                                    defaultValue={existAndReturn(selectedReferredOperation, "referral.operations[0]")}/>

                                <DataSelectBoxInput onlyReadable type="Number" tittle="Op. 2"
                                                    entries={[{name: "%", value: "PERCENTAGE"}]}
                                                    ChangeState={onInputChange}
                                                    indexValues={["currency", "value"]}
                                                    targetName={"referredOperation.referral.operations[1]"}
                                                    defaultValue={existAndReturn(selectedReferredOperation, "referral.operations[1]")}/>
                            </>)
                            :
                            (null)
                        }
                    </Grid>
                </DataContainerCard>*/}

            {/*referredOperations.length == 0 &&
                <DataContainerCard tittle="Referente">
                    <Grid columns="1">
                        <AutoCompleteInput id="ownerName" key="ownerName"
                                           onChange={(e) => handleFilterContacts(e, 'Contact')}
                                           ChangeState={onInputChange}
                                           targetName={"referredOperation.referral.user"}
                                           showAttributes={["name", "surname"]}
                                           placeHolder="Ingresa nombre y apellido"
                                           tittle="Nombre del referente *"/>
                    </Grid>

                    <Grid columns="2">
                        <>
                            <DataSelectBoxInput type="Number" tittle="Op. 1"
                                                entries={[{name: "%", value: "PERCENTAGE"}]}
                                                ChangeState={onInputChange}
                                                indexValues={["currency", "value"]}
                                                targetName={"referredOperation.referral.operations[0]"}/>

                            <DataSelectBoxInput type="Number" tittle="Op. 2"
                                                entries={[{name: "%", value: "PERCENTAGE"}]}
                                                ChangeState={onInputChange}
                                                indexValues={["currency", "value"]}
                                                targetName={"referredOperation.referral.operations[1]"}/>
                        </>
                    </Grid>
                </DataContainerCard>*/}


            <DataContainerCard>
                <Grid columns="1">
                    <DataInput bigInput type="Text" tittle="Descripción del local" ChangeState={onInputChange}
                               targetName={"property.description"}
                               defaultValue={existAndReturn(contractForm, "property.description")}/>
                </Grid>
            </DataContainerCard>

            <DataContainerCard tittle="Garantia">
                <Grid columns="1">
                    <div style={{display: showWarranty ? 'none' : 'block'}}><DropZoneFiles form={contractForm}
                                                                                           setForm={setContractForm}
                                                                                           type='file'
                                                                                           path='pdfWarranty'/></div>
                    {showPDFFn(existAndReturn(contractForm, "pdfWarranty"), showWarranty, setShowWarranty)}
                </Grid>
            </DataContainerCard>

            <DataContainerCard tittle="PDF">
                <Grid columns="1">
                    <div style={{display: showPDF ? 'none' : 'block'}}><DropZoneFiles form={contractForm}
                                                                                      setForm={setContractForm}
                                                                                      type='file' path='pdfContract'/>
                    </div>
                    {showPDFFn(existAndReturn(contractForm, "pdfContract"), showPDF, setShowPDF)}
                </Grid>
            </DataContainerCard>

            <ContractObservations contractForm={contractForm} setContractForm={setContractForm}
                                  onInputChange={onInputChange}/>
        </>
    )
}
