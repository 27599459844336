import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "../../CSS/Reusable/PropertiesMap.scss";

export default function PropertiesLocationMap({icon, properties, center, zoom}) {
  return (
<MapContainer
className="PropertiesMap"
center={center}
zoom={zoom}
scrollWheelZoom={true}
>
<TileLayer
  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
/>
{properties?.map((prop) => (
  <Marker //MODULARIZAR ESTE MARKER PARA PASARLE TAMBIEN EL PRECIO
    key={prop.id}
    position={[prop.address.latitude, prop.address.longitude]}
    icon={icon}
  />
))}
</MapContainer>)}