import {useState} from "react";
import "../../CSS/Reusable/ButtonStandard.scss";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import "../../CSS/Reusable/ButtonBlue.scss";
import { getIcon } from "../../Services/iconService";
import ButtonStandard from "./ButtonStandard";

export default function ButtonSelector({actions, rowComponentId, showName, iconName, float,  largeButton, buttonType}) {


    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <ButtonStandard showName={showName} 
                            onClick={handleClick} 
                            buttonType={buttonType} 
                            iconName={iconName?iconName:undefined} 
                            float={float} 
                            largeButton={largeButton?largeButton:undefined}/>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                {actions.map((action)=>{
                  return  <MenuItem onClick={() => action.onClick(rowComponentId)}>{action.showName}</MenuItem>
                })}
            </Menu>
        </>
    );

}