import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import reducerLoginAuth from "./Reducers/reducerLoginAuth";
import reducerProperties from "./Reducers/reducerProperties";

const rootReducer = combineReducers({
  reducerLoginAuth,
  reducerProperties,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
