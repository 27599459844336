import Grid from "../Grid"
import "../../../CSS/Reusable/BookingContractForm.scss";
import {useNavigate, useParams} from "react-router-dom";
import ButtonBlue from "../ButtonBlue";
import ButtonStandard from "../ButtonStandard";
import BackgroundContainer from "../../MainViews/BackgroudContainer"
import DataContainerCard from "../DataContainerCard";
import DataInput from "../DataInput"
import DataSelectBox from "../DataSelectBox"
import DataSelectBoxInput from "../DataSelectBoxInput"
import DataDayPicker from "../DataDayPicker"
import AutoCompleteInput from "../AutoCompleteInput";
import ContractObservations from "./ContractObservations";
import CircularLoader from "../CircularLoader";
import ContractModalForm from "../ContractForm/ContractModalForm";
import {addNewUser} from "../../../Services/MainViews/Users/contactsService";
import MyModal from "../MyModal";
import {useEffect, useState} from "react";
import {createBookingContract, getContract, updateBookingContract} from "../../../Services/MainViews/Contracts/contractsService";
import {getProperty} from "../../../Services/MainViews/Properties/propertiesService";
import {fetchContacts} from "../../../Services/MainViews/Users/contactsService";
import {unionJsonValue, existAndReturn,devLog, addPrefixIfMissing} from "../../../globalHelper"
import {checkRequiredInputs} from "../../../errorValidationHelper"
import {
    bookingOperationsEntries, moneyEntries, propertyTypeEntries,suburbsEntries} from "../../../dataEntriesHelper"

export default function BookingContractForm() {

    const style = {width: '100%'};

    const [isSending, setIsSending] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    let params = useParams();

    const navigate = useNavigate();

    const [propertyInfo, setPropertyInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [bookingContractForm, setBookingContractForm] = useState({
        operationType: "RENTAL",
        bookingDate: new Date(),
        paymentSchedule: {
            firstPaymentStartDate: new Date(),
            lastPaymentEndDate: new Date()
        },       
        observations:[
        ]
    });
    const [scheduleFormRentState, setScheduleFormRentState] = useState({});
    const [scheduleFormBuyState, setScheduleFormBuyState] = useState({});

    const [responseModal, setResponseModal] = useState(false);
    const [showModal, setShowModal] = useState({show: false});
    const [contactModalForm, setContactModalForm] = useState({
        username: '',
        name: '',
        surname: '',
        cellPhone:''
    })

    const modalChildren = <ContractModalForm contractModalForm={contactModalForm} setContractModalForm={setContactModalForm}/>;

    const modalSubmit = (e) => {
        e.preventDefault();

        let requiredInputs = [
            {value: existAndReturn(contactModalForm, "username"), name: 'Correo'},
            {value: existAndReturn(contactModalForm, "name"), name: 'Nombre'},
            {value: existAndReturn(contactModalForm, "surname"), name: 'Apellido'},
            {value: existAndReturn(contactModalForm, "cellPhone"), name: 'Telefono'},
        ]
       if (checkRequiredInputs(requiredInputs) <= 0) {
            const cellPhoneAdapted = contactModalForm.cellPhone.prefix+''+contactModalForm.cellPhone.phoneNumber
            const newUser ={
                "username": contactModalForm.username,
                "name": contactModalForm.name,
                "surname": contactModalForm.surname,
                "cellPhone": addPrefixIfMissing(cellPhoneAdapted),
                "type": "Contact"
            }
            
            addNewUser(newUser)
                .then((res) => {
                    if (res) {
                        devLog(true, "[Binculo] addNewUser: ", res)
                        alert("[Binculo] Usuario añadido");
                        setContactModalForm(
                            {
                                username: '',
                                name: '',
                                surname: '',
                                cellPhone:''
                            }
                        )

                    } else {
                        devLog(true, "[Binculo] Error al añadir el contacto: ", res)
                        alert("[Binculo] Error al añadir el contacto");
                    }
                })
                .catch((error) => {
                    console.error("[Binculo] Error inesperado:", error);
                });
        }  
        else{
            alert("Debe llenar los siguientes campos: " + checkRequiredInputs(requiredInputs))
        }
    }

    const getSingleContract = async () => {
        setIsLoading(true);
        if (params.id) {
            const contract = await getContract(params.id)
            setPropertyInfo(contract.property);
            contract.bookingDate = new Date(contract.contractBooking.bookingDate);
            contract.paymentSchedule.firstPaymentStartDate = new Date(contract.paymentSchedule.firstPaymentStartDate);
            if (bookingContractForm.operationType === "BUY" || bookingContractForm.operationType === "REFERENDUM_BUY") {
                contract.paymentSchedule.lastPaymentEndDate = new Date(contract.paymentSchedule.firstPaymentStartDate);
            }
            /*Setting render values*/
            contract.paymentSchedule.initialAmount = {
                value: contract.paymentSchedule.initialAmount.value,
                currency: contract.paymentSchedule.initialAmount.currency
            }
            delete contract.paymentSchedule.id;
            delete contract.paymentSchedule.payment;
            /*Filtering null values*/
            contract.property = contract.property;
            /*Setting render values*/
            contract.operationType = contract.contractBooking.operationType;
            contract.bookingUser = contract.contractBooking.bookingUser;
            contract.contact = contract.contractBooking.contact;

            setBookingContractForm(contract);
            setIsLoading(false);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getSingleContract();
    }, [params.id]);

    const onInputChange = (jsonData) => {
        let newValue = unionJsonValue(bookingContractForm, jsonData);
        setBookingContractForm(newValue);
        setLoading(!loading);
    }

    function isFunction(functionToCheck) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }

    function dateIsValid(date) {
        return !Number.isNaN(new Date(date).getTime());
      }

    const onSubmit = (e) => {
        let requiredInputs =    [  
            {value:existAndReturn(bookingContractForm, "operationType"), name:'Tipo de operaciónes'},
            {value:existAndReturn(bookingContractForm, "bookingDate"), name:'Fecha de reserva'},
            {value:existAndReturn(bookingContractForm, "paymentSchedule.firstPaymentStartDate"),    name:'Fecha de pago'},
            {value:existAndReturn(bookingContractForm, "bookingUser"),   name:'Nombre del intermediario'},
            {value:existAndReturn(bookingContractForm, "contact"),   name:'Nombre del contacto'},
            {value:existAndReturn(bookingContractForm, "paymentSchedule.initialAmount"),   name:'Monto inicial'},
            {value:existAndReturn(bookingContractForm, "property.id"), name:'Propiedad'}
            ]
        if (checkRequiredInputs(requiredInputs) <= 0) {
                let newBookingDate = bookingContractForm.bookingDate;
                let newOPstartDate = bookingContractForm.paymentSchedule.firstPaymentStartDate;
        
                if ((bookingContractForm.bookingDate instanceof Date)) {
                    if (dateIsValid(bookingContractForm.bookingDate)) {
                        newBookingDate = bookingContractForm.bookingDate.toISOString().substring(0, 10);
                    }      
                }
                if ((bookingContractForm.paymentSchedule.firstPaymentStartDate instanceof Date)) {
                    if (dateIsValid(bookingContractForm.paymentSchedule.firstPaymentStartDate)) {
                        newOPstartDate = bookingContractForm.paymentSchedule.firstPaymentStartDate.toISOString().substring(0, 10);
                    }      
                }
        
                e.preventDefault();
                /*PaymentSchedule.type*/
                let bookingTypeOperation = '';
                const newContract = bookingContractForm;
                newContract.bookingDate = newBookingDate;
                newContract.paymentSchedule.firstPaymentStartDate = newOPstartDate;
        
                if (bookingContractForm.operationType === "BUY" || bookingContractForm.operationType === "REFERENDUM_BUY") {
                    bookingTypeOperation = "BuyPaymentSchedule";
                }
                if (bookingContractForm.operationType === "RENTAL" || bookingContractForm.operationType === "REFERENDUM_RENT") {
                    bookingTypeOperation = "RentPaymentSchedule";
                    if (newContract.paymentSchedule) {
                        newContract.paymentSchedule.lastPaymentEndDate = newContract.paymentSchedule.firstPaymentStartDate;
                    }
                }
                /*PaymentSchedule.type*/
                /*Setting missing keys*/
                if (newContract.paymentSchedule) {
                    newContract.paymentSchedule.type = bookingTypeOperation;
                }
                newContract.type = "BookingContract";
                if (params.id) {
                    newContract.id = Number(params.id);
                }
                newContract.paymentSchedule.paysEveryXMonths = 1;
        
                /*Setting missing keys*/

                if (isContactEdit()) {
                    const newTestContract = JSON.parse(JSON.stringify(newContract));
                    newTestContract.property ={
                        id: newContract.property.id
                    }
                    delete newTestContract.bookingUser;
                    delete newTestContract.contact;
                    delete newTestContract.bookingDate;
                    newTestContract.commissionContainer = null
                    setIsSending(true)
                    Promise.resolve(updateBookingContract(newTestContract)).then( (res) => {
                        if (res.message) {
                            alert(res.message)
                            setIsSending(false)         
                        }
                        else{
                            alert("Reserva actualizada con exito");
                            window.location.href = "/home";
                            setIsSending(false)
                        }
                        
                    })
                }
                if(!params.id){
                    const newTestContract = {
                        "dtype": null,
                        "operationType": newContract.operationType,
                        "key": null,
                        "paymentSchedule": newContract.paymentSchedule,
                        "commissionContainer": null,
                        "observations": newContract.observations,
                        "contractFinal": null,
                        "property":{id: newContract.property.id,},
                        "contractBooking": {
                          "type": "BookingContract",
                          "operationType": newContract.operationType,
                          "bookingDate": newContract.bookingDate,
                          "paymentSchedule": newContract.paymentSchedule,
                          "commission": {
                            "currency": "PERCENTAGE",
                            "value": "10"
                          },
                          "bookingUser": {
                            "id": newContract.bookingUser.id
                          },
                          "contact": {
                            "id": newContract.contact.id
                          }
                        },
                        "type": "BookingContract"
                      }
                    setIsSending(true)
                    Promise.resolve(createBookingContract(newTestContract)).then( (res) => {
                        if (res.message) {
                            alert(res.message)
                            setIsSending(false)
                        }
                        else{
                            alert("Reserva creada con exito");
                            window.location.href = "/home";
                            setIsSending(false)
                        }
                        
                    })
                }
        }
        else{
            alert("Debe llenar los siguientes campos: "+checkRequiredInputs(requiredInputs))
        }
    }

    /*Inicia Control del tipo de operacion*/
    const settingScheduleForm = (data) => {
        if (data === "BUY" || data === "REFERENDUM_BUY") {
            setScheduleFormRentState({"display": "none"})
            setScheduleFormBuyState({"display": "block"})
        }
        if (data === "RENTAL" || data === "REFERENDUM_RENT") {
            setScheduleFormRentState({"display": "block"})
            setScheduleFormBuyState({"display": "none"})
        }
    }
    useEffect(() => {
        let data = bookingContractForm.operationType;
        settingScheduleForm(data);
    }, [])

    const paymentScheduleForm = (data) => {
        settingScheduleForm(data);
    }
    /*Finaliza Control del tipo de operacion*/

    const handleFilterContacts = (names, type) => {
        let fields = names.split(" ");
        let searchContactDTO = {};
        searchContactDTO.types =[type];
        if (fields.length === 1)
            searchContactDTO.fieldOne = fields[0];
        if (fields.length === 2) {
            searchContactDTO.fieldOne = fields[0];
            searchContactDTO.fieldOne = fields[1];
        }

        if (fields.length === 3) {
            searchContactDTO.fieldOne = fields[0];
            searchContactDTO.fieldOne = fields[1];
            searchContactDTO.fieldOne = fields[2];
        }
        return fetchContacts(searchContactDTO);
    }

    const getPropertyInfo = async (e) => {
        e.preventDefault();
        Promise.resolve(getProperty(bookingContractForm.property.id)).then( (res) => {
            if (res.message) {
                alert(res.message)          
            }
            else{
                setPropertyInfo(res)
            }
            
        })
    }
    const isContactEdit = () =>{
        return params.id
    } 

    return (
        <>
            <BackgroundContainer>

                <div style={style}>

                    <Grid columns="1">
                        <form>
                            <DataContainerCard tittle="DATOS DE RESERVA" className="ContractForm">
                                {isLoading?
                                <CircularLoader/>:
                                <Grid columns="1">
                                    <DataSelectBox tittle="Tipo de operaciónes *" entries={bookingOperationsEntries}
                                                    ChangeState={onInputChange} targetName={"operationType"}
                                                    defaultValue={existAndReturn(bookingContractForm, "operationType")}
                                                    paymentScheduleForm={paymentScheduleForm}/>
                                </Grid>
                                }
                                {isLoading?
                                <CircularLoader/>:
                                <Grid columns="1">
                                    <DataDayPicker tittle="Fecha de reserva *" placeHolder="Ingrese la fecha de inicio"
                                                    ChangeState={onInputChange} targetName={"bookingDate"}
                                                    defaultValue={new Date(bookingContractForm.bookingDate)}/>
                                </Grid>
                                }
                                {isLoading?
                                <CircularLoader/>:
                                <Grid columns="1">
                                    <AutoCompleteInput id="bookingUser" key="bookingUser"
                                                        onChange={(e) => handleFilterContacts(e,'Contact')}
                                                        ChangeState={onInputChange} targetName={"bookingUser"}
                                                        defaultValue={existAndReturn(bookingContractForm, "bookingUser")}
                                                        showAttributes={["name", "surname"]}
                                                        tittle="Nombre del intermediario *"
                                                        placeHolder={"Ingrese nombre y apellido"}/>
                                </Grid>
                                }
                                {isLoading?
                                <CircularLoader/>:
                                <Grid columns="3">
                                    <DataInput tittle="Número ID de la propiedad *"
                                                placeHolder="Completa número ID de la propiedad" type="Number"
                                                columnSpan={2} ChangeState={onInputChange} targetName={"property.id"}
                                                defaultValue={existAndReturn(bookingContractForm, "property.id")}/>
                                    <div    style={{"alignSelf":"end","paddingRight":"4rem"}}><ButtonStandard showName="Añadir propiedad" float="right" onClick={getPropertyInfo} blueColor/></div>
                                </Grid>
                                }
                                {isLoading?
                                <CircularLoader/>:
                                <Grid columns="1">
                                    <div className="UnabledButton">
                                        <DataInput type="Text" tittle="Calle y número"
                                                    targetName={"property.address.street"}
                                                    defaultValue={existAndReturn(propertyInfo, "address.street")}
                                                    className="UnabledButton"/>
                                    </div>
                                </Grid>
                                }
                                {isLoading?
                                <CircularLoader/>:
                                <Grid columns="3">
                                    <div className="UnabledButton">
                                        <DataInput type="Text" tittle="Unidad"
                                                    targetName={"property.address.houseNumber"}
                                                    defaultValue={existAndReturn(propertyInfo, "address.houseNumber")}
                                                    className="UnabledButton"/>
                                    </div>
                                    <div className="UnabledButton">
                                        <DataSelectBox tittle="Barrios" columnSpan={2}
                                                        entries={suburbsEntries}
                                                        targetName={"property.address.suburb"}
                                                        defaultValue={existAndReturn(propertyInfo, "address.suburb")}
                                                        className="UnabledButton"/>
                                    </div>
                                </Grid>
                                }
                                {isLoading?
                                <CircularLoader/>:
                                <Grid columns="1">
                                    <div className="UnabledButton">
                                        <DataSelectBox tittle="Tipo de propiedad"
                                                        entries={propertyTypeEntries}
                                                        targetName={"property.type"}
                                                        defaultValue={existAndReturn(propertyInfo, "type")}/>
                                    </div>
                                </Grid>
                                }
                            </DataContainerCard>
                            <DataContainerCard>
                                {isLoading?
                                <CircularLoader/>:
                                <Grid columns="1">
                                    <AutoCompleteInput id="contact" key="contact"
                                                        onChange={(e) => handleFilterContacts(e,'Contact')}
                                                        ChangeState={onInputChange} targetName={"contact"}
                                                        defaultValue={existAndReturn(bookingContractForm, "contact")}
                                                        showAttributes={["name", "surname"]}
                                                        tittle="Nombre del contacto *"
                                                        placeHolder={"Ingrese nombre y apellido *"}/>
                                </Grid>}
                                <Grid columns="1">
                                    <label className="text-blue-900 font-semibold cursor-pointer mt-1 mb-1"
                                        onClick={() => {
                                            setShowModal({show: true});
                                        }}>
                                        + Agregar nuevo contacto
                                    </label>
                                    {<MyModal modalState={showModal} children={modalChildren} className="ContractModalForm"
                                            modalSubmit={modalSubmit} responseModal={responseModal} canBeClosedBeforeSubmiting noCloseOnSubmit/>}
                                </Grid>
                                {isLoading?
                                <CircularLoader/>:
                                <Grid columns="3">
                                    <div className="UnabledButton">
                                        <DataInput tittle="DNI" type="text" targetName={"contact.identificationNumber"}
                                                    defaultValue={existAndReturn(bookingContractForm, "contact.identificationNumber")}/>
                                    </div>
                                    <div className="UnabledButton">
                                        <DataInput tittle="Telefono" type="Text" targetName={"contact.cellPhone"}
                                                    defaultValue={existAndReturn(bookingContractForm, "contact.cellPhone")}/>
                                    </div>
                                    <div className="UnabledButton">
                                        <DataInput tittle="Mail" type="Text" targetName={"contact.username"}
                                                    defaultValue={existAndReturn(bookingContractForm, "contact.username")}/>
                                    </div>
                                </Grid>}
                                {isLoading?
                                <CircularLoader/>:
                                <Grid columns="1">
                                    <DataDayPicker tittle="Fecha de pago *" placeHolder="Ingrese la fecha de inicio"
                                                    ChangeState={onInputChange}
                                                    targetName={"paymentSchedule.firstPaymentStartDate"}
                                                    defaultValue={new Date(bookingContractForm.paymentSchedule.firstPaymentStartDate)}/>
                                </Grid>}
                                <div style={scheduleFormRentState}>
                                {isLoading?
                                <CircularLoader/>:
                                <Grid columns="2">
                                    <DataSelectBoxInput type="Number" tittle="Monto de alquiler *"
                                                         entries={moneyEntries} ChangeState={onInputChange}
                                                         targetName={"paymentSchedule.initialAmount"}
                                                         indexValues={["currency", "value"]}
                                                         defaultValue={existAndReturn(bookingContractForm, "paymentSchedule.initialAmount")}/>
                                    <DataInput tittle="Duración de contrato" placeHolder="Ingrese número de meses"
                                                type="Number" ChangeState={onInputChange}
                                                targetName={"paymentSchedule.daysOfGrace"}
                                                defaultValue={existAndReturn(bookingContractForm, "paymentSchedule.daysOfGrace")}/>
                                </Grid>}
                                {isLoading?
                                <CircularLoader/>:
                                <Grid columns="3">
                                    <DataInput tittle="Aumento cada" placeHolder="Ingrese número de meses"
                                                type="Number" ChangeState={onInputChange}
                                                targetName={"paymentSchedule.increaseEveryXMonths"}
                                                defaultValue={existAndReturn(bookingContractForm, "paymentSchedule.increaseEveryXMonths")}/>
                                    <DataInput type="Number" tittle="Ajuste" ChangeState={onInputChange}
                                                targetName={"paymentSchedule.increaseEveryXMonthsPercentage"}
                                                defaultValue={existAndReturn(bookingContractForm, "paymentSchedule.increaseEveryXMonthsPercentage")}/>
                                    <DataInput tittle="Ajuste / Descripción" type="Text"
                                                ChangeState={onInputChange}
                                                targetName={"paymentSchedule.description"}
                                                defaultValue={existAndReturn(bookingContractForm, "paymentSchedule.description")}/>
                                </Grid>}</div>
                                <div style={scheduleFormBuyState}>
                                {isLoading?
                                <CircularLoader/>:
                                <Grid columns="1">
                                    <DataSelectBoxInput type="Number" tittle="Monto de Venta *" entries={moneyEntries}
                                                         ChangeState={onInputChange}
                                                         targetName={"paymentSchedule.initialAmount"}
                                                         indexValues={["currency", "value"]}
                                                         defaultValue={existAndReturn(bookingContractForm, "paymentSchedule.initialAmount")}/>
                                </Grid>}
                                </div>
                            </DataContainerCard>
                            <ContractObservations contractForm={bookingContractForm} setContractForm={setBookingContractForm} onInputChange={onInputChange}/>
                        </form>               
                    </Grid>

                </div>
                    <div className={isSending ? 'UnabledButton' : ''}   style={{"margin":"1.6rem 0px 1.6rem 0px"}}>
                        <ButtonStandard  showName={isContactEdit()?"Actualizar reserva":"Crear reserva"} onClick={onSubmit} float="right" blueColor/>
                    </div>
            </BackgroundContainer>
        </>
    )
}