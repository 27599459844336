import ButtonStandard from "./ButtonStandard"
import {Box} from "@mui/material";
import Modal from '@mui/material/Modal';
import '../../CSS/Reusable/MyModal.scss'

import {useEffect, useState} from "react";
import Grid from "./Grid";


export default function DoubleConfirmation({modalState, children, modalSubmit, responseModal, title,currentSelectedID}) {

    const [submitButton, setSubmitButton] = useState({
        "display":"none"
    });

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        modalState.show = false;
        setOpen(false);
    }

    useEffect(() => {
        if (modalState.show)
            handleOpen();
        if(modalSubmit){
            setSubmitButton({
                "display":"block"
            })
        }
    }, [modalState.show])

    const handleEventButton =(e)=>{
        modalSubmit(currentSelectedID)
        handleClose()
    }
    

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description">
            <Box className="modalContainer rounded-md medium-modal">
                <div className="float-right">
                    <label onClick={handleClose}>X</label>
                </div>
                <div    className="confirmation-check-container">
                    <Grid columns="1">
                        <h1 className="confirmation-check-title">{title}</h1>
                    </Grid>
                    <Grid columns="1">
                        <p  className="confirmation-check-data">{children}</p>
                    </Grid>
                    <div    style={submitButton}>
                        <div className="w-full flex">
                            <ButtonStandard showName="Confirmar"  blueColor onClick={handleEventButton} float="center"/>
                            <ButtonStandard showName="Cancelar"  blueColor onClick={handleClose}  float="center"/>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )

}