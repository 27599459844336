import React, { useEffect, useRef, useState } from 'react';
import "../../CSS/Reusable/TimeLine.scss";
import { devLog, pad } from "../../globalHelper";
import ButtonStandard from "./ButtonStandard";
import {mapCurrencyName} from "../../dataMappingUtilHelper";
import {formatNumberWithDots} from "../../globalHelper";
//Assets
import { ReactComponent as BLogo } from '../../Assets/timeLineLogo.svg';

export default function TimeLine({ payments, showOnlyMonths = false }) {
    const [sortedPayments, setSortedPayments] = useState([]);
    const timelineRef = useRef(null);
    const sizeRef = useRef(null);

    const [size, setSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        if (payments) {
            let sortedArray = payments.sort((a, b) => new Date(a.expectedPaymentDate) - new Date(b.expectedPaymentDate));
            setSortedPayments(sortedArray);
        }
    }, [payments]);

    useEffect(() => {
        const currentMonthElement = timelineRef.current.querySelector(".actual_month");
        const allMonths = timelineRef.current.querySelectorAll(".time_line_structure");

        let foundCurrentMonth = false;

        allMonths.forEach((month) => {
            if (month.contains(currentMonthElement)) {
                foundCurrentMonth = true;
                month.classList.add("current-month");
            }

            if (foundCurrentMonth) {
                month.classList.add("future-month");
            } else {
                month.classList.add("past-month");
            }
        });

        if (currentMonthElement) {
            currentMonthElement.closest(".time_line_structure").classList.remove("future-month");
            currentMonthElement.closest(".time_line_structure").classList.add("past-month");
        }
    }, [sortedPayments]);

    useEffect(() => {
        if (sizeRef.current) {
            const { offsetWidth, offsetHeight } = sizeRef.current;
            setSize({ width: offsetWidth, height: offsetHeight });
        }
    }, [sizeRef]);

    function isPaymentOnActualMonth(payment) {
        let currentYear = new Date().getFullYear();
        let currentMonth = new Date().getMonth() + 1;

        let paymentYear = new Date(payment.expectedPaymentDate).getFullYear();
        let paymentMonth = new Date(payment.expectedPaymentDate).getMonth() + 1;

        let _todayDate = currentYear + '-' + pad(currentMonth);
        let _paymentDate = paymentYear + '-' + pad(paymentMonth);

        devLog(true, paymentYear + '-' + pad(paymentMonth) + '-' + new Date(payment.expectedPaymentDate).getDate());

        return _todayDate === _paymentDate;
    }

    const generateFirstTimeLineElement = () => {
        return (
            <div className="relative z-0 text-center">
                <div className="ml-11 h-full flex flex-col justify-start">
                    {[...Array(5)].map((_, i) => <div key={i} className="time_line_first_element" />)}
                </div>
                {!showOnlyMonths?<span className='absolute right-0 left-0 text-[#081382] font-semibold first_payment mt-[-16px] w-max mx-auto ml-[-80%]'>
                        {sortedPayments && sortedPayments[0]?sortedPayments[0].currency:''}
                        {sortedPayments && sortedPayments[0]?formatNumberWithDots(sortedPayments[0].amount):''}
                </span>:''}
                {!showOnlyMonths && <div className="first_payment font-bold w-max mx-auto ml-[-80%] text-[#081382]"><p>Alquiler Inicial</p></div>}
            </div>
        );
    };

    const generateTimeLineMiddleElement = (payment, day) => {
        return (
            <div key={day} className="relative z-0 time_line_structure sm:pb-[5.125rem] lg:pb-[4.125rem]">
                <div className="e1" />
                <div className="e2" />
                <div className="float-right text-[#565764]">
                    <p className={isPaymentOnActualMonth(payment) ? "actual_month" : ""}>{day}</p>
                    {isPaymentOnActualMonth(payment) && !showOnlyMonths?
                    <span className='absolute right-0 left-0 ml-[40%] text-[#081382] font-semibold'>
                        {payment.currency}{formatNumberWithDots(payment.amount)}
                    </span>
                    :''}
                </div>
            </div>
        );
    };

    const generateLastTimeLineElement = () => {
        return (
            <div className="relative z-0 sm:pb-[5.125rem] lg:pb-[4.125rem] text-center">
                <div className="h-full flex flex-col justify-center relative min-w-[5.313rem]">
                    <BLogo className='absolute left-0 right-0 ml-[-30px] sm:mt-[10%]'/>
                </div>
                {!showOnlyMonths?<span className='absolute right-0 left-0 text-[#081382] font-semibold last_payment mt-[-16px] w-max mx-auto ml-[-80%]'>
                        {sortedPayments && sortedPayments[sortedPayments.length - 1]?sortedPayments[sortedPayments.length - 1].currency:''}
                        {sortedPayments&& sortedPayments[sortedPayments.length - 1]?formatNumberWithDots(sortedPayments[sortedPayments.length - 1].amount):''}
                </span>:''}
                {!showOnlyMonths && <div className="last_payment font-bold w-max mx-auto ml-[-80%] text-[#081382]"><p>Último Alquiler</p></div>}
            </div>
        );
    };

    const moveLeft = () => {
        document.getElementById('timeLine').scrollLeft += 30;
    };

    const moveRight = () => {
        document.getElementById('timeLine').scrollLeft -= 30;
    };

    return (
        <>
            <div id="timeLine" ref={timelineRef} className="flex over_flow_x cursor-move timeline-container text-[#565764]">
                <div className='centered-timeline' ref={sizeRef}>
                    <div className="flex firstElement">
                        {sortedPayments && generateFirstTimeLineElement()}
                    </div>
                    <div className="flex justify-center">
                        {sortedPayments && sortedPayments.map((payment, i) => generateTimeLineMiddleElement(payment, i + 1))}
                    </div>
                    <div className="flex">
                        <div className="relative z-0 time_line_structure">
                            <div className="e1" />
                        </div>
                    </div>
                    <div className="flex">
                        {sortedPayments && generateLastTimeLineElement()}
                    </div>
                </div>
            </div>
            <div className={"flex justify-between lg:justify-center mx-auto"} style={{ gap: "2rem", marginTop: "1rem"}}>
                <ButtonStandard showName="<" onClick={moveRight} buttonType={'secondBlue'} shortButton/>
                <ButtonStandard showName=">" onClick={moveLeft} buttonType={'secondBlue'} shortButton/>
            </div>
        </>
    );
}