import React, {useState, useEffect} from 'react';
import axios from "axios";
import authHeaderService from "./authHeaderService";
import {authorizationCheck, devLog} from "../globalHelper";


export async function fetchReferredOperationsByReferredUserId(referredId) {
    return await axios
        .get(
            `${process.env.REACT_APP_API_URL}/api/referredoperations/referreduser/${referredId}`,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}

export async function fetchReferredOperationsForLoggedInReferral() {
    return await axios
        .get(
            `${process.env.REACT_APP_API_URL}/api/referredoperations`,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}

export async function fetchCommissionForReferredOperation(referredOperationId) {
    return await axios
        .get(
            `${process.env.REACT_APP_API_URL}/api/referredoperations/${referredOperationId}/commissions`,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}

export async function createReferredOperation(referredOperation) {
    // devLog(true, "Binculo", referredOperation)
    return await axios
        .post(
            `${process.env.REACT_APP_API_URL}/api/referredoperations`,
            referredOperation,
            authHeaderService()
        )
        .then((res) => {
            // devLog(true, "Binculo", "referredoperations: ", res.data)
            return res;
        })
        .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}

export async function updateReferredOperation(referredOperation) {
    return await axios
        .put(
            `${process.env.REACT_APP_API_URL}/api/referredoperations`,
            referredOperation,
            authHeaderService()
        )
        .then((res) => {
            return res;
        })
        .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}

