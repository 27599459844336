import "../../CSS/Reusable/DataInput.scss";
import React, {useEffect, useState} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {addDays} from "date-fns";
import es from 'date-fns/locale/es';
import {setJsonFromDotedValue} from "../../globalHelper"

export default function DataDayPicker(props) {

    registerLocale('es', es)

    const [date, setDate] = useState(props.defaultValue);

    useEffect(()=>{
        let date = props.defaultValue;
        setDate(date);
    }, [props.defaultValue])

    const eventHandler = (date)=>{
        if(props.ChangeState)
        {
            setDate(date)
            props.ChangeState(setJsonFromDotedValue({},props.targetName, date))
        }
        else{
            setDate(date)
        }

    }
    const getClasses = () =>{
        return "dataInput".
        concat(props.onlyReadable?" onlyReadable":"");
    }

    const columnSpan = props.columnSpan ? "md:col-span-" + props.columnSpan : null;

    return (
        <div className={columnSpan}>
            {props.tittle?<h3>{props.tittle}</h3>:null}
            <DatePicker locale="es" dateFormat="dd/MM/yyyy" closeOnScroll={false} fixedHeight
                        placeholderText={props.placeHolder}
                        className={getClasses()} selected={date} onChange={(date: Date) => eventHandler(date)} value={date}/>
        </div>
    );
};


