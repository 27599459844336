import React, {useEffect, useState} from "react";
import "../../CSS/MainViews/LoginAuth.scss";
import {login} from "../../Services/MainViews/Login/authLoginService";
import {useDispatch} from "react-redux";
import {obtainLoggedUser} from "../../Redux/Actions/actionsLoginAuth";
import {getDefaultHomeComponentPathForRole} from "../../Services/MainViews/Login/userRouteComponentService";
import {  checkBackendHealth } from "../../Services/backendHealth";
import {isUserLoggedIn} from "../../globalHelper"
import loginIcon from "../../Assets/feature_login/login_icon2.svg";

// UI
import {
    Button,
    TextField,
    Typography,
    Container,
    Box,
    InputAdornment,
    IconButton
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


export default function LoginAuth() {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showVersion, setShowVersion] = useState(false);
    const [backendVersion, setBackendVersion] = useState('3.1.5');

    const frontendVersion = process.env.REACT_APP_FRONTEND_VERSION;

    const checkBackendVersion = async () =>{
      const backendHealth = await checkBackendHealth();
      if (backendHealth.components?.custom?.details?.version) {
        setBackendVersion(backendHealth.components.custom.details.version)
      }
    }
    
    useEffect(() => {
        console.log('v:'+frontendVersion);
        
        checkBackendVersion()
        const url = window.location.href;

        if (url.includes('localhost')) {
        setShowVersion(true);
        }

        if (url.includes('sandbox')) {
        setShowVersion(true);
        }
    }, []);

    useEffect(() => {
        if (isUserLoggedIn()) {
            window.location.ref = getDefaultHomeComponentPathForRole()
        }
    }, []);

    function validateForm() {
        const emailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let emailRevisado = emailRegex.test(email);
        return email.length > 0 && password.length > 0 && emailRevisado;
    }

    function handleSubmit(event) {
        event.preventDefault();
        login({username: email, password: password}).then((res) =>
            dispatch(obtainLoggedUser(res))
        );
    }


    return (
        <div className=" min-h-[100vh] w-full h-full bg-[#F3F6FC] flex flex-col justify-center items-center">
            <Container className="login-container" component="main" maxWidth="xs">
                <Box className="login-box">
                    <img src={loginIcon} alt="Login Icon" className="login-logo"/>

                    <Typography className=" !text-[1.625rem] lg:!text-[3.25rem] text-[#234069] !font-bold mx-auto !mb-[2.25rem] lg:!mb-[4.5rem] !mt-[1rem] lg:!mt-[2rem]" component="h1">
                        ¡Bienvenidos!
                    </Typography>

                    <Box className="login-form" component="form" onSubmit={handleSubmit}>
                        <TextField
                            margin="normal"
                            required //
                            fullWidth
                            label="Email"
                            className="bg-[white] !rounded-xl "
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            required //
                            fullWidth
                            label="Contraseña"
                            className="bg-[white] !rounded-xl "
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Button
                            className="login-button"
                            type="submit"
                            fullWidth
                            disabled={!validateForm()}
                        >
                            Iniciar Sesión
                        </Button>

                        {/*<Button
                            className="forgot-password-button"
                            fullWidth
                            onClick={() => {
                            }}

                        >
                            ¿Olvidaste la contraseña?
                        </Button>*/}
                    </Box>
                </Box>
            </Container>
            {showVersion?
            <span className="text-xs text-neutral-800/35 font-bold my-auto text-center">front-{frontendVersion} / back-{backendVersion}</span>:''
            }
        </div>
    );
}
