import SearchesFormStep1 from "./SearchesFormStep1"
import Grid from "../Grid"
import ButtonStandard from "../ButtonStandard";
import {useNavigate, useParams} from "react-router-dom";
import BackgroundContainer from "../../MainViews/BackgroudContainer"
import {useEffect, useState} from "react";
import DataContainerCard from "../DataContainerCard";
import {Step, StepLabel, Stepper} from "@mui/material";
import MyModal from "../MyModal";
import {addNewSearch, editSearch, fetchSearchById} from "../../../Services/MainViews/Properties/searchesService";
import {existAndReturn, unionJsonValue} from "../../../globalHelper"
import {checkRequiredInputs} from "../../../errorValidationHelper"


export default function SearchesForm() {

    let params = useParams();

    const style = {width: '100%'};

    const navigate = useNavigate();

    const [searchesForm, setSearchesForm] = useState({
        date: new Date(),
        operationType: 'RENTAL',
        propertyType: "HOUSE"

    });

    const [isSending, setIsSending] = useState(false);
    const [editing, setEditing] = useState(false);

    const loadSearch = async () => {
        if (params.id) {
            const search = await fetchSearchById(params.id)
            setSearchesForm(search)
            setEditing(true);
        }
    };

    useEffect(() => {
        loadSearch();
    }, [params.id]);


    const [step, setStep] = useState(0);

    const steps = ["Paso 1"];

    const [showSuccessModal, setShowSuccessModal] = useState({show: false});

    const getStepComponent = () => {
        switch (step) {
            case 0:
                return <SearchesFormStep1 searchesForm={searchesForm}
                                          onInputChange={onInputChange}
                                          setSearchesForm={setSearchesForm}/>;
            default:
                return <SearchesFormStep1/>;
        }
    };

    const plusStep = () => {
        if (step < steps.length - 1) {
            setStep(step + 1);
        }
    }

    const minusStep = () => {
        if (step > 0)
            setStep(step - 1);
    }

    const filteringOverlapMultibox = (services, data, overlapForm, newForm) => {
        if (Object.keys(data)[0] === services) {
            delete overlapForm[services];
            newForm = unionJsonValue(overlapForm, data);
        }
    }

    const onInputChange = (jsonData) => {
        let newValue = unionJsonValue(searchesForm, jsonData);
        setSearchesForm(newValue)

        let servicesOverlapFilter = searchesForm;
        filteringOverlapMultibox("services", jsonData, servicesOverlapFilter, newValue);
        filteringOverlapMultibox("amenities", jsonData, servicesOverlapFilter, newValue);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        let requiredInputs = [
            {value: existAndReturn(searchesForm, "date"), name: 'Fecha'},
            {value: existAndReturn(searchesForm, "operationType"), name: 'Tipo de operación'},
            {value: existAndReturn(searchesForm, "propertyType"), name: 'Tipo de propiedad'},
            {value: existAndReturn(searchesForm, "suburbs.[0]"), name: 'Barrio'},
            {value: existAndReturn(searchesForm, "applicant"), name: 'Nombre del solicitante'},
            {value: existAndReturn(searchesForm, "manager"), name: 'Nombre del gestor'},
            {value: existAndReturn(searchesForm, "description"), name: 'Descripcion'}
        ]
        if (checkRequiredInputs(requiredInputs) <= 0) {
            let newProperty = searchesForm;

            let newDate = searchesForm.date;

            if ((searchesForm.date instanceof Date)) {
                newDate = searchesForm.date.toISOString().substring(0, 10);
            }
            newProperty.date = newDate;
            /*anotherParams*/
            if (params.id) {
                newProperty.id = Number(params.id);
            }
            newProperty.applicant = {id: newProperty.applicant.id};
            newProperty.manager = {id: newProperty.manager.id};

            //
            setIsSending(true)

            if (isPropertyEdit()) {
                Promise.resolve(editSearch(params.id, newProperty)).then((res) => {
                    if (res.message) {
                        alert(res.message)
                        setIsSending(false)
                    } else {
                        alert("Busqueda actualizada con exito")
                        window.location.href = "/home";
                        setIsSending(false)
                    }

                })
            } else {
                Promise.resolve(addNewSearch(newProperty)).then((res) => {
                    if (res.message) {
                        alert(res.message)
                        setIsSending(false)
                    } else {
                        alert("Busqueda creada con exito");
                        window.location.href = "/home";
                        setIsSending(false)
                    }

                })
            }

        } else {
            alert("Debe llenar los siguientes campos: " + checkRequiredInputs(requiredInputs))
        }
    }

    const isPropertyEdit = () => {
        return params.id
    }


    return (
        <BackgroundContainer>

            <ButtonStandard showName="Atrás" onClick={() => navigate("/home")} blueColor/>

            <div style={style}>

                <DataContainerCard>
                    <Grid columns="1">
                        <Stepper activeStep={step} alternativeLabel className="mt-5">
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </DataContainerCard>

                {getStepComponent()}

                <MyModal modalState={showSuccessModal} children={<label>Busqueda Creada</label>}/>

            </div>

            <div className="w-full flex" style={{"margin": "1.6rem 0px 1.6rem 0px"}}>
                {step > 0 ? <ButtonStandard showName="Anterior" onClick={minusStep} float="left" blueColor/> : null}
                {/*step === 0 ? <ButtonBlue showName="Guardar y Continuar" onClick={plusStep} float="right"/> : null*/}
                {step === 0 ? <div className={isSending ? 'UnabledButton' : ''}
                                   style={{'marginRight': 'auto', 'marginLeft': 'auto'}}><ButtonStandard
                    showName={isPropertyEdit() ? "Actualizar busqueda" : "Crear busqueda"} onClick={onSubmit}
                    float="right" blueColor/></div> : null}
            </div>

        </BackgroundContainer>

    )
}
