import "./CSS/App.scss";
import LoginAuth from "./Components/MainViews/LoginAuth";
import {BrowserRouter, Navigate, Route, Routes, useParams, useLocation } from "react-router-dom";
import {fetchSideBarRoutes} from "./Services/MainViews/Login/userRouteComponentService";
import NotFound from "./Components/MainViews/NotFound";
import NavBar from "./Components/GeneralStructure/NavBar";
import MobileNavbar from "./Components/GeneralStructure/MobileNavbar";
import SideBarLeft from "./Components/GeneralStructure/SideBarLeft";
import {Suspense, useEffect, useState} from "react";
import PropertyDetail from "./Components/Reusable/PropertyDetail";
import PropertyForm from "./Components/Reusable/PropertyForm/PropertyForm";
import SearchesForm from "./Components/Reusable/PropertyForm/SearchesForm";
import ContactForm from "./Components/Reusable/ContactForm";
import BookingContractForm from "./Components/Reusable/ContractForm/BookingContractForm";
import ContractForm from "./Components/Reusable/ContractForm/ContractForm";
import Contracts from "./Components/MainViews/Contracts";
import Properties from "./Components/MainViews/Properties";
import Contacts from "./Components/MainViews/Contacts";
import Home from "./Components/MainViews/Home";
import TermsAndConditionsModal from "./Components/MainViews/TermsAndConditionsModal";
import TenantOwnerHome from "./Components/MainViews/TenantOwner/TenantOwnerHome";
import BrokerRunnerHome from "./Components/MainViews/BrokerRunner/BrokerRunnerHome";
import BrokerRunnerSearches from "./Components/MainViews/BrokerRunner/BrokerRunnerSearches";
import OwnerContract from "./Components/MainViews/TenantOwner/OwnerContract";
import TenantContract from "./Components/MainViews/TenantOwner/TenantContract";
import CalendarView from "./Components/MainViews/CalendarView";
import ReferralsHome from "./Components/MainViews/Referrals/ReferralsHome";

//Componentes de segundo dominio
import ExportedPropertyWrapper from "./Components/MainViews/ExportedPropertyWrapper";

import {getLoggedUserRoles} from "./globalHelper";
import RoleSelector from "./Components/GeneralStructure/RoleSelector";

function App() {

    const isVisualPropiedad = window.location.href.includes('visualpropiedad');

    // Detecta el dominio
    const hostname = window.location.hostname;

    const forbiddenRoutesForSideBar = [ '/', '/roleselector']

    const isNavBarAllowed = () => {
        return forbiddenRoutesForSideBar.find(element => element === window.location.pathname) !== undefined
    }

    // Checks
    const environment = process.env.REACT_APP_ENVIRONMENT;
    console.log("[Binculo] Environment: ", environment);

    if (hostname !== process.env.REACT_APP_SECOND_DOMAIN) {
        return (
            <div className="App">
                <Suspense fallback={<div>Loading...</div>}>
                    <BrowserRouter>
                        {!isNavBarAllowed() && !isVisualPropiedad ? <div><NavBar/> <MobileNavbar/></div>: ""}
                        <div className="App_Container">
    
                            {!isNavBarAllowed() ? <SideBarLeft/> : ""}
                            <Routes>
                                <Route path="/" element={<LoginAuth/>}/>
    
                                <Route path="/roleselector" element={<RoleSelector/>}/>
    
                                /*RUTAS ADMIN*/
                                <Route path="/home" element={<Home/>}/>
                                <Route path="/calendar" element={<CalendarView/>}/>
                                <Route path="/properties" element={<Properties/>}>
                                </Route>
                                <Route path="/properties/:id"  element={<ExportedPropertyWrapper internalLink={true}/>}/>
                                <Route path="/properties/new" element={<PropertyForm/>}/>
                                <Route path="/properties/edit/:id" element={<PropertyForm/>}/>
                                <Route path="/searches/new" element={<SearchesForm/>}/>
                                <Route path="/searches/edit/:id" element={<SearchesForm/>}/>
                                <Route path="/bokerrunner/searches" element={<BrokerRunnerSearches/>}/>
                                <Route path="/bokerrunner/properties" element={<BrokerRunnerHome/>}/>
                                <Route path="/contacts" element={<Contacts/>}>
                                </Route>
                                <Route path="/contacts/edit/:id" element={<ContactForm/>}/>
                                <Route path="/contacts/new" element={<ContactForm/>}/>
                                <Route path="/contracts" element={(<Contracts/>)}/>
                                <Route path="/contracts/bookingContract" element={(<BookingContractForm/>)}/>
                                <Route path="/contracts/finalContract" element={(<ContractForm/>)}/>
                                <Route path="/contracts/bookingContract/:id" element={(<BookingContractForm/>)}/>
                                <Route path="/contracts/finalContract/:id" element={(<ContractForm/>)}/>
                                <Route path="*" element={<NotFound/>}/>
    
                                /*RUTAS OWNER TENANT*/
                                <Route path="/addresses" element={<TenantOwnerHome/>}/>
                                <Route path="/addresses/owner/contracts/:id" element={<OwnerContract/>}/>
                                /*RUTAS RUNNER/BROKER*/
                                <Route path="/addresses/tenant/contracts/:id" element={<TenantContract/>}/>
    
                                <Route path="/referrals" element={<ReferralsHome/>}/>
    
                            </Routes>
                        </div>
                        <TermsAndConditionsModal/>
                    </BrowserRouter>
                </Suspense>
            </div>
        ); 
    }
    else if (hostname === process.env.REACT_APP_SECOND_DOMAIN) {
        return (            
        <div className="App">
            <Suspense fallback={<div>Loading...</div>}>
            <BrowserRouter>
                <Routes>
                    <Route path="/property/:id" element={<ExportedPropertyWrapper internalLink={false}/>} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
            </Suspense>
        </div>
        );
    }
    else{
        return <NotFound/>;
    }
}

export default App;
