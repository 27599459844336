import { ClassNames } from "@emotion/react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";

export default function PropertyInfoMap({ icon, zoom, position,classNameProp}) {
    const PropertyInfoMapClassname=''.concat(
        'h-[310px]  lg:h-[489px] rounded-[20px] '
        ,classNameProp?classNameProp:''
    )
  return (
    <MapContainer center={position} zoom={zoom} scrollWheelZoom={true} className={PropertyInfoMapClassname}>
    <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <Marker position={position}>
    </Marker>
  </MapContainer>
  );
}