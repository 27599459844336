/*Asigna los valores de los servicios en la edicion de contrato*/
import {bsAsCabaNeighborhoods, propertyCategories} from "./dataEntriesHelper";
import {devLog} from "./globalHelper";

export     const dataAssignContractServices =(data)=>{
    let dataAssignedAarray = [];
    data.map(service=>{
        if(service==="GAS"){
            dataAssignedAarray.push({label: "Gas", value: service});
        }
        if(service==="WIFI"){
            dataAssignedAarray.push({label: "Wi fi", value: service});
        }
        if(service==="ELECTRICITY"){
            dataAssignedAarray.push({label: "Electricidad", value: service});
        }
        if(service==="WATER"){
            dataAssignedAarray.push({label: "Agua", value: service});
        }
        if(service==="ABL"){
            dataAssignedAarray.push({label: "Abl", value: service});
        }
        if(service==="TV_PROVIDER"){
            dataAssignedAarray.push({label: "Cable", value: service});
        }
        if(service==="PHONE"){
            dataAssignedAarray.push({label: "Teléfono", value: service});
        }
        if(service==="ORDINARY_EXPENSES"){
            dataAssignedAarray.push({label: "Expensas ordinarias", value: service});
        }
        if(service==="EXTRAORDINARY_EXPENSES"){
            dataAssignedAarray.push({label: "Expensas extraordinarias", value: service});
        }
        })
    return dataAssignedAarray;
}

/*Asgina los valores de los servicios y amenities en la edicion de la propiedad*/
export  const dataAssignPropertyServices =(data)=>{
    let dataAssignedAarray = [];
    data.map(service=>{
        if(service==="SAUNA"){
            dataAssignedAarray.push({label: "Sauna", value: service});
        }
        if(service==="JACUZZI"){
            dataAssignedAarray.push({label: "Jacuzzi", value: service});
        }
        if(service==="BARBECUE"){
            dataAssignedAarray.push({label: "Parrilla", value: service});
        }
        if(service==="GUEST_GARAGE"){
            dataAssignedAarray.push({label: "Garage de invitados", value: service});
        }
        if(service==="OUTDOOR_POOL"){
            dataAssignedAarray.push({label: "Pileta descubierta", value: service});
        }
        if(service==="INDOOR_POOL"){
            dataAssignedAarray.push({label: "Pileta cubierta", value: service});
        }
        if(service==="SUM"){
            dataAssignedAarray.push({label: "Sum", value: service});
        }
        if(service==="PLAY_ROOM"){
            dataAssignedAarray.push({label: "Sala de juegos", value: service});
        }
        if(service==="MICROCINEMA"){
            dataAssignedAarray.push({label: "Microcine", value: service});
        }
        if(service==="PARKING"){
            dataAssignedAarray.push({label: "Cochera", value: service});
        }
        })
    return dataAssignedAarray;
}
export const dataAssignPropertyAmenities =(data)=>{
    let dataAssignedAarray = [];
    data.map(service=>{
        if(service==="SUM"){
            dataAssignedAarray.push({label: "Sum", value: service});
        }
        if(service==="BARBECUE"){
            dataAssignedAarray.push({label: "Parrilla", value: service});
        }
        if(service==="OUTSIDE_POOL"){
            dataAssignedAarray.push({label: "Pileta externa", value: service});
        }
        if(service==="INSIDE_POOL"){
            dataAssignedAarray.push({label: "Pileta interna", value: service});
        }
        if(service==="PLAY_ROOM"){
            dataAssignedAarray.push({label: "Salon de juegos", value: service});
        }
        if(service==="GUESTS_GARAGES"){
            dataAssignedAarray.push({label: "Garage de invitados", value: service});
        }
        })
    return dataAssignedAarray;
}

export  const dataAssignPropertyCategories =(data)=>{
    let dataAssignedAarray = [];
    if (data !== undefined) {
        data.forEach(aPropertyCategory=>{
            if (propertyCategories[aPropertyCategory]) {
                dataAssignedAarray.push({ name: propertyCategories[aPropertyCategory], value: aPropertyCategory })
            }
        })
    }
    return dataAssignedAarray;
}

/*Asgina los valores de los barrios en la edicion de usuario*/
export  const dataAssignSuburbs =(data)=>{
    let dataAssignedAarray = [];
    if (data !== undefined) {
        data.forEach(suburb=>{
            if (bsAsCabaNeighborhoods[suburb]) {
                dataAssignedAarray.push({ name: bsAsCabaNeighborhoods[suburb], value: suburb })
            }
        })
    }
    return dataAssignedAarray;
}