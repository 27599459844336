import React from 'react';
import axios from "axios";
import authHeaderService from "./authHeaderService";
import {authorizationCheck, devLog} from "../globalHelper";

export async function getPaymentsByContractId(id) {
    return await axios
        .get(
            `${process.env.REACT_APP_API_URL}/api/contracts/${id}/payments`,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
                else{
                    console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
                }
        });
}


