import axios from "axios";
import authHeaderService from "./authHeaderService";

export async function checkBackendHealth() {
    return await axios
        .get(
            `${process.env.REACT_APP_API_URL}/actuator/health`,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if (e.response) {
                return  e.response;
            }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
};