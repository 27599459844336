import DataContainerCard from "../DataContainerCard"
import "../../../CSS/Reusable/BookingContractForm.scss";
import {mapCurrencyName} from "../../../dataMappingUtilHelper"
import ButtonStandard from "../ButtonStandard";
import Grid from "../Grid"
import DataInput from "../DataInput"
import DataSelectBox from "../DataSelectBox"
import DataSelectBoxInput from "../DataSelectBoxInput"
import DataDayPicker from "../DataDayPicker"
import AutoCompleteInput from "../AutoCompleteInput";
import CommissionTitle from "../CommissionTitle";
import DataMultiSelectBox from "../DataMultiSelectBox"
import {getProperty} from "../../../Services/MainViews/Properties/propertiesService";
import {getUser} from "../../../Services/MainViews/Users/contactsService";
import {addNewUser, fetchContacts,fetchContactsByRole} from "../../../Services/MainViews/Users/contactsService";
import MyModal from "../MyModal";
import ContractModalForm from "./ContractModalForm";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom"
import ContractPayments from "./ContractPayments";
import {getIcon} from "../../../Services/iconService";
import {existAndReturn, fixSystemDate, unionJsonValue, devLog, calculateTotalRent, monthsBetween, addPrefixIfMissing} from "../../../globalHelper"
import {contractOperationsEntries, contractServicesEntries, moneyEntries} from "../../../dataEntriesHelper"
import {checkRequiredInputs} from "../../../errorValidationHelper"
import { data } from "autoprefixer";


export default function ContractFormStep1({
                                              contractForm,
                                              setContractForm,
                                              onInputChange,
                                              onInputChangeCommission,
                                              commissionData,
                                              setCommissionData,
                                              baredesCommissionPercent,
                                              catchmentCommissionPercentEditableValue,
                                              setCatchmentCommissionPercentEditableValue,
                                              setKeyCommissionPercentEditableValue,
                                              keyCommissionPercentEditableValue,
                                              adjustment
                                          }) {

    const [showModal, setShowModal] = useState({show: false});

    const [showKey, setShowKey] = useState(false);
    const [activeCommissionModifiedValues, setActiveCommissionModifiedValues] = useState({
        contractCommissionActive: false,
        keyCommissionActive: false,
    });

    const toggleVisibleEditableValue = (propertyName) => {
        
        setActiveCommissionModifiedValues(prevState => ({
          ...prevState,
          [propertyName]: !prevState[propertyName]
        }));
      };

      const handleInputChangeCommission2 = (eventValue, eventName, setCurrentState, currentState) => {
        const keys = eventName.split('.');
        let updatedState = { ...currentState };
    
        if (eventName === '') {
            updatedState = eventValue;
        } else {
            let currentSection = updatedState;
            for (let i = 0; i < keys.length - 1; i++) {
                let key = keys[i];
                if (!currentSection[key]) {
                    currentSection[key] = isNaN(keys[i + 1]) ? {} : [];
                }
                currentSection = currentSection[key];
            }
            currentSection[keys[keys.length - 1]] = eventValue;
        }
    
        setCurrentState(updatedState);
    };

    const [responseModal, setResponseModal] = useState(false);

    const [scheduleFormRentState, setScheduleFormRentState] = useState({});
    const [scheduleFormBuyState, setScheduleFormBuyState] = useState({});

    const [contractModalForm, setContractModalForm] = useState({
        username: '',
        name: '',
        surname: '',
        cellPhone:''
    })

    const getPropertyInfo = async (e) => {
        e.preventDefault();
        let actualProperty = {};
        try {
            const res = await getProperty(contractForm.property.id);
            if (res.message) {
                alert(res.message);
            } else {
                let propertyOwnerList = [];
                actualProperty = res;
                res.owners.forEach(owner => {
                    const ownerUser = {
                        user: owner
                    };
                    propertyOwnerList.push(ownerUser);
                });
    
                const userInfos = res.commissionContainer.catchmentCommission.commissionOwners.map(user => ({
                    ...user,
                }));
    
                const userPromises = userInfos.map(info => info);
                const results = await Promise.allSettled(userPromises);
    
                const newCommissionData = {
                    catchmentCommission: {
                        owners: [],
                        referrer: { commissionPercent: 0 },
                        runner: { commissionPercent: 0 },
                        baredes: { commissionPercent: 0 }
                    }
                };
    
                results.forEach((result, index) => {
                    if (result.status === 'fulfilled' && result.value) {
                        const userType = userInfos[index].commissionType;
                        const combinedUserData = { ...userInfos[index], ...result.value };
                        if (userType === 'OWNER') {
                            newCommissionData.catchmentCommission.owners.push(combinedUserData);
                        } else if (userType === 'REFERRAL') {
                            newCommissionData.catchmentCommission.referrer = combinedUserData;
                        } else if (userType === 'RUNNER') {
                            newCommissionData.catchmentCommission.runner = combinedUserData;
                        } else if (userType === 'BAREDES') {
                            newCommissionData.catchmentCommission.baredes = combinedUserData;
                        }
                    }
                });

                const newOwnersEquity = await res.owners.map((singleOwner) => {
                    return {equity:0,user:singleOwner};
                })

                setContractForm(prevState => ({
                    ...prevState,
                    ownersEquity: newOwnersEquity
                }));
                
                setCommissionData(prevState => ({
                    ...prevState,
                    catchmentCommission: newCommissionData.catchmentCommission
                }));
    
                alert('Se añadió la propiedad correctamente');
            }
        } catch (error) {
            console.error("Error fetching property or user data:", error);
        }
    };

    const modalSubmit = (e) => {
        e.preventDefault();

        let requiredInputs = [
            {value: existAndReturn(contractModalForm, "username"), name: 'Correo'},
            {value: existAndReturn(contractModalForm, "name"), name: 'Nombre'},
            {value: existAndReturn(contractModalForm, "surname"), name: 'Apellido'},
            {value: existAndReturn(contractModalForm, "cellPhone"), name: 'Telefono'},
        ]
       if (checkRequiredInputs(requiredInputs) <= 0) {
            const cellPhoneAdapted = contractModalForm.cellPhone.prefix+''+contractModalForm.cellPhone.phoneNumber
            const newUser ={
                "username": contractModalForm.username,
                "name": contractModalForm.name,
                "surname": contractModalForm.surname,
                "cellPhone": addPrefixIfMissing(cellPhoneAdapted),
                "type": "Contact"
            }
            
            addNewUser(newUser)
                .then((res) => {
                    if (res) {
                        devLog(true, "[Binculo] addNewUser: ", res)
                        alert("[Binculo] Usuario añadido");
                        setContractModalForm(
                            {
                                username: '',
                                name: '',
                                surname: '',
                                cellPhone:''
                            }
                        )

                    } else {
                        devLog(true, "[Binculo] Error al añadir el contacto: ", res)
                        alert("[Binculo] Error al añadir el contacto");
                    }
                })
                .catch((error) => {
                    console.error("[Binculo] Error inesperado:", error);
                });
        }  
        else{
            alert("Debe llenar los siguientes campos: " + checkRequiredInputs(requiredInputs))
        }
    }

    let keyCommision;

    const settingScheduleForm = (data) => {
        if (data === "BUY") {
            setScheduleFormRentState({"display": "none"})
            setScheduleFormBuyState({"display": "block"})
        }
        if (data !== "BUY") {
            setScheduleFormRentState({"display": "block"})
            setScheduleFormBuyState({"display": "none"})
        }
    }

    useEffect(() => {
        if (contractForm.keyCommission) {
            setShowKey(true);
        }
        let data = contractForm.operationType;
        settingScheduleForm(data);
    }, [contractForm.operationType, contractForm.keyCommission])

    const paymentScheduleForm = (data) => {
        settingScheduleForm(data);
    }

    const handleFilterContacts = async (names, type, isByRole) => {
        let fields = names.split(" ");
        let searchContactDTO = {};
        searchContactDTO.types =[type];
        if (fields.length === 1)
            searchContactDTO.fieldOne = fields[0];
        if (fields.length === 2) {
            searchContactDTO.fieldOne = fields[0];
            searchContactDTO.fieldOne = fields[1];
        }

        if (fields.length === 3) {
            searchContactDTO.fieldOne = fields[0];
            searchContactDTO.fieldOne = fields[1];
            searchContactDTO.fieldOne = fields[2];
        }  
        if (isByRole) {
            searchContactDTO.role =type;
            return fetchContactsByRole(searchContactDTO);
        }
        else{
            return fetchContacts(searchContactDTO);
        }
    }

    const newOwnerEquity = ()  =>{
        let newData ={
            user:{},
            equity:0
        }
        setContractForm({
            ...contractForm,
            ownersEquity:[
                ...contractForm.ownersEquity, 
                newData
            ]
        })
    }

    const deleteOwnerEquity = (e,OPindex) =>  {
        e.preventDefault();
        setContractForm(prev => {
            const ownersEquity = contractForm.ownersEquity.filter((_, index) => index !== OPindex);
            return {
              ...prev,
              ownersEquity
            }
          })
    }

    const closeKeyCommission =()=>{
        setShowKey(false)
        let newContract = contractForm;
        if (newContract.keyCommission) {
            delete newContract.keyCommission;
            setContractForm(newContract);
        }
    }
    if (showKey) {
        keyCommision =
            <Grid columns="2">
                <DataSelectBoxInput type="Number" tittle="Comisión Llave" modalState={showKey}
                                 entries={moneyEntries} ChangeState={onInputChange} targetName={"keyCommission.payment"}
                                 indexValues={["currency", "amount"]}
                                 defaultValue={existAndReturn(contractForm, "keyCommission.payment")}/>
                <button>
                    <div    onClick={closeKeyCommission} className="dataInputIconElement">{getIcon("deleteX")}</div>
                </button> 
            </Grid>            
    } else {
        keyCommision = '';
    }

    const modalChildren = <ContractModalForm contractModalForm={contractModalForm} setContractModalForm={setContractModalForm}/>;
    let params = useParams();
    let PaymentsMonths;

    if (params.id) {
        PaymentsMonths = <ContractPayments showServices={false} showAmounts={false} payments={contractForm.payments} contractFormPayment/>;
    }

    return (
        <>
            <form>
                <DataContainerCard tittle="DATOS DE CONTRATO" className="ContractForm">
                    <Grid columns="1">
                        <DataSelectBox tittle="Tipo de operaciónes *" entries={contractOperationsEntries} ChangeState={onInputChange}
                                        targetName={"operationType"} paymentScheduleForm={paymentScheduleForm}
                                        defaultValue={existAndReturn(contractForm, "operationType")}/>
                    </Grid>
                </DataContainerCard>

                <DataContainerCard tittle="" className="ContractForm">
                    <Grid columns="1">
                        <AutoCompleteInput id="tenantName" key="tenantName" onChange={(e) => handleFilterContacts(e,'Contact')}
                                            ChangeState={onInputChange} targetName={"secondPartsEquity[0].user"}
                                            defaultValue={existAndReturn(contractForm, "secondPartsEquity[0].user")}
                                            showAttributes={["name", "surname"]}
                                            tittle="Nombre del Inquilino *"
                                            placeHolder={"Ingrese nombre y apellido"} required/>
                    </Grid>

                    <Grid columns="1">
                        <label className="text-blue-900 font-semibold cursor-pointer mt-1 mb-1"
                               onClick={() => {
                                   setShowModal({show: true});
                               }}>
                            + Agregar nuevo contacto
                        </label>
                        {<MyModal modalState={showModal} children={modalChildren} className="ContractModalForm"
                                  modalSubmit={modalSubmit} responseModal={responseModal} canBeClosedBeforeSubmiting noCloseOnSubmit/>}
                    </Grid>
                    {
                        contractForm.ownersEquity.map((owner, index) => {
                            return (
                                <Grid columns="3" key={index}>
                                    <AutoCompleteInput          id="ownerName" key="ownerName"
                                                                onChange={(e) => handleFilterContacts(e,'Contact')}
                                                                ChangeState={onInputChange}
                                                                targetName={'ownersEquity['+index+'].user'}
                                                                defaultValue={owner.user}
                                                                showAttributes={["name", "surname"]}
                                                                placeHolder="Ingresa nombre y apellido"
                                                                tittle="Nombre del propietario *"/>

                                    <DataInput type="Number"    placeHolder="Porcentaje a cobrar" 
                                                                tittle="Porcentaje *"
                                                                defaultValue={Number(owner.equity)} 
                                                                ChangeState={onInputChange}
                                                                targetName={'ownersEquity['+index+'].equity'}/>

                                <button onClick={(e) => deleteOwnerEquity(e,index)}  style={{display: index ? 'block' : 'none' }}>
                                    <div className="dataInputIconElement">{getIcon("deleteX")}</div>
                                </button>
                                </Grid>
                            );
                        })
                    }
                    <Grid columns="1">
                        <label className="text-blue-900 font-semibold cursor-pointer mt-1 mb-1" onClick={newOwnerEquity}> +
                            Agregar nuevo
                            propietario</label>
                    </Grid>

                </DataContainerCard>

                <DataContainerCard>
                    <div style={scheduleFormRentState}>
                        <div className={params.id ? 'UnabledButton' : ''}>
                            <Grid columns="2">
                                <DataDayPicker tittle="Fecha de inicio *" placeHolder="Ingrese la fecha de inicio"
                                                ChangeState={onInputChange}
                                                targetName={"paymentSchedule.firstPaymentStartDate"}
                                                defaultValue={fixSystemDate(contractForm.paymentSchedule.firstPaymentStartDate)}/>
                                <DataDayPicker tittle="Fecha de finalización *"
                                                placeHolder="Ingrese la fecha de finalización"
                                                ChangeState={onInputChange}
                                                targetName={"paymentSchedule.lastPaymentEndDate"}
                                                defaultValue={fixSystemDate(contractForm.paymentSchedule.lastPaymentEndDate)}/>
                            </Grid>

                            <Grid columns="2">
                                <DataSelectBoxInput type="Number" tittle="Alquiler inicial *"
                                                     entries={moneyEntries} ChangeState={onInputChange}
                                                     targetName={"paymentSchedule.initialAmount"}
                                                     indexValues={["currency", "value"]}
                                                     defaultValue={existAndReturn(contractForm, "paymentSchedule.initialAmount")}/>
                                <DataSelectBoxInput type="Number" tittle="Llave"
                                                     entries={moneyEntries} ChangeState={onInputChange}
                                                     targetName={"key"} indexValues={["currency", "value"]}
                                                     defaultValue={existAndReturn(contractForm, "key")}/>
                            </Grid>

                            <Grid columns="3">
                                <DataInput tittle="Aumento cada" placeHolder="Ingrese número de meses" type="Number"
                                            ChangeState={onInputChange}
                                            targetName={"paymentSchedule.increaseEveryXMonths"}
                                            defaultValue={existAndReturn(contractForm, "paymentSchedule.increaseEveryXMonths")}/>
                                <DataInput type="Number" tittle="Ajuste %" placeHolder="Ingrese un porcentaje"
                                            ChangeState={onInputChange}
                                            targetName={"paymentSchedule.increaseEveryXMonthsPercentage"}
                                            defaultValue={existAndReturn(contractForm, "paymentSchedule.increaseEveryXMonthsPercentage")}/>
                                <DataInput tittle="Ajuste / Descripción" type="Text" ChangeState={onInputChange}
                                            targetName={"paymentSchedule.description"}
                                            defaultValue={existAndReturn(contractForm, "paymentSchedule.description")}/>
                            </Grid>
                        </div>
                        <Grid columns="2">
                            <div className={params.id ? 'UnabledButton' : ''}>
                                <DataInput tittle="Mes de gracia" type="Number" ChangeState={onInputChange}
                                            targetName={"paymentSchedule.daysOfGrace"}
                                            defaultValue={existAndReturn(contractForm, "paymentSchedule.daysOfGrace")}/>
                            </div>
                            <DataInput tittle="Rubro Inquilino" type="Text" ChangeState={onInputChange}
                                        targetName={"secondPartsEquity.user.area"}
                                        defaultValue={existAndReturn(contractForm, "secondPartsEquity[0].user.area")}/>
                        </Grid>

                        <Grid columns="2">
                            <DataMultiSelectBox entries={contractServicesEntries} tittle="Servicios inquilino" ChangeState={onInputChange} targetName={"showTenantServices"}
                                                 defaultValue={existAndReturn(contractForm, "showTenantServices")}/>
                            <DataMultiSelectBox entries={contractServicesEntries} tittle="Servicios propietario" ChangeState={onInputChange} targetName={"showOwnerServices"}
                                                 defaultValue={existAndReturn(contractForm, "showOwnerServices")}/>
                        </Grid>
                    </div>
                    <div style={scheduleFormBuyState}>
                        <Grid columns="1">
                            <DataDayPicker tittle="Fecha de pago *" placeHolder="Ingrese la fecha de pago"
                                            ChangeState={onInputChange}
                                            targetName={"paymentSchedule.firstPaymentStartDate"}
                                            defaultValue={new Date(contractForm.paymentSchedule.firstPaymentStartDate)}/>
                        </Grid>
                        <Grid columns="1">
                            <DataSelectBoxInput type="Number" tittle="Precio *"
                                                 entries={moneyEntries} ChangeState={onInputChange}
                                                 targetName={"paymentSchedule.initialAmount"}
                                                 indexValues={["currency", "value"]}
                                                 defaultValue={existAndReturn(contractForm, "paymentSchedule.initialAmount")}/>
                        </Grid>
                    </div>

                    <Grid columns="3">
                        <DataInput tittle="Número ID *" placeHolder="Completa número ID de la propiedad" type="Number"
                                    ChangeState={onInputChange} targetName={"property.id"}
                                    defaultValue={existAndReturn(contractForm, "property.id")}/>
                        <div    style={{"alignSelf":"end",'marginRight':'auto'}}><ButtonStandard showName="Añadir propiedad" float="right" onClick={getPropertyInfo} blueColor/></div>
                        <DataInput tittle="Número interno de contrato" type="Text"
                                    ChangeState={onInputChange} targetName={"internalContractNumber"}
                                    defaultValue={existAndReturn(contractForm, "internalContractNumber")}/>
                    </Grid>
                </DataContainerCard>

                <DataContainerCard>
                    <Grid columns="2">
                        <Grid columns="2">
                            <DataSelectBoxInput type="Number" tittle="Comisión Contrato"
                                                    name="contractCommission.amount"
                                                    entries={moneyEntries} 
                                                    ChangeState={handleInputChangeCommission2}
                                                    currentState={commissionData}
                                                    currentSetState={setCommissionData}
                                                    targetName={"contractCommission.amount"}
                                                    realTimeRefresh
                                                    indexValues={["currency", "value"]}
                                                    defaultValue={existAndReturn(commissionData, "contractCommission.amount")}
                                                    className={" col-start-1 lg:col-start-2 "}
                                                    columnSpan={2}/>

                            {existAndReturn(commissionData, "contractCommission.amount.currency") === "PERCENTAGE"?
                            <div className="flex space-x-8">
                                <label className="flex items-center justify-center text-grey-2 mt-2 font-900 cursor-pointer"  onClick={()=>toggleVisibleEditableValue('contractCommissionActive')}>
                                    {
                                    mapCurrencyName(contractForm?.paymentSchedule?.initialAmount?.currency)+" "+(
                                        calculateTotalRent(
                                            existAndReturn(contractForm, "paymentSchedule.initialAmount.value"), 
                                            existAndReturn(contractForm, "paymentSchedule.increaseEveryXMonthsPercentage"), 
                                            existAndReturn(contractForm, "paymentSchedule.increaseEveryXMonths"), 
                                            monthsBetween(existAndReturn(contractForm, "paymentSchedule.firstPaymentStartDate"),existAndReturn(contractForm, "paymentSchedule.lastPaymentEndDate"))
                                        )*commissionData?.contractCommission?.amount?.value)/100
                                    }
                                </label>      
                                <DataSelectBoxInput type="Number" tittle=""   
                                                        entries={moneyEntries} 
                                                        ChangeState={handleInputChangeCommission2}
                                                        currentState={catchmentCommissionPercentEditableValue}
                                                        currentSetState={setCatchmentCommissionPercentEditableValue}
                                                        realTimeRefresh={true}
                                                        name={""}
                                                        indexValues={["currency", "value"]}
                                                        value={catchmentCommissionPercentEditableValue}
                                                        onlyReadable={activeCommissionModifiedValues.contractCommissionActive?true:false}
                                                        defaultValue={catchmentCommissionPercentEditableValue}/>
                            </div>:''}
                        </Grid>
                        <div style={scheduleFormRentState}>
                            <Grid columns="2">
                                

                                
                                <DataSelectBoxInput type="Number" tittle="Comisión Llave"
                                                        name="keyCommission.amount"
                                                        realTimeRefresh
                                                        entries={moneyEntries} 
                                                        ChangeState={handleInputChangeCommission2}
                                                        currentState={commissionData}
                                                        currentSetState={setCommissionData}
                                                        targetName={"keyCommission.amount"} indexValues={["currency", "value"]}
                                                        defaultValue={existAndReturn(commissionData, "keyCommission.amount")}
                                                        className={" col-start-1 lg:col-start-2 "}
                                                        columnSpan={2}/>
                                {existAndReturn(commissionData, "keyCommission.amount.currency") === "PERCENTAGE"?
                                <div className="flex space-x-8">
                                    <label className="flex items-center justify-center text-grey-2 mt-2 font-900 cursor-pointer" onClick={()=>toggleVisibleEditableValue('keyCommissionActive')}>
                                        {contractForm?.key && contractForm?.key?.value >0?mapCurrencyName(contractForm?.key?.currency)+" "+(contractForm?.key?.value*commissionData?.keyCommission?.amount?.value)/100:'Se necesita el campo llave para comision llave'}
                                    </label> 
                                    {contractForm?.key && contractForm?.key?.value >0?<DataSelectBoxInput type="Number" tittle=""   
                                                            entries={moneyEntries} 
                                                            ChangeState={handleInputChangeCommission2}
                                                            currentState={keyCommissionPercentEditableValue}
                                                            currentSetState={setKeyCommissionPercentEditableValue}
                                                            realTimeRefresh={true}
                                                            name={""}
                                                            indexValues={["currency", "value"]}
                                                            value={keyCommissionPercentEditableValue}
                                                            onlyReadable={activeCommissionModifiedValues.keyCommissionActive?true:false}
                                                            defaultValue={keyCommissionPercentEditableValue}/>:''}
                                </div>:''}
                            </Grid>
                        </div>
                    </Grid>
                    <Grid columns="3">
                        <div className=" col-start-1 lg:col-start-2">
                            <DataInput tittle="Ajuste" placeHolder="Ingrese el ajuste"
                                                type="Number"
                                                value={adjustment}
                                                targetName={'BaredesProperty.propertyOperations'}
                                                defaultValue={adjustment}
                                                className={"lg:w-[60%] text-center"}
                                                onlyReadable/>
                        </div>
                    </Grid>
                    <Grid columns="2">
                        <div>
                            <Grid columns="2">
                                <CommissionTitle>Comisión <strong>Captación</strong></CommissionTitle>
                                <DataInput tittle="%" placeHolder="Ingrese un porcentaje"
                                        type="Number"
                                        name={'catchmentCommission.totalPercent'}
                                        realTimeRefresh={true}
                                        ChangeState={handleInputChangeCommission2}
                                        currentState={commissionData}
                                        currentSetState={setCommissionData}
                                        targetName={'catchmentCommission.totalPercent'}
                                        defaultValue={existAndReturn(commissionData, "catchmentCommission.totalPercent")}
                                        className={"w-[90px] text-center items-end "}/>
                            </Grid>
                            <Grid columns="4">
                                <span className="xl:col-start-2">
                                    <DataInput tittle="%" placeHolder=""
                                                type="Number"
                                                ChangeState={onInputChangeCommission}
                                                targetName={'catchmentCommission.totalPercent'}
                                                defaultValue={100}
                                                value={100}
                                                className={"w-[90px] text-center"}
                                                onlyReadable/>

                                </span>
                                <div className=" col-start-2 lg:col-start-3">
                                    <DataInput tittle="Monto" placeHolder=""
                                            type="Number"
                                            onlyReadable
                                            defaultValue={(existAndReturn(commissionData, "catchmentCommission.totalPercent")*adjustment)/100}
                                            className={" text-center items-end "}/>
                                </div>
                            </Grid>
                            <Grid columns="4">
                                <AutoCompleteInput          id="ownerName" key="ownerName"
                                                            onChange={(e) => handleFilterContacts(e,'Contact')}
                                                            ChangeState={e => onInputChangeCommission(e)}
                                                            targetName={''}
                                                            defaultValue={existAndReturn(commissionData, "catchmentCommission.referrer.owner")}
                                                            showAttributes={["name", "surname"]}
                                                            placeHolder="Ingresa nombre y apellido"
                                                            tittle="Referidor  "
                                                            onlyReadable
                                                            classname={" col-span-2 lg:col-start-1 lg:col-span-1  "}/>
                                <span className="col-start-1 lg:col-start-2">
                                    <DataInput tittle="%" placeHolder="Ingrese un porcentaje"
                                                type="Number"
                                                ChangeState={handleInputChangeCommission2}
                                                currentState={commissionData}
                                                currentSetState={setCommissionData}
                                                realTimeRefresh={true}
                                                name={""}
                                                targetName={""}
                                                onlyReadable
                                                defaultValue={existAndReturn(commissionData, "catchmentCommission.referrer.percentageCurrentOperation")}
                                                className={"w-[90px] text-center"}/>

                                </span>
                                <div className=" col-start-2 lg:col-start-3">
                                    <DataInput tittle="Monto" placeHolder=""
                                                        type="Number"
                                                        targetName={'BaredesProperty.propertyOperations'}
                                                        defaultValue={(((existAndReturn(commissionData, "catchmentCommission.totalPercent")*adjustment)/100)*existAndReturn(commissionData, "catchmentCommission.referrer.percentageCurrentOperation"))/100}
                                                        className={" text-center"}
                                                        onlyReadable/>
                                </div>
                            </Grid>
                            <Grid columns="4">
                                <AutoCompleteInput          id="ownerName" key="ownerName"
                                                            onChange={(e) => handleFilterContacts(e,'RUNNER',true)}
                                                            ChangeState={e => onInputChangeCommission(e)}
                                                            targetName={''}
                                                            defaultValue={existAndReturn(commissionData, "catchmentCommission.runner.owner")}
                                                            showAttributes={["name", "surname"]}
                                                            placeHolder="Ingresa nombre y apellido"
                                                            tittle="Agente "
                                                            onlyReadable
                                                            classname={" col-span-2 lg:col-start-1 lg:col-span-1  "}/>
                                <span className="col-start-1 lg:col-start-2">
                                    <DataInput tittle="%" placeHolder="Ingrese un porcentaje"
                                                type="Number"
                                                ChangeState={handleInputChangeCommission2}
                                                currentState={commissionData}
                                                currentSetState={setCommissionData}
                                                realTimeRefresh={true}
                                                name={""}
                                                targetName={""}
                                                defaultValue={existAndReturn(commissionData, "catchmentCommission.runner.percentageCurrentOperation")}
                                                className={"w-[90px] text-center"}
                                                onlyReadable/>

                                </span>
                                <div className=" col-start-2 lg:col-start-3">
                                    <DataInput tittle="Monto" placeHolder=""
                                                        type="Number"
                                                        targetName={'BaredesProperty.propertyOperations'}
                                                        defaultValue={(((existAndReturn(commissionData, "catchmentCommission.totalPercent")*adjustment)/100)*existAndReturn(commissionData, "catchmentCommission.runner.percentageCurrentOperation"))/100}
                                                        className={" text-center"}
                                                        onlyReadable/>
                                </div>
                            </Grid>
                            {
                                commissionData.catchmentCommission.owners.map((owner, index) => {
                                        return (
                                            <Grid columns="4" key={index}>
                                                <AutoCompleteInput          id="ownerName" key="ownerName"
                                                                            onChange={(e) => handleFilterContacts(e,'Contact')}
                                                                            ChangeState={onInputChange}
                                                                            targetName={'owners['+index+'].owner'}
                                                                            defaultValue={owner.owner}
                                                                            showAttributes={["name", "surname"]}
                                                                            placeHolder=""
                                                                            onlyReadable
                                                                            classname={'onlyReadable col-span-2 lg:col-start-1 lg:col-span-1 '}
                                                                            tittle="Propietario"/>
                                                <span className="col-start-1 lg:col-start-2">
                                                    <DataInput type="Number"    placeHolder="Porcentaje a cobrar" 
                                                                                onlyReadable
                                                                                tittle="%"
                                                                                defaultValue={Number(owner.percentageCurrentOperation)} 
                                                                                ChangeState={onInputChange}
                                                                                targetName={'ownersEquity['+index+'].equity'}
                                                                                className={"w-[90px] text-center"}/>
                                                </span>
                                                <div className=" col-start-2 lg:col-start-3">
                                                    <DataInput tittle="Monto" placeHolder=""
                                                                                type="Number"
                                                                                targetName={'BaredesProperty.propertyOperations'}
                                                                                defaultValue={(((existAndReturn(commissionData, "catchmentCommission.totalPercent")*adjustment)/100)*Number(owner.percentageCurrentOperation))/100}
                                                                                className={" text-center"}
                                                                                onlyReadable/>
                                                </div>

                                            </Grid>
                                        );
                                })
                            }
                            <Grid columns="4">
                                <h3 className="mt-auto ">Binculo</h3>
                                <span className="col-start-1 lg:col-start-2">
                                    <DataInput tittle="%" placeHolder=""
                                                type="Number"
                                                defaultValue={existAndReturn(commissionData, "catchmentCommission.baredes.percentageCurrentOperation")}
                                                className={"w-[90px] text-center"}
                                                onlyReadable/>

                                </span>
                                <div className=" col-start-2 lg:col-start-3">
                                    <DataInput tittle="Monto" placeHolder=""
                                                        type="Number"
                                                        onlyReadable
                                                        targetName={'catchmentCommission.totalPercent'}
                                                        defaultValue={(((existAndReturn(commissionData, "catchmentCommission.totalPercent")*adjustment)/100)*existAndReturn(commissionData, "catchmentCommission.baredes.percentageCurrentOperation"))/100}
                                                        className={" text-center"}/>
                                </div>
                            </Grid>
                        </div>
                        <div>
                            <Grid columns="2">
                                <CommissionTitle>Comisión <strong>Venta</strong></CommissionTitle>
                                <DataInput tittle="%" placeHolder="Ingrese un porcentaje"
                                        type="Number"
                                        ChangeState={handleInputChangeCommission2}
                                        currentState={commissionData}
                                        currentSetState={setCommissionData}
                                        realTimeRefresh={true}
                                        name={'salesCommission.totalPercent'}
                                        targetName={'salesCommission.totalPercent'}
                                        defaultValue={existAndReturn(commissionData, "salesCommission.totalPercent")}
                                        className={"w-[90px] text-center items-end "}/>
                            </Grid>
                            <Grid columns="4">
                                <span className="xl:col-start-2">
                                    <DataInput tittle="%" placeHolder=""
                                                type="Number"
                                                ChangeState={onInputChangeCommission}
                                                targetName={'runner.propertyOperations'}
                                                defaultValue={100}
                                                value={100}
                                                className={"w-[90px] text-center"}
                                                onlyReadable/>

                                </span>
                                <div className=" col-start-2 lg:col-start-3">
                                    <DataInput tittle="Monto" placeHolder=""
                                            type="Number"
                                            onlyReadable
                                            defaultValue={(existAndReturn(commissionData, "salesCommission.totalPercent")*adjustment)/100}
                                            className={" text-center items-end "}/>
                                </div>
                            </Grid>
                            <Grid columns="4">
                                <AutoCompleteInput          id="ownerName" key="ownerName"
                                                            onChange={(e) => handleFilterContacts(e,'Broker')}
                                                            ChangeState={e => onInputChangeCommission(e)}
                                                            targetName={'salesCommission.broker.owner'}
                                                            defaultValue={existAndReturn(commissionData, "salesCommission.broker.owner")}
                                                            showAttributes={["name", "surname"]}
                                                            placeHolder="Ingresa nombre y apellido"
                                                            tittle="Broker  "
                                                            classname={" col-span-2 lg:col-start-1 lg:col-span-1  "}/>
                                <span className="col-start-1 lg:col-start-2">
                                    <DataInput tittle="%" placeHolder="Ingrese un porcentaje"
                                                type="Number"
                                                ChangeState={handleInputChangeCommission2}
                                                currentState={commissionData}
                                                currentSetState={setCommissionData}
                                                realTimeRefresh={true}
                                                name={"salesCommission.broker.percentageCurrentOperation"}
                                                targetName={"salesCommission.broker.percentageCurrentOperation"}
                                                defaultValue={existAndReturn(commissionData, "salesCommission.broker.percentageCurrentOperation")}
                                                className={"w-[90px] text-center"}/>

                                </span>
                                <div className=" col-start-2 lg:col-start-3">
                                    <DataInput tittle="Monto" placeHolder=""
                                                        type="Number"
                                                        targetName={''}
                                                        defaultValue={existAndReturn(commissionData, "salesCommission.broker.percentageCurrentOperation") > 0?(((existAndReturn(commissionData, "salesCommission.totalPercent")*adjustment)/100)*existAndReturn(commissionData, "salesCommission.broker.percentageCurrentOperation"))/100:"0"}
                                                        className={" text-center"}
                                                        onlyReadable/>
                                </div>
                            </Grid>
                            <Grid columns="4">
                                <AutoCompleteInput          id="ownerName" key="ownerName"
                                                            onChange={(e) => handleFilterContacts(e,'RUNNER',true)}
                                                            ChangeState={e => onInputChangeCommission(e)}
                                                            targetName={'salesCommission.runner.owner'}
                                                            defaultValue={existAndReturn(commissionData, "salesCommission.runner.owner")}
                                                            showAttributes={["name", "surname"]}
                                                            placeHolder="Ingresa nombre y apellido"
                                                            tittle="Agente "
                                                            classname={" col-span-2 lg:col-start-1 lg:col-span-1 "}/>
                                <span className="col-start-1 lg:col-start-2">
                                    <DataInput tittle="%" placeHolder="Ingrese un porcentaje"
                                                type="Number"
                                                ChangeState={handleInputChangeCommission2}
                                                currentState={commissionData}
                                                currentSetState={setCommissionData}
                                                realTimeRefresh={true}
                                                name={"salesCommission.runner.percentageCurrentOperation"}
                                                targetName={"salesCommission.runner.percentageCurrentOperation"}
                                                defaultValue={existAndReturn(commissionData, "salesCommission.runner.percentageCurrentOperation")}
                                                className={"w-[90px] text-center"}/>

                                </span>
                                <div className=" col-start-2 lg:col-start-3">
                                    <DataInput tittle="Monto" placeHolder=""
                                                        type="Number"
                                                        targetName={'BaredesProperty.propertyOperations'}
                                                        defaultValue={existAndReturn(commissionData, "salesCommission.runner.percentageCurrentOperation") > 0?(((existAndReturn(commissionData, "salesCommission.totalPercent")*adjustment)/100)*existAndReturn(commissionData, "salesCommission.runner.percentageCurrentOperation"))/100:"0"}
                                                        className={" text-center"}
                                                        onlyReadable/>
                                </div>
                            </Grid>
                            <Grid columns="4"><h3 className="mt-auto ">Binculo</h3>
                                <span className="col-start-1 lg:col-start-2">
                                    <DataInput tittle="% esta op." placeHolder="Ingrese un porcentaje"
                                            type="Number"
                                            value={baredesCommissionPercent}
                                            targetName={'baredesCommissionPercent'}
                                            defaultValue={baredesCommissionPercent>0?baredesCommissionPercent:"0"}
                                            className={"w-[90px] text-center"}
                                            onlyReadable/>


                                </span>
                                <div className=" col-start-2 lg:col-start-3">
                                    <DataInput tittle="Monto" placeHolder=""
                                                        type="Number"
                                                        onlyReadable
                                                        targetName={'BaredesProperty.propertyOperations'}
                                                        defaultValue={baredesCommissionPercent > 0?(((existAndReturn(commissionData, "salesCommission.totalPercent")*adjustment)/100)*baredesCommissionPercent)/100:"0"}
                                                        className={" text-center"}/>
                                </div>
                            </Grid>
                        </div>
                    </Grid>
                </DataContainerCard>
                <div    style={{display: params.id ? 'block' : 'none' }}>
                    <DataContainerCard>
                        <h1 style={{'textAlign':'center','fontSize':'1.5rem'}}>SERVICIOS</h1>
                        {PaymentsMonths}
                    </DataContainerCard>
                </div>
            </form>
        </>
    )
}
