import {useEffect, useState} from "react";
import "../../CSS/Reusable/ListLinks.scss";


export default function ListLinks(props) {
    let activeChange ='';

    return (
        <ul className="ListLinksUL">
            {
                props.childrens.map((children,index)=>{
                    if (children.value === props.actualCommissionType) {
                        activeChange = "Active";
                    }
                    else{
                        activeChange = "";
                    }
                    return(
                        <li id={"list"+children.value} className={"ListLinksChildren list"+activeChange} key={index} onClick={(e=>props.clickEvent(children.value))}>{children.showName}</li>
                    )
                })
            }
        </ul>
    )

}