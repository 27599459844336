import axios from "axios";
import {
    getDefaultHomeComponentPathForRole,
    getDefaultPathAfterLogin
} from "../../../Services/MainViews/Login/userRouteComponentService"

export async function login(user) {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/login`;
    
    try {
        const response = await axios.post(apiUrl, user);

        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));

        window.location.href = getDefaultPathAfterLogin();

        return response.data.user
    } catch (error) {
        console.error("[DEBUG] Binculo | Login - Error:", error);
        alert("Usuario o Contraseña incorrectos");
    }
}

export function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("selectedRole");
  localStorage.removeItem("tandc");
  localStorage.removeItem("user");
  localStorage.removeItem("userType");
  window.location.href = "/";
}

export function changeProfile() {
    localStorage.removeItem("selectedRole");
    window.location.href = "/roleselector";
}
