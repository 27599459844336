import { useEffect, useState } from "react";

// Styles
import "../../CSS/MainViews/HomeCard.css";

// Components
import BackgroudContainerLarge from "./BackgroudContainerLarge";
import Card from './Card'
import FirstLoginModal from "../Reusable/Users/FirstLoginModal";

export default function Home() {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user.hasAlreadyChangedPassword && user.roles[0] !== 'ADMIN') {
            setShowModal(true);
        }
    }, []);

    return (
        <>
            <BackgroudContainerLarge>
                <div className="container tw-w-95">
                    {showModal && (
                        <FirstLoginModal
                            modalState={{ show: showModal }}
                            title="Ingrese su nueva contraseña con la que ingresará al sistema"
                            className="ContractModalForm"
                            onClose={() => setShowModal(false)}
                            modalSubmit={()=>{}}
                        />
                    )}
                    <Card />
                </div>
            </BackgroudContainerLarge>
        </>
    );
}