import React from 'react';
import {useEffect, useState} from "react";
import "../../CSS/Reusable/PropertyForm.scss";
import ButtonStandard from "./ButtonStandard";
import LazyImage from "./LazyImage";
import {getIcon} from "../../Services/iconService";
import {convertBase64} from "../../globalHelper";
import dropZone from "../../Assets/drop-zone.svg";
import fileDrop from "../../Assets/file-SVG.svg";
export default function  DropZoneFiles ({form,setForm,type,path}){

    const [loading, setLoading] = useState(false);
    const [iconType, setIconType] = useState('');
    const [textDropZone, setTextDropZone] = useState('');
    const [allowwedFiles, setAllowwedFilese] = useState('');

    useEffect(() => {
        getDropZoneType();
      }, []);

    /*****/
    const [dragActive, setDragActive] = React.useState(false);
    // ref
    const inputRef = React.useRef(null);
    
    // handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
        } else if (e.type === "dragleave") {
        setDragActive(false);
        }
    };
    
    // triggers when file is dropped
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        convertBase64(e.dataTransfer.files,form,setForm,type,path,setLoading);
        // handleFiles(e.dataTransfer.files);
        }
    };
    
    // triggers when file is selected with click
    const handleChange = function(e) {
        console.log(e); 
        e.preventDefault();
        if (e.target.files && e.target.files[0]) { 
        // handleFiles(e.target.files);
        }
        convertBase64(e.target.files,form,setForm,type,path,setLoading);
    };
    
    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };
    const getDropZoneType=()=>{
        switch (type) {
            case 'image':
                setIconType(dropZone)
                setTextDropZone('Arrastra y soltá las imágenes acá.')
                setAllowwedFilese('png, jpeg, jpg')
                break;

            case 'file':
                setIconType(fileDrop)
                setTextDropZone('Arrastra y soltá los archivos acá.')
                setAllowwedFilese('pdf')
                break;

            default:
                setIconType(fileDrop)
                setTextDropZone('Arrastra y soltá los archivos acá.')
                setAllowwedFilese('pdf')
                break;
        }
    }

    return (
            <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                <div className='grid-drop-zone'>
                    <LazyImage src={iconType} height="75px"  width="75px" className='drop-zone-item' style={{'maxHeight':'75px','maxWidth':'75px'}}/>
                    <h1 className='drop-zone-title  drop-zone-item'>{textDropZone}</h1>
                    <h5 className='drop-zone-item'  style={{'color':'grey'}}>( {allowwedFiles} )</h5>
                    <ButtonStandard showName={type==='file'?'Seleccionar archivo':"Seleccionar imágen"}  blueColor onClick={onButtonClick} float="center" largeButton/>
                </div> 
                </label>
                { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
            </form>
        )
}