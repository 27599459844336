import PropertyFormStep1 from "./PropertyFormStep1"
import Grid from "../Grid"
import ButtonBlue from "../ButtonBlue";
import ButtonStandard from "../ButtonStandard";
import {useNavigate, useParams} from "react-router-dom";
import BackgroundContainer from "../../MainViews/BackgroudContainer"
import {useEffect, useState} from "react";
import PropertyFormStep2 from "./PropertyFormStep2";
import DataContainerCard from "../DataContainerCard";
import CircularLoader from "../CircularLoader";
import {Step, StepLabel, Stepper} from "@mui/material";
import MyModal from "../MyModal";
import {
    addNewProperty,
    fetchPropertyById,
    updateProperty
} from "../../../Services/MainViews/Properties/propertiesService";
import {checkReferedAndReferallCommissions} from "../../../Services/MainViews/Properties/commissionsCheck";
import {getUser} from "../../../Services/MainViews/Users/contactsService";
import {unionJsonValue, existAndReturn, devLog} from "../../../globalHelper"
import {checkRequiredInputs} from "../../../errorValidationHelper"
import {dataAssignPropertyServices, dataAssignPropertyAmenities, dataAssignContractServices} from "../../../dataAssignHelper"
import {createReferredOperation, updateReferredOperation, fetchReferredOperationsByReferredUserId} from "../../../Services/referredOperationService";
import {
    categorizePropertyType,
    PROPERTY_RESIDENTIAL_HOUSE
} from "../../../Services/filterService";
import {
    PROPERTY_CATEGORY_RESIDENTIAL_KEY
} from "../../../dataEntriesHelper";


export default function PropertyForm() {

    let params = useParams();

    const style = {width: '100%'};

    const [isSending, setIsSending] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [baredespercentageCurrentOperation, setBaredespercentageCurrentOperation] = useState(0);
    const [referredOperationsAvailable, setReferredOperationsAvailable] = useState([true,true]);

    const [editing, setEditing] = useState(false);

    const navigate = useNavigate();
    
    const [propertyForm, setPropertyForm] = useState({
        category: "RESIDENTIAL",
        type: "HOUSE",
        address:{
            suburb: 'AGRONOMIA'
        },
        propertyOperations: [
            {
                type: "RentOperation",
                price: 0,
                currency: "PESOS"
            }
        ],
        owners: [
            {}
        ],
        images: []
    });

    const [commissionData, setCommissionData] = useState({
        catchmentCommission:{
            owners:[
                {}
            ],
            referrer:{
                percentageCurrentOperation:0
            },
            runner:{
                percentageCurrentOperation:0
            },
            baredes:{
                percentageCurrentOperation:0
            }
        },
    });

    const checkReferedOwnerOperations = async (id) =>{
        const ownersReferedOperations = await fetchReferredOperationsByReferredUserId(id)
        if (ownersReferedOperations.length > 0) {

            setCommissionData((prevState) => ({
                ...prevState,
                catchmentCommission: {
                  ...prevState.catchmentCommission,
                  referrer: {
                    ...prevState.catchmentCommission.referrer,
                    owner: ownersReferedOperations[0].referral.user,
                    percentageCurrentOperation: ownersReferedOperations.length === 1?ownersReferedOperations[0].referral.operations[1].value:'0',
                    percentageNextOperation: "0"
                  }
                }
              }));
            setReferredOperationsAvailable(prevArray => {
                const newArray = [...prevArray];
                newArray[1] = false;
                newArray[0] = ownersReferedOperations.length === 1?true:false;
                return newArray;
              });
        }
        else{
            setReferredOperationsAvailable([true,true])
        }
    }

    const getReferredOwner = async (id) =>{
        const referredOwner = await getUser(id)
        return referredOwner
    }

    const getSingleProperty = async () => {
        setIsLoading(true);
        if (params.id) {
            const res = await fetchPropertyById(params.id)
            /*Setting propertyOperations*/
            if (res.propertyOperations) {
                res.propertyOperations.map(op => {
                    if (op.increaseEveryXMonthsPercentage === null) {
                        op.increaseEveryXMonthsPercentage = 0;
                    }
                    delete op.keyPrice;
                })
            }

            const userInfos = res.commissionContainer.catchmentCommission.commissionOwners.map(user => ({
                ...user,
              }));

              const userPromises = userInfos.map(info => info);
      
              const results = await Promise.allSettled(userPromises);

              const newCommissionData = {
                catchmentCommission: {
                  owners: [],
                  referrer: { commissionPercent: 0 },
                  runner: { commissionPercent: 0 },
                  baredes: { commissionPercent: 0 }
                }
              };
             
              results.forEach((result, index) => {
                if (result.status === 'fulfilled' && result.value) {
                  const userType = userInfos[index].commissionType;
                  const combinedUserData = { ...userInfos[index], ...result.value };
                  if (userType === 'OWNER') {
                    newCommissionData.catchmentCommission.owners.push(combinedUserData);
                  } else if (userType === 'REFERRAL') {
                    newCommissionData.catchmentCommission.referrer = combinedUserData;
                  } else if (userType === 'RUNNER') {
                    newCommissionData.catchmentCommission.runner = combinedUserData;
                  } else if (userType === 'BAREDES') {
                    newCommissionData.catchmentCommission.baredes = combinedUserData;
                  }
                }
              });
      
              setBaredespercentageCurrentOperation(newCommissionData.catchmentCommission.baredes.percentageCurrentOperation)
              setCommissionData(newCommissionData);

            let newRuuner;
            let newReferrer;

             let ownersCommissionFormatedList =[]

             res.commissionContainer.catchmentCommission.commissionOwners.map(owner => {
                if (owner.commissionType === "OWNER") {
                    ownersCommissionFormatedList.push(owner)
                }
                if(owner.commissionType === "RUNNER"){
                    newRuuner = owner
                }
                if(owner.commissionType === "REFERRAL"){
                    newReferrer = owner
                }
            });

            res.type = res.propertyType;

            res.showServices = dataAssignContractServices(res.services);
            res.showAmenities = dataAssignPropertyAmenities(res.amenities);

            /**Asigno propietario referido */
            if (
                res?.commissionContainer?.catchmentCommission?.commissionOwners &&
                Array.isArray(res?.commissionContainer?.catchmentCommission?.commissionOwners)
              ) {

                const owners = res.commissionContainer.catchmentCommission.commissionOwners;
                const validOwners = owners.filter(owner => 
                  owner.commissionType === 'REFERRAL' &&
                  owner.referredId !== null &&
                  owner.referredId !== '' &&
                  owner.referredId !== undefined
                );

                if (validOwners.length > 0) {
                    const correctReferred  = await getReferredOwner(validOwners[0].referredId) 
                    setCommissionData(prevState => ({
                            ...prevState,
                            catchmentCommission: {
                                ...prevState.catchmentCommission,
                                referedOwner: correctReferred
                            }
                        }));
                } 
              }
            /**Se termina la asignacion */
            setPropertyForm(res)
            setEditing(true);
            setIsLoading(false);
            
        }
        setIsLoading(false);
    };

    /**Traigo la propiedad */
    useEffect(() => {
        getSingleProperty();
    }, [params.id]);

    /**Scoutear el campo de referido para traer referidor si existe*/
    useEffect(() => {
        if (commissionData.catchmentCommission.referedOwner !== null && commissionData.catchmentCommission.referedOwner !== undefined && !params.id) {
            checkReferedOwnerOperations(commissionData.catchmentCommission.referedOwner.id)
        }
        else{
            setReferredOperationsAvailable([true,true])
        }
        
    }, [commissionData.catchmentCommission.referedOwner]);


    /**Checkeo commissionData para setear el porcentaje de baredes*/
    useEffect(() => {
        let totalOwnersValue =  parseInt(commissionData.catchmentCommission.owners.reduce((collector, owner) => parseInt(collector) + parseInt(owner.percentageCurrentOperation), 0));
        let firstNumber = parseInt(commissionData.catchmentCommission.runner.percentageCurrentOperation);
        let secondNumber = parseInt(commissionData.catchmentCommission.referrer.percentageCurrentOperation);
        let totalBaredesPercent = 100-((firstNumber>0?firstNumber:0) + (secondNumber>0?secondNumber:0) + (totalOwnersValue>0?totalOwnersValue:0));
        
        setBaredespercentageCurrentOperation(totalBaredesPercent);
    }, [commissionData]);

    const [step, setStep] = useState(0);

    const steps = ["Paso 1", "Paso 2"];

    const [showSuccessModal, setShowSuccessModal] = useState({show: false});

    const getStepComponent = () => {
        switch (step) {
            case 0:
                return <PropertyFormStep1 propertyForm={propertyForm}
                                          onInputChange={onInputChange}
                                          setPropertyForm={setPropertyForm}
                                          commissionData={commissionData}
                                          setCommissionData={setCommissionData}
                                          baredespercentageCurrentOperation={baredespercentageCurrentOperation}
                                          referredOperationsAvailable={referredOperationsAvailable}
                                          />;
            case 1:
                return <PropertyFormStep2 propertyForm={propertyForm}
                                          setPropertyForm={setPropertyForm}
                                          onInputChange={onInputChange}
                                          params={params}/>;
            default:
                return <PropertyFormStep1/>;
        }
    };

    const plusStep = () => {
        if ((!commissionData.catchmentCommission.referedOwner && commissionData.catchmentCommission?.referrer?.owner?.username) ||
            (commissionData.catchmentCommission.referedOwner?.username && !commissionData.catchmentCommission?.referrer?.owner?.username)) {
            alert('No se puede agregar un referido sin un referidor y No se puede agregar un referidor sin un referido')
        }
        else{
            if (step < steps.length - 1) {
                setStep(step + 1);
            }
        }
    }

    const minusStep = () => {
        if (step > 0)
            setStep(step - 1);
    }

    const filteringOverlapMultibox = (services, data, overlapForm, newForm) => {
        if (Object.keys(data)[0] === services) {
            delete overlapForm[services];
            newForm = unionJsonValue(overlapForm, data);
        }
    }

    const onInputChange = (change) => {
        devLog(false, "[Binculo] PropertyForm - onInputChange > current: ", propertyForm);
        devLog(false, "[Binculo] PropertyForm - onInputChange > change:", change);

        if (['type'].includes(change.name)) {
            onInputChange_propertyType(change);
        } else {
            onInputChange_simple(change)
        }
    };

    const onInputChange_propertyType = (jsonData) => {
        const newCategory = categorizePropertyType({ type: jsonData.value });

        const newValue = {
            ...propertyForm,
            [jsonData.name]: jsonData.value, // Property Type
            category: newCategory // Property Category
        };

        devLog(false, "[Binculo] PropertyForm - onInputChange_propertyType > new value: ", newValue);
        setPropertyForm(newValue)

        let servicesOverlapFilter = propertyForm;
        filteringOverlapMultibox("showServices", jsonData, servicesOverlapFilter, newValue);
        filteringOverlapMultibox("showAmenities", jsonData, servicesOverlapFilter, newValue);
    }


    const onInputChange_simple = (jsonData) => {
        let newValue = unionJsonValue(propertyForm, jsonData);

        devLog(false, "[Binculo] PropertyForm - onInputChange_simple > new value: ", newValue);
        setPropertyForm(newValue)

        let servicesOverlapFilter = propertyForm;
        filteringOverlapMultibox("showServices", jsonData, servicesOverlapFilter, newValue);
        filteringOverlapMultibox("showAmenities", jsonData, servicesOverlapFilter, newValue);
    }

    const onSubmit = (e) => {
        devLog(true, "[Binculo] PropertyForm: onSubmit:", propertyForm);
        
        e.preventDefault();
        let requiredInputs = [
            {value: existAndReturn(propertyForm, "owners.[0]"), name: 'Propietario'},
            {value: existAndReturn(propertyForm, "category"), name: 'Categoria de propiedad'},
            {value: existAndReturn(propertyForm, "type"), name: 'Tipo de propiedad'},
            {value: existAndReturn(propertyForm, "address.street"), name: 'Calle'},
            {value: existAndReturn(propertyForm, "address.houseNumber"), name: 'Unidad'},
            {value: existAndReturn(propertyForm, "address.suburb"), name: 'Barrio'}
        ]

        if (checkRequiredInputs(requiredInputs) <= 0) {
            let newProperty = JSON.parse(JSON.stringify(propertyForm));
            /*Setting new services and amenities*/
            let propertyServiceValues = [];
            let propertyAmenitiesValues = [];

            if (propertyForm.showServices !== undefined) {
                propertyForm.showServices.map(service => {
                    propertyServiceValues.push(service.value);
                })
            }
            if (propertyForm.showAmenities !== undefined) {
                propertyForm.showAmenities.map(service => {
                    propertyAmenitiesValues.push(service.value);
                })
            }

            /**Formateo las comisiones */
            let ownersCommissionFormated = commissionData.catchmentCommission.owners.map(owner => {
                if (owner.owner) {
                    return { commissionType: "OWNER",owner:owner?.owner, percentageCurrentOperation: owner.percentageCurrentOperation,percentageNextOperation: 0  };
                }
            });

            let newRuuner = {commissionType: "RUNNER",owner:{id:commissionData.catchmentCommission.runner?.owner?.id}, percentageCurrentOperation: commissionData.catchmentCommission.runner.percentageCurrentOperation, percentageNextOperation: 0 };
            let newReferrer = {commissionType: "REFERRAL",owner:commissionData.catchmentCommission.referrer?.owner, percentageCurrentOperation: commissionData.catchmentCommission.referrer.percentageCurrentOperation, percentageNextOperation: commissionData.catchmentCommission.referrer.percentageNextOperation,referredId: commissionData.catchmentCommission?.referedOwner?.id?commissionData?.catchmentCommission?.referedOwner?.id:null};
            let newBaredes = {commissionType: "BAREDES",owner:{id:1}, percentageCurrentOperation: baredespercentageCurrentOperation};
            

            if (commissionData.catchmentCommission.runner.percentageCurrentOperation > 0) {
                ownersCommissionFormated.push(newRuuner);
            }
            if (commissionData.catchmentCommission.referrer.percentageCurrentOperation > 0) {
                ownersCommissionFormated.push(newReferrer);
            }
            ownersCommissionFormated.push(newBaredes);
            /**Defino el DTO de catchmentCommission*/
            let catchmentCommission ={}

            catchmentCommission = {
                commissionOwners:ownersCommissionFormated
            }



            newProperty.services = propertyServiceValues;
            newProperty.amenities = propertyAmenitiesValues;

            let newTermsAndConditions = {
                date: null,
                termsAndConditionsApp: { version: "1", date: null },
                termsAndConditionsStatus: "ACCEPTED"
            };

            newProperty.images.forEach((image, index) => {
                image.imageOrder = index + 1; 
              });
              
              // Recorriendo el array y actualizando la clave termsAndConditions
            newProperty.owners.forEach(item => {
            if (item.account && item.account.termsAndConditions) {
                item.account.termsAndConditions = newTermsAndConditions;
            }
            });
            /*anotherParams*/
            if (params.id) {
                newProperty.id = Number(params.id);
            }
            newProperty.propertyType = newProperty.type
            if (existAndReturn(propertyForm, "closedSquareMeters") + existAndReturn(propertyForm, "openedSquareMeters") > existAndReturn(propertyForm, "totalSquareMeters")) {
                alert('La Superficie cubierta y la Superficie descubierta, no deben superar a la Superficie total ')
            } else {
                setIsSending(true)
                //Si hay usuario referido y referidor
                const referralEntry = catchmentCommission.commissionOwners.find(entry => entry && entry.commissionType === "REFERRAL");

                if (referralEntry && referralEntry.referredId && referralEntry.owner?.id && referralEntry.percentageCurrentOperation && referralEntry.percentageNextOperation) {
                    const newReferedOperation = {
                        "referred": {
                            "id": referralEntry.referredId
                        },
                        "referral": {
                            "user": {
                                "id": referralEntry.owner.id
                            },
                            "operations": []
                        },
                        "contracts": []
                    };
                
                    // Agregar operaciones solo si los valores no son null/undefined
                    if (referralEntry.percentageCurrentOperation) {
                        newReferedOperation.referral.operations.push({
                            "currency": "PERCENTAGE",
                            "value": referralEntry.percentageCurrentOperation
                        });
                    }
                
                    if (referralEntry.percentageNextOperation) {
                        newReferedOperation.referral.operations.push({
                            "currency": "PERCENTAGE",
                            "value": referralEntry.percentageNextOperation
                        });
                    }
                }
                
                if (isPropertyEdit()) {
                    newProperty.commissionContainer.catchmentCommission = catchmentCommission;
                    Promise.resolve(updateProperty(newProperty)).then((res) => {
                        setIsSending(true)
                        if (res?.status !== 200) {
                            setIsSending(false)
                        } else if(res?.status === 200) {
                            alert("Propiedad actualizada con exito");
                            window.location.href = "/properties";
                            setIsSending(false)
                        }
                    })
                } else {
                    //Reasigno el rol de propietario al crear propiedad 
                    newProperty.owners.forEach(singleOwner => {
                        singleOwner.roles = singleOwner.roles.filter(rol => rol !== 'CONTACT');
                        if (!singleOwner.roles.includes('OWNER')) {
                            singleOwner.roles.push('OWNER');
                          }
                    });
                    catchmentCommission.commissionOwners.forEach(singleOwnerCommission => {
                        if (singleOwnerCommission?.commissionType === 'OWNER' && singleOwnerCommission != undefined && singleOwnerCommission.owner != null) {
                            singleOwnerCommission.owner.roles = singleOwnerCommission.owner.roles.filter(rol => rol !== 'CONTACT');
                            if (!singleOwnerCommission.owner.roles.includes('OWNER')) {
                                singleOwnerCommission.owner.roles.push('OWNER');
                              }
                        }
                    });
                    catchmentCommission.commissionOwners.forEach(singleOwnerCommission => {
                        if (singleOwnerCommission?.commissionType === 'REFERRAL' && singleOwnerCommission != undefined && singleOwnerCommission.owner != null) {
                            singleOwnerCommission.owner.roles = singleOwnerCommission.owner.roles.filter(rol => rol !== 'CONTACT');
                            if (!singleOwnerCommission.owner.roles.includes('REFERRAL')) {
                                singleOwnerCommission.owner.roles.push('REFERRAL');
                              }
                        }
                    });
                    let propertyCreated;
                    Promise.resolve(addNewProperty(newProperty, catchmentCommission)).then((res) => {
                        setIsSending(true);
                        if (res?.status !== 200) {
                            alert(res.message);
                            setIsSending(false);
                        } else if (res?.status === 200) {
                            // Asigna el resultado exitoso a propertyCreated
                            propertyCreated = res;
                    
                            // Si hay usuario referido y referidor
                            const referralEntry = catchmentCommission.commissionOwners.find(entry => entry && entry.commissionType === "REFERRAL");
                            if (referralEntry) {
                                const newReferedOperation = {
                                    "referred": {
                                        "id": referralEntry.referredId
                                    },
                                    "referral": {
                                        "user": {
                                            "id": referralEntry.owner.id
                                        },
                                        "operations": [
                                            {
                                                "currency": "PERCENTAGE",
                                                "value": referralEntry.percentageCurrentOperation
                                            },
                                            {
                                                "currency": "PERCENTAGE",
                                                "value": referralEntry.percentageNextOperation
                                            }
                                        ]
                                    },
                                    "contracts": []
                                };
                                // Crea la operación referida
                                Promise.resolve(createReferredOperation(newReferedOperation)).then(res => {
                                    setIsSending(true);
                                    if (res?.status !== 200) {
                                        alert(res.message);
                                        setIsSending(false);
                                    } else if (res?.status === 200) {
                                        const actualReferredOperation = {
                                            id: res.data.id,
                                            properties: [
                                                {
                                                    "id": propertyCreated.data.id
                                                }
                                            ]
                                        };                   
                                        Promise.resolve(updateReferredOperation(actualReferredOperation)).then(res => {
                                            if (res?.status !== 200) {
                                                alert(res.message);
                                                setIsSending(false);
                                            } else if (res?.status === 200) {
                                                alert("Propiedad creada con éxito");
                                                window.location.href = "/properties";
                                                setIsSending(false);
                                            }
                                        });
                                    }
                                });
                            } else {
                                alert("Propiedad creada con éxito");
                                window.location.href = "/properties";
                                setIsSending(false);
                            }
                        }
                    });
                }
            }

        } else {
            alert("Debe llenar los siguientes campos: " + checkRequiredInputs(requiredInputs))
        }
    }

    const isPropertyEdit = () => {
        return params.id
    }
    const checkFormLoading = () => {
        if (isPropertyEdit && isLoading) {
            return <CircularLoader/>
        } else {
            return getStepComponent()
        }
    }


    return (
        <BackgroundContainer>

            <div style={style}>

                <DataContainerCard>
                    <Grid columns="1">
                        <Stepper activeStep={step} alternativeLabel className="mt-5">
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </DataContainerCard>

                {params.id ?
                    checkFormLoading() : getStepComponent()}

                <MyModal modalState={showSuccessModal} children={<label>Propiedad creado</label>} canBeClosedBeforeSubmiting/>

            </div>
            <div className="w-full flex" style={{"margin": "1.6rem 0px 1.6rem 0px"}}>
                {step > 0 ?
                    
                    <div className={isSending ? 'UnabledButton' : ''} style={{'marginRight': 'auto'}}><ButtonStandard
                        showName="Anterior" onClick={minusStep} float="left" blueColor/></div> : null}
                {step === 0 ? <ButtonBlue showName="Guardar y Continuar" onClick={plusStep} float="right"/> : null}
                {step === 1 ?
                    <div className={isSending ? 'UnabledButton' : ''} style={{'marginLeft': 'auto'}}><ButtonStandard
                        showName={isPropertyEdit() ? "Actualizar propiedad" : "Crear propiedad"} onClick={onSubmit}
                        float="right" blueColor/></div> : null}
            </div>

        </BackgroundContainer>

    )
}
