import YouTube from "react-youtube";
import "../../CSS/Reusable/YoutubePlayer.scss";
import { getIcon } from "../../Services/iconService";

export default function YoutubePlayer({ videoURL, item, setItem, i }) {

  const videoOnReady = (event) => {
    event.target.pauseVideo();
  };

  const options = {
    playerVars: {
      autoplay: 1,
      enablejsapi: 1,
      origin: "http://localhost:3000",
    }
  };

  function handleDelete(e) {
    e.preventDefault();
    setItem({
      ...item,
      [e.target.id]: '',
  })
  }

  function getYouTubeVideoId(url) {
    const regExp = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|shorts|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  }

  return (
    <>
      {videoURL !== undefined  && videoURL !== null ?
        <div className="flex w-full rounded-[20px] [&>*]:w-full" >
          {item && setItem && <button id={i} name={videoURL} onClick={(e) => handleDelete(e)}>{getIcon('deleteX')}</button>}
          <YouTube
            videoId={getYouTubeVideoId(videoURL)}
            opts={options}
            onReady={videoOnReady}
            className={'w-full h-[19.375rem] lg:h-[38.75rem] mx-center rounded-[20px]'}
            iframeClassName={'w-full h-full mx-center rounded-[20px]'}
          />
        </div>
        : null
      }
    </>
  )
}