import  React,  { useState, useEffect  } from  'react';
import {getLoggedUserRoles, getSelectedLoggedUserRole, setSelectedLoggedUserRole} from "../../globalHelper";
import DataContainerCard from "../Reusable/DataContainerCard";
import ButtonStandard from "../Reusable/ButtonStandard";
import Grid from "../Reusable/Grid";
import {getUser} from "../../Services/MainViews/Users/contactsService";
import {
    fetchSideBarRoutes,
    getDefaultHomeComponentPathForRole
} from "../../Services/MainViews/Login/userRouteComponentService";
import BackgroudContainer from "../MainViews/BackgroudContainer";
import { ReactComponent as NextArrowVector } from '../../Assets/profileImages/profile-next-arrow.svg';
import tenant from "../../Assets/profileImages/tenant-vector.svg";
import owner from "../../Assets/profileImages/owner-vector.svg";
import referral from "../../Assets/profileImages/referral-vector.svg";
import brokerrunner from "../../Assets/profileImages/broker-runner-vector.svg";


export default function RoleSelector() {

    const [isOnlyContact, setIsOnlyContact] = useState(false);
    const loggedUserRoles = getLoggedUserRoles();

    const getIdToTermsAndConditions = async ()=>{
        let settings;
        let settingsObj;
        settings = localStorage.getItem('user');
        settingsObj = settings ? JSON.parse(settings) : {};
        const user = await getUser(settingsObj.id);
    }
    useEffect(() => {
        getIdToTermsAndConditions()
      }, []);

    const rolesSpanishMap = {
        "ADMIN": "Administrador",
        "OWNER": "Propietario",
        "TENANT": "Inquilino",
        "BROKER": "Broker",
        "RUNNER": "Agente",
        "REFERRAL": "Referidor",
        "CONTACT": "Contacto"
    }

    const imageSelector = (role) => {
        switch (role) {
            case "TENANT":
                return tenant;
            case "OWNER":
                return owner;
            case "REFERRAL":
                return referral;
            case "BROKER":
                return brokerrunner;
            case "RUNNER":
                return brokerrunner;
            default:
                return undefined;
        }
    }

    const handleSelectRole = (role) => {
        setSelectedLoggedUserRole(role);
        window.location.href = getDefaultHomeComponentPathForRole();
    }

    return (
        <BackgroudContainer>
            <DataContainerCard tittle="Selección de perfil">
                {loggedUserRoles.length === 1 && loggedUserRoles[0] === 'CONTACT'?
                    <p className='text-center font-800 text-2xl text-[#081382]'>No tiene ningun rol asignado</p>:
                    <div className="flex flex-col space-y-6">
                        {(loggedUserRoles.map(role => {
                            return (
                                <>
                                    <div onClick={() => handleSelectRole(role)} className="bg-[#F3F6FC] rounded-xl shadow-md flex relative justify-center p-4 cursor-pointer min-h-[9.375rem]">
                                        <div className="flex flex-col space-y-6 justify-center items-center">
                                            <h2 className="text-[#396CAA] text-xl font-bold uppercase text-center tracking-wider">{rolesSpanishMap[role]}</h2>
                                            {imageSelector(role) ?
                                                <img src={imageSelector(role)} alt="profile"/> : null
                                            }
                                        </div>
                                        <div className="absolute right-0 top-[50%] bottom-[50%] bg-[#F8F9FB] h-max w-max shadow-lg py-2 px-4 rounded-full flex items-center justify-center mr-6"><NextArrowVector/></div>
                                    </div>
                                </>

                            )
                        }))}
                    </div>
                }
            </DataContainerCard>
        </BackgroudContainer>
    );

}
