import "../../CSS/MainViews/NotFound.scss";

export default function NotFound({errorStatus}) {

  const urlMalformed = window.location.toString();

  return (
    <div className="flex flex-col h-screen items-center justify-center space-y-4 text-lg lg:text-2xl text-center break-words p-8 text-[#858585] lg:w-[60%] mx-auto">
      <h1 className="text-6xl font-bold text-[#5F585A]">{errorStatus?errorStatus:''}</h1>
      <h2 className="text-4xl uppercase">Not found</h2>
      <br />
      <br />
      <p className="break-all">
        La URL provista es: <span className="text-[#081382]">{urlMalformed}</span>. 
      </p>
      <br/>
      <p>
        Por favor verifica que sea
        correcta. Si el problema persiste, por favor contacta al administrador.
      </p>
    </div>
  );
}
