import {
  bsAsCabaNeighborhoods,
  PROPERTY_CATEGORY_COMMERCIAL_KEY, PROPERTY_CATEGORY_COMMERCIAL_VALUE,
  PROPERTY_CATEGORY_LAND_KEY,
  PROPERTY_CATEGORY_LAND_VALUE, PROPERTY_CATEGORY_RESIDENTIAL_KEY, PROPERTY_CATEGORY_RESIDENTIAL_VALUE
} from "../dataEntriesHelper";
import {devLog} from "../globalHelper";

export const PROPERTY_LAND_LAND = "LAND"

export const PROPERTY_COMMERCIAL_DEPOSIT = "DEPOSIT"
export const PROPERTY_COMMERCIAL_STORE = "STORE"
export const PROPERTY_COMMERCIAL_OFFICE = "OFFICE"
export const PROPERTY_COMMERCIAL_SHOPPING_TRIP = "SHOPPING_TRIP"
export const PROPERTY_RESIDENTIAL_HOUSE = "HOUSE"
export const PROPERTY_RESIDENTIAL_BUILDING = "BUILDING"

export const bsAsCabaNeighborhoodsFilters = Object.entries(bsAsCabaNeighborhoods).map(([key, showName]) => ({
  showName: showName,
  name: key,
  operator: "EQ"
}));

bsAsCabaNeighborhoodsFilters.unshift({
  showName: "Ubicación",
  name: "propertyLocation",
});



const categoryMap = {
  [PROPERTY_CATEGORY_LAND_KEY]: PROPERTY_CATEGORY_LAND_VALUE,
  [PROPERTY_CATEGORY_COMMERCIAL_KEY]: PROPERTY_CATEGORY_COMMERCIAL_VALUE,
  [PROPERTY_CATEGORY_RESIDENTIAL_KEY]: PROPERTY_CATEGORY_RESIDENTIAL_VALUE,
};

export const getPropertyCategoryValueByKey = (key) => {
  return categoryMap[key] || "";
};


export function categorizePropertyType(propertyTypeObj) {
  let formatPropertyType = propertyTypeObj.type 
  let newCategory = ''
  if (formatPropertyType === PROPERTY_COMMERCIAL_DEPOSIT || 
    formatPropertyType === PROPERTY_COMMERCIAL_STORE || 
    formatPropertyType === PROPERTY_COMMERCIAL_OFFICE || 
    formatPropertyType === PROPERTY_COMMERCIAL_SHOPPING_TRIP
  ) {
    newCategory = PROPERTY_CATEGORY_COMMERCIAL_KEY;
  } else if (formatPropertyType === PROPERTY_LAND_LAND) {
    newCategory = PROPERTY_CATEGORY_LAND_KEY;
  } else if (formatPropertyType === PROPERTY_RESIDENTIAL_HOUSE ||
    formatPropertyType === PROPERTY_RESIDENTIAL_BUILDING
  ) {
    newCategory = PROPERTY_CATEGORY_RESIDENTIAL_KEY;
  } else {
    newCategory = ""
  }
  
  return newCategory
}


export function categorizePropertyType2(propertyTypeObj, property, categorizeSuburb) {
  let formatPropertyType = propertyTypeObj.type;
  let addressSuburb = property.address.suburb; // Asumiendo que `address.suburb` es la propiedad donde está almacenado el suburbio de la propiedad
  const mappedSuburbs = categorizeSuburb.map(item => {
    const suburbs = item.suburbs.map(suburbObj => suburbObj.suburb);
    return { propertyCategory: item.propertyCategory, suburbs: suburbs };
  });

  // Filtrar propiedades según la categorización de barrios
  const filteredCategory = mappedSuburbs.find(item => {
    return item.propertyCategory === formatPropertyType && item.suburbs.some(suburb => suburb === addressSuburb);
  });

  if (filteredCategory) {
    return formatPropertyType;
  } else {
    return '';
  }
}


export function isPropertyCategoryOf(property, checkPropertyCategory,categorizeSuburb) {
  return categorizePropertyType2({ type: property.category },property,categorizeSuburb) === checkPropertyCategory
}


const filters = {
  Properties: {
    'propertyOperations.type': [
      { name: "propertyOperation", showName: "Tipo de Operación" },
      { name: "RentOperation", showName: "Alquiler", operator: "EQ" },
      { name: "BuyOperation", showName: "Venta", operator: "EQ" }
    ],
    type: [
      { name: "propertyType", showName: "Tipo de Propiedad" },
      { name: "HOUSE", showName: "Casa", operator: "EQ" },
      { name: "BUILDING", showName: "Departamento", operator: "EQ" },
      { name: "DEPOSIT", showName: "Deposito", operator: "EQ" },
      { name: "STORE", showName: "Local Comercial", operator: "EQ" },
      { name: "LAND", showName: "Terreno", operator: "EQ" },
      { name: "OFFICE", showName: "Oficina", operator: "EQ" },
      { name: "SHOPPING_TRIP", showName: "Paseo de Compras", operator: "EQ" }
    ],
    'address.suburb': bsAsCabaNeighborhoodsFilters,
    rooms: [
      { name: "propertyRooms", showName: "Ambientes" },
      { name: "1", showName: "Monoambiente", operator: "EQ" },
      { name: "2", showName: "2 Ambientes", operator: "EQ" },
      { name: "3", showName: "3 Ambientes", operator: "EQ" },
      { name: "4", showName: "4 Ambientes", operator: "EQ" },
      { name: "5", showName: "5 Ambientes", operator: "EQ" },
      { name: "5", showName: "6 o + Ambientes", operator: "GT" },
    ],
    bedrooms: [
      { name: "propertyRooms", showName: "Dormitorios" },
      { name: "1", showName: "Monoambiente", operator: "EQ" },
      { name: "2", showName: "2 Ambientes", operator: "EQ" },
      { name: "3", showName: "3 Ambientes", operator: "EQ" },
      { name: "4", showName: "4 Ambientes", operator: "EQ" },
      { name: "5", showName: "5 Ambientes", operator: "EQ" },
      { name: "5", showName: "6 o + Ambientes", operator: "GT" },
    ],
    bathrooms: [
      { name: "propertyBathrooms", showName: "Baños" },
      { name: "1", showName: "1", operator: "EQ" },
      { name: "2", showName: "2", operator: "EQ" },
      { name: "3", showName: "3", operator: "EQ" },
      { name: "4", showName: "4", operator: "EQ" },
      { name: "5", showName: "5", operator: "EQ" },
      { name: "5", showName: "6 o +", operator: "GT" },
    ],
    garages: [
      { name: "propertyGarages", showName: "Cocheras" },
      { name: "1", showName: "1", operator: "EQ" },
      { name: "2", showName: "2", operator: "EQ" },
      { name: "3", showName: "3", operator: "EQ" },
      { name: "4", showName: "4", operator: "EQ" },
      { name: "4", showName: "5 o +", operator: "GT" },
    ],
    amenities: [
      { name: "propertyAmenities", showName: "Amenities" },
      {showName: "Garage de invitados", name: "GUESTS_GARAGES", operator: "EQ"},
      {showName: "Parrilla", name: "BARBECUE", operator: "EQ"},
      {showName: "Pileta externa", name: "OUTSIDE_POOL", operator: "EQ"},
      {showName: "Pileta interna", name: "INSIDE_POOL", operator: "EQ"},
      {showName: "Salon de juegos", name: "PLAY_ROOM", operator: "EQ"},
      {showName: "Sum", name: "SUM", operator: "EQ"}
    ],
    services: [
      { name: "propertyServices", showName: "Servicios" },
      {showName: "Agua caliente", name: "HOT_WATER", operator: "EQ"},
      {showName: "Jacuzzi", name: "JACUZZI", operator: "EQ"},
      {showName: "Parrilla", name: "BARBECUE", operator: "EQ"}
    ],
    
    'propertyOperations.currency': [
      { name: "currency", showName: "Moneda" },
      { name: "PESOS", showName: "$", operator: "EQ" },
      { name: "DOLLARS", showName: "U$D", operator: "EQ" },
      { name: "OFFICIAL_DOLLARS", showName: "U$D Oficiales", operator: "EQ" },
      { name: "USDT", showName: "USDT", operator: "EQ" }
    ],
    totalSquareMeters: [
      { name: "propertyTotalSize", showName: "Tamaño Total" },
    ],
    price: [
      { name: "propertyPrice", showName: "Precio" }
    ],
  },
  
  Contracts: {
    type: [
      {name: "type", showName: "Tipo de Contrato"},
      {name: "PurchaseContract", showName: "Contrato de Compra"},
      {name: "RentalContract", showName: "Contrato de Alquiler"}
    ]
  }
};

export function getSpecificFilter(component, filter) {
  return filters[component][filter];
}


//Este filtro deberia usarse para el siguiente caso (type : "HOUSE" OR type : "BUILDING")
export function addConditionalLogic(filters) {
  /*const filterProperties = {};
  for (let filter of filters) {
    if (filterProperties[filter.property]) {
      filter.logicOperator = "OR";
    } else {
    filterProperties[filter.property] = true;
    filter.logicOperator = "AND";
   }
  }*/

   const firstLoadOperations = {
      "property": "isAvailable",
      "value": true,
      "operator": "EQ",
      "logicOperator": "AND"
    };

  const updatedFilters = [...filters]; // Copia el array de filters

  // Verificar si existe el filtro con property: "isAvailable"
  const existsIsAvailable = filters.some(filter => filter.property === "isAvailable");

  if (!existsIsAvailable) {
    // Si no existe, agregar el objeto preestablecido
    filters.unshift(firstLoadOperations);
  }

   filters.forEach(function (filter, i) {
    
     if (filter.property !== "address.suburb") {
       filters[i].logicOperator = "AND";
     } else if (filter.property === "address.suburb") {
       filters[i].logicOperator = "AND";
     }
    else
      filters[i].logicOperator = "AND";
   });

  return filters;
}