import DataContainerCard from "../DataContainerCard"
import "../../../CSS/Reusable/BookingContractForm.scss";
import Grid from "../Grid"
import DataInput from "../DataInput"
import DataSelectBox from "../DataSelectBox"
import DataSelectBoxInput from "../DataSelectBoxInput"
import AutoCompleteInput from "../AutoCompleteInput";
import DataMultiSelectBox from "../DataMultiSelectBox";
import CommissionTitle from "../CommissionTitle";
import ButtonStandard from "../ButtonStandard";
import {addNewUser, fetchContacts, fetchContactsByRole} from "../../../Services/MainViews/Users/contactsService";
import MyModal from "../MyModal";
import ContractModalForm from "../ContractForm/ContractModalForm";
import {useEffect, useState} from "react";
import {getIcon} from "../../../Services/iconService";
import {devLog, existAndReturn, unionJsonValue,addPrefixIfMissing} from "../../../globalHelper"
import {checkRequiredInputs} from "../../../errorValidationHelper"
import {
    amenitiesEntries,
    contractServicesEntries,
    moneyEntries,
    operationsEntries, propertyCategoryEntries,
    propertyTypeEntries,
    suburbsEntries
} from "../../../dataEntriesHelper"
import {fetchReferredOperationsByReferredUserId} from "../../../Services/referredOperationService";
import {categorizePropertyType, getPropertyCategoryValueByKey} from "../../../Services/filterService";
import './PropertyFormStep1.scss'

export default function PropertyFormStep1({
                                              propertyForm,
                                              setPropertyForm,
                                              onInputChange,
                                              commissionData,
                                              setCommissionData,
                                              baredespercentageCurrentOperation,
                                              referredOperationsAvailable
                                          }) {

    const [referredOperations, setReferredOperations] = useState([]);

    const [selectedReferredOperation, setSelectedReferredOperation] = useState(null);

    const [firstOwner, setFirstOwner] = useState(null);
    const [firstOwnerCommission, setFirstOwnerCommission] = useState(null);

    let referralSecondOperationClassname = 'w-[90px] text-center items-end '.concat(referredOperationsAvailable[1]===true?'':'pointer-events-none !bg-slate-400/25 !text-black/25')
    let referralFirstOperationClassname = 'w-[90px] text-center items-end '.concat(referredOperationsAvailable[0]===true?'':'pointer-events-none !bg-slate-400/25 !text-black/25')

    const handleSelectReferredOperation = (selectedIndex) => {
        setSelectedReferredOperation(referredOperations[selectedIndex]);
    }

    const checkFirstOwnerOnState =(dataState)=>{
        if (dataState.owners[0] && dataState.owners[0].id) {
            fetchReferredOperationsByReferredUserId(dataState.owners[0].id).then(response => {
                setReferredOperations(response);
                if (response.length === 1) {

                    if (response[0].actualReferrals === 2) {
                        setReferredOperations([]);
                    } else {
                        setSelectedReferredOperation(response[0]);
                        dataState.referredOperation = response[0];
                    }
                }

            })
        }
    }
    const checkFirstOwnerOnStateCommission =(dataState)=>{
        if (dataState.catchmentCommission?.owners[0]?.id && dataState.catchmentCommission?.owners[0]) {
            fetchReferredOperationsByReferredUserId(dataState.catchmentCommission.owners[0]?.id).then(response => {
                setReferredOperations(response);
                if (response.length === 1) {

                    if (response[0].actualReferrals === 2) {
                        setReferredOperations([]);
                    } else {
                        setSelectedReferredOperation(response[0]);
                        dataState.referredOperation = response[0];
                    }
                }

            })
        }
    }


    //I Check if there is a user in the owner's equity
    useEffect(() => {
            checkFirstOwnerOnState(propertyForm)
        }
        , [firstOwner])

    useEffect(() => {
        checkFirstOwnerOnStateCommission(commissionData)
    }
    , [firstOwnerCommission])

    const handleSelectOwner = (e, index) => {
        if (index === 0){
            onInputChange(e);
            setFirstOwner(e)
        }
        else{
            onInputChange(e);
        }
    }

    //Funciones auxiliares para captar los cambios en los propietarios de comisiones
    const onInputChangeCommission =(jsonData)=>{
        let newValue = unionJsonValue(commissionData, jsonData, true);
        setCommissionData(newValue)
    }
    
    const handleInputChangeCommission2 = (eventValue, eventName) => {
        const keys = eventName.split('.'); 
        let updatedState = { ...commissionData };
    
        let currentSection = updatedState;
        for (let i = 0; i < keys.length - 1; i++) {
            if (!currentSection[keys[i]]) {
                currentSection[keys[i]] = {};
            }
            currentSection = currentSection[keys[i]];
        }
    
        currentSection[keys[keys.length - 1]] = eventValue;
    
        setCommissionData(updatedState);
    };

    const handleSelectOwnerCommission = (e, index) => {
        if (index === 0){
            onInputChangeCommission(e);
            setFirstOwnerCommission(e)
        }
        else{
            onInputChangeCommission(e);
        }
    }
    //Fin funciones auxiliares

    const [showModal, setShowModal] = useState({show: false});
    const [responseModal, setResponseModal] = useState(false);
    const [myStyle, setMyStyle] = useState(false);
    const [contractModalForm, setContractModalForm] = useState({
        username: '',
        name: '',
        surname: '',
        cellPhone:''
    })

    const modalSubmit = (e) => {
        e.preventDefault();
        
        let requiredInputs = [
            {value: existAndReturn(contractModalForm, "username"), name: 'Correo'},
            {value: existAndReturn(contractModalForm, "name"), name: 'Nombre'},
            {value: existAndReturn(contractModalForm, "surname"), name: 'Apellido'},
            {value: existAndReturn(contractModalForm, "cellPhone"), name: 'Telefono'},
        ]
       if (checkRequiredInputs(requiredInputs) <= 0) {
            const cellPhoneAdapted = contractModalForm.cellPhone.prefix+''+contractModalForm.cellPhone.phoneNumber
            const newUser ={
                "username": contractModalForm.username,
                "name": contractModalForm.name,
                "surname": contractModalForm.surname,
                "cellPhone": addPrefixIfMissing(cellPhoneAdapted),
                "type": "Contact"
            }
            
            addNewUser(newUser)
                .then((res) => {
                    if (res) {
                        devLog(true, "[Binculo] addNewUser: ", res)
                        alert("[Binculo] Usuario añadido");
                        setContractModalForm(
                            {
                                username: '',
                                name: '',
                                surname: '',
                                cellPhone:''
                            }
                        )

                    } else {
                        devLog(true, "[Binculo] Error al añadir el contacto: ", res)
                        alert("[Binculo] Error al añadir el contacto");
                    }
                })
                .catch((error) => {
                    console.error("[Binculo] Error inesperado:", error);
                });
        }  
        else{
            alert("Debe llenar los siguientes campos: " + checkRequiredInputs(requiredInputs))
        }
    }
    const handleClick = (id) => {
        setMyStyle(prevState => ({
          ...myStyle,
          [id]: !prevState[id]
        }))
      }

    const handleFilterContacts = (names, type, isByRole) => {
        let fields = names.split(" ");
        let searchContactDTO = {};
        searchContactDTO.types =[type];
        if (fields.length === 1)
            searchContactDTO.fieldOne = fields[0];
        if (fields.length === 2) {
            searchContactDTO.fieldOne = fields[0];
            searchContactDTO.fieldOne = fields[1];
        }

        if (fields.length === 3) {
            searchContactDTO.fieldOne = fields[0];
            searchContactDTO.fieldOne = fields[1];
            searchContactDTO.fieldOne = fields[2];
        }
        if (isByRole) {
            searchContactDTO.role =type;
            return fetchContactsByRole(searchContactDTO);
        }
        else{
            return fetchContacts(searchContactDTO);
        }
    }

    const newPropertyOperation = ()  =>{
        let newData ={
            price:0,
            currency:"PESOS",
            type:"RentOperation"
        }
        setPropertyForm({
            ...propertyForm,
            propertyOperations:[
                ...propertyForm.propertyOperations, 
                newData
            ]
        })
    }
    
    const deletePropertyOperation = (e,OPindex) =>  {
        e.preventDefault();
        setPropertyForm(prev => {
            const propertyOperations = propertyForm.propertyOperations.filter((_, index) => index !== OPindex);
            return {
              ...prev,
              propertyOperations
            }
          })
    }
    const withoutHouseNumber =(e)=>{
        e.preventDefault();
        const whitoutHouseNumberSet = {address:{
            houseNumber: 'Sin Unidad'
        }};
        let newValue = unionJsonValue(propertyForm, whitoutHouseNumberSet);
        setPropertyForm(prev => {
            return {
              ...prev,
              whitoutHouseNumberSet
            }
          })
    }
    const newOwner = (e,dataState, setDataState, isCommission)  =>{
        e.preventDefault();
        let newData ={}
        if (isCommission) {
            setDataState(prevState => ({
                ...prevState,
                catchmentCommission: {
                    ...prevState.catchmentCommission,
                    owners: [
                        ...prevState.catchmentCommission.owners,
                        newData
                    ]
                }
            }));
        }
        else{
            setDataState({
                ...dataState,
                owners:[
                    ...dataState.owners, 
                    newData
                ]
            })
        }
        
    }

    const deleteOwner = (e, itemIndex, path, dataState, setDataState) => {
        e.preventDefault();
      
        const updateNestedState = (prev, path, updater) => {
          const keys = path.split('.');
          const lastKey = keys.pop();
          let nested = prev;
      
          keys.forEach(key => {
            nested = nested[key];
          });
      
          nested[lastKey] = updater(nested[lastKey]);
          return {...prev};
        };
      
        setDataState(prev => updateNestedState(prev, path, array => {
          return array.filter((_, index) => index !== itemIndex);
        }));
      };
      

    const modalChildren = <ContractModalForm contractModalForm={contractModalForm} setContractModalForm={setContractModalForm}/>;

    const handlePropertyTypeChange = (selectedPropertyType) => {
        const propertyType = selectedPropertyType.type;
        devLog(true, "[Binculo] Property type changed to: ", propertyType);
        onInputChange({ name: 'type', value: propertyType })
    };
    
    return(
        <>
            <form>
                {
                    propertyForm.propertyOperations.map((item, index) => {
                        let interalDefaultValue = {
                            currency:item.currency,
                            price: item.price
                        }
                        return (
                            <DataContainerCard tittle="Operacion de propiedad" className="ContractForm">
                            <Grid columns="3">
                                <DataSelectBox tittle="Tipo de operación*" entries={operationsEntries} ChangeState={onInputChange}
                                                                                        targetName={'propertyOperations['+index+'].type'}
                                                                                        targetIndex={index}
                                                                                        paymentScheduleForm={() => handleClick(index)}
                                                                                        defaultValue={item.type}/>

                                <DataSelectBoxInput type="Number" 
                                                    tittle="Precio de la propiedad"
                                                    entries={moneyEntries} 
                                                    ChangeState={onInputChange}
                                                    targetName={'propertyOperations['+index+']'}
                                                    targetIndex={index}
                                                    indexValues={["currency", "price"]}
                                                    defaultValue={interalDefaultValue}/>    
                                <button onClick={(e) => deletePropertyOperation(e,index)}  style={{display: index ? 'block' : 'none' }}>
                                    <div className="dataInputIconElement">{getIcon("deleteX")}</div>
                                </button>
                            </Grid>
                            {
                                item.type === "BuyOperation"?  "": (<Grid columns="2">
                                <DataInput tittle="Aumento cada" placeHolder="Ingrese número de meses"
                                                    type="Number"
                                                    ChangeState={onInputChange}
                                                    targetName={'propertyOperations['+index+'].increaseEveryXMonths'}
                                                    defaultValue={ item.increaseEveryXMonths?item.increaseEveryXMonths : null}/>

                                <DataInput tittle="Ajuste %" placeHolder="Ingrese un porcentaje"
                                                    type="Number"
                                                    ChangeState={onInputChange}
                                                    targetName={'propertyOperations['+index+'].increaseEveryXMonthsPercentage'}
                                                    defaultValue={item.increaseEveryXMonthsPercentage ? item.increaseEveryXMonthsPercentage  : null}/>                           
                                
                            </Grid>)
                            }
                        </DataContainerCard>
                        );
                    })
                }
                <DataContainerCard>
                    <Grid columns="1">
                        <label className="text-blue-900 font-semibold cursor-pointer mt-1 mb-1" onClick={newPropertyOperation}> +
                            Agregar nueva
                            operacion</label>
                    </Grid>
                </DataContainerCard>
                <DataContainerCard  tittle="Informacion de la propiedad">
                    {
                        propertyForm.owners.map((owner, index) => {
                            return (
                                <Grid columns="2" key={index}>
                                    <AutoCompleteInput          id="ownerName" key="ownerName"
                                                                onChange={(e) => handleFilterContacts(e,'Contact')}
                                                                ChangeState={e => handleSelectOwner(e, index)}
                                                                targetName={'owners['+index+']'}
                                                                defaultValue={owner}
                                                                showAttributes={["name", "surname"]}
                                                                placeHolder="Ingresa nombre y apellido"
                                                                tittle="Nombre del propietario *"/>

                                <button onClick={(e) => deleteOwner(e, index, 'owners', propertyForm, setPropertyForm)}  style={{display: index ? 'block' : 'none' }}>
                                    <div className="dataInputIconElement">{getIcon("deleteX")}</div>
                                </button>
                                </Grid>
                            );
                        })
                    }
                    <Grid columns="1">
                    <label className="text-blue-900 font-semibold cursor-pointer mt-1 mb-1" onClick={(e)=>newOwner(e,propertyForm,setPropertyForm)}> +
                        Agregar nuevo
                        propietario</label>
                    </Grid>
                    <Grid columns="1">
                        <label className="text-blue-900 font-semibold cursor-pointer mt-1 mb-1"
                            onClick={() => {
                                setShowModal({show: true});
                            }}>
                            + Agregar nuevo contacto
                        </label>
                        {<MyModal modalState={showModal} children={modalChildren} className="ContractModalForm"
                                modalSubmit={modalSubmit} responseModal={responseModal}  canBeClosedBeforeSubmiting noCloseOnSubmit/>}
                    </Grid>
                    <Grid columns="1">
                        <DataInput tittle="Calle y numero *" placeHolder=""
                                                ChangeState={onInputChange}
                                                targetName={"address.street"}
                                                defaultValue={existAndReturn(propertyForm, "address.street")}/>
                    </Grid>
                    <Grid columns="3">
                        <DataInput tittle="Unidad *" placeHolder=""
                                                    ChangeState={onInputChange}
                                                    targetName={"address.houseNumber"}
                                                    form={propertyForm}
                                                    defaultValue={existAndReturn(propertyForm, "address.houseNumber")}/>
                        <div    style={{"alignSelf":"end","paddingRight":"4rem"}}><ButtonStandard showName="Sin Unidad" float="left" onClick={withoutHouseNumber} blueColor/></div>
                        <DataSelectBox tittle="Barrios *" entries={suburbsEntries} ChangeState={onInputChange}
                                        targetName={"address.suburb"}
                                        defaultValue={existAndReturn(propertyForm, "address.suburb")}/>
                    </Grid>
                </DataContainerCard>
                <DataContainerCard  tittle="Caracteristicas principales">
                    <Grid columns="2">

                        <DataSelectBox
                            tittle="Tipo de propiedad"
                            entries={propertyTypeEntries}
                            ChangeState={handlePropertyTypeChange}
                            targetName="type"
                            defaultValue={existAndReturn(propertyForm, "type")}
                        />

                        <div className="category-container">
                            <h3>Categoría:</h3>
                            <div className="onlyReadable">
                                <span>{getPropertyCategoryValueByKey(propertyForm.category)}</span>
                            </div>
                        </div>
                        
                        <DataInput tittle="Dormitorios" placeHolder=""
                                                            type="Number"
                                                            ChangeState={onInputChange}
                                                            targetName={"bedrooms"}
                                                            defaultValue={existAndReturn(propertyForm, "bedrooms")}/>
                    </Grid>
                    <Grid columns="3">

                        <DataInput tittle="Ambientes" placeHolder=""
                                                    type="Number"
                                                    ChangeState={onInputChange}
                                                    targetName={"rooms"}
                                                    defaultValue={existAndReturn(propertyForm, "rooms")}/>
                                                    
                        <DataInput tittle="Baño" placeHolder=""
                                                type="Number"
                                                ChangeState={onInputChange}
                                                targetName={"bathrooms"}
                                                defaultValue={existAndReturn(propertyForm, "bathrooms")}/>

                        <DataInput tittle="Cochera" placeHolder=""
                                                type="Number"
                                                ChangeState={onInputChange}
                                                targetName={"garages"}
                                                defaultValue={existAndReturn(propertyForm, "garages")}/>
                    </Grid>
                    <Grid columns="2">
                        <DataMultiSelectBox entries={amenitiesEntries}   tittle="Amenities" 
                                                                        ChangeState={onInputChange}
                                                                        targetName={"showAmenities"}
                                                                        defaultValue={existAndReturn(propertyForm, "showAmenities")}/>

                        <DataMultiSelectBox entries={contractServicesEntries}   tittle="Servicios" 
                                                                        ChangeState={onInputChange}
                                                                        targetName={"showServices"}
                                                                        defaultValue={existAndReturn(propertyForm, "showServices")}/>
                    </Grid>
                    <Grid columns="1">
                        <h1>Superficie</h1>
                    </Grid>
                    <Grid columns="3">

                        <DataInput tittle="Superficie cubierta m2" placeHolder="superficie en m2"
                                                    type="Number"
                                                    ChangeState={onInputChange}
                                                    targetName={"closedSquareMeters"}
                                                    defaultValue={existAndReturn(propertyForm, "closedSquareMeters")}/>
                                                    
                        <DataInput tittle="Superficie descubierta m2" placeHolder="superficie en m2"
                                                type="Number"
                                                ChangeState={onInputChange}
                                                targetName={"openedSquareMeters"}
                                                defaultValue={existAndReturn(propertyForm, "openedSquareMeters")}/>

                        <DataInput tittle="Superficie total m2" placeHolder="superficie en m2"
                                                type="Number"
                                                ChangeState={onInputChange}
                                                targetName={"totalSquareMeters"}
                                                defaultValue={existAndReturn(propertyForm, "totalSquareMeters")}/>
                    </Grid>
                    <Grid columns="1">
                        <h1>Describí la propiedad</h1>
                    </Grid>
                    <Grid columns="1">
                        <DataInput tittle="Titulo" placeHolder=""
                                                        ChangeState={onInputChange}
                                                        targetName={"name"}
                                                        defaultValue={existAndReturn(propertyForm, "name")}/>
                    </Grid>
                    <Grid columns="1">
                        <DataInput tittle="Descripcion" placeHolder=""
                                                        ChangeState={onInputChange}
                                                        targetName={"description"}
                                                        defaultValue={existAndReturn(propertyForm, "description")}
                                                        bigInput/>
                    </Grid>
                </DataContainerCard>

                <DataContainerCard  tittle="Comisión Captación">
                    <div>
                        {
                            commissionData.catchmentCommission.owners.map((owner, index) => {
                                return (
                                    <Grid columns="4" key={index}>
                                        <AutoCompleteInput          id="ownerName" key="ownerName"
                                                                    onChange={(e) => handleFilterContacts(e,'Contact')}
                                                                    ChangeState={e => handleSelectOwnerCommission(e, index)}
                                                                    targetName={'catchmentCommission.owners['+index+'].owner'}
                                                                    defaultValue={owner.owner}
                                                                    showAttributes={["name", "surname"]}
                                                                    placeHolder="Ingresa nombre y apellido"
                                                                    tittle="Nombre del propietario"
                                                                    classname={"md:col-span-2 xl:col-start-2 "}/>
                                        <div className="flex flex-row space-x-8 col-start-3">
                                            <DataInput tittle="% esta op." placeHolder="Ingrese un porcentaje"
                                                        type="Number"
                                                        name={'catchmentCommission.owners.'+index+'.percentageCurrentOperation'}
                                                        realTimeRefresh={true}
                                                        ChangeState={handleInputChangeCommission2}
                                                        targetName={'catchmentCommission.owners.['+index+'].percentageCurrentOperation'}
                                                        defaultValue={existAndReturn(commissionData, 'catchmentCommission.owners['+index+'].percentageCurrentOperation')}
                                                        className={"w-[90px] text-center md:col-span-2 xl:col-span-1 items-end "}/>   

                                            <button onClick={(e) => deleteOwner(e, index, 'catchmentCommission.owners', commissionData, setCommissionData)}  style={{display: index ? 'block' : 'none' }}>
                                                <div className="dataInputIconElement">{getIcon("deleteX")}</div>
                                            </button>
                                        </div>
                                    </Grid>
                                );
                            })
                        }
                        {commissionData.catchmentCommission.owners.length < propertyForm.owners.length?<Grid columns="4">
                            <label className="text-blue-900 font-semibold cursor-pointer mt-1 mb-1 xl:col-start-2 md:col-span-2 xl:col-span-1" onClick={(e)=>newOwner(e,commissionData,setCommissionData, true)}> +
                                Agregar nuevo propietario
                            </label>
                        </Grid>:''}
                        <Grid columns="4">
                            <AutoCompleteInput          id="runner" key="runner"
                                                        onChange={(e) => handleFilterContacts(e,'RUNNER', true)}
                                                        ChangeState={e => onInputChangeCommission(e)}
                                                        targetName={'catchmentCommission.runner.owner'}
                                                        defaultValue={existAndReturn(commissionData, "catchmentCommission.runner.owner")}
                                                        showAttributes={["name", "surname"]}
                                                        placeHolder="Ingresa nombre y apellido"
                                                        tittle="Agente "
                                                        classname={" xl:col-start-2 "}/>
                            <span className="xl:col-start-3">
                                <DataInput tittle="% esta op." placeHolder="Ingrese un porcentaje"
                                            type="Number"
                                            name="catchmentCommission.runner.percentageCurrentOperation"
                                            realTimeRefresh={true}
                                            ChangeState={handleInputChangeCommission2}
                                            targetName={'catchmentCommission.runner.percentageCurrentOperation'}
                                            defaultValue={existAndReturn(commissionData, "catchmentCommission.runner.percentageCurrentOperation")}
                                            className={"w-[90px] text-center"}/>

                            </span>
                        </Grid>
                        <Grid columns="4">
                            <AutoCompleteInput  id="referedOwner" key="referedOwner"
                                onChange={(e) => handleFilterContacts(e,'Contact')}
                                ChangeState={e => onInputChangeCommission(e)}
                                targetName={'catchmentCommission.referedOwner'}
                                defaultValue={existAndReturn(commissionData, "catchmentCommission.referedOwner")}
                                showAttributes={["name", "surname"]}
                                placeHolder="Ingresa nombre y apellido"
                                tittle="Propietario referido "/>
                            <AutoCompleteInput  id="referrer" key="referrer"
                                onChange={(e) => handleFilterContacts(e,'Contact')}
                                ChangeState={e => onInputChangeCommission(e)}
                                targetName={'catchmentCommission.referrer.owner'}
                                defaultValue={existAndReturn(commissionData, "catchmentCommission.referrer.owner")}
                                showAttributes={["name", "surname"]}
                                placeHolder="Ingresa nombre y apellido"
                                tittle="Referidor "
                                classname={" items-end "}/>
                            <DataInput tittle="% esta op." placeHolder="Ingrese un porcentaje"
                                        type="Number"
                                        name="catchmentCommission.referrer.percentageCurrentOperation"
                                        realTimeRefresh={true}
                                        ChangeState={handleInputChangeCommission2}
                                        targetName={'catchmentCommission.referrer.percentageCurrentOperation'}
                                        defaultValue={existAndReturn(commissionData, "catchmentCommission.referrer.percentageCurrentOperation")}
                                        className={referralFirstOperationClassname}/>
                            <DataInput tittle="% sig op." placeHolder="Ingrese un porcentaje"
                                        type="Number"
                                        name="catchmentCommission.referrer.percentageNextOperation"
                                        realTimeRefresh={true}
                                        ChangeState={handleInputChangeCommission2}
                                        targetName={'catchmentCommission.referrer.percentageNextOperation'}
                                        defaultValue={existAndReturn(commissionData, "catchmentCommission.referrer.percentageNextOperation")}
                                        className={referralSecondOperationClassname}/>

                        </Grid>
                        <Grid columns="4">
                            <label className="text-blue-900 font-semibold cursor-pointer mt-1 mb-1 xl:col-start-2 md:col-span-2 xl:col-span-1"
                                onClick={() => {
                                    setShowModal({show: true});
                                }}>
                                + Agregar nuevo contacto
                            </label>
                            {referredOperationsAvailable[0]===false && referredOperationsAvailable[1] === false?<p className="text-rose-900/70 font-bold">Se alcanzaron el maximo de opearciones entre referido y referidor</p>:''}
                            {<MyModal modalState={showModal} children={modalChildren} className="ContractModalForm"
                                    modalSubmit={modalSubmit} responseModal={responseModal}/>}
                        </Grid>
                        <Grid columns="4">
                            <div className="h-full flex xl::col-start-2 md:col-span-2 xl:col-span-1 ">
                                <CommissionTitle className="mt-auto" ><strong>Binculo</strong></CommissionTitle>
                            </div>
                            <DataInput tittle="% esta op." placeHolder=""
                                        type="Number"
                                        value={baredespercentageCurrentOperation}
                                        targetName={'BaredesProperty.propertyOperations'}
                                        defaultValue={baredespercentageCurrentOperation}
                                        className={"w-[90px] text-center"}
                                        onlyReadable/>

                        </Grid>
                    </div>
                </DataContainerCard>

            </form>
        </>
    )
}
