import "../../CSS/Reusable/label.scss";

export default function Label({showName, isPayed, isPartialPayed, isNotPayed, isContact, isTenant, isOwner}) {

    const getClassLabelColor = () => {
        if (isPayed)
            return "payed";
        if (isNotPayed)
            return "not_payed";
        if (isPartialPayed)
            return "partial_payed"
        return "";
    }

    const getClasses = () => {
        return getClassLabelColor().concat(" label");
    }
    return (
        <div className={getClasses()}>
            {showName}
        </div>
    )
}