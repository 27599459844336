import "../../CSS/GeneralStructure/SideBarLeft.scss";
import {useState, useEffect} from "react";
import SideBarLi from "./SideBarLi";
import {fetchSideBarRoutes} from "../../Services/MainViews/Login/userRouteComponentService";
import {NavLink, Outlet} from "react-router-dom";
import {changeProfile, logout} from "../../Services/MainViews/Login/authLoginService";
import {getIcon} from "../../Services/iconService";

localStorage.setItem("userType", "User");

export default function SideBarLeft() {
    const [sideBarData, setSideBarData] = useState();
    const [sideBarView, setSideBarView] = useState(false);

    useEffect(() => {
        fetchSideBarRoutes().then((res) => {
            setSideBarData(res);
        });
    }, []);

    return (
        <div className="SideBarLeft">
            <div
                className="SideBarLeft_Container"
                style={{marginRight: sideBarView ? "0" : "4rem"}}>
                <button
                    className="SideBarLeft_OpenClose"
                    onClick={() => setSideBarView(!sideBarView)}>
                    {sideBarView ? getIcon("sidebarclosed") : getIcon("sidebaropen")}
                </button>
                <nav>
                {Array.isArray(sideBarData) && sideBarData.map((elem) => (
                    <SideBarLi {...{...elem, sideBarView}} key={elem.title} />
                ))}
                </nav>
                <Outlet/>
                <NavLink to="/roleselector" onClick={changeProfile} className="SideBarLeft_divSalir">
                    {getIcon("profile")}
                    {!sideBarView && <div className="SideBarLeft_salir">Perfil</div>}
                </NavLink>
                <NavLink to="/" onClick={logout} className="SideBarLeft_divSalir">
                    {getIcon("logout")}
                    {!sideBarView && <div className="SideBarLeft_salir">Salir</div>}
                </NavLink>
            </div>
        </div>
    );
}
