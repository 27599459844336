import React, {useState, useEffect} from 'react';
import axios from "axios";
import Switch from '@mui/material/Switch';
import authHeaderService from "../../authHeaderService";
import {authorizationCheck, devLog} from "../../../globalHelper";


export async function getContract(id) {
    return await axios
        .get(
            `${process.env.REACT_APP_API_URL}/api/contracts/${id}`,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if (e.response) {
                devLog(true, e);
                authorizationCheck(e);
                return  e.response.data;
            }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
};

export async function createFinalContract(contract) {
    return await axios
        .post(
            `${process.env.REACT_APP_API_URL}/api/finalcontracts`,
            contract,
            authHeaderService()
        )
        .then((res) => {
            return res
        })
        .catch((e) => {
            if (e.response) {
                devLog(true, e);
                authorizationCheck(e);
                return  e.response.data;
            }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}

export async function updateFinalContract(contract) {
    return await axios
        .post(
            `${process.env.REACT_APP_API_URL}/api/finalcontracts`,
            contract,
            authHeaderService()
        )
        .then((res) => {
            return res.data
        })
        .catch((e) => {
            if (e.response) {
                devLog(true, e);
                authorizationCheck(e);
                return  e.response.data;
            }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}

export async function createBookingContract(contract) {
    return await axios
        .post(
            `${process.env.REACT_APP_API_URL}/api/bookingcontracts`,
            contract,
            authHeaderService()
        )
        .then((res) => {
            return res.data
        })
        .catch((e) => {
            if (e.response) {
                devLog(true, e);
                authorizationCheck(e);
                return  e.response.data;
            }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}

export async function updateBookingContract(contract) {
    return await axios
        .put(
            `${process.env.REACT_APP_API_URL}/api/bookingcontracts`,
            contract,
            authHeaderService()
        )
        .then((res) => {
            return res.data
        })
        .catch((e) => {
            if (e.response) {
                devLog(true, e);
                authorizationCheck(e);
                return  e.response.data;
            }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}

export async function fetchContracts(operationsFilters,page = 0, size = 7) {
    let preSet =        [{
        property: "isDeleted",
        value: "false",
        operator: "EQ",
        logicOperator: "AND"
    }  
    ]
    let data =                  
                {
                    paging: {
                        page: page,
                        size: size
                    },
                    operations: operationsFilters ? preSet.concat(operationsFilters): preSet,
                }

    return await axios
        .put(
            `${process.env.REACT_APP_API_URL}/api/contracts`
            , data
            , authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if (e.response) {
                devLog(true, e);
                authorizationCheck(e);
                return  e.response.data;
            }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}


export async function deleteContract(id) {
    return await axios
        .delete(
            `${process.env.REACT_APP_API_URL}/api/contracts/${id}`,
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if (e.response) {
                devLog(true, e);
                authorizationCheck(e);
                return  e.response.data;
            }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}

/*SERVICES*/
export async function servicesPayed(id, areMonthServicesPayed) {
    return await axios
        .put(
            `${process.env.REACT_APP_API_URL}/api/payments`,
            {
                "id": id,
                "areMonthServicesPayed": areMonthServicesPayed
            },
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if (e.response) {
                devLog(true, e);
                authorizationCheck(e);
                return  e.response.data;
            }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}