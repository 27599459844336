import { useState } from "react";

// Components
import Grid from "../../Reusable/Grid";
import DataContainerCard from "../../Reusable/DataContainerCard";
import DataTable from "../../Reusable/DataTable/DataTable";
import ButtonStandard from "../../Reusable/ButtonStandard";
import CircularLoader from "../../Reusable/CircularLoader";
import AutoCompleteInput from "../../Reusable/AutoCompleteInput";

// Services
import { fetchAddressesByStreet } from "../../../Services/addressesService";
import { getExpensesAddress } from "../../../Services/MainViews/Home/Home";

// Helpers
import { mapCurrencyName } from "../../../dataMappingUtilHelper";
import { unionJsonValue, formatedDate, existAndReturn, formatNumberWithDots } from "../../../globalHelper";

export default function Expenses() {

  const [isLoading, setIsLoading] = useState(false);
  const [expensesForm, setExpensesForm] = useState({
    address: {},
  });
  const [expensesPageable, setExpensesPageable] = useState([]);

  // Manejador de cambio en la entrada de texto
  const onInputChange = (jsonData) => {
    let newValue = unionJsonValue(expensesForm, jsonData);
    setExpensesForm(newValue);
  };

  // Manejador de filtro de contactos por calle
  const handleFilterContacts = (street) => {
    return fetchAddressesByStreet({ street: street });
  };

  // Obtener información de los gastos
  const getExpensesInfo = async () => {
    setIsLoading(true);
    const res = await getExpensesAddress(expensesForm.address);
    if (res && res !==null && res !==undefined && res.status !== 500) {
      res.forEach((expense) => {
        expense.amountAndCurrency = `${mapCurrencyName(existAndReturn(expense, "value.currency"))} ${formatNumberWithDots(existAndReturn(expense, "value.value"))}`;
        expense.date = formatedDate(existAndReturn(expense, "date"));
  
        expense.contract.secondPartsEquity[0].user.fullname = `${existAndReturn(expense, "contract.secondPartsEquity[0].user.name")} ${existAndReturn(expense, "contract.secondPartsEquity[0].user.surname")}`;
        expense.contract.ownersEquity[0].user.fullname = `${existAndReturn(expense, "contract.ownersEquity[0].user.name")} ${existAndReturn(expense, "contract.ownersEquity[0].user.surname")}`;
      });
      setExpensesPageable(res);
      setIsLoading(false);
    }
    else{
      setExpensesPageable([]);
      setIsLoading(false);
    }

  };

  const dataTableColumns = [
    { field: "contract.ownersEquity.[0].user.fullname", showName: "Propietario" },
    { field: "contract.secondPartsEquity.[0].user.fullname", showName: "Inquilino" },
    { field: "date", showName: "Fecha" },
    { field: "description", showName: "Descripción" },
    { field: "amountAndCurrency", showName: "Monto" }
  ];

  return (
    <>
      <DataContainerCard tittle="Gastos">
        <Grid columns="2">
          <div>
            <Grid columns="1">
              <AutoCompleteInput
                id="addressFilter"
                key="addressFilter"
                onChange={(e) => handleFilterContacts(e)}
                ChangeState={onInputChange}
                targetName={"address"}
                showAttributes={["street", "houseNumber"]}
                tittle="Ingrese dirección"
                placeHolder={"Ingrese dirección"}
                required
              />
            </Grid>
            <Grid columns="1">
              <div style={{ paddingRight: "1.5rem", marginTop: "1rem" }}>
                <ButtonStandard showName="Buscar gastos" float="right" onClick={getExpensesInfo} blueColor largeButton />
              </div>
            </Grid>
          </div>
          {isLoading ? (
            <CircularLoader />
          ) : (
            <div className="dataHomeCard">
              {expensesPageable ? <DataTable rows={expensesPageable} columns={dataTableColumns} /> : null}
            </div>
          )}
        </Grid>
      </DataContainerCard>
    </>
  );
}