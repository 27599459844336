import "../../../CSS/MainViews/Contacts.scss";
import React, {useEffect, useState, useMemo } from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchProperties} from "../../../Services/MainViews/Properties/propertiesService";
import {getProperties, resetFilters} from "../../../Redux/Actions/actionsProperties";
import {
    addConditionalLogic,
    isPropertyCategoryOf,
} from "../../../Services/filterService";
import {
    PROPERTY_CATEGORY_COMMERCIAL_KEY,
    PROPERTY_CATEGORY_LAND_KEY, PROPERTY_CATEGORY_RESIDENTIAL_KEY, propertyCategories, bsAsCabaNeighborhoods
} from "../../../dataEntriesHelper";
import PropertiesFilters from "../../Reusable/PropertiesFilters";
import Pagination from "../../Reusable/PaginationList";
import {useNavigate} from "react-router-dom";
import ButtonStandard from "../../Reusable/ButtonStandard";
import MyModal from "../../Reusable/MyModal";
import BackgroudContainer from "../BackgroudContainer";
import DataTable from "../../Reusable/DataTable/DataTable";
import {fetchBrokerSuburbs, fetchUserPropertyCategory} from "../../../Services/MainViews/Properties/searchesService";
import {mapCurrencyName, mapPropertyType, mapOperationType} from "../../../dataMappingUtilHelper"
import CircularLoader from "../../Reusable/CircularLoader";
import {devLog, existAndReturn, exportProperty, formatNumberWithDots} from "../../../globalHelper"
import FirstLoginModal from "../../Reusable/Users/FirstLoginModal";
import {AppBar, Box, Tab, Tabs} from '@mui/material';
import TabPanel from "../../Reusable/TabPanel";
import './PropertiesTabs.scss'
import {StatusChip} from "../../Reusable/DesignSystem/StatusChip/StatusChip";

export default function BrokerRunnerHome() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [actualPropertyCategory, setActualPropertyCategory] = useState('COMMERCIAL');
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const [clearFilters, setClearFilters] = useState('');
    const [propertiesFilterList, setPropertiesFilterList] = useState({
        filtersRequest: []
    });
    const [keyword, setKeyword] = useState('');
    const [showModal, setShowModal] = useState({show: false});
    const [responseModal, setResponseModal] = useState(false)
    const [currentProperties, setCurrentProperties] = useState({
        content: [],
        number: 0
    })
    const originalPropertiesFromBackend = currentProperties.content;
    const [processedProperties, setProcessedProperties] = useState([]);
    const [paginatedProperties, setPaginatedProperties] = useState([]);
    const [currentFilters, setCurrentFilters] = useState([]);
    const [categorizeSuburb, setCategorizeSuburb] = useState([]);

    const filters = useSelector((state) => state.reducerProperties.filters);

    const firstLoadOperations =
        [{
            "property": "isAvailable",
            "value": true,
            "operator": "EQ",
            "logicOperator": "AND"
        }
    ];

    if (filters.includes(...currentFilters) === false) filters.push(...currentFilters);

    let checkboxList = Array.from(
        document.getElementsByClassName("SelectBoxFilter_checkbox")
    );

    useEffect(() => {
        // Copia procesada de las propiedades filtradas
        const filteredList = [...processedProperties];
        setPaginatedProperties(filteredList);
    }, [processedProperties]);

    useEffect(() => {
        const user = JSON.parse(localStorage.user);
        if (user.hasAlreadyChangedPassword === false) {
            setShowModal({show: true});
        }
    }, [])

    useEffect(() => {
        dispatch(resetFilters());
        checkboxList?.map((checkbox) => (checkbox.checked = false));
        fetchAndFilterProperties();
        let availableFilters = [];
        firstFetchfilterOperations(availableFilters);
        setCurrentFilters(availableFilters)
    }, [actualPropertyCategory]); //eslint-disable-line

    const settingProperties = (propertiesList) => {
        if (propertiesList !== undefined && propertiesList !== null) {
            propertiesList.map(property => {
                if (property.propertyOperations[0]) {
                    property.priceCurrency = "" +mapCurrencyName(property.propertyOperations[0].currency)  + " " +formatNumberWithDots(property.propertyOperations[0].price);
                    if (property, existAndReturn(property, "isBooked")) {
                        property.status = "Reservada";
                    }
                }
            })
        }
    }

    useEffect(()=>{
        const setFirstCategory = async () =>{
            const brokerSuburbs = await fetchBrokerSuburbs();
            setActualPropertyCategory(brokerSuburbs[0].propertyCategory)
        }
        setFirstCategory();
    },[])

    const fetchAndFilterProperties = async () => {
        devLog(true, "[Binculo] Preparing to fetch and filter properties...");

        let availableFilters = [];
        await firstFetchfilterOperations(availableFilters);
        devLog(true, "[Binculo] Filters applied for properties fetch:", availableFilters);
        getPageableProperties(availableFilters, currentProperties.number, itemsPerPage)
    }

    const fetchFiltersData = async () => {
        const brokerSuburbsV2 = await fetchBrokerSuburbs();
        setCategorizeSuburb(brokerSuburbsV2);
        const brokerSuburbs =[];
        
        brokerSuburbsV2.map((category)=>{
            category.suburbs.map((suburb)=>{
                brokerSuburbs.push(suburb.suburb)
            })
        })
        
        const propertyCategories = ["COMMERCIAL","LAND","RESIDENTIAL"];
        return { brokerSuburbs, propertyCategories };
    };

    const firstFetchfilterOperations = async (availableFilters, brokerSuburbs, propertyCategories) => {
        devLog(true, "[Binculo] Fetching broker suburbs and property categories for filter...");

        if (brokerSuburbs) {
            devLog(true, "[Binculo] Broker suburbs received:", brokerSuburbs);
            brokerSuburbs.forEach(suburb => {
                availableFilters.push({
                    "property": "address.suburb",
                    "value": suburb,
                    "operator": "EQ",
                    "logicOperator": "OR"
                });
            });
        }

        if (propertyCategories) {
            devLog(true, "[Binculo] Property categories received:", propertyCategories);
            propertyCategories.forEach(category => {
                availableFilters.push({
                    "property": "category",
                    "value": category,
                    "operator": "EQ",
                    "logicOperator": "AND"
                });
            });
        }
        availableFilters.push({ "property": "category",
            "value": actualPropertyCategory,
            "operator": "EQ",
            "logicOperator": "AND"})
        availableFilters.push(firstLoadOperations[0]);

        devLog(true, "[Binculo] Final filters to be applied:", availableFilters);
    };
    const getAndSetProperties = async (operations, page,size)=>{
        setIsLoading(true);
        let properties = null;
        properties = await fetchProperties(operations, page, size);
    
        if (properties && properties !== null && properties !== undefined) {
          properties.content = properties.content.filter(property => property.isAvailable);
          settingProperties(properties.content);
          setCurrentProperties(properties)
          setIsLoading(false);
          setClearFilters(!clearFilters);
        }
        else{
          console.log('Error fetching properties or no properties available');
          settingProperties([]);
          setCurrentProperties([])
          setIsLoading(false);
        }
    }
      
    const getPageableProperties = (operations, page, size) => {
        getAndSetProperties(operations, page, size)
    }

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        addConditionalLogic(filters);
        getPageableProperties(filters, currentProperties.number, itemsPerPage)
        setIsLoading(false);
        setClearFilters(!clearFilters);
    }

    const handleReset = async (e) => {
        let availableFilters = [];
        await firstFetchfilterOperations(availableFilters);
        e.preventDefault();
        if (currentProperties !== undefined) {
            dispatch(resetFilters());
            checkboxList?.map((checkbox) => (checkbox.checked = false));
            fetchAndFilterProperties();
        } else {
            getAndSetProperties(availableFilters)
        }
        setClearFilters(!clearFilters);
    }

    const modalFunction = () => {
        let newLocalVars = JSON.parse(localStorage.user);
        newLocalVars.hasAlreadyChangedPassword = true;
        localStorage.setItem("user", JSON.stringify(newLocalVars));
    }
    
    useEffect(() => {
        const processProperties = async () => {
            devLog(true, "[Binculo] Properties > original (from backend): ", originalPropertiesFromBackend);

            const { brokerSuburbs, propertyCategories } = await fetchFiltersData();

            const filteredProperties = originalPropertiesFromBackend?.filter(property => {
                const suburbMatch = !brokerSuburbs.length || (property.address && brokerSuburbs.includes(property.address.suburb));
                const categoryMatch = !propertyCategories.length || propertyCategories.includes(property.category);
                return suburbMatch && categoryMatch;
            });

            const processed = filteredProperties?.map(property => ({
                ...property,
                typeMapped: mapPropertyType(property.propertyType),
                status: createPropertyStatus(property),
                suburbMapped: bsAsCabaNeighborhoods[property.address.suburb],
                propertyOperation:property.propertyOperations[0]?mapOperationType(property.propertyOperations[0].type):'-'
            }));
            console.log({ brokerSuburbs, propertyCategories });
            devLog(true, "[Binculo] Properties > processed properties: ", processed);
            setProcessedProperties(processed?processed:[]);
            
        };

        processProperties();
    }, [currentProperties]);


    return (
        <>
            <BackgroudContainer>
                {<FirstLoginModal modalState={showModal}
                                  childrenTitle={'Ingrese su nueva contraseña con la que ingreserá al sistema'}
                                  className="ContractModalForm"
                                  modalSubmit={modalFunction} responseModal={responseModal}/>}
                <div className="w-[inherit]">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4 justify-items-center mb-8">
                        <div className="flex flex-row lg:flex-col justify-between lg:justify-end lg:items-end lg:space-y-4 w-full lg:w-[80%] lg:col-start-2">
                            <div id="addFilter">
                                <ButtonStandard showName="Aplicar Filtros" onClick={(e) => handleSubmit(e)} largeButton/>
                            </div>
                            <div id="clearFilter">
                                <ButtonStandard showName="Limpiar Filtros" onClick={(e) => handleReset(e)} largeButton/>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-1 xl:gap-4 justify-items-center ">
                        <PropertiesFilters propertiesFilterList={propertiesFilterList}
                                            setPropertiesFilterList={setPropertiesFilterList} clearFilters={clearFilters}
                                            currentFilters={filters}/>
                    </div>
                    <div className="bg-white rounded-3xl border-2 border-[#F0F0F0] py-[50px] space-y-[50px] mt-8 lg:mt-16">
                        <h1 className="text-[1.5rem] lg:text-[2rem] text-center">LISTA DE PROPIEDADES</h1>

                        <PropertiesTabs isLoading={false} processedProperties={processedProperties} categorizeSuburb={categorizeSuburb} setActualPropertyCategory={setActualPropertyCategory}/>

                        <div className="Properties_Pagination" style={{"display": "flex", "justifyContent": "center"}}>
                            <Pagination {...{itemsPerPage,}} fetchData={fetchProperties} setList={getPageableProperties}
                                        filters={filters} dataChanges={currentProperties}/>
                    </div>
                    </div>
                </div>
            </BackgroudContainer>
        </>
    )
}



export function PropertiesTabs({ isLoading, processedProperties, categorizeSuburb, setActualPropertyCategory }) {
    const [value, setValue] = useState(0);
    const [filteredProperties, setFilteredProperties] = useState([]);

    const categories = useMemo(
        () => categorizeSuburb.map(suburb => suburb.propertyCategory),
        [categorizeSuburb]
    );

    const handleChange = (event, newValue) => {
        const selectedCategory = categories[newValue];
        setActualPropertyCategory(selectedCategory);
        setValue(newValue);
    };

    const actions = [
        { onClick: (id, type) => showProperty(id, type), showName: "Ver propiedad" },
        { onClick: (id, type) => exportPropertyFn(id, type), showName: "Copiar Link" },
      ];
      
      const showProperty = (id, type) => {
        exportProperty(id, "openActualTab");
      };
      
      const exportPropertyFn = async (id, type) => {
        return await exportProperty(id, "copyClipboard"); // Retorna el texto
      };

    useEffect(() => {
        const selectedCategory = categories[value];
        const filtered = processedProperties.filter(property => 
            isPropertyCategoryOf(property, selectedCategory, categorizeSuburb)
        );

        setFilteredProperties(filtered);
    }, [value, processedProperties, categories, categorizeSuburb]);

    return (
        <div>
            <Box sx={{ backgroundColor: 'white' }}>
                <AppBar position="static" className="!shadow-none border-t-2 border-[#F0F0F0]">
                    <Tabs value={value} onChange={handleChange} className="tab-bar shadow-md p-[32px] lg:px-[117px]">
                        {categories.map((category, index) => (
                            <Tab
                                key={category}
                                label={propertyCategories[category]}
                                className="!mx-auto !text-lg lg:!text-2xl"
                            />
                        ))}
                    </Tabs>
                </AppBar>
                <div className="Properties !bg-white">
                    {categories.map((category, index) => (
                        <TabPanel key={category} value={value} index={index}>
                            {isLoading ? (
                                <CircularLoader />
                            ) : (
                                <DataTable
                                    rows={filteredProperties}
                                    columns={generateColumns(category)}
                                    actions={actions}
                                />
                            )}
                        </TabPanel>
                    ))}
                </div>
            </Box>
        </div>
    );
}

const renderCellWithPropertyStatus = (property) => {
    devLog(false, "[Binculo] renderCellWithPropertyStatus", property.status)
    return <StatusChip status={property.status}/>
};

const TAB_COMERCIAL = 0;
const TAB_RESIDENCIAL = 1;
const TAB_TERRENOS = 2;

const FILTER_CONDITIONS = {
    [TAB_COMERCIAL]: (property, categorizeSuburb) => isPropertyCategoryOf(property, PROPERTY_CATEGORY_COMMERCIAL_KEY, categorizeSuburb),
    [TAB_RESIDENCIAL]: (property, categorizeSuburb) => isPropertyCategoryOf(property, PROPERTY_CATEGORY_RESIDENTIAL_KEY, categorizeSuburb),
    [TAB_TERRENOS]: (property, categorizeSuburb) => isPropertyCategoryOf(property, PROPERTY_CATEGORY_LAND_KEY, categorizeSuburb)
  };

const generateColumns = (currentTab) => {
    let columns = [
        {
            field: "status",
            showName: <span className="table-column-title">Estado</span>,
        },
        {
            field: "propertyOperation",
            showName: <span className="table-column-title">Tipo de operacion</span>,
        },
        {
            field: "suburbMapped",
            showName: <span className="table-column-title">Barrio</span>,
        },
        {
            field: "address.street",
            showName: <span className="table-column-title">Direccion</span>,
        },
        {
            field: "priceCurrency",
            showName: <span className="table-column-title">Precio</span>,
        },
        {
            field: "description",
            showName: <span className="table-column-title">Descripcion</span>,
        },
    ]

    if (currentTab !== TAB_TERRENOS) {
        columns.splice(1, 0, {
            field: "typeMapped",
            showName: <span className="table-column-title">Tipo de propiedad</span>,
        });
    }

    return columns;
};

export const PROPERTY_NOT_PROPERTY = "No disponible"
export const PROPERTY_AVAILABLE = "Disponible"
export const PROPERTY_BOOKED = "Reservado"

export function createPropertyStatus(property) {
    return property.isBooked ? PROPERTY_BOOKED :
        property.isAvailable ? PROPERTY_AVAILABLE :
            PROPERTY_NOT_PROPERTY;
}