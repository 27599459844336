import "../../CSS/Reusable/EachProperty.scss";
import CryptoJS from 'crypto-js';
import { getIcon } from "../../Services/iconService";
import { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import ThreeDotSelector from "./ThreeDotSelector";
import DoubleConfirmation from "./DoubleConfirmation";
import LazyImage from "./LazyImage";
import {mapCurrencyName, mapOperationType, mapPropertyType} from "../../dataMappingUtilHelper";
import {propertyCategories, bsAsCabaNeighborhoods} from "../../dataEntriesHelper";
import { exportProperty, formatNumberWithDots} from "../../globalHelper";
import {deleteProperty} from "../../Services/MainViews/Properties/propertiesService";
import { logDOM } from "@testing-library/react";
import bigPhotoDefault from "../../Assets/big-photo-without-image.png";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import {updateProperty} from "../../Services/MainViews/Properties/propertiesService";

export default function Property(fullProperty) {
  
  const navigate = useNavigate();
  const [responseModal, setResponseModal] = useState(false);
  const [showModal, setShowModal] = useState({show: false});
  const [portraitPhoto, setPortraitPhoto] = useState({urlPath:bigPhotoDefault});
  const [actionMenu, setActionMenu] = useState(false);
  let {
    address,
    bathrooms,
    bedrooms,
    garages,
    isStarred,
    propertyOperations,
    images,
    rooms,
    showMap,
    totalSquareMeters,
    type,
    category,
    id,
    isBooked
  } = fullProperty

  useEffect(() => {
    if (images !== undefined && images !== null && images.length > 0) {
      let selectedImage = images.reduce((min, image) => {
        return image.imageOrder < min.imageOrder ? image : min;
      }, images[0]);
      setPortraitPhoto(selectedImage)
    }
  }, [fullProperty]);
;

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);// The maximum is exclusive and the minimum is inclusive
  }

  const delProperty = (propertyId) => {
    setShowModal({show: true});
  }

  const finallyDelProperty =(propertyId)=>{
    Promise.resolve(deleteProperty(propertyId)).then( (res) => {
        if (res.message) {
            alert(res.message)          
        }
        else{
          window.location.reload();
        }
    })
}

  const edProperty = (propertyId) => {
    navigate("/properties/edit/"+propertyId);
  }

  const viewProperty = (propertyId) =>{
    navigate("/properties/"+propertyId);
  }

  const exportPropertyFn = async (id, type) => {
    return await exportProperty(id, "copyClipboard"); // Retorna el texto
  };
  
  const updateStarred = () =>{
    let newStarredProperty = Object.assign({}, fullProperty);
    newStarredProperty.isStarred = !newStarredProperty.isStarred;
    delete newStarredProperty.propertyOperations;
    Promise.resolve(updateProperty(newStarredProperty)).then( (res) => {
      if (res.message) {
          alert(res.message)
      }
      else{
          window.location.href = "/properties";
      }
      
  })
  }


  const actions = [
    {onClick: (e) => edProperty(e), showName: 'Editar' },
    {onClick: (e) => viewProperty(e), showName: 'Ver propiedad'},
    {onClick: (e) => exportPropertyFn(e), showName: 'Copiar Link'},
    {onClick: (e) => delProperty(e), showName: 'Eliminar'},
  ];


  return (
    <div className="EachProperty" style={{ width: showMap && "100%", height: showMap && "20rem" }}>

      {<DoubleConfirmation modalState={showModal} children={'Si la propiedad es eliminada, no podra recuperarse. Deberas crearla nuevamente'} className="ContractModalForm"
                                  modalSubmit={finallyDelProperty} responseModal={responseModal} title={'¡CUIDADO ESTA POR ELIMINAR UNA PROPIEDAD!'} currentSelectedID={id}/>}

      <NavLink className="EachProperty_Container" to={`/properties/${id}`} >
        <div className="flex max-h-[296px] min-h-[296px] overflow-hidden ">
          <LazyImage src={images.length > 0 ? images[0].urlPath : bigPhotoDefault} alt={type} loading="lazy" className=' w-full '/>
        </div>
      </NavLink>
      <section className="EachProperty_Top">
        <div className="w-full px-4 py-3 flex flew-row">
          <div className="text-start w-full">
            <div className="font-bold text-2xl ">{propertyCategories[category]}</div>
            <div className="font-normal text-base ">{address?.street}, {bsAsCabaNeighborhoods[address?.suburb]}.</div> 
          </div>
          <div className="EachProperty_type">
            <ThreeDotSelector rowComponentId={id} actions={actions}/>
            <button className="EachProperty_idCopy" value={id} onClick={(e) =>navigator.clipboard.writeText(e.target.value)}>ID: {id}<p>{getIcon("copy")}</p></button>
        </div>
        </div>
      </section>

      <section className="EachProperty_Middle !mx-0">
        <div className="flex flex-row px-4 py-3 first:color-red ">
          <p className="!ml-[0px]">{getIcon("totalSquareMeters")}{totalSquareMeters ? Math.round(totalSquareMeters) : "N/A"} m<sup>2</sup></p>
          <p>{getIcon("rooms")}{rooms}</p>
          <p>{getIcon("bedrooms")}{bedrooms}</p>
          <p>{getIcon("bathrooms")}{bathrooms}</p>
          <p>{getIcon("garage")}{garages}</p>
        </div>
      </section>
      <section className="EachProperty_Bottom !mx-0 px-4 py-3">
        {propertyOperations?.map((operation, i) => i < 2 && <div key={i}>{mapCurrencyName(operation.currency)}{" "}{formatNumberWithDots(operation.price)}, {mapOperationType(operation.type)}</div>)} 
        <div className="ml-auto text-lg flex !mr-[0px] !justify-end !aling-center">
          <pill  style={{display: isBooked ? 'block' : 'none' }} id="Reservada">Reservada</pill>
        </div>
      </section>
    </div>
  );
}
