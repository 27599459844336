export const PROPERTY_CATEGORY_LAND_KEY = "LAND"
export const PROPERTY_CATEGORY_COMMERCIAL_KEY = "COMMERCIAL"
export const PROPERTY_CATEGORY_RESIDENTIAL_KEY = "RESIDENTIAL"

export const PROPERTY_CATEGORY_LAND_VALUE = "Terrenos"
export const PROPERTY_CATEGORY_COMMERCIAL_VALUE = "Comercial"
export const PROPERTY_CATEGORY_RESIDENTIAL_VALUE = "Residencial"



export const amenitiesEntries =
[
    {label: "Garage de invitados", value: "GUESTS_GARAGES"},
    {label: "Parrilla", value: "BARBECUE"},
    {label: "Pileta externa", value: "OUTSIDE_POOL"},
    {label: "Pileta interna", value: "INSIDE_POOL"},
    {label: "Salon de juegos", value: "PLAY_ROOM"},
    {label: "Sum", value: "SUM"}
]

export const propertyCategories =
    {
        [PROPERTY_CATEGORY_COMMERCIAL_KEY]: PROPERTY_CATEGORY_COMMERCIAL_VALUE,
        [PROPERTY_CATEGORY_RESIDENTIAL_KEY]: PROPERTY_CATEGORY_RESIDENTIAL_VALUE,
        [PROPERTY_CATEGORY_LAND_KEY]: PROPERTY_CATEGORY_LAND_VALUE,
    }

export const bsAsCabaNeighborhoods =
    {
        "AGRONOMIA": "Agronomía",
        "ALMAGRO": "Almagro",
        "BALVANERA": "Balvanera",
        "BARRACAS": "Barracas",
        "BELGRANO": "Belgrano",
        "BOEDO": "Boedo",
        "CABALLITO": "Caballito",
        "CHACARITA": "Chacarita",
        "COGHLAN": "Coghlan",
        "COLEGIALES": "Colegiales",
        "CONSTITUCION": "Constitución",
        "FLORES": "Flores",
        "FLORESTA": "Floresta",
        "FUERA_DE_CAPITAL": "Fuera de Capital",
        "LA_BOCA": "La Boca",
        "LA_PATERNAL": "La Paternal",
        "LINIERS": "Liniers",
        "MATADEROS": "Mataderos",
        "MONTE_CASTRO": "Monte Castro",
        "MONTSERRAT": "Montserrat",
        "NUEVA_POMPEYA": "Nueva Pompeya",
        "NUNES": "Nuñez",
        "ONCE": "Once",
        "PALERMO": "Palermo",
        "PARQUE_AVELLANEDA": "Parque Avellaneda",
        "PARQUE_CHACABUCO": "Parque Chacabuco",
        "PARQUE_CHAS": "Parque Chas",
        "PARQUE_PATRICIOS": "Parque Patricios",
        "PUERTO_MADERO": "Puerto Madero",
        "RECOLETA": "Recoleta",
        "RETIRO": "Retiro",
        "SAAVEDRA": "Saavedra",
        "SAN_CRISTOBAL": "San Cristóbal",
        "SAN_NICOLAS": "San Nicolás",
        "SAN_TELMO": "San Telmo",
        "VERSALLES": "Versalles",
        "VILLA_CRESPO": "Villa Crespo",
        "VILLA_DEVOTO": "Villa Devoto",
        "VILLA_GENERAL_MITRE": "Villa General Mitre",
        "VILLA_LUGANO": "Villa Lugano",
        "VILLA_LURO": "Villa Luro",
        "VILLA_ORTUZAR": "Villa Ortúzar",
        "VILLA_PUEYRREDON": "Villa Pueyrredón",
        "VILLA_REAL": "Villa Real",
        "VILLA_RIACHUELO": "Villa Riachuelo",
        "VILLA_SANTA_RITA": "Villa Santa Rita",
        "VILLA_SOLDATI": "Villa Soldati",
        "VILLA_URQUIZA": "Villa Urquiza",
        "VILLA_DEL_PARQUE": "Villa del Parque",
        "VELEZ_SARSFIELD": "Vélez Sarsfield",
    }

export const moneyEntries =
[
    {name: "$", value: "PESOS"},
    {name: "U$D", value: "DOLLARS"},
    {name: "U$D Oficiales", value: "OFFICIAL_DOLLARS"},
    {name: "USDT", value: "USDT"},
    {name: "%", value: "PERCENTAGE"}
];

export const phonePrefixEntries = [
    { name: "AR", value: "54" }, // Argentina
    { name: "BO", value: "591" }, // Bolivia
    { name: "BR", value: "55" }, // Brasil
    { name: "CL", value: "56" }, // Chile
    { name: "CO", value: "57" }, // Colombia
    { name: "EC", value: "593" }, // Ecuador
    { name: "PY", value: "595" }, // Paraguay
    { name: "PE", value: "51" },  // Perú
    { name: "UY", value: "598" }, // Uruguay
    { name: "VE", value: "58" },  // Venezuela

    // América Central
    { name: "CR", value: "506" }, // Costa Rica
    { name: "GT", value: "502" }, // Guatemala
    { name: "HN", value: "504" }, // Honduras
    { name: "NI", value: "505" }, // Nicaragua
    { name: "PA", value: "507" }, // Panamá
    { name: "SV", value: "503" }, // El Salvador

    // América del Norte
    { name: "MX", value: "52" },  // México
    { name: "US/CA", value: "1" },   // Estados Unidos y Canadá
];

/*Tipo de operaciones en crear propiedad*/
export const operationsEntries =
[
    {name: "Alquiler", value: "RentOperation"},
    {name: "Venta", value: "BuyOperation"}
]

/*Tipo de operaciones en creacion de contrato*/
export const contractOperationsEntries =
[
    {name: "Alquiler", value: "RENTAL"},
    {name: "Venta", value: "BUY"}
]

/*Tipo de operaciones en crear reserva*/
export const bookingOperationsEntries =
[
    {name: "Alquiler", value: "RENTAL"},
    {name: "Alquiler ad referéndum", value: "REFERENDUM_RENT"},
    {name: "Venta", value: "BUY"},
    {name: "Venta ad referéndum", value: "REFERENDUM_BUY"}

]

export const propertyCategoryEntries = [
    {name: "Residencial", value: "RESIDENTIAL"},
    {name: "Terrenos", value: "LAND"},
    {name: "Comercial", value: "COMMERCIAL"}
]

export const propertyTypeEntries =

[
    {name: "Casa", value: "HOUSE"},
    {name: "Departamento", value: "BUILDING"},
    {name: "Deposito", value: "DEPOSIT"},
    {name: "Local Comercial", value: "STORE"},
    {name: "Terreno", value: "LAND"},
    {name: "Oficina", value: "OFFICE"},
    {name: "Paseo de Compras", value: "SHOPPING_TRIP"},
    //{name: "Paseo comercial", value: "SHOPPING_WALK"}
]

/*Tipo de servicios en creacion de propiedad*/
export const propertyServicesEntries =
[
    {label: "Sauna", value: "SAUNA"},
    {label: "Jacuzzi", value: "JACUZZI"},
    {label: "Parrilla", value: "BARBECUE"},
    {label: "Garage de invitados", value: "GUEST_GARAGE"},
    {label: "Pileta descubierta", value: "OUTDOOR_POOL"},
    {label: "Pileta cubierta", value: "INDOOR_POOL"},
    {label: "Sum", value: "SUM"},
    {label: "Sala de juegos", value: "PLAY_ROOM"},
    {label: "Microcine", value: "MICROCINEMA"},
    {label: "Cochera", value: "PARKING"}
]

/*Tipo de servicios en creacion de contrato*/
export const contractServicesEntries =
[
    {label: "Electricidad", value: "ELECTRICITY"},
    {label: "Gas", value: "GAS"},
    {label: "Wi fi", value: "WIFI"},
    {label: "Agua", value: "WATER"},
    {label: "Abl", value: "ABL"},
    {label: "Cable", value: "TV_PROVIDER"},
    {label: "Teléfono", value: "PHONE"},
    {label: "Expensas ordinarias", value: "ORDINARY_EXPENSES"},
    {label: "Expensas extraordinarias", value: "EXTRAORDINARY_EXPENSES"}
]

export const bsAsCabaNeighborhoodsOfNewUser = Object.entries(bsAsCabaNeighborhoods).map(([key, name]) => ({
    name: name,
    value: key
}));


export const suburbsEntries = Object.entries(bsAsCabaNeighborhoods).map(([value, name]) => ({
    name,
    value
}));

/*Entries del multibox de barrios*/
export const runnersPropertyCategoryEntries = Object.entries(propertyCategories).map(([value, label]) => ({
    label,
    value
}));

/*Entries del multibox de barrios*/
export const brokersPropertyCategoryEntries = Object.entries(propertyCategories).map(([value, label]) => ({
    label,
    value
}));

/*Entries del multibox de barrios*/
export const brokersSuburbsEntries = Object.entries(bsAsCabaNeighborhoods).map(([value, label]) => ({
    label,
    value
}));

/*Filter Entries*/
export const contractOperationsEntriesFilter =
[
    {name: "Sin filtro", value: ""},
    {name: "Alquiler", value: "RENTAL"},
    {name: "Venta", value: "BUY"}
]

export const usersEntries =
[
    {name: "Sin filtro", value: ""},
    {name: "Admin", value: "Admin"},
    {name: "Broker", value: "Broker"},
    {name: "Agente", value: "Agente"},
    {name: "Inquilino", value: "Inquilino"},
    {name: "Propietario", value: "Propietario"}
]
export const suburbsFilter =
[
    {name: "Sin filtro", value: ""},

    ...Object.entries(bsAsCabaNeighborhoods).map(([key, name]) => ({
        name: name,
        value: key
    }))
]

/*Filtros de propiedades*/
export const roomsPropertiesFilter =
[
    {property: "rooms", label: "Dormitorios", value: "propertyRooms", operator: "EQ", logicOperator: "OR"},
    {property: "rooms", value: "1", label: "Monoambiente", operator: "EQ", logicOperator: "OR"},
    {property: "rooms", value: "2", label: "2 Ambientes", operator: "EQ", logicOperator: "OR"},
    {property: "rooms", value: "3", label: "3 Ambientes", operator: "EQ", logicOperator: "OR"},
    {property: "rooms", value: "4", label: "4 Ambientes", operator: "EQ", logicOperator: "OR"},
    {property: "rooms", value: "5", label: "5 Ambientes", operator: "EQ", logicOperator: "OR"},
    {property: "rooms", value: "5", label: "6 o + Ambientes", operator: "GT", logicOperator: "OR"},
]

export const suburbsPropertiesFilter = Object.entries(bsAsCabaNeighborhoods).map(([key, value]) => ({
    property: "address.suburb",
    label: value,
    value: key,
    operator: "EQ",
    logicOperator: "OR"
}));

suburbsPropertiesFilter.unshift({
    property: "address.suburb",
    label: "Ubicación",
    value: "propertyLocation",
    operator: "EQ",
    logicOperator: "OR"
});


export const operationsPropertiesFilter =
[
    {property: "propertyOperations.type", label: "Tipo de Operación", value: "propertyOperation", operator: "EQ", logicOperator: "OR"},
    {property: "propertyOperations.type", label: "Alquiler", value: "RENTAL", operator: "EQ", logicOperator: "OR"},
    {property: "propertyOperations.type", label: "Venta", value: "BUY", operator: "EQ", logicOperator: "OR"}
]
export const propertyTypePropertiesFilter =
[
    {property: "type", label: "Tipo de Propiedad", value: "propertyType", operator: "EQ", logicOperator: "OR"},
    {property: "type", label: "Casa", value: "HOUSE", operator: "EQ", logicOperator: "OR"},
    {property: "type", label: "Departamento", value: "BUILDING", operator: "EQ", logicOperator: "OR"},
    {property: "type", label: "Deposito", value: "DEPOSIT", operator: "EQ", logicOperator: "OR"},
    {property: "type", label: "Local Comercial", value: "STORE", operator: "EQ", logicOperator: "OR"},
    {property: "type", label: "Terreno", value: "LAND", operator: "EQ", logicOperator: "OR"},
    {property: "type", label: "Oficina", value: "OFFICE", operator: "EQ", logicOperator: "OR"},
    {property: "type", label: "Paseo de Compras", value: "SHOPPING_TRIP", operator: "EQ", logicOperator: "OR"},
    //{name: "Paseo comercial", value: "SHOPPING_WALK"}
]

export const propertyIconDetails =
{
    totalSquareMeters: "Superficie total m2",
    rooms: "Ambientes",
    bedrooms: "Dormitorios",
    bathrooms: "Baño",
    garage: "Cochera"
}