import axios from "axios";
import authHeaderService from "../../authHeaderService";
import {authorizationCheck, devLog} from "../../../globalHelper";

function pad(n) {
    return (n < 10) ? ("0" + n) : n;
}

export async function fetchTakingAndCollection(){

    let year = new Date().getFullYear();
    let month = new Date().getMonth()+1;
    const zeroMonth = pad(month);
    let lastDayMonth =  new Date(year,month, 0).getDate()
    let first = year+"-"+zeroMonth+"-01";
    let last = year+"-"+zeroMonth+"-"+lastDayMonth;
    const dateParam = "date1="+first+"&date2="+last;

    return await axios
    .get(
        `${process.env.REACT_APP_API_URL}/api/home/payments?`+dateParam,
        authHeaderService()
    )
    .then((res) => {
        return res.data;
    })
    .catch((e) => {
        if(e.response){
            devLog(true, e.response.status)
            authorizationCheck(e);
            return  e.response.data;
            }
        else{
            console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
        }
    });
  };
  export async function collectedController(id, value) {
    return await axios
        .put(
            `${process.env.REACT_APP_API_URL}/api/payments`,
            {
                "id": id,
                "isCollected": value,
                "isAccounted" : false        
            },
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}
export async function accountedController(id, value) {
    return await axios
        .put(
            `${process.env.REACT_APP_API_URL}/api/payments`,
            {
                "id": id,
                "isAccounted": value,
                "isCollected": true,
            },
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}

export async function updatePartialCollectedAmount(id,partialCollectedAmount) {
    return await axios
        .put(
            `${process.env.REACT_APP_API_URL}/api/payments`,
            {
                "id": id,
                "partialCollectedAmount": partialCollectedAmount
                
            },
            authHeaderService()
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            if(e.response){
                devLog(true, e.response.status)
                authorizationCheck(e);
                return  e.response.data;
                }
            else{
                console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
            }
        });
}

export async function getBookingContracts(){
    return await axios
    .get(
        `${process.env.REACT_APP_API_URL}/api/home/bookingcontracts`,
        authHeaderService()
    )
    .then((res) => {
        return res.data;
    })
    .catch((e) => {
        if(e.response){
            devLog(true, e.response.status)
            authorizationCheck(e);
            return  e.response.data;
            }
        else{
            console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
        }
    });
  };

  export async function getExpensesAddress(address){
    return await axios
    .put(
        `${process.env.REACT_APP_API_URL}/api/home/expenses`,
        address,
        authHeaderService()
    )
    .then((res) => {
        return res.data;
    })
    .catch((e) => {
        if(e.response){
            devLog(true, e.response.status)
            authorizationCheck(e);
            return  e.response.data;
            }
        else{
            console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
        }
    });
  };

  export async function getCommissions(commissionType){
    return await axios
    .get(
        `${process.env.REACT_APP_API_URL}/api/home/commissions?type=`+commissionType,
        authHeaderService()
    )
    .then((res) => {
        devLog(true, "[Binculo] Home - commisions > res.data:", res.data)
        return res.data;
    })
    .catch((e) => {
        if(e.response){
            devLog(true, e.response.status)
            authorizationCheck(e);
            return  e.response.data;
            }
        else{
            console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
        }
    });
  };

  export async function addNewExpense(expense) {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/expenses`,
            expense,
            authHeaderService()
        );
        return {
            error: false,
            data: res.data
        };
    } catch (e) {
        if(e.response){
            devLog(true, e.response.status)
            authorizationCheck(e);
            return  e.response.data;
            }
        else{
            console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
        }
        return {
            error: true,
            message: "Error al crear el gasto, revise los campos"
        };
    }
}