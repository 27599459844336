import "../../CSS/Reusable/ButtonStandard.scss";
import { getIcon } from "../../Services/iconService";

export default function ButtonStandard({ showName, onClick, iconName, float, largeButton, shortButton,buttonType}) {

  function getFloatPosition(){
    if (float){
      switch (float.toLowerCase()){
        case "left":{
          return " !mr-auto "
        }
        case "right":{
          return " !ml-auto "
        }
        case "center":{
          return " !mr-auto !ml-auto "
        }
      }
    }

  }
  function getButtonType() {
    switch (buttonType) {
        case 'white':
          return " white-button ";
          break;
        case 'blue':
            return " blue-button ";
            break;
        case 'secondBlue':
          return " bg-[#081382] hover:bg-[#121f9a] rounded-full border-0 shadow-md hover:shadow-xl shadow-[#0957CB]/30 text-white ";
          break;
        case 'greyGradient':
          return " gredientGrey-button ";
          break;
        case 'onlyText':
            return " bg-transparent text-[#5F585A] font-light border-0 text-base hover:bg-transparent hover:text-[#081382] hover:text-lg ";
            break;
        case 'locationButton':
          return " flex flex-row gap-x-4 py-6 bg-[#CDCED4] min-w-full lg:min-w-[280px] text-[white] font-bold text-lg border-0 shadow-md hover:shadow-xl";
          break;
        default:
            return " blue-button "
    }
  }

  function getSize (){
    return "".concat(largeButton?" large-button ":"",shortButton?" short-button ":"")
  }

  const buttonClasses = ''.concat(
    getFloatPosition(),
    getSize(),
    getButtonType()
  )

  return (
    <button className={"standard-button "+buttonClasses} onClick={onClick}>{showName}{iconName?getIcon(iconName):undefined}</button>)
}