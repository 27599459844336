import React from 'react';
import { useParams } from 'react-router-dom';
import useQuery from "../../Hooks/useQuery";
import ExportedProperty from './ExportedProperty';

function ExportedPropertyWrapper({internalLink}) {
  const { id } = useParams();
  const query = useQuery();
  const exporterName = query.get('exporterName');
  const exporterPhone = query.get('exporterPhone');
  const exporterMail = query.get('exporterMail');

  return <ExportedProperty id={id} exporterName={exporterName} exporterPhone={exporterPhone} exporterMail={exporterMail} internal={internalLink}/>;
}

export default ExportedPropertyWrapper;