import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";


// Components
import Grid from "../../Reusable/Grid";
import MyModal from "../../Reusable/MyModal";
import DataContainerCard from "../../Reusable/DataContainerCard";
import DataTable from "../../Reusable/DataTable/DataTable";
import CircularLoader from "../../Reusable/CircularLoader";
import ExpenseForm from "../../Reusable/ExpenseForm/ExpenseForm";

// Services
import {getContract} from "../../../Services/MainViews/Contracts/contractsService";
import {
  accountedController,
  collectedController,
  fetchTakingAndCollection,
  addNewExpense
} from "../../../Services/MainViews/Home/Home";

// Helpers
import {mapCurrencyName} from "../../../dataMappingUtilHelper";
import {formatedDate, existAndReturn, formatNumberWithDots, phoneFormateNumber} from "../../../globalHelper";

export default function CollectionAndAccounting() {

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState({show: false});
  const [responseModal, setResponseModal] = useState(false);
  const [changeState, setChangeState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [takingCollectionPageable, setTakingCollectionPageable] = useState([]);

  const [expenseModalForm, setExpenseModalForm] = useState({
    date: new Date()
})

// Función para manejar el envío del formulario del modal
const modalSubmit = async (e) => {
  e.preventDefault(); 

  let newData = { ...expenseModalForm };
  if (newData.date instanceof Date) {
      newData.date = newData.date.toISOString().substring(0, 10);
  }

  setIsSending(true);
  try {
    const res = await addNewExpense(newData);
    
    if (res && res.error) {
        alert(res.message || "Ocurrió un error al agregar el gasto.");
    } else if (res && !res.error) {
        alert("¡Gasto añadido con éxito!");
    } else {
        alert("Respuesta no esperada del servidor.");
    }
} catch (error) {
    console.error("Error al agregar el gasto:", error);
    alert("Ocurrió un error al agregar el gasto. Por favor, inténtalo de nuevo.");
} finally {
    setIsSending(false);
}
};

const fetchAccountedAndCollectedPaymentsSchedulesPayments = async () => {
  setIsLoading(true);
  let payments = null;
  payments = await fetchTakingAndCollection();
  if (payments && payments !==null && payments !==undefined && payments.status !== 500) {
    payments.map((payment, index) => {
      const { isAccounted, isCollected } = payment.payment;
      switch (true) {
        case isAccounted && isCollected:
          payment.status = "Finalizado";
          break;
        case !isAccounted && isCollected:
          payment.status = "Rendir";
          break;
        case isAccounted && !isCollected:
          payment.status = "Cobrar";
          break;
        default:
          payment.status = "";
      }
      payment.payment.isAccountedCheckBox = <input type="checkbox" id={payment.payment.id} value="isAccounted" checked={isAccounted} onChange={OnChangeCheckBox} />;
      payment.payment.isCollectedCheckBox = <input type="checkbox" id={payment.payment.id} value="isCollected" checked={isCollected} onChange={OnChangeCheckBox} />;
      payment.payment.amountAndCurrency = `${mapCurrencyName(payment.payment.currency)} ${formatNumberWithDots(payment.payment.amount)}`;
      payment.payment.paymentProgress = `${payment.payment.paymentNumber}/${payment.payment.totalPayments}`;
      payment.payment.expectedPaymentDate = formatedDate(payment.payment.expectedPaymentDate);
    
      if (payment.contract && payment.contract !== null && payment.contract !== undefined) {
        payment.contract.contractFinal.secondPartsEquity[0].user.cellPhone = phoneFormateNumber(payment.contract.contractFinal.secondPartsEquity[0].user.cellPhone)
        if (payment.contract.contractFinal !== null && payment.contract.contractFinal !== undefined) {
          payment.contract.contractFinal.secondPartsEquity[0].fullname = existAndReturn(payment, "contract.contractFinal.secondPartsEquity[0].user.name") + ' ' + existAndReturn(payment, "contract.contractFinal.secondPartsEquity[0].user.surname");
          payment.contract.contractFinal.ownersEquity[0].fullname = existAndReturn(payment, "contract.contractFinal.ownersEquity[0].user.name") + ' ' + existAndReturn(payment, "contract.contractFinal.ownersEquity[0].user.surname");
        } 
        payment.id = payments[index].contract.contractId;
      }
    });
  }
  setTakingCollectionPageable(payments?payments:[]);
  setIsLoading(false);
};

  useEffect(()  => {
    fetchAccountedAndCollectedPaymentsSchedulesPayments();
  }, [changeState])

  const OnChangeCheckBox = (e) => {
    const paymentValue = e.target.checked;
    const paymentLabel = e.target.value;
    const paymentId = Number(e.target.id);

    // Dependiendo de la etiqueta del checkbox, llamamos a la función correspondiente
    switch (paymentLabel) {
        case "isCollected":
            // Llamamos a la función para cambiar el estado de cobrado
            Promise.resolve(collectedController(paymentId, paymentValue))
                .then((res) => {
                    if (res.message) {
                        alert(res.message);
                        setIsSending(false);
                    } else {
                        setChangeState(!changeState);
                    }
                });
            break;
        case "isAccounted":
            // Llamamos a la función para cambiar el estado de rendido
            Promise.resolve(accountedController(paymentId, paymentValue))
                .then((res) => {
                    if (res.message) {
          
                        alert(res.message);
                        setIsSending(false);
                    } else {
                        setChangeState(!changeState);
                    }
                });
            break;
        default:
            break;
    }
}

  const actions = [
    {onClick: (e) => addExpense(e), showName: 'Añadir gasto'},
    {onClick: (e) => goToCalendar(e), showName: 'Ver en calendario'}
];

  const addExpense = async (id) => {
    let res = await getContract(id);
    setExpenseModalForm({
      ...expenseModalForm,
      contract: {
        "id" : res.id,
        "type" : res.type
    }
    });
    setShowModal({show: true})
  }

  const goToCalendar = (e) => {
    navigate("/calendar");
  }

  const modalChildren = <ExpenseForm  expenseModalForm={expenseModalForm} setExpenseModalForm={setExpenseModalForm}/>;

  const dataTableColumns = [
    {field: 'status', showName: 'Estado'},
    {field: 'payment.expectedPaymentDate', showName: 'Fecha'},
    {field: 'payment.paymentProgress', showName: 'Pago actual'},
    {field: 'contract.property.address.street', showName: 'Direccion'},
    {field: 'payment.amountAndCurrency', showName: 'Monto'},
    {field: 'contract.contractFinal.secondPartsEquity[0].fullname', showName: 'Inquilino'},
    {field: 'contract.contractFinal.secondPartsEquity[0].user.cellPhone', showName: 'Teléfono'},
    {field: 'payment.isCollectedCheckBox', showName: 'Cobrado'},
    {field: 'contract.contractFinal.ownersEquity[0].fullname', showName: 'Propietario'},
    {field: 'payment.isAccountedCheckBox', showName: 'Rendido'}
  ];

  return(
    <>

    <DataContainerCard tittle="Cobranzas y rendiciones">
        {isLoading?
        <CircularLoader/>:
        <Grid columns="1">
            <div  className="dataHomeCard">{takingCollectionPageable?<DataTable rows={takingCollectionPageable} columns={dataTableColumns} actions={actions}/>:null}</div>
        </Grid>
        }
        {<div  className={isSending ? 'UnabledButton' : ''}><MyModal modalState={showModal} children={modalChildren} className="ContractModalForm"
                                  modalSubmit={modalSubmit} responseModal={responseModal}/></div>}
    </DataContainerCard>
    </>
  )
}