import React, {useEffect, useState} from "react";
import {MultiSelect} from "react-multi-select-component";
import "../../CSS/Reusable/DataMultiSelectBox.scss";
import {setJsonFromDotedValue} from "../../globalHelper"
import {
    bsAsCabaNeighborhoods
} from "../../dataEntriesHelper"


export default function DataMultiSelectBox(props) {

    const columnSpan = props.columnSpan ? "md:col-span-" + props.columnSpan : null;

    const [selectedValues, setSelectedValues] = useState([]);

    useEffect(()=>{
        if(props.defaultValue){
            setSelectedValues(props.defaultValue);
            if (props.category) {
                let newDefaultValue = props.defaultValue.map(category=> {return {label:bsAsCabaNeighborhoods[category.suburb], value:category.suburb}})
                setSelectedValues(newDefaultValue)
            }
        }
    }, [props.defaultValue])

    const eventHandler = (event)=>{
        if(props.ChangeState)
        {
            if (props.category) {
                setSelectedValues(event)
                props.ChangeState(event,props.category)
            }
            else{
                setSelectedValues(event)
                props.ChangeState(setJsonFromDotedValue({},props.targetName, event))
            }
        }
        else{
            setSelectedValues(event)
        }
    }



    return (
        <div className={columnSpan}>
            {props.tittle ? <h3>{props.tittle}</h3> : null}
            <MultiSelect
                className="dataMultiSelectBox"
                options={props.entries}
                value={selectedValues}
                onChange={eventHandler}
                overrideStrings={{  
                "allItemsAreSelected": "Todos los items fueron seleccionados.",
                "clearSearch": "Limpiar busqueda",
                "clearSelected": "Limpiar seleccion",
                "noOptions": "No hay opciones",
                "search": "Busqueda",
                "selectAll": "Seleccionar todo",
                "selectAllFiltered": "Seleccionar todo (Filtrado)",
                "selectSomeItems": "Seleccionar...",
                "create": "Crear",
                }}
                labelledBy={"Seleccionar..."}/>
        </div>
    );
};
