import React from 'react';
//Helpers
import {mapPropertyServices, mapPropertyAmenities} from "../../dataMappingUtilHelper";

function AmenitiesServicesPropertyList({path, type}) {

  const getListType = (item) =>{
    if (type === 'amenities') {
      return mapPropertyAmenities(item)
    }
    else{
      return mapPropertyServices(item)
    }
  }

  return (
    <ul>
        {
        path.map((item,index)=>{
            return(
            <li key={index} className='text-[#858585] text-[1.25rem] lg:text-[1.563rem] font-normal'>{getListType(item)}</li>
            )
        })
        }
    </ul>
  );
}

export default AmenitiesServicesPropertyList;