import {getBookingContracts} from "../../../Services/MainViews/Home/Home";
import {fetchContracts, deleteContract} from "../../../Services/MainViews/Contracts/contractsService";
import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Grid from "../../Reusable/Grid";
import DoubleConfirmation from "../../Reusable/DoubleConfirmation";
import DataContainerCard from "../../Reusable/DataContainerCard";
import DataTable from "../../Reusable/DataTable/DataTable";
import {existAndReturn} from "../../../globalHelper"
import CircularLoader from "../../Reusable/CircularLoader";
import Pagination from "../../Reusable/PaginationList";
import BackgroudContainer from "../BackgroudContainer";
import ButtonBlue from "../../Reusable/ButtonBlue";

export default function ReservationRecords() {

  const [currentSelectedContractID, setCurrentSelectedContractID] = useState('');
  const [showSection, setShowSection] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [showModal, setShowModal] = useState({show: false});
  const [reservationsPageable, setReservationsPageablePageable] = useState([{
    content:[]
  }]);
    const navigate = useNavigate();

    let arrowSection;
    let searchesList;

    const filters =[{
      property: "type",
      value: "BookingContract",
      operator: "EQ",
      logicOperator: "AND"
  } 
  ];

  const getBookingsContracts = async (operations,page,size) => {
    setIsLoading(true);
    const reservationContracts = await fetchContracts(operations, page,size);
    if (reservationContracts && reservationContracts !==null && reservationContracts !==undefined && reservationContracts.status !== 500) {
      reservationContracts.content.map(bookingContract=>{
        bookingContract.property.owners[0].fullname = existAndReturn(bookingContract, "property.owners[0].name")+' '+existAndReturn(bookingContract, "property.owners[0].surname");
        bookingContract.contractBooking.bookingUser.fullname = existAndReturn(bookingContract, "contractBooking.bookingUser.name")+' '+existAndReturn(bookingContract, "contractBooking.bookingUser.surname");
        bookingContract.id = bookingContract.contractId
      })
      setReservationsPageablePageable(reservationContracts);
      setIsLoading(false);
    }
    else{
      console.log('Error fetching contracts or no contracts available');
      setReservationsPageablePageable([]);
      setIsLoading(false);
    }
  }

  useEffect(()  => {
    getBookingsContracts(filters,0,itemsPerPage);
  }, [])

  const dataTableColumns = [
    {field: 'contractBooking.bookingUser.fullname', showName: 'Nombre de quien genera la reserva'},
    {field: 'property.address.street', showName: 'Direccion'},
    {field: 'property.owners[0].fullname', showName: 'Propietario'},
    {field: 'property.owners[0].cellPhone', showName: 'Teléfono'}
  ];

  const delContract = (contractId) => {
    setShowModal({show: true});
    setCurrentSelectedContractID(contractId);
  }

  const finallyDelContract =(contractId)=>{
      Promise.resolve(deleteContract(contractId)).then( (res) => {
          if (res.message) {
              alert(res.message)          
          }
          else{
            getBookingsContracts(filters,0,itemsPerPage);
          }
      })
  }

  const edContract = (contractId,rowComponentType) => {
      if (rowComponentType ==="BookingContract") {
          navigate("/contracts/bookingContract/"+contractId);
      }
  }
  const actions = [
    { onClick: (e) => delContract(e), showName: 'Eliminar' },
    { onClick: (e,rowComponentType) => edContract(e,rowComponentType), showName: 'Editar' }
];


  if (showSection === true) {
      searchesList = 
      
      <div>
      <Grid columns="1">
          {isLoading?
            <CircularLoader/>:
            <div  className="dataHomeCard">{reservationsPageable?<DataTable rows={reservationsPageable?.content}  columns={dataTableColumns} actions={actions}/>:null}</div>
          }
          {<DoubleConfirmation
              modalState={showModal}
              children={'Si el contrato es eliminado, no podrá recuperarse. Deberás crearlo nuevamente'}
              className="ContractModalForm"
              modalSubmit={finallyDelContract}
              responseModal={responseModal}
              title={'¡CUIDADO ESTÁ POR ELIMINAR UN CONTRATO!'}
              currentSelectedID={currentSelectedContractID}
          />}
      </Grid>
      <div className="Properties_Pagination" style={{'display':'flex','justifyContent':'center'}}>
        <Pagination {...{ itemsPerPage,} }fetchData={fetchContracts} setList={getBookingsContracts} filters={filters} dataChanges={reservationsPageable}/>
      </div>
    </div>

  arrowSection='▴'
  }
  else{
      searchesList =''
      arrowSection='▾'
  }

    return(
      <>
      <DataContainerCard>
          <div    style={{'display':'flex',"justify-content":"space-between",'margin':'1rem 0px'}}>
              <h1>Historial de reservas</h1>
              <button onClick={()=>{setShowSection(!showSection)}}  style={{'fontSize':'2rem'}}>{arrowSection}</button>
          </div>
          {
            searchesList
            }
      </DataContainerCard>
      </>
    )
  }