import React, { useEffect, useState } from 'react';
import "../../CSS/GeneralStructure/NavBar.scss";
import logoBaredes from "../../Images/BaredesLogo2.svg";
import profileImage from "../../Assets/profileImages/user-standard-profile.png";
import alertIcon from "../../Assets/Icons/alerts-icon.svg";
import { NavLink } from "react-router-dom";
import { getIcon } from "../../Services/iconService";
import {  checkBackendHealth } from "../../Services/backendHealth";
import { useSelector } from "react-redux";

export default function NavBar() {
  const user = useSelector((state) => state.reducerLoginAuth.user);
  const [showVersion, setShowVersion] = useState(false);
  const [backendVersion, setBackendVersion] = useState('3.1.5');
 
  const frontendVersion = process.env.REACT_APP_FRONTEND_VERSION;

  const checkBackendVersion = async () =>{
    const backendHealth = await checkBackendHealth();
    if (backendHealth.components?.custom?.details?.version) {
      setBackendVersion(backendHealth.components.custom.details.version)
    }
  }

  useEffect(() => {
    console.log('v:'+frontendVersion);
    
    checkBackendVersion()
    const url = window.location.href;

    if (url.includes('localhost')) {
      setShowVersion(true);
    }

    if (url.includes('sandbox')) {
      setShowVersion(true);
    }
  }, []);

  return (
    <nav className='flex flex-row p-4 lg:px-12 lg:py-6 justify-between border-2'>
      <div className='flex flex-col items-center max-w-[45%]'>
        <NavLink to="/home">
          <img  src={logoBaredes} alt="Binculo" />
        </NavLink>
        {showVersion?
          <span className="text-xs text-neutral-800/35 font-bold my-auto">front-{frontendVersion} / back-{backendVersion}</span>:''
        }
      </div>
      <div className="flex flex-row space-x-2 lg:space-x-4 items-center">
        <div className="text-[#7F8191] font-semibold text-base hidden lg:flex">
            {user?.name} {user?.surname}
        </div>
      </div>
    </nav>
  );
}
