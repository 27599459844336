import "../../CSS/Reusable/DataInput.scss";
import {useEffect, useRef, useState} from "react";
import {setJsonFromDotedValue} from "../../globalHelper"

export default function AutoCompleteInput({placeHolder, showAttributes, onChange, tittle, entries, columnSpan,targetName,ChangeState, defaultValue,targetIndex,classname, onlyReadable}) {

    const ref = useRef()

    const colSpan = columnSpan ? "md:col-span-" + columnSpan : null;

    const [data, setData] = useState(defaultValue);

    const [isEntrySelected, setIsEntrySelected] = useState(false);

    const [selectedEntry, setSelectedEntry] = useState(false);

    const [_entries, _setEntries] = useState([]);

    let inputClasses ="";

    inputClasses = inputClasses.concat(
        " dataInput "
        ,onlyReadable ? " onlyReadable " : ""
    );


    let classNameAutoCompleteInput = "";

    classNameAutoCompleteInput = classNameAutoCompleteInput.concat(
        " "
        ,colSpan
        ,classname?classname:''
    );


    useEffect(()=>{
        if(defaultValue){
            setData(formatValueByShowAttributesList(defaultValue));
            setSelectedEntry(defaultValue);
            setIsEntrySelected(true);
        }
    }, [defaultValue])

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (!isEntrySelected && ref.current && !ref.current.contains(e.target)) {
                if (_entries.length > 0) {
                    setData(formatValueByShowAttributesList(_entries[0]));
                    setSelectedEntry(_entries[0]);
                    setIsEntrySelected(true);
                }
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isEntrySelected])

    useEffect((e) => {
        if (entries)
            _setEntries(entries);
    }, []);

    useEffect(() => {
        cleanData();
    }, [data]);

    const handleListClick = (e) => {
        if(ChangeState){
            setData(formatValueByShowAttributesList(e));
            ChangeState(setJsonFromDotedValue({},targetName, e),targetIndex)
            setSelectedEntry(e);
            setIsEntrySelected(true);
        }
        else{
            setData(formatValueByShowAttributesList(e));
            setSelectedEntry(e);
            setIsEntrySelected(true);
        }
    }

    const handleInputChange = (e) => {
        setData(e.target.value);
        setIsEntrySelected(false);
        if (onChange) {
            Promise.resolve(onChange(e.target.value)).then(values => {
                _setEntries(values);
            })
        }
    }

    const cleanData = () => {
        if (!data)
            setIsEntrySelected(true);
    }
    if (!data && defaultValue !== null) {
        ChangeState(setJsonFromDotedValue({},targetName, null),targetIndex)
    }

    const formatValueByShowAttributesList = (obj) => {
        if (showAttributes) {
            return showAttributes.map(attr => {
                return obj[attr]?obj[attr] : "" + " ";
            })
        }
        return obj;
    }

    return (
        <div className={classNameAutoCompleteInput} ref={ref}>
            {tittle ? <h3>{tittle}</h3> : null}
            <input value={data} onChange={(e) => handleInputChange(e)} placeholder={placeHolder} className={inputClasses}/>
            {_entries.length > 0 && !isEntrySelected ?
                (<div className="border-2 absolute bg-white z-10 relative overflow-y-scroll min-h-[8rem]">
                    {_entries.map((elem, i) => {
                        return (
                            <label key={elem.id} id={elem.id} className="grid grid-flow-col auto-cols-max"
                                   onClick={() => handleListClick(elem)}>
                                {formatValueByShowAttributesList(elem)}
                            </label>
                        )
                    })}
                </div>) : null}
        </div>
    )

}