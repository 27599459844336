import React from 'react';

function MultimediaButtonWrapper({children}) {

  return (
    <div className='grid grid-cols-2 gap-2 lg:flex lg:flex-row lg:items-end lg:space-y-3 lg:space-x-3 lg:space-y-0'>
        {children}
    </div>
  );
}

export default MultimediaButtonWrapper;