import React, {useEffect, useState} from 'react';
import DataContainerCard from "../DataContainerCard"
import DropZoneFiles from "../DropZoneFiles"
import "../../../CSS/Reusable/PropertyForm.scss";
import Grid from "../Grid"
import YoutubePlayer from "../YoutubePlayer";
import DataInput from "../DataInput"
import ButtonStandard from "../ButtonStandard";
import LazyImage from "../LazyImage";
import {getIcon} from "../../../Services/iconService";
import {existAndReturn} from "../../../globalHelper"
import {fetchContacts} from "../../../Services/MainViews/Users/contactsService";

export default function PropertyFormStep2({
                                            propertyForm,
                                            setPropertyForm,
                                            onInputChange,
                                            params
                                        }) {

    const [showVideo, setShowVideo] = useState(propertyForm.video);

    useEffect(() => {
        if (params.id) {
            if (propertyForm.video) {
                setShowVideo(true)
            }
        }
      }, []);
                                         
    const deleteImages = (e,OPindex) =>  {
        e.preventDefault();
        setPropertyForm(prev => {
            const images = propertyForm.images.filter((_, index) => index !== OPindex);
            return {
              ...prev,
              images
            }
          })
    }
    const setVideo =()=>{
        const youtubeRegex = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/;
        let newURLVideo = existAndReturn(propertyForm, "videoURL");
        if (newURLVideo.match(youtubeRegex) !== null) {
            setPropertyForm(prev => {
                return {
                  ...prev,
                  video:{urlPath:newURLVideo}
                }
              })
              setShowVideo(true);
        }
        else{
            alert('El link del video debe pertenecer a YouTube')   
        }
    }

    const deleteVideo =(e)=>{
        e.preventDefault();
        delete propertyForm.video;
        setShowVideo(false);
    }
    const showVideoFn =()=>{
        if (showVideo) {
            return <div    style={{'display':'flex','justifyContent':'center'}}>
                        <div className="img-container">
                            <button className="delete-image-icon-button"    onClick={(e) => deleteVideo(e)}>
                                <div className="delete-image-icon">{getIcon("deleteX")}</div>
                            </button>
                            <div    style={{display: existAndReturn(propertyForm, "video.urlPath") ? 'block' : 'none' }}><YoutubePlayer videoURL={existAndReturn(propertyForm, "video.urlPath")} /></div>
                        </div>
                    </div>
        }
    }

    const handleFilterContacts = (names, type) => {
        let fields = names.split(" ");
        let searchContactDTO = {};
        searchContactDTO.types =[type];
        if (fields.length === 1)
            searchContactDTO.fieldOne = fields[0];
        if (fields.length === 2) {
            searchContactDTO.fieldOne = fields[0];
            searchContactDTO.fieldOne = fields[1];
        }

        if (fields.length === 3) {
            searchContactDTO.fieldOne = fields[0];
            searchContactDTO.fieldOne = fields[1];
            searchContactDTO.fieldOne = fields[2];
        }
        return fetchContacts(searchContactDTO);
    }

    
    return (
        <>
        <DataContainerCard tittle="FOTOS">
            <Grid columns="1">
            <DropZoneFiles form={propertyForm} setForm={setPropertyForm} type='image'/>
            </Grid>
            <Grid columns="4" containerType="image-grid">
            {
                propertyForm.images?.map((item,index)=>{
                    return(
                        <div key={index} className="img-container">
                            <button className="delete-image-icon-button"    onClick={(e) => deleteImages(e,index)}>
                                <div className="delete-image-icon">{getIcon("deleteX")}</div>
                            </button>
                            <LazyImage src={item.base64Code ? item.base64Code: item.urlPath} height="200px"  width="200px" className="image-gallery"/>
                        </div>
                    )
                })
            }
            </Grid> 
        </DataContainerCard>
        <DataContainerCard tittle="VIDEOS"> 
            <Grid columns="3">
            <DataInput tittle="URL"
                        placeHolder="URL"
                        columnSpan={2} ChangeState={onInputChange} targetName={"videoURL"}
                        defaultValue={existAndReturn(propertyForm, "videoURL")}/>
            <div    style={{"alignSelf":"end","paddingRight":"4rem"}}><ButtonStandard showName="Añadir video" float="right" onClick={setVideo} blueColor/></div>
            </Grid>
            <Grid columns="1">
                {showVideoFn()}
            </Grid>
        </DataContainerCard>
        </>
    )
}
