import "../../../CSS/MainViews/Contacts.scss";
import ButtonStandard from "../../Reusable/ButtonStandard";
import { useNavigate } from "react-router-dom";
import BackgroudContainer from "../BackgroudContainer";
import {Link} from "react-router-dom";
import Grid from "../../Reusable/Grid";
import DataContainerCard from "../../Reusable/DataContainerCard";
import CircularLoader from "../../Reusable/CircularLoader";
import DataTable from "../../Reusable/DataTable/DataTable";
import DataInput from "../../Reusable/DataInput";
import DataSelectBox from "../../Reusable/DataSelectBox";
import {fetchSearches, fetchBrokerSuburbs} from "../../../Services/MainViews/Properties/searchesService";
import {mapCurrencyName, mapOperationType2, mapPropertyType} from "../../../dataMappingUtilHelper"
import {contractOperationsEntriesFilter, bsAsCabaNeighborhoods} from "../../../dataEntriesHelper"
import {formatedDate, existAndReturn, formatNumberWithDots} from "../../../globalHelper"
import {useEffect, useState} from "react";
import { logDOM } from "@testing-library/react";


export default function BrokerRunnerSearches(){

    const [keyword, setKeyword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [operationType, setOperationType] = useState('');
    const [currentSearches, setCurrentSearches] = useState([]);
    const navigate = useNavigate();

    const getSearches = async () => {
        setIsLoading(true);
        let brokerSuburbs = await fetchBrokerSuburbs();
        let unifiedBrokerSuburbs = [];
        let suburbSet = new Set();
        
        brokerSuburbs.forEach((category) => {
          category.suburbs.forEach((suburb) => {
            if (!suburbSet.has(suburb.suburb)) {
              suburbSet.add(suburb.suburb);
              unifiedBrokerSuburbs.push(suburb.suburb);
            }
          });
        });
        Promise.resolve(fetchSearches()).then(searches => {
            if (searches) {
                searches = searches.filter(function(itm){
                    return unifiedBrokerSuburbs.indexOf(itm.suburbs[0]) > -1;
                });
                searches.map(search =>{
                    search.date =  formatedDate(existAndReturn(search, "date"));
                    search.operationType =  mapOperationType2(existAndReturn(search, "operationType"));
                    search.propertyType = mapPropertyType(existAndReturn(search, "propertyType"));
                    if (existAndReturn(search, "minimumValue.value") === undefined || existAndReturn(search, "minimumValue.value") === null || existAndReturn(search, "minimumValue.currency") === undefined || existAndReturn(search, "minimumValue.currency") === null) {
                        search.completeMinPrice = "Sin definir"
                    }
                    else{
                        search.completeMinPrice = mapCurrencyName(existAndReturn(search, "minimumValue.currency"))+" "+formatNumberWithDots(existAndReturn(search, "minimumValue.value"));
                    }
                    if (existAndReturn(search, "maximumValue.value") === undefined || existAndReturn(search, "maximumValue.value") === null || existAndReturn(search, "maximumValue.currency") === undefined || existAndReturn(search, "maximumValue.currency") === null) {
                        search.completeMaxPrice = "Sin definir"
                    }
                    else{
                        search.completeMaxPrice = ""+mapCurrencyName(existAndReturn(search, "maximumValue.currency"))+" "+formatNumberWithDots(existAndReturn(search, "maximumValue.value"));
                    }
                    search.suburbMapped = bsAsCabaNeighborhoods[search.suburbs[0]];
                })
                setCurrentSearches(searches);
                setIsLoading(false);
            } 
        })
        .catch((e)=>{
            console.log(e);
            setCurrentSearches([])
            setIsLoading(false);
          })
    }

    useEffect(() => {
        getSearches();
        const interval = setInterval(()=>{
          getSearches()
        },30 * 10000);


        return()=>clearInterval(interval)
    }, [])

    const keywordChange = (jsonData)  =>{
        let keywordValue = Object.values(jsonData)[0];
        setKeyword(keywordValue);
    }
    const userTypeChange = (jsonData)  =>{
        let operationTypeValue = Object.values(jsonData)[0];
        if (operationTypeValue !== '') {
            setOperationType(mapOperationType2(operationTypeValue));
        }
        if (operationTypeValue === '') {
            setOperationType(operationTypeValue);
        }
        
    }

    const dataTableColumns = [
        {field: 'date', showName: 'Fecha'},
        {field: 'suburbMapped', showName: 'Barrio'},
        {field: 'operationType', showName: 'Tipo de operacion'},
        {field: 'propertyType', showName: 'Tipo de propiedad'},
        {field: 'completeMinPrice', showName: 'Precio minimo'},
        {field: 'completeMaxPrice', showName: 'Precio maximo'},
        {field: 'description', showName: 'Descripcion'},
    ];

    const filteredSearches = currentSearches.filter(x => x.suburbs[0]).filter(x => x.suburbs[0].includes(keyword)).filter((item) => {
        if (operationType !=='') {
            return (item.operationType.indexOf(operationType) >= 0);
        }
        if (operationType ==='') {
            return (item);
        }
    });

    return (
        <>
        <BackgroudContainer>
            <div    style={{'display':'flex','justifyContent': 'space-between','width': '100%',"alignItems":"center"}}>
                <div    style={{'display':'flex',"alignItems":"start"}}>
                    <DataInput tittle="Nombre del barrio" type="Text" ChangeState={keywordChange}
                                                    targetName={"keyword"}
                                                    defaultValue={''}   float="left"/>
                    <DataSelectBox tittle="Tipo contrato" entries={contractOperationsEntriesFilter} ChangeState={userTypeChange}
                                targetName={"userType"}
                                defaultValue={''}/>
                </div>
            </div>
            <h1 style={{"fontSize":"2rem"}}>LISTA DE BUSQUEDAS</h1>
            <DataContainerCard tittle="BUSQUEDAS">
                {isLoading?
                <CircularLoader/>:
                <Grid columns="1">
                    {filteredSearches?<DataTable rows={filteredSearches} columns={dataTableColumns} />:null}
                </Grid>
                }
            </DataContainerCard>
        </BackgroudContainer> 
        </>
    );
}
