import "../../CSS/MainViews/Properties.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProperties } from "../../Services/MainViews/Properties/propertiesService";
import { getProperties, resetFilters } from "../../Redux/Actions/actionsProperties";
import { addConditionalLogic } from "../../Services/filterService";
import PropertiesFilters from "../Reusable/PropertiesFilters";
import CircularLoader from "../Reusable/CircularLoader";
import Property from "../Reusable/Property";
import Pagination from "../Reusable/Pagination";
import SearchBar from "../Reusable/SearchBar";
import { useNavigate } from "react-router-dom";
import ButtonStandard from "../Reusable/ButtonStandard";
import PropertiesMap from "../Reusable/PropertiesMap";

export default function Home() {
  const [showMap, setShowMap] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clearFilters, setClearFilters] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const properties = useSelector((state) => state.reducerProperties.properties?state.reducerProperties.properties:[]);
  const [propertiesPerPage, setPropertiesPerPage] = useState(3);
  const [propertiesFilterList, setPropertiesFilterList] = useState({ filtersRequest: [] });
  const filters = useSelector((state) => state.reducerProperties.filters);


  const firstLoadOperations = [{
    "property": "isAvailable",
    "value": true,
    "operator": "EQ",
    "logicOperator": "AND"
  }];

  const getAndSetProperties = async (operations, page,size)=>{
    setIsLoading(true);
    let properties = null;
    properties = await fetchProperties(operations, page, size);

    if (properties && properties !== null && properties !== undefined) {
      properties.content = properties.content.filter(property => property.isAvailable);
      dispatch(getProperties(properties));
      setIsLoading(false);
    }
    else{
      console.log('Error fetching properties or no properties available');
      dispatch(getProperties([]));
      setIsLoading(false);
    }
  }

  useEffect(() => {

    dispatch(resetFilters());
    getAndSetProperties(firstLoadOperations, properties?.number, propertiesPerPage)
  }, [dispatch, properties?.number, propertiesPerPage]);

  function handleSubmit(e) {
    e.preventDefault();
    addConditionalLogic(filters);
    if (filters.length > 0) {
      getAndSetProperties(filters, properties?.number, propertiesPerPage)
    } else {
      getAndSetProperties([{ ...firstLoadOperations[0] }], properties?.number, propertiesPerPage)
    }
  }

  function handleChangePages(e) {
    e.preventDefault();
    setPropertiesPerPage(e.target.value);
    getAndSetProperties([{ ...firstLoadOperations[0] }], 0, e.target.value)
  }

  function handleReset(e) {
    e.preventDefault();
    dispatch(resetFilters());
    checkboxList.forEach((checkbox) => (checkbox.checked = false));
    getAndSetProperties([{ ...firstLoadOperations[0] }], properties?.number, propertiesPerPage)
    setClearFilters(!clearFilters);
  }

  const checkboxList = Array.from(document.getElementsByClassName("SelectBoxFilter_checkbox"));

  return (
    <div className="Properties">
      <div className="properties-header px-4 sm:px-6 lg:px-0">
        {/*<div id="searchBar" className="!ml-0 flex items-start justify-start"><SearchBar placeholder="Buscar" /></div>*/}
        <div id="newSearch"><ButtonStandard showName="+ Nueva busqueda" onClick={() => navigate('/searches/new')} largeButton gradientGreyColor /></div>
      </div>
      <div className="properties-header px-4 sm:px-6 lg:px-0">
        <div id="addFilter"><ButtonStandard showName="Aplicar Filtros" onClick={(e) => handleSubmit(e)} largeButton /></div>
        <div id="clearFilter"><ButtonStandard showName="Limpiar Filtros" onClick={(e) => handleReset(e)} largeButton /></div>
        <div id="newProperty"><ButtonStandard showName="+ Nueva propiedad" onClick={() => navigate('/properties/new')} blueColor largeButton /></div>
      </div>
      <div className="properties-header">
        <PropertiesFilters propertiesFilterList={propertiesFilterList} setPropertiesFilterList={setPropertiesFilterList} clearFilters={clearFilters} />
      </div>
      <div className="Properties_Pagination px-4 sm:px-6 lg:px-0">
        <Pagination {...{ propertiesPerPage, properties, filters }} />
        <div>
          Propiedades por página:{" "}
          <select onChange={(e) => handleChangePages(e)}>
            <option value="3">3</option>
            <option value="6">6</option>
            <option value="12">12</option>
          </select>
        </div>
      </div>
      {isLoading ?
        <CircularLoader /> :
        <div className="Properties_Bottom">
          <div className={showMap ? "Properties_Table  justify-items-start content-center halfWidth px-4 sm:px-6 lg:px-0" : "Properties_Table justify-items-start content-center px-4 sm:px-6 lg:px-0 "}>
            {properties?.content?.map((prop) => (
              <Property {...{ ...prop, showMap }} key={prop.id} />
            ))}
          </div>
          <div>
            {showMap && <PropertiesMap properties={properties?properties:[]} />}
          </div>
        </div>
      }
    </div>
  );
}