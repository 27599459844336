import BackgroudContainer from "../BackgroudContainer";
import Grid from "../../Reusable/Grid";
import DataContainerCard from "../../Reusable/DataContainerCard";
import {useEffect, useState} from "react";
import {getContractByIdForOwner, getExpensesByContractId} from "../../../Services/MainViews/TenantOwner/tenantOwnerService";
import DataInput from "../../Reusable/DataInput";
import {existAndReturn, formatedDate, fixSystemDate, formatNumberWithDots} from "../../../globalHelper"
import {mapCurrencyName} from "../../../dataMappingUtilHelper"
import ContractPayments from "../../Reusable/ContractForm/ContractPayments";
import CircularLoader from "../../Reusable/CircularLoader";
import {useNavigate, useParams} from "react-router-dom";
import ButtonBlue from "../../Reusable/ButtonBlue";
import ButtonStandard from "../../Reusable/ButtonStandard";
import DataTable from "../../Reusable/DataTable/DataTable";
import TimeLine from "../../Reusable/TimeLine";
import DataDayPicker from "../../Reusable/DataDayPicker"
import ButtonSelector from "../../Reusable/ButtonSelector";

export default function OwnerContract() {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const params = useParams();

    const [contract, setContract] = useState();

    const [expenses, setExpenses] = useState([]);

    const expensesColumns = [
        {field: 'date', showName: 'Dia'},
        {field: 'description', showName: 'Descripción'},
        {field: 'amountAndCurrency', showName: 'Monto'}
    ];

    const fetchData = () => {
        setIsLoading(true);
        Promise.resolve(getContractByIdForOwner(getContractId())).then(Contract => {
            if (Contract !== undefined && Contract !== null) {
                Contract.paymentSchedule.payments.map(payment=>{
                    payment.currency =  mapCurrencyName(payment.currency);
                    payment.expectedPaymentDate = fixSystemDate(payment.expectedPaymentDate);
                })
                Contract.paymentSchedule.firstPaymentStartDate =  formatedDate(Contract.paymentSchedule.firstPaymentStartDate);
                Contract.paymentSchedule.lastPaymentEndDate =  formatedDate(Contract.paymentSchedule.lastPaymentEndDate);
                setContract(Contract);
                setIsLoading(false);
            }
        })
        Promise.resolve(getExpensesByContractId(getContractId())).then(expenses  => {
            if (expenses  !== undefined && expenses  !== null) {
                expenses .map(expense=>{
                    expense.date = formatedDate(existAndReturn(expense, "date"));
                    expense.amountAndCurrency = ''+mapCurrencyName(existAndReturn(expense, "value.currency"))+' '+formatNumberWithDots(existAndReturn(expense, "value.value"));
                })
                setExpenses(expenses);
                setIsLoading(false);
            }
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    const operationActions  = [
        {onClick: (e) => showWarranty(e), showName: 'Ver garantia'},
        {onClick: (e) => showContract(e), showName: 'Ver contrato' }
    ];

    const showWarranty =()=>{
        if (existAndReturn(contract, "contractFinal.pdfWarranty.urlPath")) {
            window.open(existAndReturn(contract, "contractFinal.pdfWarranty.urlPath"), '_blank').focus();
        }
        else{
            alert('No hay garantia en pdf')
        }
    }

    const showContract =()=>{
        if (existAndReturn(contract, "contractFinal.pdfContract.urlPath")) {
            window.open(existAndReturn(contract, "contractFinal.pdfContract.urlPath"), '_blank').focus();
        }
        else{
            alert('No hay contrato en pdf')
        }
        
    }

    const getContractId = () => {
        return params.id
    }

    const navigateToTenantOwnerContractHome = () => {
        navigate(`/addresses`)
    }

    const showOservations =()=>{
        if (contract !== undefined && contract.observations.length > 0) {
            return  contract.observations.map((item, index) => {
                let showIndex = index+1;
                return (
                    <DataContainerCard tittle={'#'+showIndex} className="ContractForm">
                    <Grid columns="2">
                        <DataDayPicker tittle="Fecha"   placeHolder="Ingrese la fecha de la observacion"
                                                        targetName={'observations['+index+'].date'}
                                                        defaultValue={new Date(item.date)}
                                                        onlyReadable/>
                    </Grid>
                    <Grid columns="1">
                        <DataInput bigInput type="Text" tittle="Observaciones"  
                                                        targetName={'observations['+index+'].description'}
                                                        defaultValue={item.description}
                                                        onlyReadable/>
                    </Grid>
                </DataContainerCard>
                );
            })
        }
        else{
            return  <label   className="ml-auto mr-auto label-whitout-data"><h1>No hay datos aún.</h1></label>
        }
    }

    return (
        <BackgroudContainer>

            <div className="flex flex-row justify-between w-full"> 
                <ButtonStandard showName="< &nbsp; &nbsp; Atrás" onClick={navigateToTenantOwnerContractHome} buttonType='onlyText'/>
                <ButtonSelector showName="Documentos"    actions={operationActions}  float="right"   buttonType='secondBlue'/>
            </div>
            <DataContainerCard tittle="DATOS DEL CONTRATO" centerTittle cardType="special" plegable>
                {isLoading?
                <CircularLoader/>:
                <Grid columns="4">
                    <DataInput onlyReadable tittle="Fecha de Inicio" type="Text"
                               defaultValue={existAndReturn(contract,"paymentSchedule.firstPaymentStartDate")}/>
                    <DataInput onlyReadable tittle="Fecha de Finalización" type="Text"
                               defaultValue={existAndReturn(contract,"paymentSchedule.lastPaymentEndDate")}/>
                    <DataInput onlyReadable tittle="Dirección" type="Text"
                               defaultValue={existAndReturn(contract,"property.address.street")}/>
                    <DataInput onlyReadable tittle="Unidad" type="Text"
                               defaultValue={existAndReturn(contract,"property.address.houseNumber")}/>
                </Grid>
                }
                {isLoading?
                <CircularLoader/>:
                <Grid columns="3">
                    <DataInput onlyReadable tittle="Periodo de tiempo" type="Text"
                               defaultValue={existAndReturn(contract,"paymentSchedule.totalNumberOfPayments")}/>
                    <DataInput onlyReadable tittle="Aumento cada (en meses)" type="Text"
                               defaultValue={existAndReturn(contract,"paymentSchedule.increaseEveryXMonths")}/>
                    <DataInput onlyReadable tittle="Aumento %" type="Text"
                               defaultValue={existAndReturn(contract,"paymentSchedule.increaseEveryXMonthsPercentage")}/>
                </Grid>
                }
                {isLoading?
                <CircularLoader/>:
                <Grid columns="3">
                    <DataInput onlyReadable tittle="Llave" type="Text"
                               defaultValue={existAndReturn(contract,"key.value")}/>
                    <DataInput onlyReadable tittle="Alquiler inicial" type="Text"
                               defaultValue={existAndReturn(contract,"paymentSchedule.initialAmount.value")}/>
                    <DataInput onlyReadable tittle="Mes de gracia" type="Text"
                               defaultValue={existAndReturn(contract,"paymentSchedule.daysOfGrace")}/>
                </Grid>
                }
            </DataContainerCard>
            <DataContainerCard tittle="LINEA DE TIEMPO" centerTittle cardType="special">
                {isLoading?
                <CircularLoader/>:
                <TimeLine payments={existAndReturn(contract,"paymentSchedule.payments")}/>
                }
            </DataContainerCard>
            <DataContainerCard tittle="TABLA DE RENDICIONES" centerTittle cardType="special">
                {isLoading?
                <CircularLoader/>:
                <ContractPayments showAmounts showServices={false}
                                  payments={existAndReturn(contract,"paymentSchedule.payments")}/>
                }
            </DataContainerCard>

            <DataContainerCard tittle="GASTOS" centerTittle cardType="special">
                {isLoading?
                <CircularLoader/>:
                <DataTable rows={expenses} columns={expensesColumns}/>
                }
            </DataContainerCard>

            <DataContainerCard tittle="SERVICIOS ACTUALIZADOS" centerTittle cardType="special">
                {isLoading?
                <CircularLoader/>:
                <ContractPayments showServices={true} showAmounts={false}
                                  payments={existAndReturn(contract,"paymentSchedule.payments")}/>
                }
            </DataContainerCard>

            <DataContainerCard tittle="OBSERVACIONES" centerTittle cardType="special">
                {isLoading?
                <CircularLoader/>:
                showOservations()
                }
            </DataContainerCard>
        </BackgroudContainer>
    );
}
