export const obtainLoggedUser = (user) => {

    return {
        type: "SET_LOGGED_USER",
        payload: user,
    };
};

export function logout() {
    return (dispatch) => {
        dispatch({
            type: "LOGOUT",
        });
    };
}
