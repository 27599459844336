import { useState, useEffect } from "react";
import "../../CSS/Reusable/PriceFilter.scss";
import { filterProperties } from "../../Redux/Actions/actionsProperties";
import { useDispatch } from "react-redux";
import "../../CSS/Reusable/SelectBoxFilter.scss";
import ButtonWhite from "./ButtonWhite";

export default function SelectBoxFilter({ filterComponent, filterName, onChangeFunction, usedForForm, entries, clearFilters, currentFilters = [] }, ref) { // Valor por defecto

  const dispatch = useDispatch();
  const [propertyFilter, setPropertyFilter] = useState(entries);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    setSelectedOption("")
  }, [clearFilters]); //eslint-disable-line

  function handleChange(e) {
    const value = e.target.value;
    setSelectedOption(value);

    const selectedFilter = propertyFilter.find(filter => filter.value === value);

    const newFilter = {
      property: filterName,
      value: value,
      operator: selectedFilter.operator
    };

    dispatch(filterProperties(newFilter));
  }

  return (
    propertyFilter && (
      <div className='slider-filter-container'>
        <label htmlFor={filterName}></label>
        <select id={filterName} name={filterName} value={selectedOption} onChange={handleChange} className="slider-button-filter text-center">
          <option value="" disabled>{propertyFilter[0].label}</option>
          {propertyFilter.map(
            (option, i) =>
              i !== 0 && (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              )
          )}
        </select>
      </div>
    )
  );
}