import "../../CSS/Reusable/Pagination.scss";
import { getIcon } from "../../Services/iconService";
import {useEffect, useState} from "react";

export default function Pagination({ itemsPerPage, filters, fetchData, setList, dataChanges}) {
  const [dataList, setDataList] = useState('');
  const pageNumbers = Array.from(Array(dataList?.totalPages), (_, i) => i + 1);
  let filtersToApply = filters.length > 0 ? filters : null;

  const getData = async()=>{
    const data = await fetchData(filters,0,itemsPerPage);
    setDataList(data);
  }

  useEffect(() => {
    getData();
    
}, [dataChanges])

  function handleClick(e) {
    fetchData(filtersToApply, e.target.id - 1, itemsPerPage).then(
      (res) => {
        setDataList(res)
      }
    );
    setList(filtersToApply, e.target.id - 1, itemsPerPage);
  }

  function handlePrevNext(e) {
    console.log(e);
    let pageToApply = e.target.parentNode.name === "Previous"? dataChanges.number - 1: dataChanges.number + 1;

    if (dataChanges.number < dataList.totalPages && dataChanges.number >= 0) {
      fetchData(filtersToApply, pageToApply, itemsPerPage).then(
        (res) => {
          setDataList(res)
        }
      );
      setList(filtersToApply, pageToApply, itemsPerPage);
      
    }
  }

  return (
    <>
      {dataChanges && !Array.isArray(dataChanges) ? (
        <div className="Pagination">
          <button
            onClick={(e) => handlePrevNext(e)}
            name="Previous"
            className={
              dataChanges.first ? "PrevNextPage disabled" : "PrevNextPage"
            }
            disabled={dataChanges.first}
          >
            {getIcon("pagination_leftarrow",'#081382')}
          </button>
          {pageNumbers?.map((number) => (
            <button
              key={number}
              id={number}
              onClick={(e) => handleClick(e)}
              className={
                number === dataChanges.number + 1 ? "Page active" : "Page"
              }
            >
              {number}
            </button>
          ))}
          <button
            onClick={(e) => handlePrevNext(e)}
            name="Next"
            className={
              dataChanges.last ? " PrevNextPage disabled " : " PrevNextPage !bg-[#E5E6E8]  !shadow-md"
            }
            disabled={dataChanges.last}
          >
            {getIcon("pagination_rightarrow",'#081382')}
          </button>
        </div>
      ) : (
       ''
      )}
    </>
  );
}
