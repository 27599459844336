import DataContainerCard from "../DataContainerCard"
import Grid from "../Grid"
import {useEffect, useState} from "react";
import Switch from '@mui/material/Switch';
import "../../../CSS/Reusable/ContractPayments.scss";
import {useParams} from "react-router-dom";
import {servicesPayed} from "../../../Services/MainViews/Contracts/contractsService";
import Label from "../Label";
import {getPaymentsByContractId} from "../../../Services/paymentsService";
import {mapCurrencyName} from "../../../dataMappingUtilHelper"
import {fixSystemDate, formatNumberWithDots} from "../../../globalHelper"

export default function ContractPayments({payments, showAmounts = false, showServices = false, contractFormPayment}) {


    let params = useParams();

    const [shownPaymentData, setPaymentData] = useState({});
    const [loading, setLoading] = useState(false);
    const [paymentsSorted, setPaymentsSorted] = useState({});

    const settingPayments = async () => {

        let _payments = payments;

        if (params.id) {
            _payments = await getPaymentsByContractId(params.id);
            console.log(_payments.status);
            
            
        }

        if (_payments !== undefined && _payments !== null ) {
            console.log(_payments);
            
            let paymentYearsList = {};
            _payments.map(payment => {
                let formatDate = fixSystemDate(payment.expectedPaymentDate);
                let yearDate = formatDate.getFullYear();
                if (paymentYearsList[yearDate] !== yearDate) {
                    paymentYearsList[yearDate] = [];
                }
            })
            _payments.map(payment => {
                let formatDate = fixSystemDate(payment.expectedPaymentDate);
                let yearDate = formatDate.getFullYear();

                for (const [key, value] of Object.entries(paymentYearsList)) {

                    let testVar = yearDate.toString();
                    if (key === testVar) {
                        value.push(payment);
                    }
                }
            })

            Object.keys(paymentYearsList).forEach(function(key, index) {
                paymentYearsList[key].sort(function(a,b){
                    return fixSystemDate(a.expectedPaymentDate) - fixSystemDate(b.expectedPaymentDate);
                  });
              });

            setPaymentsSorted(paymentYearsList);
        }
        else{
            setPaymentsSorted({});
        }

    }

    useEffect(() => {
        if (payments) {
            settingPayments();
        }
    }, [loading, payments])


    const paymentsSortedList = paymentsSorted;
    const array = Object.keys(paymentsSortedList)
    if (paymentsSortedList !== '') {

        array.map((paymentYear) => {
            return (<h1>{paymentYear}</h1>)
        })
    }

    const handleChange = (event, year) => {
        let checked = event.target.checked;
        let paymentId = event.target.id;
        let areMonthServicesPayedYet = (event.target.value === 'true');
        let changeAreMonthServicesPayed = !areMonthServicesPayedYet;

        servicesPayed(paymentId, changeAreMonthServicesPayed);
        setLoading(!loading);

    };

    const getServiceLabel = (payment) => {
        let year = new Date().getFullYear();
        let month = new Date().getMonth()+1;
        let actualMonthDate = new Date(year+"-"+month+"-1");

        const currentPaymentDate = new Date(payment.expectedPaymentDate);
        
        if ( payment.areMonthServicesPayed) {
            return <Label isPayed showName="Pago"/>
        }
        else if(!payment.areMonthServicesPayed && (currentPaymentDate <= actualMonthDate)){
            return <Label isNotPayed showName="Deuda"/>
        }
    }

    const toggleComment = (e,id) => {
        e.preventDefault();
        setPaymentData(prevPaymentData => ({
        ...prevPaymentData,
        [id]: !prevPaymentData[id]
      }));
    };

    return (
        <div style={{"textAlign": "center"}} className="PaymentContainer">
            <Grid columns="3">
                {
                    Object.keys(paymentsSorted).map((paymentYear,index) => {
                        if (contractFormPayment) {
                            shownPaymentData[paymentYear] = true;
                        } 
                        return (<div className="ContractServices"><DataContainerCard>
                            <Grid columns='2'>
                            <h1 key={paymentYear} style={{"fontSize": "1.6rem", "color": "#081382",'justifySelf':'end'}}>{paymentYear}</h1>
                            {!contractFormPayment ? <button onClick={(e) => toggleComment(e,paymentYear)} style={{'justifySelf':'start'}}>{shownPaymentData[paymentYear] ? '▴':'▾'}</button>:null}
                            </Grid>
                            {shownPaymentData[paymentYear] ?  paymentsSorted[paymentYear].map(payment => {
                                
                                const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
                                    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
                                ];
                                const d = fixSystemDate(payment.expectedPaymentDate);
                                if (payment.expectedPaymentDate !== undefined) {
                                    console.log(payment);
                                    return (
                                        <div className="PaymentsMonths" key={payment.id}>
                                            <h2>{monthNames[d.getMonth()]}</h2>
                                            {!showServices && showAmounts ? (
                                                <h1 style={{'margin-left': '1px'}}>{mapCurrencyName(payment.currency)}{formatNumberWithDots(payment.amount)}</h1>) : <></>}
                                            {showServices && !showAmounts ? (
                                                <h1 style={{'margin-left': '1px'}}>{getServiceLabel(payment)}</h1>): <></>}
                                            {!showAmounts && !showServices ?
                                                (<Switch inputProps={{'aria-label': 'controlled'}}
                                                        onChange={(e) => handleChange(e, paymentYear)}
                                                        id={payment.id.toString()}
                                                        value={payment.areMonthServicesPayed}
                                                        checked={payment.areMonthServicesPayed}/>) : <></>}
                                        </div>
                                    )
                                }
                            }): null}
                        </DataContainerCard>
                        </div>)
                    })
                }
            </Grid>
        </div>
    )

}