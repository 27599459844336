import DataContainerCard from "../DataContainerCard"
import "../../../CSS/Reusable/BookingContractForm.scss";
import Grid from "../Grid"
import DataInput from "../DataInput"
import DataSelectBox from "../DataSelectBox"
import DataSelectBoxInput from "../DataSelectBoxInput"
import AutoCompleteInput from "../AutoCompleteInput";
import DataDayPicker from "../DataDayPicker"
import DataMultiSelectBox from "../DataMultiSelectBox"
import {addNewUser, fetchContacts} from "../../../Services/MainViews/Users/contactsService";
import MyModal from "../MyModal";
import ContractModalForm from "../ContractForm/ContractModalForm";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom"
import {getIcon} from "../../../Services/iconService";
import { logDOM } from "@testing-library/react";
import {existAndReturn} from "../../../globalHelper"
import {
    amenitiesEntries, moneyEntries, operationsEntries,
    propertyTypeEntries,propertyServicesEntries,suburbsEntries, contractOperationsEntries} from "../../../dataEntriesHelper"

export default function SearchesFormStep1({
                                              searchesForm,
                                              setSearchesForm,
                                              onInputChange
                                          }) {

    const [showModal, setShowModal] = useState({show: false});
    const [responseModal, setResponseModal] = useState(false);
    const [myStyle, setMyStyle] = useState(false);
    const [contractModalForm, setContractModalForm] = useState({
        username: '',
        password: '',
        name: '',
        surname: ''
    })

    const internalModalChange = (formData, targetName) => {
        setContractModalForm({
            ...contractModalForm,
            [targetName]: formData
        })
    }
    const modalSubmit = (e) => {
        e.preventDefault();
        const newUser ={
            "username": contractModalForm.username,
            "password": contractModalForm.password,
            "name": contractModalForm.name,
            "surname": contractModalForm.surname,
            "type": "Contact"
        }
        Promise.resolve(addNewUser(newUser)).then((res) => {
            alert("Usuario añadido");
        })
    }
    const handleClick = (id) => {
        setMyStyle(prevState => ({
          ...myStyle,
          [id]: !prevState[id]
        }))
      }

    const handleFilterContacts = (names, type) => {
        let fields = names.split(" ");
        let searchContactDTO = {};
        searchContactDTO.types =type;
        if (fields.length === 1)
            searchContactDTO.fieldOne = fields[0];
        if (fields.length === 2) {
            searchContactDTO.fieldOne = fields[0];
            searchContactDTO.fieldOne = fields[1];
        }

        if (fields.length === 3) {
            searchContactDTO.fieldOne = fields[0];
            searchContactDTO.fieldOne = fields[1];
            searchContactDTO.fieldOne = fields[2];
        }
        return fetchContacts(searchContactDTO);
    }
    
    const modalChildren = <ContractModalForm internalModalChange={internalModalChange}/>;

    return(
        <>
            <form>
                <DataContainerCard  tittle="BUSQUEDAS">
                    <Grid columns="1">
                        <DataDayPicker tittle="Fecha *" placeHolder="Ingrese la fecha"
                                                    ChangeState={onInputChange}
                                                    targetName={"date"}
                                                    defaultValue={new Date(searchesForm.date)}/>
                    </Grid>
                    <Grid columns="2">

                        <DataSelectBox tittle="Tipo de operación *" entries={contractOperationsEntries} ChangeState={onInputChange}
                                            targetName={"operationType"}
                                            defaultValue={existAndReturn(searchesForm, "operationType")}/>
                                                    
                        <DataSelectBox tittle="Tipo de propiedad *" entries={propertyTypeEntries} ChangeState={onInputChange}
                                            targetName={"propertyType"}
                                            defaultValue={existAndReturn(searchesForm, "propertyType")}/>

                        <div    style={{"display":"none"}}><DataInput tittle="Sector" placeHolder=""
                                                type="Number"
                                                ChangeState={onInputChange}
                                                targetName={"sector"}
                                                defaultValue={existAndReturn(searchesForm, "sector")}/></div>
                    </Grid>
                    <Grid columns="3">
                        <DataSelectBox tittle="Barrio *" entries={suburbsEntries} ChangeState={onInputChange}
                                            targetName={"suburbs[0]"}
                                            defaultValue={existAndReturn(searchesForm, "suburbs.[0]")}/>

                        <DataSelectBoxInput type="Number" tittle="Rango de precio min-"
                                                                        entries={moneyEntries} ChangeState={onInputChange}
                                                                        targetName={"minimumValue"} indexValues={["currency", "value"]}
                                                                        defaultValue={existAndReturn(searchesForm, "minimumValue")}/>
                        <DataSelectBoxInput type="Number" tittle="Rango de precio max+"
                                                                        entries={moneyEntries} ChangeState={onInputChange}
                                                                        targetName={"maximumValue"} indexValues={["currency", "value"]}
                                                                        defaultValue={existAndReturn(searchesForm, "maximumValue")}/>
                    </Grid>
                    <Grid columns="2">

                        <AutoCompleteInput id="tenantName" key="tenantName" onChange={(e) => handleFilterContacts(e,['Broker','Contact'])}
                                                ChangeState={onInputChange} targetName={"applicant"}
                                                defaultValue={existAndReturn(searchesForm, "applicant")}
                                                showAttributes={["name", "surname"]}
                                                tittle="Nombre del solicitante *"
                                                placeHolder={"Ingrese nombre y apellido"} required/>

                        <AutoCompleteInput id="tenantName" key="tenantName" onChange={(e) => handleFilterContacts(e,['Broker','Contact'])}
                                                ChangeState={onInputChange} targetName={"manager"}
                                                defaultValue={existAndReturn(searchesForm, "manager")}
                                                showAttributes={["name", "surname"]}
                                                tittle="Nombre del gestor *"
                                                placeHolder={"Ingrese nombre y apellido"} required/>
                                                    
                        <div    style={{"display":"none"}}><DataInput tittle="Teléfono del solicitante" placeHolder="Teléfono del solicitante"
                                                type="Number"
                                                ChangeState={onInputChange}
                                                targetName={"requestPhone"}
                                                defaultValue={existAndReturn(searchesForm, "requestPhone")}/>

                        <DataInput tittle="Mail del solicitante" placeHolder="Mail del solicitante"
                                                ChangeState={onInputChange}
                                                targetName={"requestMail"}
                                                defaultValue={existAndReturn(searchesForm, "requestMail")}/></div>
                    </Grid>
                    <Grid columns="1">
                        <DataInput tittle="Descripcion *" placeHolder=""
                                                        ChangeState={onInputChange}
                                                        targetName={"description"}
                                                        defaultValue={existAndReturn(searchesForm, "description")}
                                                        bigInput/>
                    </Grid>
                </DataContainerCard>
            </form>
        </>
    )
}