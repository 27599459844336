import {useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';
import MyModal from "../Reusable/MyModal";
import CircularLoader from "../Reusable/CircularLoader";
import {createTermsAndConditions, acceptTerms} from "../../Services/MainViews/Login/TandCService";

export default function TandCCheck(props) {

    const location = useLocation();
    const [isSending, setIsSending] = useState(false);
    const [showPopup, setShowPopup] = useState({show: false});

    const [userRole, setUserRole] = useState('');

    const useTnC = createTermsAndConditions(userRole);

    let tandcLocal;
    let settings;
    let settingsObj;

    useEffect(() => {
      tandcLocal = localStorage.getItem('tandc');
      settings = localStorage.getItem('user');
      settingsObj = settings ? JSON.parse(settings) : {};
  
      if (tandcLocal === 'false' && location.pathname !== '/') {
        setUserRole(settingsObj.roles[0])
        setShowPopup({show: true});
        if (location.pathname !== '/roleselector') {
            window.location.href = "/roleselector";
        }
      }
    }, []);
  
    const handleClose = () => {
        let acceptedTermsObject ={
            status:"ACCEPTED"
        }
        setIsSending(true);
        Promise.resolve(acceptTerms(acceptedTermsObject)).then(
            res => {
            if (res && res.message) {
                alert(res.message);
                setIsSending(false);
            } else {
                setIsSending(false)
                localStorage.setItem("tandc", "true");
                setShowPopup({show: false});
            }

        });
    };

    return (
        <div>
            <MyModal modalState={showPopup} modalSubmit={handleClose}  isButtonAble={isSending?false:true} canBeClosedBeforeSubmiting>
                {isSending?
                <div className="font-600 text-center xs:text-xl text-4xl flex flex-col">
                    <h3>Se están aceptando los Terminos y Condiciones</h3>
                    <CircularLoader/>
                </div>
                :<div className="flex flex-col text-center space-y-8">
                    <h1 className="font-800   xs:text-xl sm:text-2xl text-[#081382]">Al confirmar, estás aceptando los <br/>
                    Términos y Condiciones</h1>
                    <p className="text-justify xs:text-base ">{useTnC.terms}</p>
                    <div><label className="font-600 xs:text-lg sm:text-xl"> Debes aceptar los términos y condiciones de uso </label></div>
                </div>}
            </MyModal>
        </div>
    )
}