import "../../CSS/Reusable/Pagination.scss";
import { getIcon } from "../../Services/iconService";
import { useDispatch } from "react-redux";
import { fetchProperties } from "../../Services/MainViews/Properties/propertiesService";
import { getProperties } from "../../Redux/Actions/actionsProperties";

export default function Pagination({ propertiesPerPage, properties, filters }) {
  const dispatch = useDispatch();
  const pageNumbers = Array.from(Array(properties?.totalPages), (_, i) => i + 1);
  let filtersToApply = filters.length > 0 ? filters : null;

  const getAndSetProperties = async (operations, page,size)=>{
    let properties = null;
    properties = await fetchProperties(operations, page, size);

    if (properties && properties !== null && properties !== undefined) {
      dispatch(getProperties(properties));
    }
    else{
      console.log('Error fetching properties or no properties available');
      dispatch(getProperties([]));
    }
  }

  function handleClick(e) {
    getAndSetProperties(filtersToApply, e.target.id - 1, propertiesPerPage)
  }

  function handlePrevNext(e) {
    let pageToApply =
      e.target.name === "Previous" || e.target.outerHTML.includes("M12.707") //M12.707 es parte del Path del SVG, sino a veces rompe si tocas justo sobre la linea
        ? properties.number - 1
        : properties.number + 1;

    if (properties.number < properties.totalPages && properties.number >= 0) {
      getAndSetProperties(filtersToApply, pageToApply, propertiesPerPage)
    }
  }

  return (
    <>
      {properties && !Array.isArray(properties) ? (
        <div className="Pagination">
          <button
            onClick={(e) => handlePrevNext(e)}
            name="Previous"
            className={
              properties.first ? "PrevNextPage disabled" : "PrevNextPage"
            }
            disabled={properties.first}
          >
            {getIcon("leftarrow")}
          </button>
          {pageNumbers?.map((number) => (
            <button
              key={number}
              id={number}
              onClick={(e) => handleClick(e)}
              className={
                number === properties.number + 1 ? "Page active" : "Page"
              }
            >
              {number}
            </button>
          ))}
          <button
            onClick={(e) => handlePrevNext(e)}
            name="Next"
            className={
              properties.last ? "PrevNextPage disabled" : "PrevNextPage"
            }
            disabled={properties.last}
          >
            {getIcon("rightarrow")}
          </button>
        </div>
      ) : (
       ''
      )}
    </>
  );
}
