import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import BackgroudContainer from './BackgroudContainer';
import ButtonSelector from '../Reusable/ButtonSelector';
import CircularLoader from '../Reusable/CircularLoader';
import DataContainerCard from '../Reusable/DataContainerCard';
import DataInput from '../Reusable/DataInput';
import DataSelectBox from '../Reusable/DataSelectBox';
import DataTable from '../Reusable/DataTable/DataTable';
import DoubleConfirmation from '../Reusable/DoubleConfirmation';
import Grid from '../Reusable/Grid';
import Pagination from '../Reusable/PaginationList';

// Services
import { deleteContract, fetchContracts } from '../../Services/MainViews/Contracts/contractsService';

// Helpers
import { suburbsFilter } from '../../dataEntriesHelper';
import { getNeighborhoodName } from '../../dataMappingUtilHelper';
import { existAndReturn,phoneFormateNumber } from '../../globalHelper';
import { property } from 'lodash';

export default function Contracts() {
  const navigate = useNavigate();

  const [currentSelectedContractID, setCurrentSelectedContractID] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [responseModal, setResponseModal] = useState(false);
  const [showModal, setShowModal] = useState({ show: false });
  const [keyword, setKeyword] = useState('');
  const [suburbFilter, setSuburbFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentContracts, setCurrentContracts] = useState({ content: [] });

  const filters = [{
      property: "type",
      value: "FinalContract",
      operator: "EQ",
      logicOperator: "AND"
  }];

  useEffect(() => {
      getContracts(filters, 0, itemsPerPage);
  }, []);

  const getContracts = async (operations, page, size) => {
      setIsLoading(true);
      let contracts = null;
      contracts = await fetchContracts(operations, page, size);
      contracts.content = contracts.content.map(contract => {
            const updatedContract = { ...contract };
        
            // Verificar si `secondPartsEquity[0].user.cellPhone` existe y formatearlo
            if (contract.contractFinal?.secondPartsEquity?.[0]?.user?.cellPhone) {
                updatedContract.contractFinal = {
                    ...contract.contractFinal,
                    secondPartsEquity: [
                        {
                            ...contract.contractFinal.secondPartsEquity[0],
                            user: {
                                ...contract.contractFinal.secondPartsEquity[0].user,
                                cellPhone: phoneFormateNumber(contract.contractFinal.secondPartsEquity[0].user.cellPhone)
                            }
                        },
                        ...contract.contractFinal.secondPartsEquity.slice(1)
                    ]
                };
            }
        
            // Verificar si `ownersEquity[0].user.cellPhone` existe y formatearlo
            if (contract.contractFinal?.ownersEquity?.[0]?.user?.cellPhone) {
                updatedContract.contractFinal = {
                    ...updatedContract.contractFinal,
                    ownersEquity: [
                        {
                            ...contract.contractFinal.ownersEquity[0],
                            user: {
                                ...contract.contractFinal.ownersEquity[0].user,
                                cellPhone: phoneFormateNumber(contract.contractFinal.ownersEquity[0].user.cellPhone)
                            }
                        },
                        ...contract.contractFinal.ownersEquity.slice(1)
                    ]
                };
            }
        
            return updatedContract;
        });
      
      if (contracts && contracts !== null && contracts !== undefined && contracts.content) {
        contracts.content.forEach(contract => {
            if (contract.contractFinal.ownersEquity && contract.contractFinal.ownersEquity[0] && contract.contractFinal.ownersEquity[0].user !== null) {
                contract.contractFinal.ownersEquity[0].user.fullname = `${existAndReturn(contract, "contractFinal.ownersEquity[0].user.name")} ${existAndReturn(contract, "contractFinal.ownersEquity[0].user.surname")}`;
                contract.contractFinal.secondPartsEquity[0].user.fullname = `${existAndReturn(contract, "contractFinal.secondPartsEquity[0].user.name")} ${existAndReturn(contract, "contractFinal.secondPartsEquity[0].user.surname")}`;
            }
            if (contract.property) {
                contract.property.address.suburb = getNeighborhoodName(contract.property.address.suburb)
            }
            contract.id =contract.contractId
            
        });
        setCurrentContracts(contracts);
        setIsLoading(false);
      }
      else{
        console.log('Error fetching contracts or no contracts available');
        setCurrentContracts([]);
        setIsLoading(false);
      }
  };

  const keywordChange = (jsonData) => {
      const keywordValue = Object.values(jsonData)[0];
      setKeyword(keywordValue);
  };

  const suburbChange = (jsonData) => {
      const suburbValue = Object.values(jsonData)[0];
      setSuburbFilter(suburbValue);
  };

  const newFinalContract = () => navigate("/contracts/finalContract");

  const newBookingContract = () => navigate("/contracts/bookingContract");

  const delContract = (contractId) => {
      setShowModal({ show: true });
      setCurrentSelectedContractID(contractId);
  };

  const finallyDelContract = (contractId) => {
    deleteContract(contractId)
    .then((res) => {
        if (res.message) {
            alert(res.message);
        } else {
            getContracts(filters, 0, itemsPerPage);
        }
    });
  };

  const edContract = (contractId,rowComponentType) => {
      if (rowComponentType === "FinalContract") {
          navigate(`/contracts/finalContract/${contractId}`);
      }
  };

  const filteredContracts = currentContracts.content?.property?currentContracts.content
      ?.filter(x => x.property.address.street)
      .filter(x => x.property.address.street.includes(keyword))
      .filter(item => suburbFilter !== '' ? item.property?.address.suburb.includes(suburbFilter) : item):currentContracts?.content;

  const dataTableColumns = [
      { field: 'property.address.street', showName: 'Dirección' },
      { field: 'property.address.houseNumber', showName: 'Unidad' },
      { field: 'property.address.suburb', showName: 'Barrio' },
      { field: 'contractFinal.ownersEquity[0].user.fullname', showName: 'Propietario' },
      { field: 'contractFinal.ownersEquity[0].user.cellPhone', showName: 'Teléfono Propietario' },
      { field: 'contractFinal.secondPartsEquity[0].user.fullname', showName: 'Inquilino' },
      { field: 'contractFinal.secondPartsEquity[0].user.cellPhone', showName: 'Teléfono inquilino' },
  ];

  const actions = [
      { onClick: (e) => delContract(e), showName: 'Eliminar' },
      { onClick: (e,rowComponentType) => edContract(e,rowComponentType), showName: 'Editar' }
  ];

  const operationActions = [
      { onClick: newFinalContract, showName: 'Añadir contrato' },
      { onClick: newBookingContract, showName: 'Añadir reserva' }
  ];

  return (
      <BackgroudContainer>
          <div class="flex justify-between w-full items-center">
              <div class="flex items-start">
                  <DataInput
                      title="Búsqueda por dirección"
                      type="Text"
                      ChangeState={keywordChange}
                      targetName="keyword"
                      defaultValue=""
                      float="left"
                  />
                  <DataSelectBox
                      title="Barrio"
                      entries={suburbsFilter}
                      ChangeState={suburbChange}
                      targetName="userType"
                      defaultValue=""
                  />
              </div>
              <ButtonSelector
                  showName="+ Nueva Operación"
                  actions={operationActions}
                  float="right"
                  blueColor
              />
          </div>
          <DataContainerCard title="CONTRATOS">
              {isLoading ? (
                  <CircularLoader />
              ) : (
                  <Grid columns="1">
                      {filteredContracts?( <DataTable rows={filteredContracts} columns={dataTableColumns} actions={actions} />):null}
                  </Grid>
              )}
              {<DoubleConfirmation
                  modalState={showModal}
                  children={'Si el contrato es eliminado, no podrá recuperarse. Deberás crearlo nuevamente'}
                  className="ContractModalForm"
                  modalSubmit={finallyDelContract}
                  responseModal={responseModal}
                  title={'¡CUIDADO ESTÁ POR ELIMINAR UN CONTRATO!'}
                  currentSelectedID={currentSelectedContractID}
              />}
          </DataContainerCard>
          <div className="Properties_Pagination">
              <Pagination itemsPerPage={itemsPerPage} fetchData={fetchContracts} setList={getContracts} filters={filters} dataChanges={currentContracts} />
          </div>
      </BackgroudContainer>
  );
};
