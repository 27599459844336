import React from 'react';
import { BsFillPeopleFill, BsFillCalendarWeekFill, BsBank2, BsEyeSlash, BsEye, BsFillCaretRightFill, BsFillCaretLeftFill, BsMap, BsThreeDots, BsArrowsFullscreen, BsFillCameraFill } from "react-icons/bs";
import {FiMail, FiLock, FiNavigation} from "react-icons/fi";
import { FaFileContract, FaBath } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { BiBuildingHouse, BiLeftArrowAlt, BiRightArrowAlt, } from "react-icons/bi";
import { IoIosNotificationsOutline, IoIosArrowDown, IoIosSearch, IoIosBed, IoIosVideocam } from "react-icons/io";
import { RiLayoutMasonryLine } from "react-icons/ri";
import { GiHomeGarage, GiFilmSpool } from "react-icons/gi";
import { Md360 } from "react-icons/md"
import { GrCopy } from "react-icons/gr";
import { ImExit, ImCross } from "react-icons/im";
import {AiOutlineUserSwitch}  from "react-icons/ai";
import { ReactComponent as UploadFile } from "../Assets/Icons/uploadFile.svg";
import { ReactComponent as PaginationRightIcon } from '../Assets/pagination_right.svg';
import { ReactComponent as PaginationLeftIcon } from '../Assets/pagination_left.svg';



export function getIcon(icon,color) {
  switch (icon) {
    case "loginmail":
      return <FiMail />;
    case "loginpassword":
      return <FiLock />;
    case "viewingpassword":
      return <BsEye />;
    case "notviewingpassword":
      return <BsEyeSlash />;
    case "home":
      return <BsBank2 />;
    case "calendar":
      return <BsFillCalendarWeekFill />;
    case "contracts":
      return <FaFileContract />;
    case "contacts":
      return <BsFillPeopleFill />;
    case "properties":
      return <BiBuildingHouse />;
    case "logout":
      return <ImExit />;
    case "sidebarclosed":
      return <BsFillCaretRightFill />;
    case "sidebaropen":
      return <BsFillCaretLeftFill />;
    case "notificationbell":
      return <IoIosNotificationsOutline />;
    case "dropdownFilter":
      return <IoIosArrowDown />;
    case "search":
      return <IoIosSearch />;
    case "map":
      return <BsMap />;
    case "leftarrow":
      return <BiLeftArrowAlt />;
    case "rightarrow":
      return <BiRightArrowAlt />;
    case "pagination_leftarrow":
      return <PaginationLeftIcon fill={color}/>;
    case "pagination_rightarrow":
      return <PaginationRightIcon fill={color}/>;
    case "totalSquareMeters":
      return <BsArrowsFullscreen />;
    case "rooms":
      return <RiLayoutMasonryLine />;
    case "bedrooms":
      return <IoIosBed />;
    case "bathrooms":
      return <FaBath />;
    case "garage":
      return <GiHomeGarage />;
    case "threedots":
      return <BsThreeDots />;
    case "photos":
      return <BsFillCameraFill name="photos"/>
    case "videos":
      return <IoIosVideocam name="videos"/>
    case "photos360":
      return < Md360 name="photos360"/>
    case "virtualTour":
      return <GiFilmSpool name="virtualTour"/>
    case "copy":
      return <GrCopy />
    case "deleteX":
      return <ImCross />;
    case "profile":
      return <AiOutlineUserSwitch/>;
    case "uploadFile":
      return <UploadFile/>;
    case "locationIcon":
      return <FaLocationDot/>;
    default:
      return;
  }
}
