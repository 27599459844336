import React from 'react';
import { getIcon } from "../../Services/iconService";

function MultimediaButton({children, buttonName, buttonFn, actualMultimedia}) {

  const multimediaButtonClassname = ''.concat(
    'flex flex-row items-center  text-base font-bold p-3  border-2 rounded-full '
    ,actualMultimedia === buttonName?'text-[#081382] border-[#081382] ':'text-[#5F585A] bg-[#DEDEE3]'
  )

  return (
    <button onClick={(e) => buttonFn(e)} name={buttonName} className={multimediaButtonClassname}>
        <div className='text-[1.875rem] mr-2 pointer-events-none' name={buttonName}>{getIcon(buttonName)}</div>
        {children}
    </button>
  );
}

export default MultimediaButton;