import ButtonBlue from "../..//Reusable/ButtonBlue";
import ButtonStandard from "../..//Reusable/ButtonStandard";
import BackgroudContainer from "../BackgroudContainer";
import Grid from "../../Reusable/Grid";
import DataContainerCard from "../../Reusable/DataContainerCard";
import CircularLoader from "../../Reusable/CircularLoader";
import {useEffect, useState} from "react";
import {
    getContractAddressesForLoggedOwner,
    getContractAddressesForLoggedTenant
} from "../../../Services/MainViews/TenantOwner/tenantOwnerService";
import {useNavigate} from "react-router-dom";
import {devLog, getLoggedUserRole, getSelectedLoggedUserRole} from "../../../globalHelper";
import MyModal from "../../Reusable/MyModal";
import FirstLoginModal from "../../Reusable/Users/FirstLoginModal";
import EmptyStateSVG from "./EmptyState.svg"

export default function TenantOwnerHome() {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [contractAddresses, setContractAddresses] = useState([]);

    const [selectedAddress, setSelectedAddress] = useState(undefined);

    const [selectedContractId, setSelectedContractId] = useState(undefined);

    const [showModal, setShowModal] = useState({show: false});
    const [responseModal, setResponseModal] = useState(false);
    let cardTest ={"montoTotal":200,"ultimoIngreso":"28,55"};

    const fetchData = () => {
        setIsLoading(true);

        if (getSelectedLoggedUserRole() === "OWNER")
            Promise.resolve(getContractAddressesForLoggedOwner()).then(res => {
                setContractAddresses(mapApiResponse(res));
                setIsLoading(false);
            })
            .catch((e)=>{
                console.log(e);
                setContractAddresses([]);
                setIsLoading(false);
              })
        if(getSelectedLoggedUserRole() === "TENANT")
            Promise.resolve(getContractAddressesForLoggedTenant()).then(res => {
                setContractAddresses(mapApiResponse(res));
                setIsLoading(false);
            })
            .catch((e)=>{
                console.log(e);
                setContractAddresses([]);
                setIsLoading(false);
              })
    }

    useEffect(() => {
        fetchData();
        const user = JSON.parse(localStorage.user);
        if (user.hasAlreadyChangedPassword === false) {
            setShowModal({show: true});
        }
    }, [])

    const navigateToTenantOwnerContractScreen = (contractId) =>{
        if (getSelectedLoggedUserRole() === "OWNER")
            navigate(`owner/contracts/${contractId}`)
        if(getSelectedLoggedUserRole() === "TENANT")
            navigate(`tenant/contracts/${contractId}`)

    }

    const mapApiResponse = (res) =>{
        if (res !== undefined && res !== null) {
            let streets = res.map(r => r.address.street);
            streets = streets.filter((item, index) => streets.indexOf(item) === index);
            let results = []
            streets.forEach(s=>{
                let values = res.filter(r=>r.address.street===s).map(r=>({houseNumber:r.address.houseNumber,contractId:r.contract.contractId}));
                results.push({street:s,values})
            });
            return results;
        }
    }

    const modalFunction =()=>{
        let newLocalVars = JSON.parse(localStorage.user);
        newLocalVars.hasAlreadyChangedPassword = true;
        localStorage.setItem("user", JSON.stringify(newLocalVars));
    }
    
    devLog(true, "[Binculo] TenantOwnerHome - contractAddresses", contractAddresses)
    devLog(true, "[Binculo] TenantOwnerHome - selectedAddress", selectedAddress)

    return (
        <BackgroudContainer>
            {<FirstLoginModal modalState={showModal} childrenTitle={'Ingrese su nueva contraseña con la que ingreserá al sistema'} className="ContractModalForm"
                modalSubmit={modalFunction} responseModal={responseModal}/>}
            {!selectedContractId ? 
                <DataContainerCard tittle={selectedAddress ? "UNIDADES": "DIRECCIONES"} centerTittle cardType="special">
                    {
                    isLoading ? (<CircularLoader/>) : contractAddresses.length > 0 ? (
                    <Grid columns="1">
                        <div style={{'gap':'1rem','display':'grid'}}>{!selectedAddress ? (contractAddresses.map(address => {
                            return <ButtonStandard float="center" showName={address.street} onClick={() => setSelectedAddress(address)} buttonType={'locationButton'} iconName={'locationIcon'} largeButton={true}/>
                        })) : undefined}</div>
    
                        <div    style={{'gap':'1rem','display':'grid'}}>{selectedAddress ? (selectedAddress.values.map(value => {
                            return <ButtonStandard float="center" showName={value.houseNumber}
                                               onClick={() => navigateToTenantOwnerContractScreen(value.contractId)}    buttonType={'locationButton'} iconName={'locationIcon'} largeButton={true}/>
                        })) : undefined}</div>
                    </Grid>
                    ) : (

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center', 
                            height: '100%',
                            color: '#666',
                            gap: '20px',
                            margin: '30px'
                        }}>
                            <span>Sin propiedades</span>
                            <img src={EmptyStateSVG} alt="No Data" style={{
                                opacity: 0.5,
                                maxWidth: '200px',
                                maxHeight: '200px'
                            }} />
                        </div>
                    )
                    }
                </DataContainerCard> : <></>}
        </BackgroudContainer>
    );
}
