import DataTableInput from "./DataTableInput";

export default function DataTable({rows, columns, actions}) {

    const getGridCols = () => {
        switch (actions ? columns.length + 1 : columns.length){
            case 1:{
                return "grid-cols-1";
            }
            case 2:{
                return "grid-cols-2";
            }
            case 3:{
                return "grid-cols-3";
            }case 4:{
                return "grid-cols-4";
            }case 5:{
                return "grid-cols-5";
            }case 6:{
                return "grid-cols-6";
            }case 7:{
                return "grid-cols-7";
            }case 8:{
                return "grid-cols-8";
            }case 9:{
                return "grid-cols-9";
            }case 10:{
                return "grid-cols-10";
            }case 11:{
                return "grid-cols-11";
            }case 12:{
                return "grid-cols-12";
            }        
            default:{
                return ""
            }
        }
    }

    const getClases = () =>{
        return "grid " + getGridCols() + " textCenter titlesTableMobile";
    }

    const showDataTable =()=>{
        if (rows && rows !== undefined && rows.length > 0) {
            return rows.map(((row) => {
                return <DataTableInput id={row.id} key={row.id} row={row} columns={columns} gridCols={getGridCols()} actions={actions}/>
            }))
        }
        else{
            return  <label className="ml-auto mr-auto label-whitout-data "><h1>No hay datos aún.</h1></label>
        }
    }

    return (
        <div className="mobileDataTable">

            <div className={getClases()}>
                {columns.map((column, i) => {
                    const isSingleColumn = columns.length === 1;
                    const labelClass = `text-center _text-ellipsis ${isSingleColumn ? '_single-column' : ''}`;
                    
                    return (
                        <label className={labelClass} key={i} title={column.showName}>
                            {column.showName}
                        </label>
                    );
                })}
            </div>

            {showDataTable()}
        </div>
    )
}