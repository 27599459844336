import _ from "lodash";
import CryptoJS from 'crypto-js';
import {TandCCheckStatus} from "./Services/MainViews/Login/TandCService";
import {getUser} from "./Services/MainViews/Users/contactsService";
import useCopyToClipboard from './Hooks/useCopyToClipboard';
import {phonePrefixEntries} from "./dataEntriesHelper"

export function getValueFromObject(object, dotNotationStringPath) {
    if (dotNotationStringPath.includes('.')) {
        return _.get(object, dotNotationStringPath);
    }
    return object[dotNotationStringPath];
}

export function setJsonFromDotedValue(json, dotNotationStringPath, value) {
    if (dotNotationStringPath.includes('.')) {
        return _.set(json, dotNotationStringPath, value);
    }
    if (!dotNotationStringPath.includes('.')) {
        return _.set(json, dotNotationStringPath, value);
    }
    return json[dotNotationStringPath] = value;
}

export function unionJsonValue(json, json2,keepOriginalState=false) {
    if (keepOriginalState) {
        const jsonCopy = _.cloneDeep(json);
        return _.merge(jsonCopy, json2)
    }
    else{
        return _.merge(json, json2)
    }
    
}

export function exist(json, path) {
    return json && _.has(json, path);
}

export function existAndReturn(json, path) {
    return exist(json, path) ? getValueFromObject(json, path) : null;
}

export function existAndReturnPath(json, path) {
    return exist(json, path) ? path : null;
}

/*export function getLoggedUserRole() {
    return JSON.parse(localStorage.user).roles[0];
}

export function getLoggedUserRoles() {
    return JSON.parse(localStorage.user).roles;
}*/

export function getLoggedUserRoles() {
    let roles = JSON.parse(localStorage.user).roles;
    let uniqueRoles = [...new Set(roles)];
    return uniqueRoles;
}

export function setSelectedLoggedUserRole(role) {
    return localStorage.setItem("selectedRole", role);
}

export function getSelectedLoggedUserRole() {
    return localStorage.selectedRole;
}

export function isUserLoggedIn() {
    return localStorage.getItem("token")
}

export function errorHandles(e) {
    const mensajeCompleto = e.response?.data?.message || "Ha ocurrido un error";
    const regex = /\[BaredesBaires\] \[ERROR-\d+\] (.*)/;
    const match = mensajeCompleto.match(regex);
    const mensajeError = match ? match[1].trim() : mensajeCompleto;
    
    alert(mensajeError);
}


export function authorizationCheck(e) {
    if (e.response.status === 403) {
        TandCCheckStatus(e)
    }
    else if(e.response.status === 400){
        errorHandles(e)
    }
    /*else if ((e.response.status === 403 || e.response.status === 401) && e.response.data.errorCode !== '#001') {
        localStorage.removeItem("token");
        window.location.href = "/";
    }*/
    return e
}


// Formated for Argentina Only

//Agregar 0
export function pad(n) {
    return (n < 10) ? ("0" + n) : n;
}

//Agregar puntos en las milesimas
export function formatNumberWithDots(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function formatedDate(date) {
    const apiDate = fixSystemDate(date);
    const day = apiDate.getDate();
    const month = apiDate.getMonth() + 1;
    const zeroMonth = pad(month);
    const year = apiDate.getFullYear();
    return day + '-' + zeroMonth + '-' + year;
}

export function formatedDateToSend(date) {

    const formatedDate = new Date(date);

    const year = formatedDate.getFullYear();
    const month = (formatedDate.getMonth() + 1).toString().padStart(2, '0');
    const day = formatedDate.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`
}

export function fixSystemDate(date){
    let apiDate = new Date(date);
    apiDate.setDate(apiDate.getDate() + 1);
    return apiDate;
}

export function nullToZero(e) {
    if (e === null) {
        return e = 0;
    } else {
        return e;
    }
}

const isDevelopment = process.env.NODE_ENV === 'development';

export const devLog = (enableLog = false, ...args) => {
    const environment = process.env.REACT_APP_ENVIRONMENT;

    if ((environment === 'DEVELOPMENT' || environment === 'SANDBOX') && enableLog) {
        console.log (...args);
    }
};

/** Convierte las imagenes  a base 64*/

export const convertBase64 = (files, form, setForm, type, path, setLoading, onlyOne) => {
    const regexSlash = /\/(.*)/;
    setLoading(true);

    // Función de conversión individual
    const processFile = (file, isImage) => {
        const fileType = file.type.match(regexSlash)?.[1];

        // Validación de tipo de archivo
        if (isImage && !['jpeg', 'png', 'jpg'].includes(fileType)) {
            alert('Solo se permiten imágenes');
            return;
        }
        if (!isImage && fileType !== 'pdf') {
            alert('Solo se permiten archivos pdf');
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function () {
            const regexAll = /[^\\]*\.(\w+)$/;
            const extension = file.name.match(regexAll)?.[1];
            const base64 = reader.result;

            if (isImage) {
                if (onlyOne) {
                    // Carga una sola imagen como 'profileImage'
                    const newImage = {
                        name: 'profileImage',
                        extension,
                        contentType: file.type,
                        base64Code: base64
                    };
                    setForm(prevForm => ({ ...prevForm, image: newImage }));
                } else if (form.images?.length < 8) {
                    // Agregar imágenes al array
                    const newImage = {
                        name: `image${form.images.length + 1}`,
                        extension,
                        contentType: file.type,
                        base64Code: base64
                    };
                    setForm(prevForm => ({
                        ...prevForm,
                        images: [...(prevForm.images || []), newImage]
                    }));
                } else {
                    alert('No pueden ser más de ocho archivos');
                }
            } else {
                // Carga de archivos PDF
                const newPdf = {
                    name: path,
                    extension,
                    contentType: file.type,
                    base64Code: base64
                };
                setForm(prevForm => ({ ...prevForm, [path]: newPdf }));
            }
        };
    };

    // Determinar si es imagen o archivo
    const isImage = type === 'image';

    // Procesar todos los archivos
    Array.from(files).forEach(file => processFile(file, isImage));

    setLoading(false);
};
/**Calcula cuantos meses hay entre dos fechas */
export function monthsBetween(date1, date2) {
 
    date1 = new Date(date1);
    date2 = new Date(date2);

    if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
        console.log("Ambos argumentos deben ser fechas válidas.");
        return null; // Retorna null en caso de error
    }

    if (date1 > date2) {
        [date1, date2] = [date2, date1];
    }

    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();
    const month1 = date1.getMonth();
    const month2 = date2.getMonth();

    return (year2 - year1) * 12 + (month2 - month1);
}
/**Calcula el porcentaje total de un contrato */
export function calculateTotalRent(initialRent, incrementPercentage, frequency, months) {
    let total = 0;
    let currentRent = initialRent;
    for (let month = 1; month <= months; month++) {
        if (month > 1 && (month - 1) % frequency === 0) {
            currentRent += currentRent * (incrementPercentage / 100);
        }
        total += currentRent;
    }

    return total+initialRent;
}

export const CapitalizeText = (text ) => {
    if (text !== undefined && text !== null) {
        return text.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());
    }
    else{
        return ''
    }
      
};

export function encryptData(data, secretKey) {
    const encrypted = CryptoJS.AES.encrypt(data, secretKey).toString(CryptoJS.format.OpenSSL);
    return base64URLEncode(encrypted);
  }
  
export function decryptData(encryptedData, secretKey) {
    const decrypted = CryptoJS.AES.decrypt(base64URLDecode(encryptedData), secretKey).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }
  
  function base64URLEncode(str) {
    return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }
  
  function base64URLDecode(str) {
    str = str.replace(/-/g, '+').replace(/_/g, '/');
    const padding = str.length % 4;
    if (padding) {
      str += '='.repeat(4 - padding);
    }
    return str;
}


const copyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        alert('Texto copiado al portapapeles');
      })
      .catch(error => {
        alert('Hubo un problema al copiar: ' + error);
      });
};
const openNewTab = (url) => {
    window.open(url, '_blank');
};
const openActualTab = (url) => {
    window.open(url);
};
export const exportProperty = async (propertyId, exportType) =>{
    const secondDomainURL = process.env.REACT_APP_SECOND_DOMAIN;
    const secondDomainProtocol = process.env.REACT_APP_SECOND_DOMAIN_PROTOCOL;
    const secretKey = process.env.REACT_APP_PROPERTY_SECRET_KEY;

    const localUser = localStorage.getItem('user');
    const localUserObj = localUser ? JSON.parse(localUser) : {};

    const actualuser = await getUser(localUserObj.id)

    const paramsToSend = {
      userFullName:actualuser.name+(actualuser.surname!==null?'+'+actualuser.surname:''),
      userMail:actualuser.username,
      userPhone:actualuser.cellPhone!==null?actualuser.cellPhone:'Sin numero'
    }

    const encryptedId = encryptData(propertyId.toString(), secretKey);

    const newPropertyExportURL = secondDomainProtocol+'://'+secondDomainURL+'/property/'+encryptedId+'?exporterName='+paramsToSend.userFullName+'&'+'exporterPhone='+paramsToSend.userPhone+'&'+'exporterMail='+paramsToSend.userMail

    console.log(newPropertyExportURL);
    if (exportType === "copyClipboard") {
        return newPropertyExportURL; // Devuelve el texto generado
      }
    else if(exportType === 'openNewTab'){
        openNewTab(newPropertyExportURL)
    }
    else if(exportType === 'openActualTab'){
        window.location.href = newPropertyExportURL; 
    }
    else{
        alert('Copiar: '+newPropertyExportURL);
    }  

  }
  /**Generar contraseña random**/
  export const passwordRandomizer =(length)=> {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
    let password = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      password += chars[randomIndex];
    }
  
    return password;
  }
/** Ajustar los campos de la tabla al llegar al maximo de height**/
export const adjustLabelWhiteSpace =(label)=> {
    const parent = label.parentElement;
  
    if (label.scrollHeight > parent.clientHeight) {
      label.style.whiteSpace = 'nowrap';
    } else {
      label.style.whiteSpace = 'normal';
    }
  }

/**Fn para añadir espacio y + a los numeros telefonicos en las vistas */
export function phoneFormateNumber(cellPhone) {
    // No hacer nada si el número es null o undefined
    if (!cellPhone) return cellPhone;

    // Comprobar si el número ya está en el formato correcto
    const formattedPattern = /^\+\d{1,3} \d+$/; // Formato esperado: +NNN NNNNNNNNNN
    if (formattedPattern.test(cellPhone)) {
        return cellPhone; // Si ya está formateado, no hacemos nada
    }

    // Buscar el prefijo que coincida
    const matchedPrefix = phonePrefixEntries.find(entry => 
        cellPhone.startsWith(entry.value)
    );

    if (matchedPrefix) {
        // Si encontramos un prefijo, formateamos el número
        const prefixLength = matchedPrefix.value.length;
        const prefix = matchedPrefix.value;
        const phoneNumber = cellPhone.substring(prefixLength);

        // Devolver el número con el '+' y el espacio
        return `+${prefix} ${phoneNumber}`;
    }

    // Si no encontramos un prefijo, asumimos que el número es incorrecto o falta un prefijo
    return cellPhone;
}

/**Fn para añadir prefijo solo y solo si no tiene prefijos a los numeros en los formularios */
export function addPrefixIfMissing(phoneNumber) {
    // Verificar si el número ya tiene alguno de los prefijos
    for (const entry of phonePrefixEntries) {
        const prefix = entry.value.replace("+", ""); // Quitamos el "+" para comparar
        if (phoneNumber?.startsWith(prefix) && phoneNumber !==null && phoneNumber !==undefined) {
            // Si el número ya tiene un prefijo, devolver el número tal cual
            return phoneNumber;
        }
    }

    // Si no tiene prefijo, asignar un prefijo por defecto, por ejemplo, Argentina (+54)
    const defaultPrefix = phonePrefixEntries.find(entry => entry.name === "AR").value.replace("+", "");
    return defaultPrefix + phoneNumber;
} 