import axios from "axios";
import authHeaderService from "../../authHeaderService";
import * as L from "leaflet";
import {logout} from "../Login/authLoginService";
import {authorizationCheck, devLog} from "../../../globalHelper";
import {TandCCheckStatus} from "../Login/TandCService";


export async function getProperty(id){
  return await axios
  .get(
      `${process.env.REACT_APP_API_URL}/api/properties/${id}`,
      authHeaderService()
  )
  .then((res) => {
      return res.data;
  })
  .catch((e) => {
    if(e.response){
      devLog(true, e.response.status)
      authorizationCheck(e);
      return  e.response.data;
    }
    else{
        console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
    }
  });
};


export async function fetchProperties(operations, page = 0, size = 10) {
  return await axios
    .put(
      `${process.env.REACT_APP_API_URL}/api/properties`,
      {
        operations: Array.isArray(operations) && operations.length > 0 ? operations : null,
        paging: { page, size },
      },
      authHeaderService()
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      if(e.response){
        devLog(true, e.response.status)
        authorizationCheck(e);
        return  e.response.data;
      }
      else{
          console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
      }
    });
}

export async function fetchPropertyById(id){
  return await axios 
  .get(
    `${process.env.REACT_APP_API_URL}/api/properties/${id}`,
    authHeaderService()
  )
  .then((res) => {
    return res.data;
  })
  .catch((e) => {
    if(e.response){
      devLog(true, e.response.status)
      authorizationCheck(e);
      return  e.response.data;
    }
    else{
        console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
    }
  });
}

export function addPropertiesFilter(property, value, operator) {
  return {
    property: property,
    value: value,
    operator: operator,
  };
}

export async function addNewProperty(property,catchmentCommission) {
  devLog(true, "[Binculo] add new property: ", property,catchmentCommission)
  return await axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/properties`,
      {property:property,
      catchmentCommission:catchmentCommission},
      authHeaderService()
    )
    .then((res) => {
      return res
    })
    .catch((e) => {
      if(e.response){
        devLog(true, e.response.status)
        authorizationCheck(e);
        return  e.response.data;
      }
      else{
          console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
      }
    });
}

export async function updateProperty(property) {
    return await axios
        .put(
            `${process.env.REACT_APP_API_URL}/api/properties/${property.id}`,
            property,
            authHeaderService()
        )
        .then((res) => {
            return res
        })
        .catch((e) => {
          if(e.response){
            devLog(true, e.response.status)
            authorizationCheck(e);
            return  e.response.data;
          }
          else{
              console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
          }
        });
}


export async function fetchPropertyGeocoding(lat, lon) {
  let config = {
    method: 'get',
    url: `https://api.geoapify.com/v1/geocode/reverse?lat=${lat}&lon=${lon}&apiKey=${process.env.REACT_APP_API_GEOAPIFY_APIKEY}`,
    headers: {}
  };

  return await axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      if(e.response){
        devLog(true, e.response.status)
        authorizationCheck(e);
        return  e.response.data;
      }
      else{
          console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
      }
    });
}

export const LeafMarker = L.Icon.extend({
  options: {},
});

export const greenMarker = new LeafMarker({
  iconUrl: "https://chart.apis.google.com/chart?chst=d_map_pin_letter_withshadow&chld=%E2%80%A2|00FF00&chf=a,s,ee00FFFF",
  iconSize: [40,37],
  iconAnchor: [20,37],
});

export const redMarker = new LeafMarker({
  iconUrl:
    "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000&chf=a,s,ee00FFFF",
    iconSize: [21,34],
    iconAnchor: [10.5,34],
});

export async function deleteProperty(id) {
  return await axios
      .delete(
          `${process.env.REACT_APP_API_URL}/api/properties/${id}`,
          authHeaderService()
      )
      .then((res) => {
          return res.data;
      })
      .catch((e) => {
        if(e.response){
          devLog(true, e.response.status)
          authorizationCheck(e);
          return  e.response.data;
        }
        else{
            console.log('No hay respuesta. Posible problema de conexion con el servidor / No response. Possible connection problem with the server');
        }
      });
}